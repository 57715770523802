import React, { useState } from 'react'
import { apiBroker, apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button'
import { t, tString } from 'ui/components/i18n/i18n'
import { sosUser } from 'ui/state'
import { sosNewQuote } from 'ui/pages/new-quote/state'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { l } from 'ui/lib/lodashImports'
import { sosToast } from 'common/components/toast'

const tPrefix = 'page.shipmentProfile.broker.shipmentInteractionButtons'

type ApiCalls = 'cancellingShipment' | 'unbookingShipment'

export const BrokerShipmentInteractionButtons = (props: {
	shipment: apiTypes.BrokerShipmentResponse
	setIsUnbookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}): React.ReactElement => {
	const [runningApiCalls, updateRunningApiCalls] = useState<ApiCalls[]>([])
	const { shipment, setIsUnbookCarrierModalOpen } = props

	return (
		<>
			{shipment.shipmentStatus !== 'booked' &&
				shipment.shipmentStatus !== 'void' && (
					<>
						<Button
							testId={'cancel-shipment-button'}
							color='red'
							onClick={async () => {
								updateRunningApiCalls([
									...runningApiCalls,
									'cancellingShipment',
								])
								const updatedShipment = l.cloneDeep(shipment)
								updatedShipment.shipmentStatus = 'void'
								const updateResult = await apiBroker.updateBrokerShipment(
									() => {},
									updatedShipment.id,
									updatedShipment,
									sosUser.getSos().getState().selectedClientConfig?.id,
								)
								if (updateResult.data) {
									sosShipmentProfileBroker.setShipmentReference(
										updateResult.data,
									)
								} else if (updateResult.error) {
									sosToast.sendApiErrorResponseToast(
										updateResult,
										tString('cancellingShipmentError', tPrefix),
									)
								}
								updateRunningApiCalls(
									l.without(runningApiCalls, 'cancellingShipment'),
								)
							}}
							isSpinning={runningApiCalls.indexOf('cancellingShipment') > -1}
						>
							{t('cancelShipment', tPrefix)}
						</Button>
						<Button
							testId={'edit-shipment-button'}
							color={'blue'}
							onClick={() =>
								sosNewQuote.navigateToNewQuote({
									shipment_id: shipment.id,
								})
							}
						>
							{t('editShipment', tPrefix)}
						</Button>
					</>
				)}
			{shipment.shipmentStatus === 'booked' && (
				<Button
					testId={'unbook-shipment-button'}
					color={'red'}
					onClick={async () => {
						if (shipment.bookedRate?.bookMethod === 'manual') {
							setIsUnbookCarrierModalOpen(true)
						} else {
							updateRunningApiCalls([...runningApiCalls, 'unbookingShipment'])
							const updateResult = await apiBroker.brokerUnbook(() => {},
							shipment.id)
							if (updateResult.data) {
								sosShipmentProfileBroker.setShipmentReference(updateResult.data)
							} else if (updateResult.error) {
								sosToast.sendApiErrorResponseToast(
									updateResult,
									tString('unbookingShipmentError', tPrefix),
								)
							}
							updateRunningApiCalls(
								l.without(runningApiCalls, 'unbookingShipment'),
							)
						}
					}}
					isSpinning={runningApiCalls.indexOf('unbookingShipment') > -1}
				>
					{t('unbookShipment', tPrefix)}
				</Button>
			)}
		</>
	)
}
