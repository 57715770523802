import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { testId } from 'ui/components/i18n/i18n'
import { r } from 'ui/lib/ramdaImports'
import { theme } from 'ui/theme'
import { addClass, addClassIf, Colors } from 'ui/theme/theme'
import { Icon, solidIcons } from '../icon'
import classes from './Button.module.scss'

const defaultProps = {
	color: 'gray',
}

const bootstrapColorClasses = {
	standard: {
		blue: 'btn-primary',
		gray: 'btn-secondary',
		green: 'btn-success',
		red: 'btn-danger',
		orange: 'btn-warning',
		teal: 'btn-info',
		lightGray: 'btn-light',
	},
	outline: {
		blue: 'btn-outline-primary',
		gray: 'btn-outline-secondary',
		green: 'btn-outline-success',
		red: 'btn-outline-danger',
		orange: 'btn-outline-warning',
		teal: 'btn-outline-info',
		lightGray: 'btn-outline-light',
	},
}

export const Button: FC = (props: {
	color?: Colors
	borderColor?: Colors | string
	type?: 'submit' | 'button'
	onClick?: () => void
	children?: React.ReactNode
	testId?: string
	isSpinning?: boolean
	isDisabled?: boolean
	isOutline?: boolean
	isSmall?: boolean
	minWidth?: string
	inline?: boolean
	className?: string
	title?: string
	bootstrapStyles?: boolean
}) => {
	const style: React.CSSProperties = {}
	let className
	if (props.bootstrapStyles) {
		className = 'btn d-flex justify-content-between'
		className = addClassIf(props.isSmall, 'btn-sm', className)

		if (props.isOutline) {
			className = addClassIf(
				bootstrapColorClasses.outline[props.color],
				bootstrapColorClasses.outline[props.color],
				className,
			)
		} else {
			className = addClassIf(
				bootstrapColorClasses.standard[props.color],
				bootstrapColorClasses.standard[props.color],
				className,
			)
		}
		if (props.isDisabled) {
			style.cursor = 'default'
			if (props.isOutline) {
				style.opacity = '1'
			}
		}
		if (props.isSpinning) {
			style.paddingLeft = '2px'
			style.paddingRight = '2px'
		} else if (props.isSmall) {
			style.paddingLeft = '11px'
			style.paddingRight = '11px'
		} else {
			style.paddingLeft = '11.5px'
			style.paddingRight = '11.5px'
		}
	} else {
		className = props.isSmall ? classes.smallButton : classes.button
		className = addClass(theme.getColorButton(props.color), className)
		className = addClass(theme.getTextColor(props.color), className)
		className = addClassIf(
			props.isSpinning || props.isDisabled,
			classes.disabled,
			className,
		)
		className = addClassIf(props.isSpinning, classes.spinningButton, className)
	}
	className = addClassIf(!r.isNil(props.className), props.className, className)

	if (props.minWidth) {
		style.minWidth = props.minWidth
	}
	if (props.inline) {
		style.display = 'inline'
	}
	if (props.borderColor) style.border = `solid 1px ${props.borderColor}`

	return (
		<button
			type={props.type}
			className={className}
			onClick={
				r.not(props.isSpinning) && r.not(props.isDisabled)
					? props.onClick
					: undefined
			}
			data-testid={props.testId}
			data-color={props.color}
			disabled={props.isSpinning || props.isDisabled}
			title={props.title}
			style={style}
		>
			{props.children}

			{props.isSpinning && (
				<div
					className={addClassIf(
						props.isSmall && props.bootstrapStyles,
						classes.smallButtonSpinner,
						classes.spinner,
					)}
					data-testid='button-spinner'
				>
					<Icon icon={solidIcons.faSpinner} spin={true} />
				</div>
			)}
			{props.testId && <div>{testId(props.testId)}</div>}
		</button>
	)
}
Button.defaultProps = defaultProps
