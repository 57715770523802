import React, { useEffect, useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { apiTypes } from 'ui/api'
import { TitledBorderBox } from 'ui/components/common/titled-border-box'
import { t, tCurrency } from 'ui/components/i18n/i18n'
import { tInvoiceStatus } from 'ui/components/i18n/commonTranslations'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import * as classes from './CustomerInvoiceDetails.module.scss'

interface CustomerInvoiceDetails {
	invoiceStatus: apiTypes.InvoiceStatus
	invoiceDate: string
	invoiceDue: string
	totalCharges: string
	totalPaid: string
	totalDue: string
	totalCost: string
	totalProfit: string
	markup: string
}

const defaultCustomerInvoiceDetails: CustomerInvoiceDetails = {
	invoiceStatus: null,
	invoiceDate: null,
	invoiceDue: null,
	totalCharges: null,
	totalPaid: null,
	totalDue: null,
	totalCost: null,
	totalProfit: null,
	markup: null,
}

const tPrefix = 'page.customerInvoice.customerInvoiceDetails'
const currencyInitial = ' USD'

export const CustomerInvoiceDetails: FC = (props: {
	brokerInvoice: apiTypes.BrokerInvoiceResponse
	providerInvoices: apiTypes.ProviderInvoiceResponse[]
	setTotalCost: React.Dispatch<React.SetStateAction<number>> // THIS IS TEMPORARY ONLY AS PER TICKET TMS-3913
	brokerInvoiceCharges: apiTypes.BrokerInvoiceChargeResponse[]
}) => {
	const { brokerInvoice, setTotalCost, brokerInvoiceCharges } = props

	const [customerInvoiceDetails, setCustomerInvoiceDetails] = useState<
		CustomerInvoiceDetails
	>(defaultCustomerInvoiceDetails)

	useEffect(() => {
		if (brokerInvoice) {
			computeCustomerCharges()
		}
	}, [brokerInvoice, brokerInvoiceCharges]) // eslint-disable-line react-hooks/exhaustive-deps

	const computeCustomerCharges = (): void => {
		const totalPrices = brokerInvoiceCharges?.map(
			(charge) => charge.unitPrice * charge.qty,
		)
		const totalCharges = l.sum(totalPrices)
		const totalPaid = 0
		const totalDue = totalCharges - totalPaid

		const providerCost = brokerInvoiceCharges?.map(
			(charge) => charge.providerCost,
		)
		const totalCost = l.sum(providerCost)
		const totalProfit = totalCharges - totalCost
		const markup = 100 * (totalProfit / totalCost) || 0

		setTotalCost(totalCost) // THIS IS TEMPORARY ONLY AS PER TICKET TMS-3913

		setCustomerInvoiceDetails({
			invoiceStatus: brokerInvoice.invoiceStatus,
			invoiceDate: brokerInvoice.invoiceDate || '-',
			invoiceDue: brokerInvoice.invoiceDue || '-',
			totalCharges: tCurrency(totalCharges) + currencyInitial,
			totalPaid: tCurrency(totalPaid) + currencyInitial,
			totalDue: tCurrency(totalDue) + currencyInitial,
			totalCost: tCurrency(totalCost) + currencyInitial,
			totalProfit: tCurrency(totalProfit) + currencyInitial,
			markup: markup === Infinity ? '0%' : markup.toFixed(0) + '%',
		})
	}

	return (
		<div>
			<Spacer height={'5px'} />
			<TitledBorderBox
				title={t('customerInvoiceDetailsTitle', tPrefix)}
				testId={'customer-invoice-details'}
				className={classes.customerInvoiceDetails}
			>
				<div className={classes.tableWrapper}>
					<table>
						<tbody>
							<tr>
								<th>{t('invoiceStatus', tPrefix)}:</th>
								<td>{tInvoiceStatus(customerInvoiceDetails.invoiceStatus)}</td>
							</tr>
							<tr>
								<th>{t('invoiceDate', tPrefix)}:</th>
								<td>{customerInvoiceDetails.invoiceDate}</td>
							</tr>
							<tr>
								<th>{t('invoiceDue', tPrefix)}:</th>
								<td>{customerInvoiceDetails.invoiceDue}</td>
							</tr>
						</tbody>
					</table>

					<table>
						<tbody>
							<tr>
								<th>{t('totalCharges', tPrefix)}:</th>
								<td>{customerInvoiceDetails.totalCharges}</td>
							</tr>
							<tr>
								<th>{t('totalPaid', tPrefix)}:</th>
								<td>{customerInvoiceDetails.totalPaid}</td>
							</tr>
							<tr>
								<th>{t('totalDue', tPrefix)}:</th>
								<td>{customerInvoiceDetails.totalDue}</td>
							</tr>
						</tbody>
					</table>

					<table>
						<tbody>
							<tr>
								<th>{t('totalCost', tPrefix)}:</th>
								<td>{customerInvoiceDetails.totalCost}</td>
							</tr>
							<tr>
								<th>{t('totalProfit', tPrefix)}:</th>
								<td>{customerInvoiceDetails.totalProfit}</td>
							</tr>
							<tr>
								<th>{t('markup', tPrefix)}:</th>
								<td>{customerInvoiceDetails.markup}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</TitledBorderBox>
		</div>
	)
}
