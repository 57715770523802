import { l } from 'ui/lib/lodashImports'
import { apiCommon, apiTypes } from '.'
import { IRequestState } from './requestState'
import WebSocketAsPromised from 'websocket-as-promised'
import { log } from 'ui/lib/log'
import { sosUser } from 'ui/state'
import { ShipmentRequest } from './apiTypes'
import { fireAndForget } from 'ui/lib/async'
import { idx } from 'ui/lib'
import { DateTime } from 'luxon'

// /shipments/{id}?include=tracking
export async function fetchShipment(
	onProgress: (rs: IRequestState<apiTypes.ShipmentResponse>) => void,
	id: string,
	includeTracking?: boolean,
	includeRates?: boolean,
): Promise<IRequestState<apiTypes.ShipmentResponse>> {
	let qs = '?include='
	if (includeTracking) {
		qs += 'tracking,'
	}
	if (includeRates) {
		qs += 'rates'
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		`shipments/${id}${qs}`,
	)
	return result
}

export async function createShipmentFromPayloads(
	onProgress: (rs: IRequestState<apiTypes.ShipmentResponse>) => void,
	payloadIds: string[],
	params?: {
		book?: boolean
		providerName?: string
		serviceLevel?: string
	},
): Promise<IRequestState<apiTypes.ShipmentResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', entireResponse: true, data: { payloadIds } },
		'shipments/from-payloads',
		params,
	)
	return result
}

export async function createShipment(
	data: apiTypes.ShipmentRequest,
	rates: boolean,
	onProgress: (rs: IRequestState<apiTypes.ShipmentResponse>) => void,
): Promise<IRequestState<apiTypes.ShipmentResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data },
		'shipments',
		{ rates },
	)
	return result
}

export async function updateShipment(
	id: string,
	data: apiTypes.ShipmentRequest,
	onProgress: (rs: IRequestState<apiTypes.ShipmentResponse>) => void,
	params?: {
		book?: boolean
		providerName?: string
		serviceLevel?: string
	},
): Promise<IRequestState<apiTypes.ShipmentResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'PUT', data },
		`shipments/${id}`,
		params,
	)
	return result
}
export async function uploadShippingDocument(
	data: apiTypes.ShippingDocumentRequest,
	onProgress: (rs: IRequestState<apiTypes.ShippingDocumentResponse>) => void,
): Promise<IRequestState<apiTypes.ShippingDocumentResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data },
		'shipping-documents',
		{},
	)
	return result
}

export async function fetchShipments(
	onProgress: (rs: IRequestState<apiTypes.ShipmentListResponse>) => void,
	body?: apiTypes.ApiListRequest,
): Promise<IRequestState<apiTypes.ShipmentListResponse>> {
	l.defaults(body, {})
	const result = await apiCommon.apiFetch(
		onProgress,
		{ entireResponse: true, method: 'POST', data: body },
		'shipments/search',
	)

	return result
}

export async function bookRate(
	onProgress: (rs: IRequestState<apiTypes.ShipmentResponse>) => void,
	shipmentId: string,
	rateId: string,
	method: 'api' | 'manual',
): Promise<IRequestState<apiTypes.ShipmentResponse>> {
	const data = {
		rateId,
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data },
		`shipments/${shipmentId}/book?bookMethod=${method}`,
	)

	return result
}

export async function rateShipment(
	onProgress: (rs: IRequestState<apiTypes.ShipmentResponse>) => void,
	shipmentId: string,
): Promise<IRequestState<apiTypes.ShipmentResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST' },
		`shipments/${shipmentId}/quote`,
	)
}

let quickRateWebSocketRequestId = 1000
let shipmentRateWebSocketRequestId = 2000

const internalServerErrorRetryNumber = 2

export async function trickleRateShipment(
	onProgressRate: (websocketData: apiTypes.RateResponse) => void,
	onProgressRatingError: (websocketData: apiTypes.RatingError) => void,
	onProgressMessage: (websocketData: string) => void,
	onMajorError: () => void,
	shipmentId: string,
	websocketUrl: string,
	retries = 0,
): Promise<void> {
	const websocket = new WebSocketAsPromised(websocketUrl)

	shipmentRateWebSocketRequestId++
	const requestId =
		'' + shipmentRateWebSocketRequestId + DateTime.local().toISO() // datestring to make sure the requestID is definitely unique
	websocket.onMessage.addListener((message) => {
		const m = JSON.parse(message)
		if (idx(() => m.message.toLowerCase().includes('internal server error'))) {
			if (retries < internalServerErrorRetryNumber) {
				retries++
				fireAndForget(
					() =>
						trickleRateShipment(
							onProgressRate,
							onProgressRatingError,
							onProgressMessage,
							onMajorError,
							shipmentId,
							websocketUrl,
							retries,
						),
					`trickle rating retry ${retries}`,
				)
			} else {
				onMajorError()
			}
			return
		}
		if (m.requestId !== requestId) {
			return // This is for a different request, reject it
		}

		if (m.type === 'Rate') {
			onProgressRate(m.data)
		}

		if (m.type === 'Error') {
			// TODO display errors?
			log('shipment-profile', 'new rates', 'Error:', m.error)
		}

		if (m.type === 'RatingError') {
			onProgressRatingError(m.data)
		}

		if (m.type === 'Message') {
			onProgressMessage(m.data)
		}
	})

	const requestObject: any = {
		// TODO find way to generate TrickleRequest for swagger
		action: 'getRates',
		requestId: requestId,
		authorization: `Bearer ${sosUser.getAuthToken()}`,
	}

	requestObject.shipmentId = shipmentId
	await websocket.open()
	websocket.send(JSON.stringify(requestObject))
}

export async function quickRate(
	onProgress: (rs: IRequestState<apiTypes.RateResponse>) => void,
	shipment: ShipmentRequest,
	websocketUrl: string,
): Promise<void> {
	const websocket = new WebSocketAsPromised(websocketUrl)

	quickRateWebSocketRequestId++
	websocket.onMessage.addListener((message) => {
		log('quickrate', '', message)
		const m = JSON.parse(message)
		if (m.requestId !== quickRateWebSocketRequestId) {
			log('shipment-profile', 'new rates', 'rejected stale message')
			return // This is for a different request, reject it
		}

		if (m.type === 'Rate') {
			log('shipment-profile', 'new rates', 'Rate:', JSON.stringify(m, null, 2))
			onProgress(m.data)
		}

		if (m.type === 'Error') {
			// TODO display rating errors?
			log('shipment-profile', 'new rates', 'Error:', m.data)
		}

		if (m.type === 'message') {
			log('shipment-profile', 'new rates', 'Message:', m.data)
			// TODO signify beginning or end
		}
	})

	await websocket.open()
	websocket.send(
		JSON.stringify({
			action: 'getRates',
			shipment,
			requestId: quickRateWebSocketRequestId,
			authorization: `Bearer ${sosUser.getAuthToken()}`,
		}),
	)
}

export async function getConnections(
	onProgress: (rs: IRequestState<apiTypes.ConnectionResponse[]>) => void,
	locationId?: string,
): Promise<IRequestState<apiTypes.ConnectionResponse[]>> {
	const url = 'providers/connections'
	const queryParams = locationId ? { locationId } : {}
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
		queryParams,
	)
	return response
}

export async function getConnection(
	onProgress: (rs: IRequestState<apiTypes.ConnectionResponse>) => void,
	connectionId: string,
): Promise<IRequestState<apiTypes.ConnectionResponse>> {
	const url = `providers/connection/${connectionId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
	)
	return response
}

export async function inviteConnections(
	onProgress: (rs: IRequestState<apiTypes.InviteConnectionsResponse>) => void,
	shipmentId: string,
	shipment: ShipmentRequest,
	connectionIds: string[],
	resend?: boolean,
): Promise<IRequestState<apiTypes.InviteConnectionsResponse>> {
	const url = `shipments/${shipmentId}/invite`
	const params: { resend?: boolean } = {}
	if (resend) {
		params.resend = true
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data: {
				shipment,
				connectionIds,
			},
		},
		url,
		params,
	)
	return result
}

export async function updateConnection(
	onProgress: (rs: IRequestState<apiTypes.ConnectionResponse>) => {},
	connectionId: string,
	connection: apiTypes.ConnectionRequest,
): Promise<IRequestState<apiTypes.ConnectionResponse>> {
	const url = `providers/connection/${connectionId}`
	const result = await apiCommon.apiFetch<apiTypes.ConnectionResponse>(
		onProgress,
		{
			method: 'PUT',
			data: connection,
		},
		url,
	)
	return result
}

export async function createConnection(
	onProgress: (rs: IRequestState<apiTypes.ConnectionResponse>) => void,
	connection: apiTypes.ConnectionRequest,
): Promise<IRequestState<apiTypes.ConnectionResponse>> {
	return apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data: connection,
		},
		`providers/connection/`,
	)
}

export async function getSpotQuoteShipment(
	onProgress: (rs: IRequestState<apiTypes.ShipmentResponse>) => void,
	shipmentId: string,
	offerId: string,
): Promise<IRequestState<apiTypes.ShipmentResponse>> {
	const url = `shipments/${shipmentId}/connection`
	const queryParams = { offerId }
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
		queryParams,
	)
	return result
}

export async function spotQuoteRate(
	onProgress: (rs: IRequestState<void>) => void,
	shipmentId: string,
	offerId: string,
	rate: apiTypes.RateRequest,
): Promise<IRequestState<void>> {
	const url = `shipments/${shipmentId}/offer/${offerId}`
	return await apiCommon.apiFetch(
		onProgress,
		{
			method: 'PUT',
			data: rate,
		},
		url,
	)
}

export async function logOfferRate(
	onProgress: (rs: IRequestState<void>) => void,
	shipmentId: string,
	rate: apiTypes.RateRequest,
): Promise<void> {
	const url = `shipments/${shipmentId}/rates`
	await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data: rate,
		},
		url,
	)
}

export async function lookupAddress(
	onProgress: (rs: IRequestState<apiTypes.AddressLookupResponse>) => void,
	zip: string,
	city: string,
	state: string,
): Promise<IRequestState<apiTypes.AddressLookupResponse>> {
	const queryParams = l.omitBy({ zip, city, state }, l.isUndefined)
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'GET' },
		'address-lookup',
		queryParams,
	)
	return result
}

export async function resendInvite(
	onProgress: (rs: IRequestState<apiTypes.ShipmentResponse>) => void,
	shipmentId: string,
	rateId: string,
): Promise<IRequestState<apiTypes.EmptyBody>> {
	return {}
	// TODO MAKE AND USE ENDPOINT FOR THIS
}

export async function exportShipments(
	onProgress: (rs: IRequestState<void>) => void,
	params?: {
		query?: string
		email?: string
	},
): Promise<void> {
	// TODO MAKE AND USE ENDPOINT FOR THIS
}

export async function unbookShipment(
	onProgress: (rs: IRequestState<apiTypes.ShipmentResponse>) => void,
	shipmentId: string,
): Promise<IRequestState<apiTypes.ShipmentResponse>> {
	const url = `shipments/${shipmentId}/unbook`
	return await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
		},
		url,
	)
}

export async function voidTrackingNumbers(
	onProgress: (rs: IRequestState<apiTypes.ShipmentResponse>) => void,
	shipmentId: string,
	trackingNumbers: string[],
): Promise<IRequestState<apiTypes.ShipmentResponse>> {
	const url = `shipments/${shipmentId}/void`
	return await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data: { trackingNumbers },
		},
		url,
	)
}

export async function getShippingDocument(
	onProgress: (rs: IRequestState<apiTypes.ShippingDocumentResponse>) => void,
	shippingDocId,
	includeImages = true,
): Promise<IRequestState<apiTypes.ShippingDocumentResponse>> {
	const url = `shipping-documents/${shippingDocId}`

	return await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
		{ includeImages },
	)
}

export async function getOrder(
	onProgress: (rs: IRequestState<apiTypes.OrderResponse>) => void,
	orderNumber: string,
): Promise<IRequestState<apiTypes.OrderResponse>> {
	const url = `shipments/order/${orderNumber}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
	)

	return response
}
