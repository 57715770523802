import zipcode_to_timezone from 'zipcode-to-timezone'
import { idx } from '../idx'
// NOTE: THIS PACKAGE ONLY WORKS IN THE UNITED STATES

export const zipToTimezone = (zipcode: string): string => {
	let timezone = zipcode_to_timezone.lookup(idx(() => zipcode.split('-')[0]))
	if (!timezone) {
		// guess swanleap time
		timezone = 'America/Chicago'
	}
	return timezone
}
