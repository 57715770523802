import { apiCommon, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'

export async function getInvoicesByShipmentId(
	shipmentId: string,
	sortBy?: string,
	order?: string,
): Promise<IRequestState<apiTypes.InvoicesForShipmentResponse>> {
	const url = `brokers/shipments/${shipmentId}/invoices`

	return await apiCommon.apiFetch(() => {}, { method: 'GET' }, url)
}

type InvoiceNumberResponse = {
	invoiceNumber: string
}

export const fetchInvoiceNumber = async (
	invoiceDate?: string,
): Promise<IRequestState<InvoiceNumberResponse>> => {
	return apiCommon.apiFetch<InvoiceNumberResponse>(
		() => {},
		{ method: 'POST' },
		'/invoiceNumber/generate',
		{ invoiceDate },
	)
}
