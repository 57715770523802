import * as apiTypes from 'ui/api/apiTypes'
import {
	getBottomLevelContainers,
	getGoodsFromContainer,
	pullFromGoods,
} from 'ui/lib/shipments/shipmentUtils'
import { l } from 'ui/lib/lodashImports'
import { idx } from 'ui/lib'
import { INewQuoteCommoditiesForm } from 'ui/pages/new-quote/INewQuoteCommoditiesForm'

export const constructCommoditiesForm = (
	shipment: apiTypes.ShipmentResponse,
): INewQuoteCommoditiesForm[] => {
	const containers = getBottomLevelContainers(shipment)
	return l.map(containers, (c) => {
		const goods = getGoodsFromContainer(c)
		const form: INewQuoteCommoditiesForm = {
			id: c.id,
			count: '' + c.count,
			class: c.class,
			length: '' + c.length,
			width: '' + c.width,
			height: '' + c.height,
			packedWeight: '' + (c.packedWeight || 0),
			description: c.description,
			type: c.type,
			piecesCount: '',
			piecesType: null,
			piecesLength: '',
			piecesWidth: '',
			piecesHeight: '',
			hazmat: '' + l.some(goods, (g) => g.hazmat),
			// stackable: c.stackable, // TODO: Not implemented in API
			nmfc: pullFromGoods(goods, 'nmfcCode'),
			nmfcSub: pullFromGoods(goods, 'nmfcSubCode'),
			hazmatClass: pullFromGoods(goods, 'hazmatClass'),
			unNumber: pullFromGoods(goods, 'hazmatUnCode'),
			packagingGroup: pullFromGoods(
				goods,
				'hazmatPackingGroup',
			) as apiTypes.Good['hazmatPackingGroup'],
			// bol: c.bol, // TODO: pull from Payload
			so: pullFromGoods(goods, 'salesOrder'),
			po: pullFromGoods(goods, 'purchaseOrder'),
			notes: pullFromGoods(goods, 'description'),
		}
		if (idx(() => c.containers[0])) {
			form.piecesCount += c.containers[0].count
			form.piecesType = c.containers[0].type
			form.piecesLength += c.containers[0].length
			form.piecesWidth += c.containers[0].width
			form.piecesHeight += c.containers[0].height
		}
		return form
	})
}
