const parcelCarrier: string[] = ['FedEx Parcel', 'UPS Parcel']

export const mappedParcelCarrier = parcelCarrier.map((value) => ({ value }))

const fedexParcelServiceLevels: string[] = [
	'FedEx Ground Home Delivery',
	'FedEx First Overnight',
	'FedEx Priority Overnight',
	'FedEx 2 Day AM',
	'FedEx Parcel',
	'FedEx Express Saver',
	'FedEx Europe First International Priority',
	'FedEx International Economy',
	'FedEx International First',
	'FedEx International Priority',
	'FedEx Smart Post',
]

export const mappedFedexParcelServiceLevels = fedexParcelServiceLevels.map(
	(value) => ({ value }),
)

const upsParcelServiceLevels: string[] = [
	'UPS Next Day Air Early',
	'UPS Next Day Air Saver',
	'UPS 2nd Day Air A.M.',
	'UPS 3 Day Select',
	'UPS Standard',
	'UPS Worldwide Express',
	'UPS Worldwide Express Plus',
	'UPS Worldwide Expedited',
	'UPS Worldwide Saver',
	'UPS Today Standard',
	'UPS Today Dedicated Courier',
	'UPS Today Intercity',
	'UPS Today Express',
	'UPS Today Express Saver',
	'UPS Access Point Economy',
	'UPS SurePost (USPS) < 1lb',
	'UPS SurePost (USPS) > 1lb',
	'UPS SurePost (USPS) BPM',
	'UPS SurePost (USPS) Media',
]

export const mappedUpsParcelServiceLevels = upsParcelServiceLevels.map(
	(value) => ({ value }),
)
