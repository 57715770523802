import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button'
import {
	FormStacked,
	FormStackedTextInput,
	StackedItem,
} from 'ui/components/form'
import { FormVertical } from 'ui/components/form/FormVertical'
import { FormVerticalCheckbox } from 'ui/components/form/FormVerticalCheckbox'
import { FormVerticalRadio } from 'ui/components/form/FormVerticalRadio'
import { IFormData } from 'ui/components/form/IFormData'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem } from 'ui/components/layout/flexRow'
import { FlexRow } from 'ui/components/layout/flexRow/FlexRow'
import { verticalSeparator } from 'ui/components/layout/flexRow/FlexRow.module.scss'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { ProviderSelector } from 'ui/components/shared/provider-selector'
import { idx } from 'ui/lib/idx'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { HeaderText } from 'ui/pages/spot-quote/MakeOfferPage.tsx'
import { theme } from 'ui/theme'
import { sosMakeOfferPage, tPrefixMakeOffer } from '.'
import { LabelText } from '../new-quote'
import { AddressForm } from '../shipment-profile/shared/components/AddressForm'
import * as classes from './MakeOfferPage.module.scss'
import {
	cancelConnectionUpdate,
	IProviderInformation,
	saveConnectionInfo,
	toggleEditingConnections,
} from './sosMakeOfferPage'

export const ProviderInformationForm: FC = (props: {
	logOffer: boolean
	formData: IFormData<IProviderInformation>
	onProviderSelected: (provider:apiTypes.ProviderResponse)=>void
}) => {
	const state = sos2.useSubscription(sosMakeOfferPage.sos)
	const { providerInformation, savedRate } = state
	const { logOffer } = props

	const formData = l.cloneDeep(props.formData)
	formData.onUpdateForm = sosMakeOfferPage.updateProviderAndConnection

	const billToAddressForm = {
		company: providerInformation?.billTo?.company,
		street1: providerInformation?.billTo?.street1,
		street2: providerInformation?.billTo?.street2,
		city: providerInformation?.billTo?.city,
		state: providerInformation?.billTo?.state,
		zip: providerInformation?.billTo?.zip,
		country: providerInformation?.billTo?.country,
	}

	const readOnly = state?.connection?.id ? !state.editingConnection : null

	return (
		<div>
			<div className={classes.offerDetailsItem}>
				<HeaderText large={true}>
					{idx(() => providerInformation.company) ||
						t('provider', tPrefixMakeOffer)}{' '}
					{t('information', tPrefixMakeOffer)}
				</HeaderText>
				<Spacer />
				{logOffer && (
					<StackedItem
						label={t('company', tPrefixMakeOffer)}
						hideOptional={true}
					>
						<ProviderSelector
							onChange={(provider: apiTypes.ProviderResponse) =>
								{
									if (provider){console.log(provider.mcNumber)}
									sosMakeOfferPage.fillInProviderFormDefaults(provider)
									if (props.onProviderSelected){
										props.onProviderSelected(provider)
									}
								}
							}
							currentlySelectedProvider={
								providerInformation?.company
									? {
											label: providerInformation.company,
											value: providerInformation.providerId,
									  }
									: null
							}
							width={'250px'}
							tPrefix={tPrefixMakeOffer}
						/>
					</StackedItem>
				)}
				<Spacer />
				<FlexRow>
					<FlexItem>
						<FormStacked>
							<div className={classes.offerDetailsProviderInformation}>
								{!logOffer && (
									<FormStackedTextInput field='company' formData={formData} />
								)}
								<FormStackedTextInput field='name' formData={formData} />
								<FormStackedTextInput field='phone' formData={formData} />
								{/*<FormStackedTextInput*/}
								{/*	field='cellPhone'*/}
								{/*	formData={formData}*/}
								{/*	readOnly={readOnly}*/}
								{/*/>*/}
								<FormStackedTextInput field='email' formData={formData} />
								<FormStackedTextInput
									field='mcNumber'
									formData={formData}
									readOnly={readOnly}
								/>
								<FormStackedTextInput
									field='usDotNumber'
									formData={formData}
									readOnly={readOnly}
								/>
								<FormStackedTextInput
									field='scac'
									formData={formData}
									readOnly={readOnly}
								/>
							</div>
						</FormStacked>
						{state?.connection?.id &&
							(state.editingConnection ? (
								<FlexRow>
									<Button
										color='green'
										onClick={saveConnectionInfo}
										testId={'saveConnectionInfo'}
									>
										{t('save', tPrefixMakeOffer)}
									</Button>
									<Button
										color='red'
										onClick={cancelConnectionUpdate}
										testId={'cancelConnectionInfo'}
									>
										{t('cancel', tPrefixMakeOffer)}
									</Button>
								</FlexRow>
							) : (
								<Button
									color='blue'
									onClick={toggleEditingConnections}
									testId={'editConnectionInfo'}
								>
									{t('editConnection', tPrefixMakeOffer)}
								</Button>
							))}
					</FlexItem>
					<div
						className={verticalSeparator + ' ' + theme.getBorderColor('gray')}
					/>
					<FlexItem>
						<FormVertical>
							<StackedItem
								label={t('companyType', tPrefixMakeOffer)}
								hideOptional={true}
							>
								<FormVerticalCheckbox
									field='carrier'
									formData={formData}
									labelSide={'right'}
								/>
								<FormVerticalCheckbox
									field='freightForwarder'
									formData={formData}
									labelSide={'right'}
								/>
								<FormVerticalCheckbox
									field='broker'
									formData={formData}
									labelSide={'right'}
								/>
							</StackedItem>
							{state.providerInformation.broker && (
								<div className={classes.indent}>
									<Spacer />
									<LabelText>
										{t('shipWith', tPrefixMakeOffer + '.shipWith')}
									</LabelText>

									<FormVerticalRadio
										labelSide='right'
										options={['fleet', 'otherCarrier']}
										selected={state.shipWith}
										formData={formData}
										onChange={(selectedOption: any) => {
											sosMakeOfferPage.setShipWith(selectedOption)
										}}
									/>
									{state.shipWith === 'otherCarrier' && (
										<div className={classes.indent}>
											<LabelText>
												{t('billToAddress', tPrefixMakeOffer)}
											</LabelText>
											<Spacer />
											<AddressForm
												mode={
													savedRate.offerStatus === 'active'
														? 'edit'
														: 'readonly'
												}
												form={billToAddressForm}
												exclude={['email', 'phone', 'contactName']}
												onUpdateForm={(key, newVal) =>
													sosMakeOfferPage.updateProviderInformationBillToForm(
														key,
														newVal,
													)
												}
												embedded={true}
											/>
										</div>
									)}
								</div>
							)}
							<Spacer />

							<StackedItem
								label={t('mode', tPrefixMakeOffer)}
								hideOptional={true}
							>
								<FormVerticalRadio
									labelSide='right'
									options={['truckload', 'ltlVolume']}
									formData={formData}
									selected={state.shipmentIs}
									onChange={(selectedOption: any) => {
										sosMakeOfferPage.setShipmentIs(selectedOption)
									}}
								/>
							</StackedItem>

							<Spacer />
							{/* <FormVerticalCheckbox         //Removed until this functionality works in the API
								field='notifyByEmail'
								formData={formData}
								labelSide={'right'}
							/> */}
						</FormVertical>
					</FlexItem>
				</FlexRow>
			</div>
		</div>
	)
}
