import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button, Navbar } from 'react-bootstrap'
import { t as translate } from 'ui/components/i18n/i18n'
import { sosUser } from 'ui/state'
import './Header.scss'

export const Header: FC = () => {
	const logoutUser = async (): Promise<void> => {
		await sosUser.logout().then(() => {
			window.location.href = 'login/index.html'
		})
	}

	return (
		<div className='header-wrapper bootstrap-wrapper'>
			<Navbar bg='light' expand='sm' className='p-0'>
				<Navbar.Brand className='logo' href='/'>
					<img
						src='/images/SwanLeapLogo2020.png'
						alt='SwanLeap logo'
						height={30}
					/>
				</Navbar.Brand>
				{sosUser.getLoginMode() === 'loggedIn' && (
					<div className='navbar-nav p-0 container-fluid justify-content-end'>
						<Button
							variant='primary'
							size={'sm'}
							id={'logout-button'}
							onClick={logoutUser}
						>
							<FontAwesomeIcon icon={faSignOutAlt} className='mr-2' />
							{translate('logout', 'pages.layout')}
						</Button>
					</div>
				)}
			</Navbar>
		</div>
	)
}
