import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { Accessorials } from 'ui/components/shared/accessorials'
import { PackingSlipUpload } from 'ui/components/shared/PackingSlipUpload'
import { ShipmentStopCard } from 'ui/components/shared/ShipmentStopCard'
import { idx } from 'ui/lib'
import { fireAndForget } from 'ui/lib/async'
import { getParams } from 'ui/lib/state/queryParams'
import { sos2 } from 'ui/lib/state/sos2'
import {
	deliveryAccessorials,
	pickupAccessorials,
} from 'ui/pages/new-quote/accessorials'
import { ContactInfo } from 'ui/pages/new-quote/ContactInfo'
import classes from 'ui/pages/new-quote/newQuotePage.module.scss'
import {
	IStateNewQuote,
	setRelationshipIdAndOrderNumber,
} from 'ui/pages/new-quote/state/sosNewQuote'
import { BillTo, VendorFormDetails } from '.'
import { CommoditiesForm } from './CommoditiesForm'
import { sosNewQuote } from './state'

export const NewQuoteVendor: FC = (props: { state?: IStateNewQuote }) => {
	const state = props.state || sos2.useSubscription(sosNewQuote.sos)
	const tPrefix = 'page.newQuote'

	useOnce(() => {
		sosNewQuote.selectBillToFilter('delivery')
		fireAndForget(async () => {
			await setRelationshipIdAndOrderNumber(
				getParams({
					vendorOrderNumber: { type: 'string' },
					vendorCompanyRelationshipId: { type: 'string' },
				}),
			)
		}, 'setting vendor new quote query string data')
	})

	return (
		<>
			<div>
				<strong>{t('whitelistWarning', tPrefix)}</strong>
				<div>{t('whitelistDescription', tPrefix)}</div>
			</div>
			<Spacer />
			<ContactInfo
				phone={idx(() => state.contactInfo.phone)}
				name={idx(() => state.contactInfo.name)}
				email={idx(() => state.contactInfo.email)}
			/>
			<Spacer />
			<div className={classes.shipmentCardNewQuote}>
				<ShipmentStopCard
					mode='pickup'
					stopId={state.stops[0].stopId}
					stopCardState={{
						addressState: state.stops[0].addressState,
						updateAddress: sosNewQuote.updateStopByAddressId,
						onSelectLocation: sosNewQuote.onSelectLocation,
						specialInstructions: state.stops[0].specialInstructions,
						updateSpecialInstructions: sosNewQuote.updateStopInstructions,
						dateTimeDesiredState: state.stops[0].dateTimeDesiredState,
						updateDateTimeDesired: sosNewQuote.updateDateTimeDesired,
						filters: ['supplierLocation'],
						updateAddressFilter: sosNewQuote.selectAddressFilter,
					}}
					accessorials={
						<div className={classes.shipmentStopCardAccessorialsNewQuote}>
							<Accessorials
								metadata={pickupAccessorials}
								accessorialObject={state.stops[0].addressState.accessorials}
								updateState={sosNewQuote.updateStopAccessorials}
								tPrefix='page.newQuote.pickupAccessorials'
								stopId={state.stops[0].stopId}
								mode='pickup'
							/>
						</div>
					}
					locationsSource='organizationRelationshipShipFromLocations'
				/>
			</div>

			<Spacer />
			<div>
				<div className={classes.shipmentCardNewQuote}>
					<ShipmentStopCard
						mode='delivery'
						stopId={state.stops[1].stopId}
						stopCardState={{
							addressState: state.stops[1].addressState,
							updateAddress: sosNewQuote.updateStopByAddressId,
							onSelectLocation: sosNewQuote.onSelectLocation,
							specialInstructions: state.stops[1].specialInstructions,
							updateSpecialInstructions: sosNewQuote.updateStopInstructions,
							dateTimeDesiredState: state.stops[1].dateTimeDesiredState,
							updateDateTimeDesired: sosNewQuote.updateDateTimeDesired,
							filters: ['customerDelivery', 'dropShip'],
							updateAddressFilter: sosNewQuote.selectAddressFilter,
							organizationRelationshipId: state.organizationRelationshipId,
						}}
						accessorials={
							<div className={classes.shipmentStopCardAccessorialsNewQuote}>
								<Accessorials
									metadata={deliveryAccessorials}
									accessorialObject={state.stops[1].addressState.accessorials}
									updateState={sosNewQuote.updateStopAccessorials}
									tPrefix='page.newQuote.deliveryAccessorials'
									stopId={state.stops[1].stopId}
									mode='delivery'
								/>
							</div>
						}
						locationsSource='organizationRelationshipShipFromLocations'
					/>
				</div>
			</div>
			<Spacer />
			<div className={classes.shipmentCardNewQuote}>
				{/* <CommoditiesAdvanced /> */}
				<CommoditiesForm commodities={state.commodities} />
			</div>
			<Spacer />
			<div className={classes.flexWrapNewQuote}>
				{state.stops[1].addressState.selectedAddressFilter === 'dropShip' && (
					<div className={classes.billToNewQuoteModule}>
						<Card title={t('billTo', tPrefix)} color='green' fillHeight={true}>
							<BillTo
								state={state}
								filters={['companyLocation']}
								billToLocations={sosNewQuote.getLocationsBySource(
									'organizationRelationshipShipToLocations',
								)}
								onSelectLocation={sosNewQuote.onSelectLocation}
								organizationRelationshipId={state.organizationRelationshipId}
							/>
						</Card>
					</div>
				)}
				<Spacer />
				<div className={classes.uploadDocumentsModule}>
					<PackingSlipUpload state={state} />
				</div>
				<Spacer />
				<div className={classes.detailsNewQuoteModule}>
					<Card title={t('page.newQuote.details')} color='blue'>
						<VendorFormDetails state={state} />
					</Card>
				</div>
			</div>
			<Spacer />
			<div>
				<strong>{t('finalizeShipment', tPrefix)}</strong>
			</div>
			<div>
				<OkCancelButtons
					ok={t('page.newQuote.finalizeVendorShipment.submit')}
					onOk={sosNewQuote.createVendorShipment}
					isSpinning={state.loadingQuotes}
					errors={state.createShipmentErrors}
					isValid={true}
				/>
			</div>
		</>
	)
}
