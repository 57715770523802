import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { apiBrokerConfig, apiTmsServiceConfig } from 'ui/api'
import { sosToast } from 'ui/common/components/toast'
import { Checkbox } from 'ui/components/common/checkbox'
import { Input } from 'ui/components/common/input'
import { Loader } from 'ui/components/common/loader'
import { FormStackedTextInput, IFormData } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Center } from 'ui/components/layout/center'
import { Spacer } from 'ui/components/layout/spacer'
import { FC } from 'ui/FunctionalComponent'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import * as classes from './GenerateCodeCard.module.scss'

export const GenerateCodeCard: FC = (props: {
	codeType: 'provider' | 'customer'
	code: apiTmsServiceConfig.UniqueCodeConfigResponse
	setCode: React.Dispatch<
		React.SetStateAction<apiTmsServiceConfig.UniqueCodeConfigResponse>
	>
	tPrefix?: string
}) => {
	const { codeType, code, setCode, tPrefix } = props

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [autoGenerateCode, setAutoGenerateCode] = useState<boolean>(
		code?.autoGenerate,
	)
	const [autoGeneratedProviderCode, setAutoGeneratedProviderCode] = useState<
		string
	>(null)
	const [autoGeneratedCustomerCode, setAutoGeneratedCustomerCode] = useState<
		string
	>(null)

	const chargeCodeForm: IFormData<apiTmsServiceConfig.UniqueCodeConfigResponse> = {
		form: code,
		metadata: {
			autoGenerate: {},
			prefix: {},
			currentValue: {},
			suffix: {},
			numberOfDigits: {},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(code)
			updatedFormData[field] = value
			setCode(updatedFormData)
		},
		tPrefix,
	}

	const updateGeneratedCode = async (bool: boolean): Promise<void> => {
		setIsLoading(true)

		if (codeType === 'provider') {
			const updateRequest: apiTmsServiceConfig.TmsServiceConfigRequest = {
				providerCodeConfig: {
					autoGenerate: bool,
					prefix: chargeCodeForm.form.prefix,
					suffix: chargeCodeForm.form.suffix,
					numberOfDigits: chargeCodeForm.form.numberOfDigits,
				},
			}

			const response = await apiTmsServiceConfig.updateTmsServiceConfig(
				updateRequest,
			)

			if (response.error) {
				sosToast.sendApiErrorResponseToast(response)
			} else {
				await getAutoGeneratedCode(
					response.data.providerCodeConfig.autoGenerate,
				)
			}
		} else if (codeType === 'customer') {
			const updateRequest: apiBrokerConfig.BrokerConfigRequest = {
				clientConfigCode: {
					autoGenerate: bool,
					prefix: chargeCodeForm.form.prefix,
					suffix: chargeCodeForm.form.suffix,
					numberOfDigits: chargeCodeForm.form.numberOfDigits,
				},
			}

			const response = await apiBrokerConfig.updateBrokerConfig(updateRequest)

			if (response.error) {
				sosToast.sendApiErrorResponseToast(response)
			} else {
				await getAutoGeneratedCode(response.data.clientConfigCode.autoGenerate)
			}
		}

		setIsLoading(false)
	}

	const getAutoGeneratedCode = async (bool: boolean): Promise<void> => {
		setIsLoading(true)

		if (codeType === 'provider' && bool === true) {
			const response = await apiTmsServiceConfig.getAutoGeneratedProviderCode()

			if (response.error) {
				sosToast.sendApiErrorResponseToast(response)
			} else {
				setAutoGeneratedProviderCode(response.data)
			}
		} else if (codeType === 'customer' && bool === true) {
			const response = await apiBrokerConfig.getAutoGeneratedCustomerCode()

			if (response.error) {
				sosToast.sendApiErrorResponseToast(response)
			} else {
				setAutoGeneratedCustomerCode(response.data)
			}
		}

		setIsLoading(false)
	}

	useEffect(() => {
		fireAndForget(async () => {
			await getAutoGeneratedCode(code?.autoGenerate)
		}, `Get AUTO Generated Code`)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Card className={classes.generateCodeCard}>
			<Card.Header>
				<Row className={classes.generateCodeTitle}>
					<Col>{t(`${codeType}Codes`, tPrefix)}</Col>
					<Col className={classes.flexEnd}>
						<Loader isLoading={isLoading} />
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				{l.isNil(code) ? (
					<>
						<Spacer height={'10px'} />
						<Center>
							{codeType === 'provider'
								? t('noLinkedProviderCode', tPrefix)
								: t('noLinkedCustomerCode', tPrefix)}
						</Center>
						<Spacer height={'10px'} />
					</>
				) : (
					<>
						<Row className={classes.generateCodeCheckbox}>
							<Col xs={12}>
								<Checkbox
									value={autoGenerateCode}
									onChange={async (bool: boolean) => {
										setAutoGenerateCode(bool)

										await updateGeneratedCode(bool)
									}}
									readOnly={isLoading}
								>
									{t(`autoGenerate ${codeType}Code`, tPrefix)}
								</Checkbox>
							</Col>
						</Row>
						<Spacer height={'20px'} />
						<FormStackedTextInput
							formData={chargeCodeForm}
							field={'prefix'}
							testId={`${codeType}-prepend`}
						/>
						<label>{t(`nextNumber`, tPrefix)}</label>
						<Input
							value={chargeCodeForm.form.currentValue.toString()}
							readOnly={true}
						/>
						<FormStackedTextInput
							formData={chargeCodeForm}
							field={'suffix'}
							testId={`${codeType}-append`}
						/>
						<FormStackedTextInput
							formData={chargeCodeForm}
							field={'numberOfDigits'}
							testId={`${codeType}-numberOfDigits`}
						/>
						<Spacer height={'20px'} />
						<AlignRight>
							<label className={classes.generateCodePreview}>
								{codeType === 'provider'
									? l.isNil(autoGeneratedProviderCode)
										? ''
										: `Preview:  ${autoGeneratedProviderCode}`
									: l.isNil(autoGeneratedCustomerCode)
									? ''
									: `Preview:  ${autoGeneratedCustomerCode}`}
							</label>
						</AlignRight>
					</>
				)}
			</Card.Body>
		</Card>
	)
}
