import { sosUser } from 'ui/state'
import { apiBroker, apiShipments, apiTypes } from '.'
import { BridgeShipmentResponseType } from 'ui/api/apiBrokerAndNonBrokerBridgeTypes'

export const upsertShipment = async (
	id: string,
	shipmentRequest: apiTypes.ShipmentRequest,
	getRates: boolean,
	clientConfigId: string,
): Promise<BridgeShipmentResponseType> => {
	let shipmentResponse: BridgeShipmentResponseType
	if (sosUser.isUserBroker()) {
		if (id) {
			shipmentResponse = await apiBroker.updateBrokerShipment(
				() => {},
				id,
				shipmentRequest,
				clientConfigId,
				false,
			)
		} else {
			shipmentResponse = await apiBroker.createBrokerShipment(
				() => {},
				shipmentRequest,
				clientConfigId,
				getRates,
			)
		}
	} else {
		if (id) {
			shipmentResponse = await apiShipments.updateShipment(
				id,
				shipmentRequest,
				() => {},
			)
		} else {
			shipmentResponse = await apiShipments.createShipment(
				shipmentRequest,
				getRates,
				() => {},
			)
		}
	}

	return shipmentResponse
}
