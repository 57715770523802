import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { apiBroker, apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button'
import { Icon, solidIcons } from 'ui/components/common/icon'
import {
	getLocalStorage,
	getWindow,
	localStorageExists,
	windowExists,
	windowOpen,
} from 'ui/components/common/router/windowUtils'
import { FormStackedTextInput, IFormData } from 'ui/components/form'
import { tMode } from 'ui/components/i18n/commonTranslations'
import { t, tString } from 'ui/components/i18n/i18n'
import { goToTms2BoL } from 'ui/lib/IframeRpc'
import { l } from 'ui/lib/lodashImports'
import { formatBolData } from 'ui/pages/shipment-profile/broker/functions/bill-of-lading/ShipmentProfileBillOfLading_utils'
import { isInTMS2 } from 'ui/theme/theme'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import classes from './BrokerShipmentProfileBOL.module.scss'

const tPrefix = 'page.shipmentProfile.bol'
const translate = (key: string, stringOnly = false): any =>
	stringOnly ? tString(key, tPrefix) : t(key, tPrefix)

interface BillOfLadingForm {
	proNumber?: string
	pickupNumber?: string
	brokerReferenceNumber?: string
	trailerNumber?: string
	containerNumber?: string
	sealNumber?: string
	providerName?: string
	mode?: string
}

export const BrokerShipmentProfileBOL: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse
}) => {
	const { shipment } = props
	const [bolForm, updateBOLForm] = useState<BillOfLadingForm>(null)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (shipment.shipmentStatus === 'booked') {
			updateBOLForm({
				proNumber: shipment.proNumber,
				pickupNumber: shipment.pickupNumber,
				brokerReferenceNumber: shipment.brokerReferenceNumber,
				trailerNumber: shipment.trailerNumber,
				sealNumber: shipment.sealNumber,
				mode: tMode(shipment.bookedRate?.method),
				providerName: shipment.bookedRate?.providerName,
				containerNumber: shipment.trailerNumber,
			})
		}
	}, [shipment])

	const formData: IFormData<BillOfLadingForm> = {
		form: bolForm,
		metadata: {
			providerName: { readOnly: true },
			mode: { readOnly: true },
			proNumber: {},
			pickupNumber: {},
			brokerReferenceNumber: {},
			trailerNumber: {},
			containerNumber: {},
			sealNumber: {},
		},
		onUpdateForm: (key: string, val: string) => {
			updateBOLForm(Object.assign({}, bolForm, { [key]: val }))
		},
		tPrefix,
	}

	const onUpdate = async (): Promise<void> => {
		setIsLoading(true)

		const updatedShipment = l.cloneDeep(shipment)
		updatedShipment.proNumber = bolForm.proNumber
		updatedShipment.pickupNumber = bolForm.pickupNumber
		updatedShipment.brokerReferenceNumber = bolForm.brokerReferenceNumber
		updatedShipment.sealNumber = bolForm.sealNumber
		//updatedShipment.trailerNumber = bolForm.containerNumber
		updatedShipment.trailerNumber = bolForm.trailerNumber

		const result = await apiBroker.updateBrokerShipment(
			() => {},
			shipment.id,
			updatedShipment,
			shipment.contractId,
		)

		if (result.data) {
			sosShipmentProfileBroker.setShipmentReference(result.data)
		} else {
			sosToast.sendApiErrorResponseToast(
				result,
				translate('shipmentUpdateError', true),
			)
		}

		setIsLoading(false)
	}

	const showBOL = async (): Promise<void> => {
		if (isInTMS2()) {
			goToTms2BoL(shipment, shipment.payloads[0])
		} else {
			const bol = await formatBolData(shipment, shipment.payloads[0])
			const serializedObjectParts: string[] = []
			// Formatting bol values to be query params
			for (const [bolKey, bolValue] of Object.entries<string>(bol)) {
				serializedObjectParts.push(bolKey + '=' + encodeURIComponent(bolValue))
			}
			if (localStorageExists) {
				getLocalStorage().setItem(
					'billOfLadingData',
					serializedObjectParts.join('&'),
				)
			}
			if (windowExists) {
				windowOpen(
					getWindow().location.origin + '/pdfs/index.html?bill-of-lading',
				)
			}
		}
	}

	return (
		<div className='bootstrap-wrapper'>
			{bolForm && (
				<Card className='p-3'>
					<Row>
						<Col>
							<Row>
								<Col>
									<div
										className={classes.downloadBox}
										onClick={async () => {
											await showBOL()
										}}
									>
										<div className={classes.downloadBoxContent}>
											<span className='d-block'>{translate('_')}</span>
											<Icon icon={solidIcons.faDownload} />
										</div>
										<div className={classes.downloadBoxSuccess}>
											<span className='mr-1'>
												{translate('printBillOfLading')}
											</span>
											<Icon icon={solidIcons.faCheckCircle} />
										</div>
									</div>
								</Col>
								<Col>
									<FormStackedTextInput
										formData={formData}
										field='providerName'
									/>
									<FormStackedTextInput formData={formData} field='mode' />
								</Col>
							</Row>
						</Col>
						<Col>
							<FormStackedTextInput
								formData={formData}
								field='proNumber'
								readOnly={isLoading}
							/>
							<FormStackedTextInput
								formData={formData}
								field='pickupNumber'
								readOnly={isLoading}
							/>
							<FormStackedTextInput
								formData={formData}
								field='brokerReferenceNumber'
								readOnly={isLoading}
							/>
							<FormStackedTextInput
								formData={formData}
								field='trailerNumber'
								readOnly={isLoading}
							/>
							<FormStackedTextInput
								formData={formData}
								field='containerNumber'
								readOnly={isLoading}
							/>
							<FormStackedTextInput
								formData={formData}
								field='sealNumber'
								readOnly={isLoading}
							/>
							<div className='d-flex justify-content-end'>
								<Button color='blue' isSpinning={isLoading} onClick={onUpdate}>
									{translate('update')}
								</Button>
							</div>
						</Col>
					</Row>
				</Card>
			)}
		</div>
	)
}
