import React, { ReactElement, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { apiTypes } from 'ui/api'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { l } from 'ui/lib/lodashImports'
import { toDollars } from 'ui/lib/numbers/toNumber'
import { sosUser } from 'ui/state'
import { sosCustomerManagement } from '../..'

const tPrefix = 'page.customerManagement.defaultMarkup'

export const BrokerDefaultMarkup = (props: {
	brokerDefaultMarkup: apiTypes.MarkupLogic
}): ReactElement => {
	const [errorToastKey, setErrorToastKey] = useState(null)

	const markupLogic = props.brokerDefaultMarkup
	const markupForm: apiTypes.MarkupLogic = {
		markupType: markupLogic.markupType,
		markupFlat: toDollars(markupLogic.markupFlat),
		minMarkupFlat: toDollars(markupLogic.minMarkupFlat),
		maxMarkupFlat: toDollars(markupLogic.maxMarkupFlat),
		markupPercent: markupLogic.markupPercent,
		minMarkupPercent: markupLogic.minMarkupPercent,
		maxMarkupPercent: markupLogic.maxMarkupPercent,
	}

	const _updateApi = l.debounce(async () => {
		const result = await sosUser.updateClientConfig(
			sosCustomerManagement.sos.getState(),
		)
		if (result?.error) {
			setErrorToastKey(sosCustomerManagement.sendErrorToast(result.error))
		}
	}, 500)

	const onChange = async (field, value): Promise<void> => {
		sosCustomerManagement.markupFormOnFormUpdate(field, value)
		if (errorToastKey) {
			sosCustomerManagement.deleteErrorToast(errorToastKey)
			setErrorToastKey(null)
		}
		await _updateApi()
	}

	return (
		<div className='bootstrap-wrapper'>
			<Card>
				<Card.Header>
					<Row>
						<Col>{t('defaultMarkup', tPrefix)}</Col>
					</Row>
				</Card.Header>
				<Row data-testid='broker-default-markup'>
					<Col
						className={'align-items-stretch'}
						data-testid='broker-default-markup-percent'
					>
						<Card className='h-100'>
							<Card.Body>
								<Form>
									<Form.Group>
										<Form.Check
											type='radio'
											label={t('percentMarkup', tPrefix)}
											onChange={(): Promise<void> =>
												onChange('markupType', 'percent')
											}
											checked={markupLogic.markupType === 'percent'}
											data-testid='form-percent'
										/>
									</Form.Group>
									<Form.Group>
										{['markupPercent', 'minMarkupFlat'].map((c) => (
											<div key={c}>
												<Form.Label>{t(c, tPrefix)}</Form.Label>
												<Form.Control
													type='text'
													onChange={(e): Promise<void> =>
														onChange(c, e.target.value)
													}
													id={c}
													value={markupForm[c] || ''}
													disabled={markupLogic.markupType !== 'percent'}
													data-testid={`form-${c}`}
												/>
												<Spacer height='5px' />
											</div>
										))}
									</Form.Group>
								</Form>
							</Card.Body>
						</Card>
					</Col>

					<Col data-testid='broker-default-markup-flat'>
						<Card>
							<Card.Body>
								<Form>
									<Form.Group controlId='flatSelector'>
										<Form.Check
											type='radio'
											label={t('flatMarkup', tPrefix)}
											onChange={(): Promise<void> =>
												onChange('markupType', 'flat')
											}
											checked={markupLogic.markupType === 'flat'}
											data-testid='form-flat'
										/>
									</Form.Group>
									<Form.Group>
										{['markupFlat', 'minMarkupPercent', 'maxMarkupPercent'].map(
											(c) => (
												<div key={c}>
													<Form.Label>{t(c, tPrefix)}</Form.Label>
													<Form.Control
														type='text'
														onChange={(e): Promise<void> =>
															onChange(c, e.target.value)
														}
														id={c}
														value={markupForm[c] || ''}
														disabled={markupLogic.markupType !== 'flat'}
														data-testid={`form-${c}`}
													/>
													<Spacer height='5px' />
												</div>
											),
										)}
									</Form.Group>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Card>
		</div>
	)
}
