import { ILink } from 'ui/components/layout/sidebar'
import { isInDev } from 'ui/theme/theme'
import { sosFeatureToggles } from 'ui/state'

export const linksDev: ILink[] = [
	{
		name: 'tests',
		pathname: '/tests-v3/',
		indexPathname: '/dev',
		subLinks: [
			{
				name: 'dev-page',
				pathname: '/dev',
				if: isInDev,
			},
			{
				name: 'dev-login',
				pathname: '/dev-login',
				if: isInDev,
			},
			{
				name: 'dev-page-config',
				pathname: '/dev-page-config',
				if: isInDev,
			},
			{
				name: 'component-examples',
				pathname: '/tests-v3/component-examples/',
				if: isInDev,
			},
			// {
			//   name: 'pdf-example',
			//   pathname: '/tests-v3/pdf-example/',
			//
			//   show: isInDev(),
			// },
			{
				name: 'test-performance',
				pathname: '/tests-v3/test-performance/',

				if: isInDev,
			},
		],
		if: isInDev,
	},
]

export const linksBroker: ILink[] = [
	{
		name: 'welcome',
		pathname: '/welcome-v3/',
		anonymous: true,
	},
	{
		name: 'new-shipment',
		pathname: '/shipments-v3/new-quote/freight',
	},
	{
		name: 'broker-shipments',
		pathname: '/shipments-v3/shipments-list',
	},
	{
		name: 'companyManagement',
		pathname: '/company-management-v3/',
	},
	{
		name: 'customerManagement',
		pathname: '/customer-management-v3/',
		indexPathname: '/customer-management-v3/default-markup',
	},
	{
		name: 'providerManagement',
		pathname: '/providers/',
	},
	{
		name: 'analytics',
		pathname: '/analytics',
		if: () => {
			return sosFeatureToggles.checkFeatureToggle('analytics')
		},
	},
	...linksDev,
]

export const links: ILink[] = [
	{
		name: 'welcome',
		pathname: '/welcome-v3/',
		anonymous: true,
	},
	{
		name: 'quick-rate',
		pathname: '/shipments-v3/quick-rate/',
		if: () => {
			return sosFeatureToggles.checkFeatureToggle('quick-rate')
		},
	},
	{
		name: 'new-quote',
		pathname: '/shipments-v3/new-quote/freight',
		subLinks: [
			{
				name: 'new-quote-parcel',
				pathname: '/shipments-v3/new-quote/parcel',
			},
			{
				name: 'new-quote-freight',
				pathname: '/shipments-v3/new-quote/freight',
			},
		],
	},
	{
		name: 'shipments',
		pathname: '/shipments-v3/shipments-list',
	},
	{
		name: 'manifests',
		pathname: '/manifests',
	},
	{
		name: 'load-optimizer',
		pathname: '/load-optimizer/',
		indexPathname: '/load-optimizer/',
		subLinks: [
			{
				name: 'loadOptimizer',
				pathname: '/load-optimizer',
			},
			{
				name: 'loadOptimizerRules',
				pathname: '/load-optimizer/rules',
			},
		],
		if: () => {
			return sosFeatureToggles.checkFeatureToggle('load-optimizer')
		},
	},
	{
		name: 'dock-scheduler',
		pathname: '/dock-scheduler',
		if: () => {
			return sosFeatureToggles.checkFeatureToggle('dock-scheduler')
		},
	},
	{
		name: 'control-tower',
		pathname: '/control-tower/calendar',
		indexPathname: '/control-tower/calendar',
		subLinks: [
			{
				name: 'calendar-view',
				pathname: '/control-tower/calendar',
			},
			{
				name: 'map-view',
				pathname: '/control-tower/map',
			},
		],
		if: () => {
			return sosFeatureToggles.checkFeatureToggle('control-tower')
		},
	},
	{
		name: 'provider-portal',
		pathname: '/provider-portal/',
		subLinks: [
			{
				name: 'invoices',
				pathname: '/provider-portal/invoices/',
			},
			{
				name: 'customers',
				pathname: '/provider-portal/customers/',
			},
		],
		if: () => {
			return sosFeatureToggles.checkFeatureToggle('provider-portal')
		},
	},
	{
		name: 'rfp',
		pathname: '/rfp/',
		indexPathname: '/rfp/upload',
		if: () => {
			return sosFeatureToggles.checkFeatureToggle('rfp')
		},
		subLinks: [
			{
				name: 'rfp-upload',
				pathname: '/rfp/upload/',
			},
			{
				name: 'rfp-analysis',
				pathname: '/rfp/analysis/',
				if: isInDev,
			},
		],
	},
	{
		name: 'companyManagement',
		pathname: '/company-management-v3/',
		indexPathname: '/company-management-v3/dock-configuration',
		subLinks: [
			{
				name: 'dockConfiguration',
				pathname: '/company-management-v3/dock-configuration',
			},
			{
				name: 'printersAndScales',
				pathname: '/company-management-v3/printers-and-scales',
			},
		],
	},

	// {
	// 	name: 'manage-books',
	// 	pathname: '/books-v3/',
	// 	if: isInDev,
	// 	subLinks: [
	// 		{
	// 			name: 'manage-accounting-code-catalogs',
	// 			pathname: '/books-v3/manage-accounting-code-catalogs-v3/',
	// 		},
	// 		{
	// 			name: 'manage-address-books',
	// 			pathname: '/books-v3/manage-address-books-v3/',
	// 		},
	// 		{
	// 			name: 'manage-package-catalogs',
	// 			pathname: '/books-v3/manage-package-catalogs-v3/',
	// 		},
	// 		{
	// 			name: 'manage-product-catalogs',
	// 			pathname: '/books-v3/manage-product-catalogs-v3/',
	// 		},
	// 	],
	// },

	{
		name: 'provider-portal-signup',
		pathname: '/provider-portal/signup/',
		anonymousOnly: true,
	},
	{
		name: 'providerManagement',
		pathname: '/providers/',
		if: isInDev,
	},
	{
		name: 'analytics',
		pathname: '/analytics',
		if: () => {
			return sosFeatureToggles.checkFeatureToggle('analytics')
		},
	},

	// TODO: Add this back in when there is stuff to put in customer management for TMS users
	// {
	// 	name: 'customerManagement',
	// 	pathname: '/customer-management-v3/',
	// },
	...linksDev,
]
