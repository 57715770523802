import React, { useEffect, useRef } from 'react'
import {
	Input,
	InputAutocompleteType,
	InputType,
} from 'ui/components/common/input/Input'

export function FormTextInput<T>(props: {
	form: T
	field: keyof T
	onUpdateForm: (field: keyof T, newVal: string) => void
	onEnter?: () => void
	name?: string
	testId?: string
	width?: string
	autofocus?: boolean
	readOnly?: boolean
	inputType?: InputType //should this just be set in the form metadata?
	autocomplete?: InputAutocompleteType
	multiline?: boolean
	notResizable?: boolean
	rows?: number
	placeholder?: string
	className?: string
}): React.ReactElement {
	const onChange = (newVal: string): void => {
		props.onUpdateForm(props.field, newVal)
	}

	const val = props.form[props.field]

	const inputRef = useRef(null)

	useEffect(() => {
		if (props.autofocus) {
			if (inputRef.current) {
				inputRef.current.focus()
			}
		}
	}, [props.autofocus])

	return (
		<div>
			<Input
				ref={inputRef}
				onChange={onChange}
				onEnter={props.onEnter}
				value={val ? '' + val : ''}
				name={props.name}
				testId={'form-' + (props.testId || props.field)}
				readOnly={props.readOnly}
				width={props.width}
				inputType={props.inputType}
				autocomplete={props.autocomplete}
				multiline={props.multiline}
				notResizable={props.notResizable}
				rows={props.rows}
				placeholder={props.placeholder}
				autofocus={props.autofocus}
				className={props.className}
			/>
		</div>
	)
}
