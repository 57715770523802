import { FC } from 'app/FunctionalComponent'
import React, { Dispatch, SetStateAction } from 'react'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons/OkCancelButtons'
import { findFormDataErrors, IFormData } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { sos2 } from 'ui/lib/state/sos2'
import { theme } from 'ui/theme'
import { sosMakeOfferPage } from '.'
import styles from './MakeOfferPage.module.scss'
import { tPrefixMakeOffer } from './OfferDetailsCard'

export const OfferDetailsOkCancelButtons: FC = (props: {
	offerStatus: string
	formData: IFormData<any>
	isBroker?: boolean
	isValid?: boolean
	setValidationErrors?: Dispatch<SetStateAction<string[]>>
	errors?: string | string[]
}) => {
	const { offerStatus, isBroker, isValid } = props
	const state = sos2.useSubscription(sosMakeOfferPage.sos)

	if (offerStatus === 'active') {
		return (
			<OkCancelButtons
				ok={t('makeOffer', tPrefixMakeOffer)}
				cancel={t('declineShipment', tPrefixMakeOffer)}
				onCancel={() => sosMakeOfferPage.toggleModal('decline')}
				okColor='green'
				isSpinning={state.isMakingOffer}
				okTestId={'makeOfferOk'}
				isValid={isValid}
				errors={props.errors}
				inlineErrors={true}
				onOk={async () => {
					if (props.setValidationErrors) {
						const errors = findFormDataErrors(props.formData).map(
							(c) => `${String(c.field)} ${c.reason}`,
						)
						props.setValidationErrors(errors)
						if (!errors.length) {
							await sosMakeOfferPage.makeOffer(isBroker)
						}
					} else {
						await sosMakeOfferPage.makeOffer(isBroker)
					}
				}}
			/>
		)
	} else if (offerStatus === 'offerMade') {
		return (
			<div className={theme.getTextColor('blue') + ' ' + styles.bold}>
				{t('offerHasBeenMade', tPrefixMakeOffer)}
			</div>
		)
	} else if (offerStatus === 'awarded') {
		return (
			<OkCancelButtons
				ok={t('acceptShipment', tPrefixMakeOffer)}
				cancel={t('declineShipment', tPrefixMakeOffer)}
				onCancel={() => sosMakeOfferPage.toggleModal('decline')}
				okColor='green'
				isValid={true}
				onOk={async () => await sosMakeOfferPage.toggleModal('accept')}
			/>
		)
	} else if (offerStatus === 'booked') {
		return (
			<div className={theme.getTextColor('blue') + ' ' + styles.bold}>
				{t('shipmentHasBeenAccepted', tPrefixMakeOffer)}
			</div>
		)
	} else {
		return null
	}
}
