import { DateTime } from 'luxon'
import { apiTypes } from 'ui/api'
import { tArgz, tString } from './i18n'

const tPrefix = 'common'

export const tEquipmentType = (
	equipmentType: apiTypes.EquipmentType,
): string => {
	return tString(equipmentType, `${tPrefix}.equipmentType`)
}

export const tMode = (mode: apiTypes.ShippingMode): string => {
	return tString(mode, `${tPrefix}.mode`)
}

export const tHandlingUnitType = (
	handlingUnitType: apiTypes.containerType,
): string => {
	return tString(handlingUnitType, `${tPrefix}.handlingUnitType`)
}

export const tStopType = (stopType: apiTypes.StopType): string => {
	return tString(stopType, `${tPrefix}.stopType`)
}

export const tShipmentFlow = (flow: apiTypes.ShipmentFlow): string => {
	return tString(flow, `${tPrefix}.shipmentFlow`)
}

export const tBillToParty = (
	billToParty: apiTypes.BillTo['billToThisParty'],
): string => {
	return tString(billToParty, `${tPrefix}.billToParty`)
}

export const tPaymentType = (
	paymentType: apiTypes.RateBillTo['paymentType'],
): string => {
	return tString(paymentType, `${tPrefix}.paymentType`)
}

export const tCustomerInvoicingStatus = (
	customerInvoicingStatus: apiTypes.CustomerInvoicingStatus,
): string => {
	return tString(customerInvoicingStatus, `${tPrefix}.customerInvoicingStatus`)
}

export const tAccessorial = (
	accesorial: keyof apiTypes.AccessorialsRequired,
): string => {
	return tString(accesorial, `${tPrefix}.accessorial`)
}

export const tAddressType = (addressType: apiTypes.AddressType): string => {
	return tString(addressType, `${tPrefix}.addressType`)
}

export const tProviderName = (providerName: string): string => {
	// probably should only be used for apiTypes.RateResponse['providerName'] or apiTypes.RateResponse['carrier']
	return providerName ? providerName.replace(/_/g, ' ') : ''
}

export const tClientStatusOnBrokerOffer = (
	clientStatusOnBrokerOffer: apiTypes.ClientStatusOnBrokerOffer,
): string => {
	return tString(
		clientStatusOnBrokerOffer,
		`${tPrefix}.clientStatusOnBrokerOffer`,
	)
}

export const tRateQuoteType = (
	clientStatusOnBrokerOffer: apiTypes.RateQuoteType,
): string => {
	return tString(clientStatusOnBrokerOffer, `${tPrefix}.rateQuoteType`)
}

export const tFreightSize = (
	freightSize: keyof apiTypes.FreightSize,
): string => {
	return tString(freightSize, `${tPrefix}.freightSize`)
}

export const tConnectionType = (
	connectionType: keyof apiTypes.ConnectionType,
): string => {
	return tString(connectionType, `${tPrefix}.connectionType`)
}

export const tTrackingStatus = (
	trackingStatus: apiTypes.TrackingStatus,
): string => {
	return tString(trackingStatus, `${tPrefix}.trackingStatus`)
}

export const tTrackingEventSource = (
	trackingEventSource: apiTypes.TrackingEventSource,
): string => {
	return tString(trackingEventSource, `${tPrefix}.trackingEventSource`)
}

export const tServiceLevel = (serviceLevel: apiTypes.ServiceLevel): string => {
	return tString(serviceLevel, `${tPrefix}.serviceLevel`)
}

export const tDocumentType = (
	documentType: apiTypes.ShippingDocumentTypes,
): string => {
	return tString(documentType, `${tPrefix}.documentType`)
}

export const tSignatureType = (
	signatureType: apiTypes.ShipmentAccessorialsInfo['signatureType'],
): string => {
	return tString(signatureType, `${tPrefix}.signatureType`)
}

export const tInvoiceStatus = (
	invoiceStatus: apiTypes.InvoiceStatus,
): string => {
	return tString(invoiceStatus, `${tPrefix}.invoiceStatus`)
}

export const tTimeRange = (
	dateTimeInfo: apiTypes.DateTimeInfo,
	stopType: 'pickUp' | 'delivery'
) : string => {

	switch(dateTimeInfo.timeType){

		case "between":
			if (dateTimeInfo.initialTime){
				
				return tArgz('between',{
				startTime:toTimeSimple(dateTimeInfo.initialTime),
				endTime:toTimeSimple(dateTimeInfo.latestTime)
				},
				`${tPrefix}.timeRange`)
			} else {
				return tArgz('betweenAnytime', { 
					startDate: dateTimeInfo.initialDate,
					endDate: dateTimeInfo.latestDate
				},
				`${tPrefix}.timeRange`)
			}
		case "at":
				if (dateTimeInfo.initialTime){
					return tArgz('at',{
						stopTime: toTimeSimple(dateTimeInfo.initialTime),
						},
						`${tPrefix}.timeRange`)
				} else {
					return tArgz('atAnytime', {
						stopDate: dateTimeInfo.initialDate
						},
						`${tPrefix}.timeRange`)
				}

		case "before":
				if (dateTimeInfo.initialTime){
					return tArgz('before',{
						stopTime: toTimeSimple(dateTimeInfo.initialTime),
						},
						`${tPrefix}.timeRange`)
				} else  {
					return tArgz('beforeAnytime', { 
						stopDate: dateTimeInfo.initialDate
						},
						`${tPrefix}.timeRange`)
				}
		
		case "after":
				if (dateTimeInfo.initialTime){
					return tArgz('after',{
						stopTime: toTimeSimple(dateTimeInfo.initialTime),
				},
						`${tPrefix}.timeRange`)
				} else {
					return tArgz('afterAnytime', {
						stopDate: dateTimeInfo.initialDate
					},
					`${tPrefix}.timeRange`)
				}
				
		case "anyTime":
			return tString('anyTime', tPrefix)

		default:
			return tString(stopType==='pickUp'? 'standardPickup':'standardDelivery', tPrefix)
				
	}
}

const toTimeSimple =(
	time:string
	) : string =>{
	return DateTime.fromISO(time).toLocaleString(DateTime.TIME_SIMPLE)
}