import 'core-js/features/array/includes' // Polyfill for IE11
import { barkdown } from 'barkdown'
import React, { useEffect, useRef, useReducer, useState } from 'react'
import Modal from 'react-modal'
import { Comment } from 'ui/components/common/comment/Comment'
import { sosPageConfig, sosTheme, sosUser } from 'ui/state'
import { theme } from 'ui/theme'
import { buildDate } from './appBuild'
import { AppRouter } from './AppRouter'
import {
	getWindow,
	windowExists,
} from 'ui/components/common/router/windowUtils'
import { useOnce } from 'ui/components/hooks/useOnce'
import { sosI18n } from 'ui/components/i18n/state'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { sos2 } from 'ui/lib/state/sos2'
import { secrets } from 'ui/secrets'
import classesCommon from 'ui/theme/common.module.scss'
import {
	ToastContext,
	ToastReducer,
	ToastState,
	ToastWrapper,
} from 'common/components/toast'

import './App.scss'
import './bootstrapWrapper.scss'
import './bootstrapGridWrapper.scss'
import { LoginModeType } from 'ui/components/common/router'
import { watch } from 'ui/components/hooks'
import { FC } from './FunctionalComponent'

barkdown.installStandard()

const fetchDevUser = true

const App: FC = (props: {}) => {
	const stateTheme = sosTheme.useSubscribe()
	const stateI18n = sos2.useSubscription(sosI18n.getSos())
	const stateUser = sos2.useSubscription(sosUser.getSos())
	const statePageConfig = sos2.useSubscription(sosPageConfig.sos)
	const [hasUser, setHasUser] = useState(false)
	const [loginMode, setLoginMode] = useState('unknown' as LoginModeType)
	const [toasts, dispatchToasts] = useReducer(ToastReducer, [] as ToastState[])
	const ref_app = useRef<HTMLDivElement>()
	useEffect(() => {
		if (ref_app.current) {
			Modal.setAppElement(ref_app.current)
		}
	})
	useOnce(async () => {
		if (!theme.isInDev() || fetchDevUser) {
			await sosUser.fetchUser()
			setLoginMode(sosUser.getLoginMode())
			setHasUser(true)
		}
		if (theme.isInDev()) {
			fireAndForget(
				secrets.fetchDevSavedBearerTokens,
				'fetchDevSavedBearerTokens',
			)
		}
	})
	watch(() => {
		setHasUser(stateUser.requestUserInfo.data ? true : false)
	}, [
		stateUser.requestUserInfo.data?.id,
		stateUser.requestUserInfo.data?.profile?.settings
			?.canViewDataForThisCompany,
	])

	theme.updateTheme(stateTheme.theme)

	return (
		<div
			className={
				classesCommon.app +
				' ' +
				classesCommon.fontPrimary +
				' ' +
				'bootstrap-grid-wrapper'
			}
			ref={ref_app}
		>
			{hasUser && (
				<ToastContext.Provider value={dispatchToasts}>
					<AppRouter loginMode={loginMode} />
					<Comment>
						Page: {windowExists && getWindow().location.pathname} +{' '}
						{windowExists && getWindow().location.hash}
					</Comment>
					<Comment>Language is: {stateI18n.locale}</Comment>
					<Comment>User is: {stateUser.username}</Comment>
					<Comment>PageConfig: {statePageConfig.isPageConfigMode}</Comment>
					<Comment>Build: {buildDate}</Comment>
					<ToastWrapper toasts={toasts} />
				</ToastContext.Provider>
			)}
		</div>
	)
}
export default App
