import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiBrokerInvoice, apiTypes } from 'ui/api'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { theme } from 'ui/theme'
import * as classes from './InvoiceCustomerConfirmModal.module.scss'

const tPrefix = 'page.customerInvoice.invoiceCustomerConfirmModal'

const spacerHeight = '10px'

export const InvoiceCustomerConfirmModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: () => void
	brokerInvoiceId: string
	brokerInvoice: apiTypes.BrokerInvoiceResponse
	setBrokerInvoice: React.Dispatch<
		React.SetStateAction<apiTypes.BrokerInvoiceResponse>
	>
}) => {
	const {
		isModalOpen,
		onModalClose,
		brokerInvoiceId,
		brokerInvoice,
		setBrokerInvoice,
	} = props
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const today = new Date().toLocaleDateString()

	const onClose = (): void => {
		onModalClose()
	}

	const confirm = async (): Promise<void> => {
		setIsLoading(true)

		const invoiceDue = new Date()
		invoiceDue.setDate(invoiceDue.getDate() + brokerInvoice.paymentTerms)
		const updatedInvoiceDue = invoiceDue.toLocaleDateString()

		const invoiceRequest: apiBrokerInvoice.UIBrokerInvoiceUpdateRequest = {
			shipmentId: brokerInvoice.shipmentId,
			clientConfigId: brokerInvoice.clientConfigId,
			invoiceNumber: brokerInvoice.invoiceNumber,
			invoiceStatus: 'invoiced',
			invoiceDate: today,
			paymentTerms: brokerInvoice.paymentTerms,
			currency: brokerInvoice.currency,
			notes: brokerInvoice.notes,
			invoiceDue: updatedInvoiceDue,
		}

		const invoiceResponse = await apiBrokerInvoice.updateBrokerInvoice(
			() => {},
			brokerInvoiceId,
			invoiceRequest,
		)

		if (invoiceResponse.data) {
			setBrokerInvoice(invoiceResponse.data)
			onModalClose()
		} else if (invoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(invoiceResponse)
		}

		setIsLoading(false)
	}

	return (
		<Modal
			content={() => (
				<>
					<div className={classes.invoiceCustomerConfirmModal}>
						<div className={classes.warningIcon}>
							<Icon
								icon={solidIcons.faExclamationTriangle}
								className={classes.icon}
								color='yellow'
							/>
							<div className={theme.getTextColor('yellow')}>
								{t('warning', tPrefix)}
							</div>
						</div>

						<div className={classes.warningMessage}>
							{t('warningMessage', tPrefix)}
						</div>
					</div>
					<div>
						<Spacer height={spacerHeight} />
						<AlignRight>
							<OkCancelButtons
								ok={t('confirm', tPrefix)}
								onOk={confirm}
								okColor='green'
								cancel={t('cancel', tPrefix)}
								onCancel={onClose}
								isSpinning={isLoading}
								isValid={true}
								okTestId={'invoice-customer-confirm-button'}
								cancelTestId={'invoice-customer-cancel-button'}
							/>
						</AlignRight>
					</div>
				</>
			)}
			isOpen={isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('invoiceCustomerConfirmModal', tPrefix)}
		/>
	)
}
