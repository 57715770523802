import React, { useState } from 'react'
import { apiBroker, apiTypes } from 'ui/api'
import { Loader } from 'ui/components/common/loader'
import { useOnce } from 'ui/components/hooks'
import { tString } from 'ui/components/i18n/i18n'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { sos2 } from 'ui/lib/state/sos2'
import { BrokerShipmentProfileSubway } from 'ui/pages/shipment-profile/broker/components/subway'
import { BrokerShipmentProfileRates } from 'ui/pages/shipment-profile/broker/components/rates'
import { compareBrokerShipmentStatus } from 'ui/pages/shipment-profile/shared/functions/shipmentStatusRanks'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { getUrlState } from './sosShipmentProfileBroker'
import { Layout } from 'ui/pages/layout'
import { InvitesSection } from 'ui/pages/shipment-profile/shared/components'
import { ShipmentProfileLayout } from 'ui/pages/shipment-profile/ShipmentProfileLayout'
import { BrokerShipmentInteractionButtons } from 'ui/pages/shipment-profile/broker/components/interaction-button'
import { BrokerShipmentDetailsSection } from 'ui/pages/shipment-profile/broker/components/detail-section'
import { ShipmentProfileTracking } from 'ui/pages/shipment-profile/shared/components/tracking'
import { BrokerShipmentSummary } from 'ui/pages/shipment-profile/broker/components/summary'
import { trickleRateBrokerShipment } from 'ui/pages/shipment-profile/broker/functions/trickleRateBrokerShipment'
import { l } from 'ui/lib/lodashImports'
import { BrokerShipmentProfileBOL } from 'ui/pages/shipment-profile/broker/components/profile-BOL'
import { DocumentSection } from 'ui/pages/shipment-profile/broker/components/document'
import { sosToast } from 'common/components/toast'
import { FC } from 'app/FunctionalComponent'
import { BrokerShipmentProfileInvoices } from 'ui/pages/shipment-profile/broker/components/customer-invoices'
import { BookCarrierModal } from 'ui/pages/shipment-profile/shared/components/book-carrier-modal'
import { UnbookCarrierModal } from 'ui/pages/shipment-profile/shared/components/unbook-carrier-modal'
import { NotifyProviderModal } from 'ui/pages/shipment-profile/shared/components/notify-provider-modal'
import {
	RateBookingDetails,
	defaultRateBookingDetails,
} from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'


const tPrefix = 'page.shipmentProfile'

const shipmentStatusesThatNeedRates: apiTypes.BrokerShipmentResponse['shipmentStatus'][] = [
	'preparing',
	'active',
	'active_invites_not_sent',
]

export const ShipmentProfileBroker: FC = (props: {}) => {
	const {
		shipment,
		clientConfig,
		runningApiCalls,
		websocketUrl,
	} = sos2.useSubscription(sosShipmentProfileBroker.getSos())
	const [isRating, setIsRating] = useState(false)

	const [isBookCarrierModalOpen, setIsBookCarrierModalOpen] = useState<boolean>(
		false,
	)
	const [isUnbookCarrierModalOpen, setIsUnbookCarrierModalOpen] = useState<
		boolean
	>(false)
	const [isNotifyProviderModalOpen, setIsNotifyProviderModalOpen] = useState<
		boolean
	>(false)
	const [rateBookingDetails, setRateBookingDetails] = useState<
		RateBookingDetails
	>(defaultRateBookingDetails)
	const [isBooking, setIsBooking] = useState<boolean>(false)

	const shouldGetNewRates = (
		shipment: apiTypes.BrokerShipmentResponse,
	): boolean => {
		return (
			shipment &&
			shipment.rates.filter(
				(rate) =>
					['contract', 'webRate'].includes(rate.quoteType) &&
					!(rate as any).isVoid,
			).length === 0
		)
	}

	useOnce(async () => {
		const { shipmentId } = getUrlState()
		const fetchResult = await sosShipmentProfileBroker.fetchShipment(shipmentId)
		if (
			shipmentStatusesThatNeedRates.includes(
				fetchResult.data?.shipmentStatus,
			) ||
			shouldGetNewRates(fetchResult.data)
		) {
			fireAndForget(async () => {
				setIsRating(true)
				const updatedShipment = l.cloneDeep(
					sosShipmentProfileBroker.getSos().getState().shipment,
				)
				updatedShipment.brokerShipmentStatus = 'quoted'
				sosShipmentProfileBroker.setShipmentReference(updatedShipment)
				await trickleRateBrokerShipment(websocketUrl, () => {
					setIsRating(false)
					fireAndForget(
						() => sosShipmentProfileBroker.fetchShipment(updatedShipment.id),
						'refetching shipment after trickle rating',
					)
				})
			}, 'trickle rating shipment')
		} else if (fetchResult.error) {
			sosToast.sendApiErrorResponseToast(
				fetchResult,
				tString('shipmentFetchError', tPrefix),
			)
		}

		const updatedRateBookingDetails = {
			...rateBookingDetails,
			shipmentId: shipmentId,
			isBroker: true,
		}
		setRateBookingDetails(updatedRateBookingDetails)
	})

	return (
		<Layout>
			{shipment ? (
				<ShipmentProfileLayout
					shipment={shipment}
					summary={
						<BrokerShipmentSummary
							shipment={shipment}
							customerName={clientConfig.tmsCompanyName}
						/>
					}
					subway={
						<BrokerShipmentProfileSubway
							brokerShipmentStatus={shipment.brokerShipmentStatus}
							tPrefix={tPrefix + '.brokerSubway'}
						/>
					}
					shipmentInteractionButtons={
						<BrokerShipmentInteractionButtons
							shipment={shipment}
							setIsUnbookCarrierModalOpen={setIsUnbookCarrierModalOpen}
						/>
					}
					shipmentDetails={<BrokerShipmentDetailsSection shipment={shipment} />}
					rates={
						<BrokerShipmentProfileRates
							shipment={shipment}
							clientConfig={clientConfig}
							runningApiCalls={runningApiCalls}
							isRating={isRating}
							setIsRating={setIsRating}
							websocketUrl={websocketUrl}
							setIsBookCarrierModalOpen={setIsBookCarrierModalOpen}
							setIsUnbookCarrierModalOpen={setIsUnbookCarrierModalOpen}
							rateBookingDetails={rateBookingDetails}
							setRateBookingDetails={setRateBookingDetails}
						/>
					}
					invites={
						<InvitesSection
							shipment={shipment}
							locationIdForConnections={clientConfig.brokerLocationId}
							inviteFunction={async (connectionIds: string[]) => {
								const inviteResult = await apiBroker.inviteConnections(
									() => {},
									shipment.id,
									shipment,
									connectionIds,
								)
								await sosShipmentProfileBroker.fetchShipment(shipment.id)
								return inviteResult
							}}
						/>
					}
					tracking={
						compareBrokerShipmentStatus(
							shipment.brokerShipmentStatus,
							'booked',
						) >= 0 ? (
							<ShipmentProfileTracking shipment={shipment} />
						) : null
					}
					bol={<BrokerShipmentProfileBOL shipment={shipment} />}
					docs={<DocumentSection shipment={shipment} />}
					invoices={
						<BrokerShipmentProfileInvoices
							shipmentId={shipment.id}
							contractId={shipment.contractId}
						/>
					}
					labels={null}
					bookUnbookModals={
						<>
							<BookCarrierModal
								isModalOpen={isBookCarrierModalOpen}
								onModalClose={() => {
									setIsBookCarrierModalOpen(false)
								}}
								tPrefix={tPrefix}
								rateBookingDetails={rateBookingDetails}
								setIsNotifyProviderModalOpen={setIsNotifyProviderModalOpen}
								setIsBooking={setIsBooking}
							/>

							<UnbookCarrierModal
								isModalOpen={isUnbookCarrierModalOpen}
								onModalClose={() => {
									setIsUnbookCarrierModalOpen(false)
								}}
								tPrefix={tPrefix}
								rateBookingDetails={rateBookingDetails}
								setIsNotifyProviderModalOpen={setIsNotifyProviderModalOpen}
								setIsBooking={setIsBooking}
							/>

							<NotifyProviderModal
								isModalOpen={isNotifyProviderModalOpen}
								onModalClose={() => {
									setIsNotifyProviderModalOpen(false)
								}}
								tPrefix={tPrefix}
								rateBookingDetails={rateBookingDetails}
								isBooking={isBooking}
							/>
						</>
					}
				/>
			) : (
				<Loader isLoading={true} />
			)}
		</Layout>
	)
}
