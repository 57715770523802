import { DateTime } from 'luxon'
import { apiTypes } from 'ui/api'
import { idx } from 'ui/lib'
import { l } from 'ui/lib/lodashImports'
import { toInteger } from 'ui/lib/numbers/toNumber'
import { AmPmType } from 'ui/components/common/TimePicker'

export type TimeType = 'standard' | 'before' | 'at' | 'after' | 'between'

// The value that indicates that no time has been selected
export const unsetTime = -1

export interface ITimeStore {
	hour?: number
	minute?: number
	amPm?: AmPmType
	isOpen?: boolean
}
export interface IDateTimeDesiredState {
	timeType?: TimeType
	date1: string
	time1?: ITimeStore
	date2: string
	time2?: ITimeStore
	isOpenDate1?: boolean
	isOpenTime1?: boolean
	isOpenDate2?: boolean
	isOpenTime2?: boolean
}

export const createDefaultTime = (): ITimeStore => {
	const defaultTime: ITimeStore = {
		hour: unsetTime,
		minute: unsetTime,
		amPm: 'pm',
		isOpen: false,
	}
	return defaultTime
}

export function createTime(timeString: string): ITimeStore {
	const time = DateTime.fromISO(timeString)
	const hour = toInteger(time.toFormat('h'))
	const minute = toInteger(time.toFormat('m')) || 0
	const amPm = idx(() => time.toFormat('a').toLowerCase())
	const newTime = createDefaultTime()
	return {
		hour: hour || newTime.hour,
		minute: hour ? minute : newTime.minute,
		amPm: amPm !== 'invalid datetime' ? amPm : newTime.amPm,
		isOpen: newTime.isOpen,
	}
}

export const createDefaultDesiredState = (): IDateTimeDesiredState => {
	const date1 = DateTime.local()
	const defaultDesiredState: IDateTimeDesiredState = {
		timeType: 'standard',
		date1: date1.toFormat('yyyy-LL-dd'),
		date2: '',
		time1: createDefaultTime(),
		time2: createDefaultTime(),
		isOpenDate1: false,
		isOpenTime1: false,
		isOpenDate2: false,
		isOpenTime2: false,
	}
	return defaultDesiredState
}

export const getDefaultTimesForLocationAndDate = (
	dateTimeDesired?: Partial<IDateTimeDesiredState>,
	location?: apiTypes.LocationResponse,
): Partial<IDateTimeDesiredState> => {
	if (!dateTimeDesired) {
		return createDefaultDesiredState()
	}

	const returnedDateTimeDesired: Partial<IDateTimeDesiredState> = l.cloneDeep(
		dateTimeDesired,
	)

	// if (location) {
	// const date1 = DateTime.fromISO(returnedDateTimeDesired.date1)
	// const dayOfWeek1 = idx(() => date1.toFormat('cccc').toLowerCase())
	// const dockHours1: apiTypes.DockHours = idx(
	// 	() => location.dockHours[dayOfWeek1],
	// )

	// if (dockHours1) {
	// 	if (!returnedDateTimeDesired.time1) {
	// 		returnedDateTimeDesired.time1 = createTime(dockHours1.openingTime)
	// 	}
	// }

	// const date2 = DateTime.fromISO(returnedDateTimeDesired.date2)
	// const dayOfWeek2 = idx(() => date2.toFormat('cccc').toLowerCase())
	// const dockHours2: apiTypes.DockHours = idx(
	// 	() => location.dockHours[dayOfWeek2],
	// )

	// if (dockHours2) {
	// 	if (!returnedDateTimeDesired.time2) {
	// 		returnedDateTimeDesired.time2 = createTime(dockHours1.closingTime)
	// 	}
	// }
	// }
	return returnedDateTimeDesired
}
