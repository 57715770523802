import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiTypes, apiPrintNode } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedSelect,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { validateForm } from 'ui/lib/validation/formValidator'
import * as classes from './PrintStationConfigModal.module.scss'

interface PrintStationConfigurationForm {
	name: string
	labelPrinter: string
	officePrinter: string
	// scale: string // TO-DO
}

const defaultPrintStationConfigurationForm: PrintStationConfigurationForm = {
	name: null,
	labelPrinter: null,
	officePrinter: null,
	// scale: null, // TO-DO
}

export const tPrefix =
	'page.companyManagement.printersAndScales.printStations.configModal'

const spacerHeight = '10px'

export const PrintStationConfigModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: (printer: apiTypes.PrinterResponse) => void
	printerList: apiTypes.PrinterResponse[]
	credentialsId: string
	printStationList: apiTypes.PrinterGroupResponse[]
	setPrintStationList: React.Dispatch<
		React.SetStateAction<apiTypes.PrinterGroupResponse[]>
	>
	selectedPrintStationConfig: apiTypes.PrinterGroupResponse
}) => {
	const {
		isModalOpen,
		onModalClose,
		printerList,
		credentialsId,
		setPrintStationList,
		selectedPrintStationConfig,
	} = props
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [
		printStationConfigurationSaveForm,
		setPrintStationConfigurationSaveForm,
	] = useState<PrintStationConfigurationForm>(
		defaultPrintStationConfigurationForm,
	)

	useEffect(() => {
		if (selectedPrintStationConfig) {
			const form = {
				name: selectedPrintStationConfig.nickname,
				labelPrinter: null,
				officePrinter: null,
			}

			selectedPrintStationConfig.printerIds.forEach((item) => {
				const savedPrinter = printerList.find((printer) => printer.id === item)

				if (savedPrinter?.printerType === 'office') {
					form.officePrinter = item
				} else if (savedPrinter?.printerType === 'label') {
					form.labelPrinter = item
				}
			})

			setPrintStationConfigurationSaveForm(form)
		} else {
			setPrintStationConfigurationSaveForm(defaultPrintStationConfigurationForm)
		}
	}, [printerList, isModalOpen, selectedPrintStationConfig])

	const onClose = (): void => {
		setPrintStationConfigurationSaveForm(defaultPrintStationConfigurationForm)
		onModalClose(null)
	}

	const onSave = async (): Promise<void> => {
		setIsLoading(true)

		const printerGroupRequest: apiTypes.PrinterGroupRequest = {
			nickname: printStationConfigurationSaveForm.name,
			printerIds: [
				printStationConfigurationSaveForm.labelPrinter,
				printStationConfigurationSaveForm.officePrinter,
			],
			printNodeCredentialsId: credentialsId,
		}

		let printerGroupResponse: IRequestState<apiTypes.PrinterGroupResponse>

		if (selectedPrintStationConfig?.id) {
			printerGroupResponse = await apiPrintNode.updatePrinterGroup(
				() => {},
				printerGroupRequest,
				credentialsId,
				selectedPrintStationConfig.id,
			)
		} else {
			printerGroupResponse = await apiPrintNode.createPrinterGroup(
				() => {},
				printerGroupRequest,
				credentialsId,
			)
		}

		if (printerGroupResponse.data) {
			const printerGroupResponse = await apiPrintNode.getPrinterGroupList(
				() => {},
				credentialsId,
			)

			if (printerGroupResponse.data) {
				setPrintStationList(printerGroupResponse.data)
			} else if (printerGroupResponse.error) {
				sosToast.sendApiErrorResponseToast(printerGroupResponse)
			}

			setPrintStationConfigurationSaveForm(defaultPrintStationConfigurationForm)
			onClose()
		} else if (printerGroupResponse.error) {
			sosToast.sendApiErrorResponseToast(printerGroupResponse)
		}

		setIsLoading(false)
	}

	const printStationForm: IFormData<PrintStationConfigurationForm> = {
		form: printStationConfigurationSaveForm,
		metadata: {
			name: { required: true },
			labelPrinter: {
				options: printerList
					.filter((printer) => printer.printerType === 'label')
					.map((printer) => ({
						label: printer.nickname || printer.computerName,
						value: printer.id,
					})),
			},
			officePrinter: {
				options: printerList
					.filter((printer) => printer.printerType === 'office')
					.map((printer) => ({
						label: printer.nickname || printer.computerName,
						value: printer.id,
					})),
			},
			// TO-DO
			// scale: {
			// 	options: [],
			// },
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(printStationConfigurationSaveForm)

			updatedFormData[field] = value
			setPrintStationConfigurationSaveForm(updatedFormData)
		},
		tPrefix,
	}

	const formIsValid = validateForm(
		printStationForm.form,
		printStationForm.metadata,
	).isValid

	return (
		<Modal
			content={() => (
				<div className={classes.printStationConfigModal}>
					<Spacer height={spacerHeight} />
					<FormStackedTextInput
						formData={printStationForm}
						field={'name'}
						testId={'print-station-config-name'}
					/>
					<Spacer height={spacerHeight} />
					<FormStackedSelect
						formData={printStationForm}
						field={'labelPrinter'}
						testId={'print-station-config-label-printer'}
						numListOptionsBeforeScroll={4}
					/>
					<Spacer height={spacerHeight} />
					<FormStackedSelect
						formData={printStationForm}
						field={'officePrinter'}
						testId={'print-station-config-office-printer'}
						numListOptionsBeforeScroll={4}
					/>
					<Spacer height={spacerHeight} />
					{/* <FormStackedSelect // TO-DO
						formData={printStationForm}
						field={'scale'}
						testId={'print-station-config-scale'}
						numListOptionsBeforeScroll={4}
					/> */}
					<Spacer height={spacerHeight} />
					<AlignRight>
						<OkCancelButtons
							ok={t('save', tPrefix)}
							onOk={onSave}
							okColor='green'
							cancel={t('cancel', tPrefix)}
							onCancel={onClose}
							isSpinning={isLoading}
							isValid={formIsValid}
							okTestId={'print-station-config-save-button'}
							cancelTestId={'print-station-config-cancel-button'}
						/>
					</AlignRight>
				</div>
			)}
			isOpen={isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('printStationConfiguration', tPrefix)}
		/>
	)
}
