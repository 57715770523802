import { apiTypes, apiProviders } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'

export const searchProviders = async (
	take = 25,
	skip = 0,
	query: string,
): Promise<IRequestState<apiTypes.ProviderListResponse>> => {
	return await apiProviders.getProviders(
		(rs: IRequestState<apiTypes.ProviderListResponse>) => {},
		take,
		skip,
		query,
	)
}
