import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import React, { useState } from 'react'
import { apiTypes } from 'ui/api'
import { Loader } from 'ui/components/common/loader'
import { useOnce } from 'ui/components/hooks'
import { tString } from 'ui/components/i18n/i18n'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { getBrokerShipmentProfileUrlState } from 'ui/pages/shipment-profile/broker/sosShipmentProfileBroker'
import { sosShipmentProfileShipper } from '.'
import { Layout } from '../../layout'
import { ShipmentProfileTracking } from '../shared/components/tracking'
import { ShipmentProfileLayout } from '../ShipmentProfileLayout'
import { ShipperShipmentProfileRates } from './components/rates'
import { ShipperShipmentDetailsSection } from './components/shipment-details'
import { ShipperShipmentInteractionButtons } from './components/shipper-shipment-interaction-buttons'
import { ShipperShipmentSummary } from './components/shipper-shipment-summary'
import { trickleRateShipperShipment } from './functions'
import { ShipperShipmentProfileLabels } from '../shared/components/labels'
import { BookCarrierModal } from 'ui/pages/shipment-profile/shared/components/book-carrier-modal'
import { UnbookCarrierModal } from 'ui/pages/shipment-profile/shared/components/unbook-carrier-modal'
import { NotifyProviderModal } from 'ui/pages/shipment-profile/shared/components/notify-provider-modal'
import {
	RateBookingDetails,
	defaultRateBookingDetails,
} from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'

const tPrefix = 'page.shipmentProfile.shipper'

const shipmentStatusesThatNeedRates: apiTypes.ShipmentResponse['shipmentStatus'][] = [
	'preparing',
	'active',
	'active_invites_not_sent',
]

export const ShipmentProfileShipper: FC = (props: {}) => {
	const { shipment, websocketUrl, runningApiCalls } = sos2.useSubscription(
		sosShipmentProfileShipper.getSos(),
	)
	const [isRating, setIsRating] = useState(false)

	const [isBookCarrierModalOpen, setIsBookCarrierModalOpen] = useState<boolean>(
		false,
	)
	const [isUnbookCarrierModalOpen, setIsUnbookCarrierModalOpen] = useState<
		boolean
	>(false)
	const [isNotifyProviderModalOpen, setIsNotifyProviderModalOpen] = useState<
		boolean
	>(false)
	const [rateBookingDetails, setRateBookingDetails] = useState<
		RateBookingDetails
	>(defaultRateBookingDetails)
	const [isBooking, setIsBooking] = useState<boolean>(false)

	const shouldGetNewRates = (shipment: apiTypes.ShipmentResponse): boolean => {
		return (
			shipment &&
			shipment.rates.filter(
				(rate) =>
					['contract', 'webRate'].includes(rate.quoteType) &&
					!(rate as any).isVoid,
			).length === 0
		)
	}

	useOnce(async () => {
		const { shipmentId } = getBrokerShipmentProfileUrlState()
		const fetchResult = await sosShipmentProfileShipper.fetchShipment(
			shipmentId,
		)
		if (
			shipmentStatusesThatNeedRates.includes(
				fetchResult.data?.shipmentStatus,
			) ||
			shouldGetNewRates(fetchResult.data)
		) {
			fireAndForget(async () => {
				setIsRating(true)
				const updatedShipment = l.cloneDeep(
					sosShipmentProfileShipper.getSos().getState().shipment,
				)
				updatedShipment.shipmentStatus = 'quoted'
				sosShipmentProfileShipper.setShipmentReference(updatedShipment)
				await trickleRateShipperShipment(websocketUrl, () => {
					setIsRating(false)
					fireAndForget(
						() => sosShipmentProfileShipper.fetchShipment(updatedShipment.id),
						'refetching shipment after trickle rating',
					)
				})
			}, 'trickle rating shipment')
		} else if (fetchResult.error) {
			sosToast.sendApiErrorResponseToast(
				fetchResult,
				tString('shipmentFetchError', tPrefix),
			)
		}

		const updatedRateBookingDetails = {
			...rateBookingDetails,
			shipmentId: shipmentId,
			isBroker: false,
		}
		setRateBookingDetails(updatedRateBookingDetails)
	})

	return (
		<Layout>
			{shipment ? (
				<ShipmentProfileLayout
					shipment={shipment}
					summary={<ShipperShipmentSummary shipment={shipment} />}
					subway={null}
					shipmentDetails={
						<ShipperShipmentDetailsSection shipment={shipment} />
					}
					shipmentInteractionButtons={
						<ShipperShipmentInteractionButtons
							shipment={shipment}
							setIsUnbookCarrierModalOpen={setIsUnbookCarrierModalOpen}
						/>
					}
					rates={
						<ShipperShipmentProfileRates
							shipment={shipment}
							isRating={isRating}
							setIsRating={setIsRating}
							websocketUrl={websocketUrl}
							runningApiCalls={runningApiCalls}
							setIsBookCarrierModalOpen={setIsBookCarrierModalOpen}
							setRateBookingDetails={setRateBookingDetails}
						/>
					}
					invites={null}
					tracking={
						shipment.shipmentStatus === 'booked' ? (
							<ShipmentProfileTracking shipment={shipment} />
						) : null
					}
					bol={null}
					docs={null}
					labels={<ShipperShipmentProfileLabels shipment={shipment} />}
					hideLogOffer={true}
					bookUnbookModals={
						<>
							<BookCarrierModal
								isModalOpen={isBookCarrierModalOpen}
								onModalClose={() => {
									setIsBookCarrierModalOpen(false)
								}}
								tPrefix={tPrefix}
								rateBookingDetails={rateBookingDetails}
								setIsNotifyProviderModalOpen={setIsNotifyProviderModalOpen}
								setIsBooking={setIsBooking}
							/>

							<UnbookCarrierModal
								isModalOpen={isUnbookCarrierModalOpen}
								onModalClose={() => {
									setIsUnbookCarrierModalOpen(false)
								}}
								tPrefix={tPrefix}
								rateBookingDetails={rateBookingDetails}
								setIsNotifyProviderModalOpen={setIsNotifyProviderModalOpen}
								setIsBooking={setIsBooking}
							/>

							<NotifyProviderModal
								isModalOpen={isNotifyProviderModalOpen}
								onModalClose={() => {
									setIsNotifyProviderModalOpen(false)
								}}
								tPrefix={tPrefix}
								rateBookingDetails={rateBookingDetails}
								isBooking={isBooking}
							/>
						</>
					}
				/>
			) : (
				<Loader isLoading={true} />
			)}
		</Layout>
	)
}
