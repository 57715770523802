import { FC } from 'app/FunctionalComponent'
import { cloneDeep } from 'lodash'
import React, { useState } from 'react'
import { apiAddressBook, apiTypes } from 'ui/api'
import { Modal } from 'ui/components/common/modal'
import { ElasticSearchPager } from 'ui/components/common/pager'
import { SearchInput } from 'ui/components/common/search'
import { StackedItem } from 'ui/components/form'
import { useOnce } from 'ui/components/hooks'
import { tAddressType } from 'ui/components/i18n/commonTranslations'
import { t } from 'ui/components/i18n/i18n'
import { DataTable, IDataTableHeader } from 'ui/components/table'
import { fireAndForget } from 'ui/lib/async'
import { copyStatePagerFromElasticsearch, IStatePager } from 'ui/state/paging'

const tPrefix = 'page.newQuote.parcel.address.addressBookModal'

const headers: IDataTableHeader<apiTypes.Address>[] = [
	{
		field: 'company',
	},
	{
		field: 'street1',
	},
	{
		field: 'street2',
	},
	{
		field: 'city',
	},
	{
		field: 'state',
		monospace: true,
	},
	{
		field: 'zip',
		monospace: true,
	},
	{
		field: 'country',
	},
	{
		field: 'addressType',
		renderer: (addressType: apiTypes.AddressType) => tAddressType(addressType),
	},
	{
		field: 'name',
	},
	{
		field: 'phone',
		monospace: true,
	},
	{
		field: 'email',
	},
]

const searchFields = [
	'address.company',
	'address.street1',
	'address.street2',
	'address.city',
	'address.state',
	'address.zip',
	'address.country',
	'address.addressType',
	'address.name',
	'address.phone',
	'address.email',
]

const pageSize = 25

const defaultPager: IStatePager = {
	pageNumber: 0,
	pageCount: 1,
	pageSize: pageSize,
	total: null,
	hasMore: false,
}

export const NewQuoteParcelAddressBookModal: FC = (props: {
	isAddressBookModalOpen: boolean
	onClose: () => void
	onClickRow?: (addressChanges: apiTypes.Address) => void
}) => {
	const { isAddressBookModalOpen, onClose, onClickRow } = props

	const [addressBookList, setAddressBookList] = useState<apiTypes.Address[]>([])
	const [modalPager, setModalPager] = useState<IStatePager>(defaultPager)
	const [searchFor, setSearchFor] = useState<string>('')
	const [fetchingAddressBookList, setFetchingAddressBookList] = useState<
		boolean
	>(false)

	useOnce(() => {
		onSearchAddressBook(searchFor, modalPager.pageNumber)
	})

	const onSearchAddressBook = (
		searchQuery: string = null,
		pageNumber: number,
	): void => {
		fireAndForget(async () => {
			setSearchFor(searchQuery)
			setFetchingAddressBookList(true)

			const result = await apiAddressBook.searchAddressBook(() => {}, {
				take: pageSize,
				skip: pageNumber * pageSize,
				fields: searchFields,
				wildcards: [searchQuery],
			})

			if (result.data) {
				setAddressBookList(
					result.data.entities.map(
						(entity: apiTypes.AddressPageResponse) => entity.address,
					),
				)

				copyStatePagerFromElasticsearch(modalPager, {
					pageNumber: pageNumber,
					pageCount: result.data.pageCount,
					pageSize: pageSize,
					total: result.data.total,
					take: result.data.take,
					skip: result.data.skip,
				})

				setModalPager(cloneDeep(modalPager))
			}

			setFetchingAddressBookList(false)
		}, 'Searching Address Book')
	}

	return (
		<Modal
			isOpen={isAddressBookModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('addressBook', tPrefix)}
			fullScreen={true}
			content={() => (
				<div>
					<StackedItem label={t('shared.address.search')} showRequired={true}>
						<SearchInput
							testId={'address-book-modal-search-input'}
							value={searchFor || ''}
							onChange={(searchTerm: string) => {
								onSearchAddressBook(searchTerm, modalPager.pageNumber)
							}}
							width='360px'
						/>
					</StackedItem>
					<DataTable
						data={addressBookList}
						state={{}}
						isLoading={fetchingAddressBookList}
						tPrefix='shared.address.addressBook'
						headers={headers}
						onClickRow={(row) => {
							if (onClickRow) {
								onClickRow(row)
							}
						}}
						testId={'address-book-modal-data-table'}
					/>
					<ElasticSearchPager
						pager={modalPager}
						onClickPage={(pageNum: number) => {
							onSearchAddressBook(searchFor, pageNum)
						}}
					/>
				</div>
			)}
		/>
	)
}
