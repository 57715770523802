import _ from 'lodash'

import { IGood } from './IGood'
import { apiTypes } from 'ui/api'

export function extractGoodsFromShipment(shipment: any): IGood[] {
	const goods = []
	if (!shipment) {
		return goods
	}
	shipment.payloads.forEach((payload) => {
		payload.containers.forEach((container) => {
			extractGoodsFromContainer(container, goods)
		})
	})
	return goods
}

function extractGoodsFromContainer(
	container: apiTypes.Container,
	goods: IGood[],
): IGood[] {
	goods.push(...container.goods)
	_.forEach(container.containers, (nestedContainer) => {
		extractGoodsFromContainer(nestedContainer, goods)
	})
	return goods
}
