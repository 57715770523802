import { apiTypes, apiShipments, apiDockScheduler } from 'ui/api'
import {
	AppointmentFlowFilter,
	AppointmentModeFilter,
} from './sosDockScheduler'
import { l } from 'ui/lib/lodashImports'
import { elasticSearchBuilder, processSearchString } from 'ui/lib/elasticSearch'
import { sosDockScheduler } from '.'
import { concatenateOrderNumbers } from 'ui/lib/shipments/shipmentUtils'
import { IRequestState } from 'ui/api/requestState'
import { fireAndForget } from 'ui/lib/async'
import { DateTime } from 'luxon'
import {
	IScheduleTimeSlot,
	tPrefixTimeSlots,
} from '../components/DockSchedulerTimeSlots'
import { zipToTimezone } from 'ui/lib/addresses/zipToTimezone'
import { idx } from 'ui/lib'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import {
	IStatePager,
	createPagerSosMeta,
	getTakeAndSkip,
	copyStatePagerFromElasticsearch,
} from 'ui/state/paging'
import { fetchAppointmentsForLocationOnDay } from './appointmentFetchUtils'
import { validateDockWithEquipmentTypeFlowMode } from '../components/dockSchedulerTimeSlotsUtils'
import {
	tEquipmentType,
	tMode,
	tStopType,
	tProviderName,
} from 'ui/components/i18n/commonTranslations'
import { tString } from 'ui/components/i18n/i18n'
import { IFormCreateAppointmentWithoutShipment } from '../schedule-tab/AppointmentWithoutShipmentCreateModal'
import { isInTMS2 } from 'ui/theme/theme'
import { navParentTo } from 'ui/lib/IframeRpc'

const fetchDebounceTime = 1000
const fetchPollInterval = 5000

const defaultAppointmentWithoutShipmentCreateForm: IFormCreateAppointmentWithoutShipment = {
	carrier: '',
	otherCompanyNames: '',
	proNumber: '',
	poNumbers: '',
	soNumbers: '',
	mode: null,
	flow: null,
	equipmentType: null,
	handlingUnits: null,
	handlingUnitType: 'palletCstm',
	weight: null,
}

export interface IShipmentWithoutAppointmentRow {
	id: string
	slid: string
	carrier: string
	mode: apiTypes.ShipmentResponse['mode']
	flow: apiTypes.AppointmentResponse['stopType']
	poNumber: string
	soNumber: string
	proNumber: string
	date: string
	archived: boolean
	equipmentType: apiTypes.ShipmentResponse['equipmentType']
}

const shipmentsWithoutAppointmentsPageSize = 10

export interface IStateDockSchedulerScheduleTab {
	requestShipmentsWithoutAppointments: IRequestState<
		apiTypes.ShipmentListResponse
	>
	fetchingShipmentsWithoutAppointments: boolean
	shipmentsWithoutAppointmentsSearchTerm: string
	shipmentsWithoutAppointmentsList: IShipmentWithoutAppointmentRow[]
	shipmentsWithoutAppointmentsPager: IStatePager
	selectedShipmentWithoutAppointment: IShipmentWithoutAppointmentRow
	selectedTimeSlot: IScheduleTimeSlot
	creatingAppointment: boolean
	appointmentCreateError: string
	appointmentCreateSuccess: boolean
	appointments: apiTypes.AppointmentResponse[]
	fetchingAppointments: boolean
	flowFilter: AppointmentFlowFilter
	modeFilter: AppointmentModeFilter
	showArchived: boolean
	shipmentsTogglingArchives: string[]
	calendarOpen: boolean
	selectedDate: string // ISO Date format
	scheduleErrorReason: string
	selectedItemThatCausedScheduleError: 'stop' | 'timeslot'
	appointmentWithoutShipmentCreateModalOpen: boolean
	appointmentWithoutShipmentCreateForm: IFormCreateAppointmentWithoutShipment
	creatingAppointmentWithoutShipment: boolean
	appointmentWithoutShipmentCreateError: string
	timeslotDockDetailsToShow: IScheduleTimeSlot
}

export const getSos = createLazySos2<IStateDockSchedulerScheduleTab>(
	'sosDockSchedulerScheduleTab',
	3,
	() => ({
		requestShipmentsWithoutAppointments: { default: null, localStorage: true },
		fetchingShipmentsWithoutAppointments: {
			default: false,
			localStorage: false,
		},
		shipmentsWithoutAppointmentsSearchTerm: { default: '', localStorage: true },
		shipmentsWithoutAppointmentsList: { default: [], localStorage: true },
		shipmentsWithoutAppointmentsPager: createPagerSosMeta(
			undefined,
			undefined,
			shipmentsWithoutAppointmentsPageSize,
		),
		selectedShipmentWithoutAppointment: { default: null, localStorage: false },
		selectedTimeSlot: { default: null, localStorage: false },
		creatingAppointment: { default: false, localStorage: false },
		appointmentCreateError: { default: null, localStorage: false },
		appointmentCreateSuccess: { default: false, localStorage: false },
		appointments: { default: [], localStorage: false },
		fetchingAppointments: { default: false, localStorage: false },
		flowFilter: { default: 'all', localStorage: true },
		modeFilter: { default: 'all', localStorage: true },
		showArchived: { default: false, localStorage: true },
		shipmentsTogglingArchives: { default: [], localStorage: false },
		calendarOpen: { default: false, localStorage: false },
		selectedDate: {
			default: DateTime.local().toISODate(),
			localStorage: false,
		},
		scheduleErrorReason: { default: null, localStorage: false },
		selectedItemThatCausedScheduleError: { default: null, localStorage: false },
		appointmentWithoutShipmentCreateModalOpen: {
			default: false,
			localStorage: false,
		},
		appointmentWithoutShipmentCreateForm: {
			default: defaultAppointmentWithoutShipmentCreateForm,
			localStorage: false,
		},
		creatingAppointmentWithoutShipment: { default: false, localStorage: false },
		appointmentWithoutShipmentCreateError: {
			default: null,
			localStorage: false,
		},
		timeslotDockDetailsToShow: { default: null, localStorage: false },
	}),
)

export function goToShipmentDetails(id: string): void {
	if (isInTMS2()) {
		fireAndForget(
			async () => await navParentTo(`/shipment_profile/${id}`, true),
			'navigating to TMS2 shipment profile',
		)
	} else {
		sosShipmentProfileBroker.navigateToShipmentProfile({
			shipmentId: id,
		})
	}
}

export async function setShipmentArchived(
	id: string,
	archive: boolean,
): Promise<void> {
	getSos().change((ds) => {
		ds.shipmentsTogglingArchives.push(id)
	})
	const requestShipmentsWithoutAppointments = l.cloneDeep(
		getSos().getState().requestShipmentsWithoutAppointments,
	)
	const shipmentToUpdate = l.cloneDeep(
		l.find(
			requestShipmentsWithoutAppointments.data.entities,
			(shipmentRespsone: apiTypes.ShipmentResponse) =>
				shipmentRespsone.id === id,
		),
	)
	if (!shipmentToUpdate.flags) {
		shipmentToUpdate.flags = []
	}
	if (archive) {
		shipmentToUpdate.flags.push('dockSchedulerArchived')
	} else {
		l.pull(shipmentToUpdate.flags, 'dockSchedulerArchived')
	}
	const updateResult = await apiShipments.updateShipment(
		shipmentToUpdate.id,
		shipmentToUpdate,
		() => {},
	)
	if (updateResult.data) {
		const responseIndex = l.findIndex(
			requestShipmentsWithoutAppointments.data.entities,
			(shipmentResponse) => shipmentResponse.id === id,
		)
		requestShipmentsWithoutAppointments.data.entities.splice(
			responseIndex,
			1,
			updateResult.data,
		)
		getSos().change((ds) => {
			ds.requestShipmentsWithoutAppointments = requestShipmentsWithoutAppointments
			l.pull(ds.shipmentsTogglingArchives, id)
			if (archive && !ds.showArchived) {
				l.remove(
					ds.shipmentsWithoutAppointmentsList,
					(shipment) => shipment.id === id,
				)
			}
		})
	}
}

export async function updateShipmentsWithoutAppointmentsCurrentPage(
	newPage: number,
): Promise<void> {
	getSos().change((ds) => {
		ds.shipmentsWithoutAppointmentsPager.fetchingPageNumber = newPage
	})
	fireAndForget(
		_fetchShipmentsWithoutAppointments,
		'fetching shipments with appointments after updating page',
	)
}

export function updateShipmentsWithoutAppointmentsSearchTerm(
	searchTerm: string,
): void {
	getSos().change((ds) => {
		ds.shipmentsWithoutAppointmentsSearchTerm = searchTerm
	})
	fireAndForget(
		_fetchShipmentsWithoutAppointments,
		'fetching shipments without appointments after updating search term',
	)
}

export const _fetchShipmentsWithoutAppointments = l.debounce(
	getShipmentsWithoutAppointments,
	fetchDebounceTime,
)

function convertRateToProviderName(rate: apiTypes.RateResponse): string {
	return l.startCase(
		rate?.providerName ? tProviderName(rate?.providerName) : rate?.carrier,
	)
}

async function getShipmentsWithoutAppointments(polling = false): Promise<void> {
	const dockSchedulerState = sosDockScheduler.getSos().getState()
	if (!dockSchedulerState.currentLocation) {
		return
	}
	const state = getSos().getState()
	const qb = elasticSearchBuilder()
	const locationId = dockSchedulerState.currentLocation.id
	qb.and('shipmentStatus', 'booked')
	qb.andOr([
		['(NOT _exists_', 'paymentStatus)'],
		['paymentStatus', 'waitingOnInvoice'],
	])
	qb.and('_exists_', 'bookedRate')
	qb.andNot('shipmentSource', 'docMatching')

	if (!state.showArchived) {
		qb.andNot('flags', 'dockSchedulerArchived')
	}

	if (state.modeFilter === 'all') {
		qb.andNot('mode', 'parcel')
	} else {
		qb.and('mode', state.modeFilter)
	}

	// below query is query where one of the payloads needs to satisfy every condition of the query instead of all the payloads combined satisfying the query
	const payloadNestedQb = elasticSearchBuilder()
	payloadNestedQb.andNot('payloads.trackingStatus', 'delivered')
	if (state.flowFilter === 'all') {
		payloadNestedQb.andOrAnd([
			[
				['payloads.originStop.metaData.locationId', locationId],
				['NOT _exists_', 'payloads.originStop.metaData.datetimeActual'],
				['NOT _exists_', 'payloads.originStop.metaData.appointmentId'],
			],
			[
				['payloads.destinationStop.metaData.locationId', locationId],
				['NOT _exists_', 'payloads.destinationStop.metaData.datetimeActual'],
				['NOT _exists_', 'payloads.destinationStop.metaData.appointmentId'],
			],
		])
	} else if (state.flowFilter === 'outbound') {
		payloadNestedQb.and('payloads.originStop.metaData.locationId', locationId)
		payloadNestedQb.and(
			'NOT _exists_',
			'payloads.originStop.metaData.datetimeActual',
		)
		payloadNestedQb.and(
			'NOT _exists_',
			'payloads.originStop.metaData.appointmentId',
		)
	} else if (state.flowFilter === 'inbound') {
		payloadNestedQb.and(
			'payloads.destinationStop.metaData.locationId',
			locationId,
		)
		payloadNestedQb.and(
			'NOT _exists_',
			'payloads.destinationStop.metaData.datetimeActual',
		)
		payloadNestedQb.and(
			'NOT _exists_',
			'payloads.destinationStop.metaData.appointmentId',
		)
	}

	const { take, skip } = getTakeAndSkip(
		state.shipmentsWithoutAppointmentsPager,
		shipmentsWithoutAppointmentsPageSize,
	)

	if (!polling) {
		getSos().change((ds) => {
			ds.shipmentsWithoutAppointmentsList = []
			ds.fetchingShipmentsWithoutAppointments = true
		})
	}
	const fields = [
		'id',
		'identifier',
		'bookedRate.carrier',
		'mode',
		'payloads.purchaseOrders',
		'payloads.salesOrders',
		'proNumber',
		'equipmentType',
	]
	const shipmentListResponse = await apiShipments.fetchShipments(
		(rs) => {
			getSos().change((ds) => {
				ds.requestShipmentsWithoutAppointments = rs
			})
		},
		{
			take,
			skip,
			query: qb.toString(),
			sort: 'createdDate:asc',
			fields,
			nestedFieldQuery: payloadNestedQb.toString(),
			wildcards: state.shipmentsWithoutAppointmentsSearchTerm
				? processSearchString(
						state.shipmentsWithoutAppointmentsSearchTerm,
						true,
				  ).split(' ')
				: undefined,
		},
	)
	if (shipmentListResponse.data) {
		const dataRows: IShipmentWithoutAppointmentRow[] = shipmentListResponse.data.entities.map(
			(shipment: apiTypes.ShipmentResponse): IShipmentWithoutAppointmentRow => {
				let flow: apiTypes.AppointmentResponse['stopType'], shipmentDate: string
				const pickingUpPayloads = l.filter(
					shipment.payloads,
					(payload) => payload.originStop.metaData.locationId === locationId,
				)
				const pickingUpFromLocation: boolean = pickingUpPayloads.length > 0
				const deliveringPayloads = l.filter(
					shipment.payloads,
					(payload) =>
						payload.destinationStop.metaData.locationId === locationId,
				)
				const deliveringToLocation = deliveringPayloads.length > 0

				let payloads: apiTypes.PayloadResponse[]
				if (pickingUpFromLocation && deliveringToLocation) {
					// TODO AAAAAAHHHHH THERE ISN'T EVEN A TYPE FOR THIS, THEY ARE PICKING UP AND DROPPING OFF (do this when we do multistop)
					flow = 'delivery'
					payloads = pickingUpPayloads.concat(deliveringPayloads)
				} else if (pickingUpFromLocation) {
					flow = 'pickup'
					payloads = pickingUpPayloads
					shipmentDate = shipment.pickupTimeRange.initialDate
				} else if (deliveringToLocation) {
					flow = 'delivery'
					payloads = deliveringPayloads
					shipmentDate = shipment.deliveryTimeRange.initialDate
				}
				return {
					id: shipment.id,
					slid: shipment.identifier,
					carrier: convertRateToProviderName(shipment.bookedRate),
					mode: shipment.mode,
					flow,
					poNumber: concatenateOrderNumbers(payloads, 'purchaseOrders'),
					soNumber: concatenateOrderNumbers(payloads, 'salesOrders'),
					proNumber: shipment.proNumber,
					date: shipmentDate,
					archived:
						idx(() => shipment.flags.indexOf('dockSchedulerArchived') > -1) ||
						false,
					equipmentType: shipment.equipmentType,
				}
			},
		)
		getSos().change((ds) => {
			ds.shipmentsWithoutAppointmentsList = dataRows
			ds.fetchingShipmentsWithoutAppointments = false
			if (
				l.isNil(ds.shipmentsWithoutAppointmentsPager.fetchingPageNumber) ||
				shipmentListResponse.data.skip / shipmentListResponse.data.take ===
					ds.shipmentsWithoutAppointmentsPager.fetchingPageNumber
			) {
				// must condition because sometimes a previous poll will overwrite what the pager state should be
				copyStatePagerFromElasticsearch(
					ds.shipmentsWithoutAppointmentsPager,
					shipmentListResponse.data,
				)
			}
		})
		if (sosDockScheduler.getUrlState().selectedPage === 'schedule') {
			setTimeout(
				async () => await _fetchShipmentsWithoutAppointments(true),
				fetchPollInterval,
			)
		}
	} else {
		getSos().change((ds) => {
			ds.fetchingShipmentsWithoutAppointments = false
		})
	}
}

export function updateScheduleTabFlowFilter(
	filter: AppointmentFlowFilter,
): void {
	if (!getSos().getState().requestShipmentsWithoutAppointments.isFetching) {
		getSos().change((ds) => {
			ds.flowFilter = filter
			ds.shipmentsWithoutAppointmentsPager.fetchingPageNumber = 0
		})
		fireAndForget(
			_fetchShipmentsWithoutAppointments,
			'refetching shipments after changing flow filter',
		)
	}
}

export function updateScheduleTabModeFilter(
	filter: AppointmentModeFilter,
): void {
	if (!getSos().getState().requestShipmentsWithoutAppointments.isFetching) {
		getSos().change((ds) => {
			ds.modeFilter = filter
			ds.shipmentsWithoutAppointmentsPager.fetchingPageNumber = 0
		})
		fireAndForget(
			_fetchShipmentsWithoutAppointments,
			'refetching shipments after changing mode filter',
		)
	}
}

export function toggleShowArchived(val: boolean): void {
	if (!getSos().getState().requestShipmentsWithoutAppointments.isFetching) {
		getSos().change((ds) => {
			ds.showArchived = val
			ds.shipmentsWithoutAppointmentsPager.fetchingPageNumber = 0
		})
		fireAndForget(
			_fetchShipmentsWithoutAppointments,
			'refetching shipments after toggling archive flag',
		)
	}
}

export function toggleCalendarShowing(showing: boolean): void {
	getSos().change((ds) => {
		ds.calendarOpen = showing
	})
}

export function setCalendarDate(date: string): void {
	getSos().change((ds) => {
		ds.selectedDate = date
	})
	fireAndForget(
		_fetchAppointmentsForLocationOnSelectedDay,
		'refetching appointments after changing date',
	)
}

export const _fetchAppointmentsForLocationOnSelectedDay = l.debounce(
	getAppointmentsForLocationOnSelectedDay,
	fetchDebounceTime,
)

async function getAppointmentsForLocationOnSelectedDay(
	polling = false,
): Promise<void> {
	if (!polling) {
		getSos().change((ds) => {
			ds.appointments = []
			ds.fetchingAppointments = true
		})
	}
	const appointments: apiTypes.AppointmentResponse[] = await fetchAppointmentsForLocationOnDay(
		sosDockScheduler.getSos().getState().currentLocation.id,
		getSos().getState().selectedDate,
	)

	getSos().change((ds) => {
		ds.appointments = appointments
		ds.fetchingAppointments = false
	})
	if (sosDockScheduler.getUrlState().selectedPage === 'schedule') {
		setTimeout(
			async () => await _fetchAppointmentsForLocationOnSelectedDay(true),
			fetchPollInterval,
		)
	}
}

export function setSelectedShipmentWithoutAppointment(
	row: IShipmentWithoutAppointmentRow,
): void {
	const state: IStateDockSchedulerScheduleTab = getSos().getState()
	const updatedShipmentWithoutAppointment =
		!state.selectedShipmentWithoutAppointment ||
		row?.id !== state.selectedShipmentWithoutAppointment.id
			? row
			: null
	let timeslotInvalidReason: string
	if (updatedShipmentWithoutAppointment && state.selectedTimeSlot) {
		timeslotInvalidReason = validateDataWithSelectedTimeSlot(
			updatedShipmentWithoutAppointment.equipmentType,
			updatedShipmentWithoutAppointment.flow,
			updatedShipmentWithoutAppointment.mode,
			state.selectedTimeSlot,
		)
	}
	getSos().change((ds) => {
		ds.selectedShipmentWithoutAppointment = updatedShipmentWithoutAppointment
		ds.scheduleErrorReason = timeslotInvalidReason
		ds.selectedItemThatCausedScheduleError = timeslotInvalidReason
			? 'stop'
			: null
	})
	if (
		updatedShipmentWithoutAppointment &&
		state.selectedTimeSlot &&
		!timeslotInvalidReason
	) {
		fireAndForget(
			() => createAppointmentForShipmentAndTimeSlot(false),
			'creating appointment after selecting shipment',
		)
	}
}

export function clearTimeslotDockDetailsToShow(): void {
	getSos().change((ds) => {
		ds.timeslotDockDetailsToShow = null
	})
}

export function setSelectedTimeslot(slot: IScheduleTimeSlot): void {
	const state = getSos().getState()
	const updatedTimeSlot = !l.isEqual(slot, state.selectedTimeSlot) ? slot : null
	let timeslotInvalidReason: string
	if (updatedTimeSlot && state.selectedShipmentWithoutAppointment) {
		timeslotInvalidReason = validateDataWithSelectedTimeSlot(
			state.selectedShipmentWithoutAppointment.equipmentType,
			state.selectedShipmentWithoutAppointment.flow,
			state.selectedShipmentWithoutAppointment.mode,
			updatedTimeSlot,
		)
	}
	getSos().change((ds) => {
		ds.selectedTimeSlot = updatedTimeSlot
		ds.scheduleErrorReason = timeslotInvalidReason
		ds.selectedItemThatCausedScheduleError = timeslotInvalidReason
			? 'timeslot'
			: null
		ds.timeslotDockDetailsToShow =
			updatedTimeSlot && !state.selectedShipmentWithoutAppointment
				? updatedTimeSlot
				: null
	})
	if (
		updatedTimeSlot &&
		state.selectedShipmentWithoutAppointment &&
		!timeslotInvalidReason
	) {
		fireAndForget(
			() => createAppointmentForShipmentAndTimeSlot(false),
			'creating appointment after selecting time slot',
		)
	}
}

function validateDataWithSelectedTimeSlot(
	equipmentType: apiTypes.ShipmentResponse['equipmentType'],
	flow: apiTypes.AppointmentResponse['stopType'],
	mode: apiTypes.ShipmentResponse['mode'],
	timeslot: IScheduleTimeSlot,
): string {
	if (timeslot.dockIds.length > 0) {
		const docks = sosDockScheduler
			.getSos()
			.getState()
			.docks.filter((dock) => timeslot.dockIds.indexOf(dock.id) > -1)
		const dockInvalidReasons = l.uniq(
			docks.map((dock) =>
				validateDockWithEquipmentTypeFlowMode(dock, equipmentType, flow, mode),
			),
		)
		if (l.compact(dockInvalidReasons).length === dockInvalidReasons.length) {
			// since validateDockWithEquipmentTypeFlowMode returns null for valid docks, if we get here every dock is invalid, return an error
			return (
				tString('noAvailableDocksFor', tPrefixTimeSlots) +
				' ' +
				dockInvalidReasons
					.map((invalidCategory) => {
						if (invalidCategory === 'equipmentType') {
							return tEquipmentType(equipmentType)
						} else if (invalidCategory === 'flow') {
							return tStopType(flow)
						} else if (invalidCategory === 'mode') {
							return tMode(mode)
						}
						return null
					})
					.join(', ')
			)
		}
	} else if (timeslot.slotUnavailableReason) {
		return timeslot.slotUnavailableReason
	}
	return null
}

export async function createAppointmentForShipmentAndTimeSlot(
	forceSchedule: boolean,
): Promise<void> {
	const state: IStateDockSchedulerScheduleTab = getSos().getState()
	if (state.selectedShipmentWithoutAppointment && state.selectedTimeSlot) {
		const location: apiTypes.LocationResponse = sosDockScheduler
			.getSos()
			.getState().currentLocation
		if (state.selectedShipmentWithoutAppointment && state.selectedTimeSlot) {
			getSos().change((ds) => {
				ds.appointmentCreateError = null
				ds.appointmentCreateSuccess = false
				ds.creatingAppointment = true
			})
			const appointmentRequest: apiTypes.AppointmentRequest = {
				locationId: location.id,
				startTime: createStartTimeFromTimeSlot(
					state.selectedTimeSlot,
					state.selectedDate,
					location,
				),
				scheduledDuration: state.selectedTimeSlot.durationMinutes,
				stopType: state.selectedShipmentWithoutAppointment.flow,
				status: 'not-arrived',
				trailerInfo: {
					carrier: state.selectedShipmentWithoutAppointment.carrier,
				},
				comment: state.selectedTimeSlot.slotUnavailableReason
					? `Appointment created with warning: ${state.selectedTimeSlot.slotUnavailableReason}`
					: undefined,
			}
			const result: IRequestState<apiTypes.AppointmentResponse> = await apiDockScheduler.createAppointment(
				() => {},
				appointmentRequest,
				forceSchedule,
				state.selectedShipmentWithoutAppointment.id,
			)
			if (result.data) {
				getSos().change((ds) => {
					ds.selectedShipmentWithoutAppointment = null
					ds.selectedTimeSlot = null
					ds.appointmentCreateSuccess = true
					ds.creatingAppointment = false
					ds.scheduleErrorReason = null
					ds.selectedItemThatCausedScheduleError = null
					ds.timeslotDockDetailsToShow = null
				})
				fireAndForget(async () => {
					await Promise.all([
						_fetchAppointmentsForLocationOnSelectedDay(),
						_fetchShipmentsWithoutAppointments(),
					])
				}, 'refetching appointments and shipments without appointments after assigning a shipment to an appointment')
			} else {
				getSos().change((ds) => {
					ds.appointmentCreateError = JSON.stringify(result.error)
					ds.creatingAppointment = false
				})
			}
		}
	}
}

export async function createAppointmentWithoutShipment(): Promise<void> {
	const state = getSos().getState()
	const location = sosDockScheduler.getSos().getState().currentLocation
	const appointmentRequest: apiTypes.AppointmentRequest = {
		locationId: location.id,
		startTime: createStartTimeFromTimeSlot(
			state.selectedTimeSlot,
			state.selectedDate,
			location,
		),
		scheduledDuration: state.selectedTimeSlot.durationMinutes,
		stopType: state.appointmentWithoutShipmentCreateForm.flow,
		status: 'not-arrived',
		shipmentInfo: {
			proNumber: state.appointmentWithoutShipmentCreateForm.proNumber,
			purchaseOrders: state.appointmentWithoutShipmentCreateForm.poNumbers
				.split(',')
				.map((purchaseOrder) => purchaseOrder.trim()),
			salesOrders: state.appointmentWithoutShipmentCreateForm.soNumbers
				.split(',')
				.map((salesOrder) => salesOrder.trim()),
			mode: state.appointmentWithoutShipmentCreateForm.mode,
			equipmentType: state.appointmentWithoutShipmentCreateForm.equipmentType,
			handlingUnits: state.appointmentWithoutShipmentCreateForm.handlingUnits,
			handlingUnitsTypes: [
				state.appointmentWithoutShipmentCreateForm.handlingUnitType,
			],
			expectedWeight: state.appointmentWithoutShipmentCreateForm.weight,
			otherCompanyNamesOnShipment: state.appointmentWithoutShipmentCreateForm.otherCompanyNames
				.split(',')
				.map((companyName) => companyName.trim()),
		},
		trailerInfo: {
			carrier: state.appointmentWithoutShipmentCreateForm.carrier,
		},
		comment: state.appointmentWithoutShipmentCreateError
			? `Appointment created with warning: ${state.appointmentWithoutShipmentCreateError}`
			: undefined,
	}
	getSos().change((ds) => {
		ds.creatingAppointmentWithoutShipment = true
	})
	const createResult = await apiDockScheduler.createAppointment(
		() => {},
		appointmentRequest,
		!l.isNil(state.appointmentWithoutShipmentCreateError),
	)
	if (createResult.data) {
		getSos().change((ds) => {
			ds.creatingAppointmentWithoutShipment = false
			ds.appointmentWithoutShipmentCreateModalOpen = false
			ds.appointmentWithoutShipmentCreateForm = defaultAppointmentWithoutShipmentCreateForm
			ds.appointments.push(createResult.data)
			ds.selectedTimeSlot = null
		})
	} else if (createResult.error) {
		getSos().change((ds) => {
			ds.creatingAppointmentWithoutShipment = false
			ds.appointmentWithoutShipmentCreateError = JSON.stringify(
				createResult.error,
			)
		})
	}
}

function createStartTimeFromTimeSlot(
	timeslot: IScheduleTimeSlot,
	date: string,
	location: apiTypes.LocationResponse,
): string {
	const dateParts = date.split('-')
	return DateTime.fromObject({
		year: Number(dateParts[0]),
		month: Number(dateParts[1]),
		day: Number(dateParts[2]),
		hour: timeslot.startHours,
		minute: timeslot.startMinutes,
		second: 0,
		millisecond: 0,
		zone: zipToTimezone(location.defaults.defaultPickupAddress.address.zip),
	}).toISO()
}

export function updateAppointmentWithoutShipmentForm(
	changes: Partial<IFormCreateAppointmentWithoutShipment>,
): void {
	const state = getSos().getState()
	const updatedForm = l.cloneDeep(state.appointmentWithoutShipmentCreateForm)
	l.assign(updatedForm, changes)
	const createdAppointmentForTimeslotError = validateDataWithSelectedTimeSlot(
		updatedForm.equipmentType,
		updatedForm.flow,
		updatedForm.mode,
		state.selectedTimeSlot,
	)
	getSos().change((ds) => {
		ds.appointmentWithoutShipmentCreateForm = updatedForm
		ds.appointmentWithoutShipmentCreateError = createdAppointmentForTimeslotError
	})
}

export function setAppointmentWithoutShipmentModalOpen(isOpen: boolean): void {
	getSos().change((ds) => {
		ds.appointmentWithoutShipmentCreateModalOpen = isOpen
		ds.appointmentWithoutShipmentCreateForm = defaultAppointmentWithoutShipmentCreateForm
		ds.appointmentWithoutShipmentCreateError = isOpen
			? ds.selectedTimeSlot.slotUnavailableReason
			: null
	})
}
