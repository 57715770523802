import React, { ReactElement } from 'react'
import { apiTypes } from 'ui/api'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { t } from 'ui/components/i18n/i18n'
import { sosUser } from 'ui/state'
import { sosCustomerManagement } from 'ui/pages/customer-management'
import Form from 'react-bootstrap/Form'
import { l } from 'ui/lib/lodashImports'

const tPrefix = 'page.customerManagement.paymentTerms'

export const PaymentTerms = (props: {
	paymentTerms: apiTypes.PaymentTerms
}): ReactElement => {
	const form: apiTypes.PaymentTerms = {
		net: props.paymentTerms.net,
	}

	const _updateApi = l.debounce(async () => {
		const result = await sosUser.updateClientConfig(
			sosCustomerManagement.sos.getState(),
		)
		if (result?.error) {
			sosCustomerManagement.sendErrorToast(result.error)
		}
	}, 500)
	const onChange = async (field, value): Promise<void> => {
		sosCustomerManagement.updatePaymentTermsForm(field, value)
		await _updateApi()
	}

	return (
		<div className='bootstrap-wrapper'>
			<Card>
				<Card.Header>
					<Row>
						<Col>{t('paymentTerms', tPrefix)}</Col>
					</Row>
				</Card.Header>
				<Row>
					<Col>
						<Card className='h-100'>
							<Card.Body>
								<Form>
									<Form.Group controlId='paymentTermsPercentSelector'>
										<Form.Label>{t('net', tPrefix)}</Form.Label>
										<Form.Control
											type='text'
											onChange={(e): Promise<void> =>
												onChange('net', e.target.value)
											}
											value={form['net']}
											data-testid={`form-net`}
										/>
									</Form.Group>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Card>
		</div>
	)
}
