import { apiCommon, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'

export interface UIProviderInvoceResponse
	extends apiTypes.ProviderInvoiceResponse {
	providerName: string
}

export async function createProviderInvoice(
	onProgress: (rs: IRequestState<apiTypes.ProviderInvoiceResponse>) => void,
	data: apiTypes.ProviderInvoiceCreateRequest,
): Promise<IRequestState<apiTypes.ProviderInvoiceResponse>> {
	const url = `invoicing/provider`

	return await apiCommon.apiFetch(onProgress, { method: 'POST', data }, url)
}

export async function updateProviderInvoice(
	providerInvoiceId: string,
	data: apiTypes.ProviderInvoiceCreateRequest,
): Promise<IRequestState<apiTypes.ProviderInvoiceResponse>> {
	const url = `invoicing/provider/${providerInvoiceId}`

	return await apiCommon.apiFetch(() => {}, { method: 'PUT', data }, url)
}

export async function getProviderInvoice(
	providerInvoiceId: string,
): Promise<IRequestState<apiTypes.ProviderInvoiceResponse>> {
	const url = `invoicing/provider/${providerInvoiceId}`

	return await apiCommon.apiFetch(() => {}, { method: 'GET' }, url)
}

export async function deleteProviderInvoice(
	onProgress: (rs: IRequestState<apiTypes.ProviderInvoiceResponse>) => void,
	providerInvoiceId: string,
): Promise<IRequestState<apiTypes.ProviderInvoiceResponse>> {
	const url = `invoicing/provider/${providerInvoiceId}`

	return await apiCommon.apiFetch(onProgress, { method: 'DELETE' }, url)
}

export async function createProviderInvoiceCharge(
	onProgress: (
		rs: IRequestState<apiTypes.ProviderInvoiceChargeResponse>,
	) => void,
	providerInvoiceId: string,
	data: apiTypes.ProviderInvoiceChargeRequest,
): Promise<IRequestState<apiTypes.ProviderInvoiceChargeResponse>> {
	const url = `invoicing/provider/${providerInvoiceId}/charges`

	return await apiCommon.apiFetch(onProgress, { method: 'POST', data }, url)
}

export async function getProviderInvoiceCharge(
	providerInvoiceId: string,
	chargeId: string,
): Promise<IRequestState<apiTypes.ProviderInvoiceChargeResponse>> {
	const url = `invoicing/provider/${providerInvoiceId}/charges/${chargeId}`

	return await apiCommon.apiFetch(() => {}, { method: 'GET' }, url)
}

export async function updateProviderInvoiceCharge(
	providerInvoiceId: string,
	chargeId: string,
	data: apiTypes.ProviderInvoiceChargeRequest,
): Promise<IRequestState<apiTypes.ProviderInvoiceChargeResponse>> {
	const url = `invoicing/provider/${providerInvoiceId}/charges/${chargeId}`

	return await apiCommon.apiFetch(() => {}, { method: 'PUT', data }, url)
}

export async function deleteProviderInvoiceCharge(
	providerInvoiceId: string,
	chargeId: string,
): Promise<IRequestState<apiTypes.ProviderInvoiceChargeResponse>> {
	const url = `invoicing/provider/${providerInvoiceId}/charges/${chargeId}`

	return await apiCommon.apiFetch(() => {}, { method: 'DELETE' }, url)
}
