import React, { useEffect, useState } from 'react'
import { apiBrokerInvoice, apiInvoices, apiTypes } from 'ui/api'
import { sosToast } from 'common/components/toast'
import { fireAndForget } from 'ui/lib/async'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Button } from 'ui/components/common/button'
import { t } from 'ui/components/i18n/i18n'
import { InvoicesTable } from 'ui/pages/shipment-profile/broker/components/customer-invoices/InvoicesTable'
import { sosRouter } from 'ui/components/common/router'

const tPrefix = 'page.shipmentProfile.invoices'

export const BrokerShipmentProfileInvoices = (props: {
	shipmentId: string
	contractId: string
}): JSX.Element => {
	const { shipmentId, contractId } = props
	const [invoices, setInvoices] = useState<apiTypes.BrokerInvoiceResponse[]>([])
	const [isLoading, setLoading] = useState(false)
	const [isInvoiceCreating, setIsInvoiceCreating] = useState<boolean>(false)

	const openLink = (invoiceId: string): void => {
		const customerInvoiceLink =
			'/shipments-v3/shipment-profile/' +
			shipmentId +
			'/customer-invoice/' +
			invoiceId

		sosRouter.navigate(customerInvoiceLink)
	}

	const fetchInvoices = async (
		sortBy = 'invoiceDate',
		order: 'asc' | 'desc',
	): Promise<void> => {
		setLoading(true)
		const invoiceResponse = await apiInvoices.getInvoicesByShipmentId(
			props.shipmentId,
			sortBy,
			order,
		)
		if (invoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(invoiceResponse)
		} else {
			setInvoices(invoiceResponse.data.brokerInvoices)
		}
		setLoading(false)
	}

	const createNewInvoice = async (): Promise<void> => {
		setIsInvoiceCreating(true)

		const brokerInvoiceRequest: apiTypes.BrokerInvoiceCreateRequest = {
			shipmentId: shipmentId,
			clientConfigId: contractId,
			invoiceNumber: '',
			invoiceStatus: 'review',
			invoiceDate: '',
			paymentTerms: 0,
			currency: 'USD',
			notes: '',
			charges: [],
		}

		const brokerInvoiceResponse = await apiBrokerInvoice.createBrokerInvoice(
			() => {},
			brokerInvoiceRequest,
		)

		if (brokerInvoiceResponse.data) {
			invoices.push(brokerInvoiceResponse.data)
			setInvoices(invoices)
		} else if (brokerInvoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(brokerInvoiceResponse)
		}

		setIsInvoiceCreating(false)
	}

	useEffect(() => {
		fireAndForget(
			async () => fetchInvoices('creationDate', 'desc'),
			`Fetching invoices`,
		)
	}, [props.shipmentId]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<React.Fragment>
			<AlignRight>
				<div>
					<Button
						color='blue'
						bootstrapStyles={true}
						isSmall={true}
						isSpinning={isInvoiceCreating}
						isDisabled={isLoading}
						onClick={createNewInvoice}
						testId='create-invoice'
					>
						{t('createInvoice', tPrefix)}
					</Button>
				</div>
			</AlignRight>
			<InvoicesTable
				data={invoices}
				testId={'invoices-table'}
				isLoading={isLoading}
				onOpenDetail={(invoice: apiTypes.BrokerInvoiceResponse) => {
					openLink(invoice.id)
				}}
				onSort={fetchInvoices}
				tPrefix={tPrefix}
			/>
		</React.Fragment>
	)
}
