import { sosToast } from 'common/components/toast'
import { ToastState } from 'common/components/toast/Toast'
import { apiLocation, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { tString } from 'ui/components/i18n/i18n'
import { FileDropZoneData } from 'ui/components/shared/csvValidator/file-drop-zone'
import {
	createDefaultMarkup,
	createDefaultPaymentTerms,
} from 'ui/lib/clientConfigs'
import {
	getApiImageTypeFromImageString,
	getBase64StringFromImageString,
} from 'ui/lib/images/imageUtils'
import { toCents } from 'ui/lib/numbers/toNumber'
import { createSos2 } from 'ui/lib/state/sos2/sos2'
import { sosUser } from 'ui/state'
import { tPrefixClientManagement } from './broker/default-markup'

const defaultMarkupFlat = 10000
const defaultMarkupPercent = 10

export interface StateCustomerManagement {
	brokerDefaultMarkupForm: apiTypes.MarkupLogic
	paymentTermsForm: apiTypes.PaymentTerms
	brokerNumber: string
	useCustomerInfoForBOL: boolean
	clientAddress: apiTypes.Address
	clientLocationId: string
	brandingImageUrl: string
	brokerLocationId?: string
	brokerLocationName?: string
}

export const sos = createSos2<StateCustomerManagement>(
	'customerManagement',
	1,
	{
		brokerDefaultMarkupForm: {
			default: createDefaultMarkup(),
		},
		paymentTermsForm: {
			default: createDefaultPaymentTerms(),
		},
		brokerNumber: {
			default: '',
		},
		useCustomerInfoForBOL: {
			default: false,
		},
		clientAddress: { default: null },
		clientLocationId: { default: null },
		brandingImageUrl: { default: null },
		brokerLocationId: {
			default: '',
		},
		brokerLocationName: {
			default: '',
		},
	},
)

export const updatePaymentTermsForm = (
	field: keyof apiTypes.PaymentTerms,
	changes: any,
): void => {
	const flatDollarFields: Array<keyof apiTypes.PaymentTerms> = []
	if (flatDollarFields.includes(field)) {
		changes = toCents(changes)
	}
	sos.change((ds) => {
		ds.paymentTermsForm[field as string] = changes
	})
}

export const markupFormOnFormUpdate = (
	field: keyof apiTypes.MarkupLogic,
	changes: any,
): void => {
	const flatDollarFields: Array<keyof apiTypes.MarkupLogic> = [
		'markupFlat',
		'maxMarkupFlat',
		'minMarkupFlat',
	]
	if (flatDollarFields.includes(field)) {
		changes = toCents(changes)
	}
	sos.change((ds) => {
		ds.brokerDefaultMarkupForm[field as string] = changes
		if (field === 'markupType') {
			if (changes === 'flat' && !ds.brokerDefaultMarkupForm.markupFlat) {
				ds.brokerDefaultMarkupForm.markupFlat = defaultMarkupFlat
			} else if (
				changes === 'percent' &&
				!ds.brokerDefaultMarkupForm.markupPercent
			) {
				ds.brokerDefaultMarkupForm.markupPercent = defaultMarkupPercent
			}
		}
	})
}

export const setBrokerNumber = (brokerNumber): void => {
	sos.change((ds) => {
		ds.brokerNumber = brokerNumber
	})
}

export const setBrokerLocationId = async (
	value: string,
): Promise<IRequestState<apiTypes.ClientConfigResponse>> => {
	sos.change((ds) => {
		ds.brokerLocationId = value
	})
	return sosUser.updateClientConfig(sos.getState())
}

export const updateWithSelectedConfig = async (
	selectedConfig: apiTypes.ClientConfigResponse,
): Promise<void> => {
	sos.change((ds) => {
		ds.brokerDefaultMarkupForm = null
		ds.paymentTermsForm = null
		ds.brokerNumber = null
		ds.clientAddress = null
		ds.brokerLocationId = null
		ds.brokerLocationName = null
	})
	if (selectedConfig) {
		const locationListResponse = await apiLocation.fetchLocations(() => {}, {
			take: 1,
			skip: 0,
			clientConfigId: selectedConfig.id,
			query: 'NOT _exists_:parentId',
		})

		let brokerLocation: apiTypes.LocationResponse
		if (selectedConfig.brokerLocationId) {
			const brokerLocationResponse = await sosUser.getLocation(
				selectedConfig.brokerLocationId,
			)

			if (brokerLocationResponse) {
				brokerLocation = brokerLocationResponse
			}
		}

		if (locationListResponse.data) {
			const location = locationListResponse.data[0]

			if (location) {
				sos.change((ds) => {
					ds.brokerDefaultMarkupForm =
						selectedConfig.defaultMarkupLogic || createDefaultMarkup()
					ds.paymentTermsForm =
						selectedConfig.paymentTerms || createDefaultPaymentTerms()
					ds.brokerNumber = selectedConfig.brokerNumber || ''
					ds.useCustomerInfoForBOL = selectedConfig.useClientInfoForBolHeader
					ds.clientAddress = location.defaults?.defaultPickupAddress?.address
					ds.clientLocationId = location.id
					ds.brandingImageUrl = location.brandingImageUrl
					ds.brokerLocationName = brokerLocation?.name
					ds.brokerLocationId = brokerLocation?.id
				})
			} else {
				sosToast.sendToast({
					type: 'danger',
					header: tString('errorFetchingClientData', tPrefixClientManagement),
					body: tString('cannotFindTopLevelLocation', tPrefixClientManagement),
				})
			}
		} else {
			sosToast.sendApiErrorResponseToast(
				locationListResponse,
				tString('errorFetchingClientData', tPrefixClientManagement),
			)
		}
	}
}

export const saveClientLogo = async (
	logoData: FileDropZoneData,
): Promise<void> => {
	const state = sos.getState()
	if (logoData.fileContent.indexOf('data:image/') === -1) {
		sosToast.sendToast({
			type: 'danger',
			body: tString('pleaseUploadAnImage', tPrefixClientManagement),
		})
		return
	}
	const imageFormat: apiTypes.DocumentImageFormat = getApiImageTypeFromImageString(
		logoData.fileContent,
	)
	if (!imageFormat) {
		sosToast.sendToast({
			type: 'danger',
			body: tString('pleaseUploadAValidImageType', tPrefixClientManagement),
		})
		return
	}
	const result = await apiLocation.uploadLocationLogo(
		() => {},
		state.clientLocationId,
		{
			filename: logoData.fileName,
			image: getBase64StringFromImageString(logoData.fileContent),
			format: imageFormat,
		},
		sosUser.getSos().getState().selectedClientConfig.id,
	)
	if (result.error) {
		sosToast.sendApiErrorResponseToast(
			result,
			tString('errorUploading', tPrefixClientManagement),
		)
	} else {
		sos.change((ds) => {
			ds.brandingImageUrl = result.data.brandingImageUrl
		})
	}
}

export const sendErrorToast = (errorMessage: string): number => {
	const toastBody: ToastState = {
		header: tString('apiUpdateError', 'page.customerManagement'),
		body: errorMessage,
		type: 'danger',
		key: Date.now(),
	}
	sosToast.sendToast(toastBody)
	return toastBody.key
}

export const deleteErrorToast = (key: number): void => {
	sosToast.deleteToastByKey(key)
}

export const updateUseClientInfoForBolHeader = (value: boolean): void => {
	sos.change((ds) => {
		ds.useCustomerInfoForBOL = value
	})
}
