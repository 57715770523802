import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { LoadingBar } from 'ui/components/common/loader/LoadingBar'
import { idx } from 'ui/lib'
import { sos2 } from 'ui/lib/state/sos2'
import { sosRfpDataUpload } from 'ui/state'

export const RfpLoadingBar: FC = (props: {}) => {
	const rfpDataUploadState = sos2.useSubscription(sosRfpDataUpload.getSos())

	const shipmentCount =
		idx(() => rfpDataUploadState.shipmentIds.length) ||
		idx(() => rfpDataUploadState.previewTableState.rawRecords.length) ||
		sosRfpDataUpload.uploadBatchSize

	const firstUploadingBatchSize = Math.min(
		sosRfpDataUpload.uploadBatchSize,
		shipmentCount,
	)

	// last batch size is however many shipments remain
	// if zero shipments remain, previous batch was full
	const lastUploadingBatchSize =
		shipmentCount % firstUploadingBatchSize || firstUploadingBatchSize

	const uploadBatchCount =
		rfpDataUploadState.createBatchCount ||
		Math.ceil(shipmentCount / firstUploadingBatchSize)

	const totalSteps = sosRfpDataUpload.getTotalSteps(
		uploadBatchCount,
		firstUploadingBatchSize,
		lastUploadingBatchSize,
	)
	if (totalSteps !== rfpDataUploadState.totalSteps) {
		sosRfpDataUpload.setTotalSteps(totalSteps)
	}

	return (
		<LoadingBar
			totalSteps={totalSteps}
			completedSteps={rfpDataUploadState.completedSteps}
			isLoading={
				rfpDataUploadState.isUploadingShipments ||
				rfpDataUploadState.isRatingShipments
			}
		/>
	)
}
