import { apiBroker, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosRouter2 } from 'ui/components/common/router'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { sosUser } from 'ui/state'

export type BrokerApiCalls =
	| 'updatingBrokerOffer'
	| 'sendingBrokerOffer'
	| 'acceptingBrokerOfferForCustomer'
	| 'clearingBrokerOffer'
	| 'retractingBrokerOffer'
	| 'awardingShipment'
	| 'resendingAward'
	| 'retractingAward'
	| 'booking'
	| 'resendingBook'
	| 'unbooking'

export interface IStateShipmentProfileBroker {
	shipment: apiTypes.BrokerShipmentResponse
	clientConfig: apiTypes.ClientConfigResponse
	runningApiCalls: BrokerApiCalls[]
	websocketUrl: string
}

export const getSos = createLazySos2<IStateShipmentProfileBroker>(
	'sosShipmentProfileBroker',
	2,
	() => ({
		shipment: { default: null },
		clientConfig: { default: null },
		runningApiCalls: { default: [], localStorage: false },
		websocketUrl: { default: null, localStorage: true },
	}),
)

export const pageInfo = {
	url: '/shipments-v3/shipment-profile/:shipmentId',
	params: {
		shipmentId: {
			name: 'shipmentId',
		},
	},
	queries: {},
}

export const getUrlState = (): {
	shipmentId: string
} => {
	const shipmentId = sosRouter2.getUrlParam(
		pageInfo,
		pageInfo.params.shipmentId,
	)

	return { shipmentId }
}

export function navigateToShipmentProfile(params: {
	shipmentId?: string
}): void {
	const existingParams = getBrokerShipmentProfileUrlState()
	params = r.mergeDeepLeft(params, existingParams)
	sosRouter2.navigateTo(pageInfo, params)
}

export const getBrokerShipmentProfileUrlState = (): {
	shipmentId: string
} => {
	const shipmentId = sosRouter2.getUrlParam(
		pageInfo,
		pageInfo.params.shipmentId,
	)

	return {
		shipmentId,
	}
}

export const fetchShipment = async (
	id: string,
): Promise<IRequestState<apiTypes.BrokerShipmentResponse>> => {
	const state = getSos().getState()
	if (id !== state.shipment?.id) {
		getSos().change((ds) => {
			ds.shipment = null
		})
	}
	const fetchResult = await apiBroker.fetchBrokerShipment(
		() => {},
		id,
		true,
		true,
	)
	if (fetchResult.data) {
		let clientConfig = state.clientConfig
		if (!clientConfig || clientConfig.id !== fetchResult.data.contractId) {
			clientConfig = (
				await sosUser.getClientConfigs([fetchResult.data.contractId])
			)[0]
		}

		getSos().change((ds) => {
			ds.shipment = fetchResult.data
			ds.clientConfig = clientConfig
		})
	} else if (fetchResult.error) {
		getSos().change((ds) => {
			ds.shipment = null
			ds.clientConfig = null
		})
	}
	return fetchResult
}

export const setShipmentReference = (
	shipment: apiTypes.BrokerShipmentResponse,
): void => {
	getSos().change((ds) => {
		ds.shipment = shipment
	})
}

export const setRunningApiCall = (
	apiCall: BrokerApiCalls,
	add = true,
): void => {
	getSos().change((ds) => {
		if (add) {
			ds.runningApiCalls.push(apiCall)
		} else {
			l.pull(ds.runningApiCalls, apiCall)
		}
	})
}

export const restRateShipment = async (): Promise<
	apiTypes.BrokerShipmentResponse
> => {
	const { shipmentId } = getBrokerShipmentProfileUrlState()

	const result: apiTypes.BrokerShipmentResponse = (
		await apiBroker.rateShipment(() => {}, shipmentId)
	).data

	getSos().change((ds) => {
		ds.shipment = result
	})
	return result
}

export const setRate = (rate: apiTypes.RateResponse): void => {
	getSos().change((ds) => {
		if (ds.shipment?.rates) {
			ds.shipment.rates.push(rate)
		} else {
			ds.shipment.rates = [rate]
		}
	})
}

export const setRatingError = (ratingError: apiTypes.RatingError): void => {
	getSos().change((ds) => {
		if (ds.shipment?.rates) {
			ds.shipment.ratingErrors.push(ratingError)
		} else {
			ds.shipment.ratingErrors = [ratingError]
		}
	})
}

export const setWebsocketUrl = (webSocketUrl: string): void => {
	getSos().change((ds) => {
		ds.websocketUrl = webSocketUrl
	})
}
