import { FC } from 'app/FunctionalComponent'
import { capitalize } from 'lodash'
import { tAccessorial } from 'ui/components/i18n/commonTranslations'
import { SimpleAddress } from 'ui/components/shared/address'
import React, { useState } from 'react'
import { t, tString, tArgz } from 'ui/components/i18n/i18n'
import { apiBroker, apiTypes } from 'ui/api'
import { Card, CardProps } from 'react-bootstrap'
import {
	divForEveryLineRenderer,
	IKeyValueOptions,
	KeyValue,
	KeyValueSpacer,
	SimpleKeyValueTable,
} from 'ui/components/table'
import { getTrueAccessorials } from 'ui/lib/accessorials/getTrueAccessorials'
import { formatCardDateTime } from 'ui/pages/shipment-profile/shared/functions/formatCardDateTime'
import { theme } from 'ui/theme'
import * as classes from './ShipmentCards.module.scss'
import { FormStackedTextInput, IFormData } from 'ui/components/form'
import { l } from 'ui/lib/lodashImports'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { sosToast } from 'common/components/toast'
import { sosUser } from 'ui/state'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'

const tPrefix = 'page.shipmentProfile.shipmentDetails.stop'

const getMostRelevantDateTimeInfo = (
	metaData: apiTypes.OriginMetaData | apiTypes.DestinationMetaData,
): apiTypes.DateTimeInfo => {
	return (
		metaData?.actualDateTimeInfo ||
		metaData?.projectedDateTimeInfo ||
		metaData?.quotedDateTimeInfo ||
		metaData?.desiredDateTimeInfo
	)
}

interface StopData {
	address: apiTypes.Address
	time: string[]
	accessorials: string
	specialInstructions: string
}

export const StopCard: FC = (props: {
	stop: apiTypes.OriginStopResponse | apiTypes.DestinationStopResponse
	stopType: 'pickup' | 'delivery' | 'deliveryAndPickup'
	stopNumber: number
	brokerBooked?: boolean
	brokerShipment?: apiTypes.BrokerShipmentResponse
}) => {
	const { stop, stopType, brokerBooked, brokerShipment } = props
	const keyValueTableOptions: IKeyValueOptions = { tPrefix }
	const metaData = stop.metaData
	const stopDateTime: apiTypes.DateTimeInfo = getMostRelevantDateTimeInfo(
		metaData,
	)
	const stopData: StopData = {
		address: stop.address,
		time: formatCardDateTime(stopDateTime) || [
			tString('standard' + capitalize(stopType), tPrefix),
		],
		accessorials: getTrueAccessorials(metaData.accessorials)
			.map((accessorialKey: keyof apiTypes.AccessorialsRequired) =>
				tAccessorial(accessorialKey),
			)
			.join(', '),
		specialInstructions: metaData?.specialInstructions,
	}

	const [addressForm, setAddressForm] = useState<apiTypes.Address>(stop.address)

	const formData: IFormData<apiTypes.Address> = {
		form: addressForm,
		metadata: {
			company: {},
			name: {},
			phone: {},
			email: {},
			street1: {},
			street2: {},
			city: {},
			state: { required: true },
			zip: {},
			country: {},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(addressForm)

			updatedFormData[field] = value
			setAddressForm(updatedFormData)
		},
		tPrefix,
	}

	const [editAddress, setEditAddress] = useState<boolean>(false)
	const [isUpdatingAddress, setIsUpdatingAddress] = useState<boolean>(false)

	const updateShipment = async (): Promise<void> => {
		setIsUpdatingAddress(true)

		const updatedShipment = l.cloneDeep(brokerShipment)

		if (stopType === 'pickup') {
			updatedShipment.payloads[0].originStop.address = addressForm
		} else if (stopType === 'delivery') {
			updatedShipment.payloads[0].destinationStop.address = addressForm
		}

		const updateResult = await apiBroker.updateBrokerShipment(
			() => {},
			updatedShipment.id,
			updatedShipment,
			sosUser.getSos().getState().selectedClientConfig?.id,
		)

		if (updateResult.data) {
			sosShipmentProfileBroker.setShipmentReference(updateResult.data)
		} else if (updateResult.error) {
			sosToast.sendApiErrorResponseToast(updateResult)
		}

		setIsUpdatingAddress(false)
	}

	let cardBorderProp: CardProps['border']
	let className = classes.card
	if (stopType === 'pickup') {
		cardBorderProp = 'success'
	} else if (stopType === 'delivery') {
		cardBorderProp = 'danger'
	} else if (stopType === 'deliveryAndPickup') {
		className = theme.addClass(
			className,
			`${classes.deliveryAndPickupCard} ${classes.specificityClass}`,
		)
	}
	return (
		<Card
			border={cardBorderProp}
			className={className}
			data-testid={`stop-${props.stopNumber}-card`}
		>
			<div
				className={`${theme.getBorderBottomColor('lightGray')} ${
					classes.cardTitle
				}`}
			>
				<div>{tArgz('stopNumber', { number: props.stopNumber }, tPrefix)}</div>
				<div className={classes.cardType}>
					<span>{t(stopType, tPrefix)}</span>
					{!brokerBooked && !editAddress && (
						<IconButton
							icon={solidIcons.faPencilAlt}
							color={'black'}
							onClick={() => {
								setEditAddress(!editAddress)
							}}
							large={false}
							buttonClassName={classes.cardIconEdit}
						/>
					)}
					{editAddress && (
						<>
							<IconButton
								icon={
									!isUpdatingAddress ? solidIcons.faCheck : solidIcons.faSpinner
								}
								color={'green'}
								onClick={async () => {
									await updateShipment()
									setEditAddress(!editAddress)
								}}
								large={false}
								buttonClassName={classes.cardIconSave}
								disabled={isUpdatingAddress}
								spin={isUpdatingAddress}
							/>
							<IconButton
								icon={solidIcons.faTimes}
								color={'red'}
								onClick={() => {
									setEditAddress(!editAddress)
								}}
								large={false}
								buttonClassName={classes.cardIconCancel}
								disabled={isUpdatingAddress}
							/>
						</>
					)}
				</div>
			</div>
			{editAddress && (
				<div className={classes.cardEditableForm}>
					<FormStackedTextInput
						formData={formData}
						field={'company'}
						className={classes.cardEditableFormInput}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'street1'}
						className={classes.cardEditableFormInput}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'street2'}
						className={classes.cardEditableFormInput}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'name'}
						className={classes.cardEditableFormInput}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'phone'}
						className={classes.cardEditableFormInput}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'email'}
						className={classes.cardEditableFormInput}
					/>
				</div>
			)}
			<div className={classes.cardBody}>
				<SimpleKeyValueTable borderless={true}>
					{!editAddress && (
						<KeyValue
							options={keyValueTableOptions}
							data={stopData}
							field='address'
							renderer={(address: apiTypes.Address) => (
								<SimpleAddress address={address} />
							)}
							testId={'stop-card-address'}
						/>
					)}
					<KeyValueSpacer />
					<KeyValue
						options={keyValueTableOptions}
						data={stopData}
						field='time'
						renderer={divForEveryLineRenderer}
						testId={'stop-card-time'}
					/>
					<KeyValueSpacer />
					<KeyValue
						options={keyValueTableOptions}
						data={stopData}
						field='accessorials'
						testId={'stop-card-accessorials'}
					/>
					<KeyValueSpacer />
					<KeyValue
						options={keyValueTableOptions}
						data={stopData}
						field='specialInstructions'
						testId={'stop-card-specialInstructions'}
					/>
				</SimpleKeyValueTable>
			</div>
		</Card>
	)
}
