import { l } from 'ui/lib/lodashImports'
import { log } from 'ui/lib/log'
import { r } from 'ui/lib/ramdaImports'
import { queryParams2 } from 'ui/lib/state'
import { sos2 } from 'ui/lib/state/sos2'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { inJestUnitTest } from 'ui/lib/testing/inJestUnitTest'
import {
	isPathnameAMatch,
	parseHref,
	sosRouter,
	splitPathnameIntoSegments,
	windowUtils,
} from '.'
import { getLocationHref } from './windowUtils'
import { theme } from 'ui/theme'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { navParentTo } from 'ui/lib/IframeRpc'

const namespace = 'sos-router-2'

export type IQueryStringConverter = [(obj: any) => string, (s: string) => any]
export interface IQueryStringMeta {
	name: string
	default: string
	converter?: IQueryStringConverter
}
export interface IUrlParamMeta {
	name: string
}
export interface IPageUrlMeta {
	url: string
	params: { [key: string]: IUrlParamMeta }
	queries: { [key: string]: IQueryStringMeta }
}

export interface IStateRouter2 {
	updateNumber: number
	/**
	 * A function to run on navigateTo; returns a boolean: true if navigation should continue after the function has run
	 */
	onLeave?: (pageInfo?: IPageUrlMeta, params?: any) => boolean
}

const getSos = createLazySos2<IStateRouter2>('sosRouter2', 2, () => ({
	updateNumber: { default: 1 },
	onLeave: { default: null },
}))

export const useSubscribeToUrlChanges = (): IStateRouter2 => {
	return sos2.useSubscription(getSos())
}

export const sendUpdate = (): void => {
	getSos().change((ds) => {
		ds.updateNumber++
	})
}

export const isOnPage = (
	pageInfo: IPageUrlMeta,
): {
	match: boolean
	params?: any
} => {
	const pathname = parseHref(getLocationHref()).pathname
	const segments = splitPathnameIntoSegments(pathname)
	const matchResult = isPathnameAMatch(segments, pageInfo.url)

	if (!matchResult.match) {
		log(namespace, 'url mismatch', getLocationHref(), pathname, pageInfo.url)
	}
	return matchResult
}

export const getUrlParam = (
	pageInfo: IPageUrlMeta,
	urlParam: IUrlParamMeta,
): string => {
	log(namespace, 'get-url-param', urlParam.name)

	try {
		let result: string = null
		const matchResult = isOnPage(pageInfo)
		if (!matchResult.match) {
			result = null
		} else {
			result = '' + matchResult.params[urlParam.name]
		}
		log(namespace, 'get-url-param', urlParam.name, result)
		return result
	} catch (ex) {
		log(namespace, 'url param parsing error', ex)
		return null
	}
}

export const getQueryParam = (
	pageInfo: IPageUrlMeta,
	query: IQueryStringMeta,
): any => {
	log(namespace, 'get-query', query.name)

	const queryString = queryParams2.getQueryString(windowUtils.getLocationHref())
	const queryObject = queryParams2.queryStringToObject(queryString)

	try {
		let q = query.default
		if (isOnPage(pageInfo).match) {
			q = queryObject[query.name] || query.default
		}
		if (query.converter) {
			return query.converter[1](q)
		}
		return q
	} catch (ex) {
		log(namespace, 'query string parsing error', ex)
		return null
	}
}

export const setQueryParam = (
	pageInfo: IPageUrlMeta,
	query: IQueryStringMeta,
	newValue: any,
	url?: string,
): boolean => {
	log(namespace, 'set-query', query.name, newValue)

	if (inJestUnitTest()) {
		return false
	}
	if (r.not(isOnPage(pageInfo))) {
		return false
	}

	const queryString = queryParams2.getQueryString(windowUtils.getLocationHref())
	const queryObject = queryParams2.queryStringToObject(queryString)

	if (query.converter) {
		queryObject[query.name] = query.converter[0](newValue)
	} else {
		queryObject[query.name] = '' + newValue
	}
	// Don't write defaults
	if (queryObject[query.name] === query.default) {
		queryObject[query.name] = undefined
	}

	const newQueryString = queryParams2.objectToQueryString(queryObject)
	if (newQueryString === queryString) {
		// No changes
		return false
	}

	sosRouter.setQueryString('?' + newQueryString)
	if (theme.isInTMS2() && url) {
		fireAndForget(
			async () => await navParentTo(url + '?' + newQueryString),
			`parent navigation ${url + '?' + newQueryString}`,
		)
	}
	sendUpdate()

	return true
}

export const navigateTo = (pageInfo: IPageUrlMeta, params: any): void => {
	if (getSos().getState().onLeave) {
		const continueNavigateTo = getSos().getState().onLeave(pageInfo, params)

		if (!continueNavigateTo) {
			return
		}
	}
	let url = pageInfo.url

	l.forEach(Object.keys(pageInfo.params), (c) => {
		const param = pageInfo.params[c]
		url = url.replace(':' + param.name, params[param.name])
	})

	// TODO: refactor do do this on one fell swoop (navigate and query string creation)
	sosRouter.navigate(url)

	log(namespace, 'navigate to', url, params)

	l.forEach(Object.keys(pageInfo.queries), (c) => {
		const query = pageInfo.queries[c]
		if (!l.isNil(params[c])) {
			setQueryParam(pageInfo, query, params[c], url)
		}
	})

	sendUpdate()
}

export const setOnLeave = (
	onLeave: (pageInfo?: IPageUrlMeta, params?: any) => boolean,
): void => {
	getSos().change((ds) => {
		ds.onLeave = onLeave
	})
}
