import React, { useEffect, useState } from 'react'
import { IconButton } from 'ui/components/common/icon'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { FC } from 'ui/FunctionalComponent'
import { Layout } from 'ui/pages/layout/Layout'
import { t, tString } from 'ui/components/i18n/i18n'
import * as classes from './CustomerInvoicePage.module.scss'
import { ReferenceInformation } from './reference-information'
import { ProviderInvoice } from './provider-invoice'
import { Button } from 'ui/components/common/button'
import { Input } from 'ui/components/common/input'
import { Card } from 'ui/components/common/card'
import { CustomerCharges } from './customer-invoice/customer-charges/CustomerCharges'
import { CustomerInvoiceNotes } from './customer-invoice/customer-invoice-notes/CustomerInvoiceNotes'
import {
	apiBrokerInvoice,
	apiCharges,
	apiTypes,
	apiBroker,
	apiInvoices,
	apiPayment,
	apiPaymentAllocation,
} from 'ui/api'
import { sosToast } from 'common/components/toast'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { Modal } from 'ui/components/common/modal/Modal'
import { AlignRight } from 'ui/components/layout/alignRight'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { sosRouter } from 'ui/components/common/router'
import { Center } from 'ui/components/layout/center'
import { InvoiceCustomerConfirmModal } from './invoice-customer-confirm-modal'
import { Col, Row } from 'react-bootstrap'
import { Spacer } from 'ui/components/layout/spacer'
import { IRequestState } from 'ui/api/requestState'
import { BrokerShipmentProfileInvoicesSubway } from 'ui/pages/customer-invoice/components/invoice-subway'
import { Debug } from 'ui/components/dev'
import { useOnce } from 'ui/components/hooks'
import { CustomerInvoiceDetails } from './customer-invoice/customer-invoice-details'
// import download from 'downloadjs'

const tPrefix = 'page.customerInvoice'

export const CustomerInvoicePage: FC = (props: {
	shipmentId?: string
	brokerInvoiceId: string
}) => {
	const { shipmentId, brokerInvoiceId } = props

	const [brokerShipment, setBrokerShipment] = useState<
		apiTypes.BrokerShipmentResponse
	>(null)

	const [brokerInvoice, setBrokerInvoice] = useState<
		apiTypes.BrokerInvoiceResponse
	>(null)
	const [providerInvoices, setProviderInvoices] = useState<
		apiTypes.ProviderInvoiceResponse[]
	>(null)

	const [paymentAllocations, setPaymentAllocations] = useState<
		apiTypes.PaymentAllocationResponse[]
	>(null)

	const [isFetchingBrokerInvoices, setIsFetchingBrokerInvoices] = useState<
		boolean
	>(false)

	const [brokerInvoiceNumber, setBrokerInvoiceNumber] = useState<string>(null)
	const [editBrokerInvoiceNumber, setEditBrokerInvoiceNumber] = useState<
		boolean
	>(false)

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isDeleting, setIsDeleting] = useState<boolean>(false)
	const [voidInvoice, setVoidInvoiceModal] = useState<boolean>(false)

	const [
		isInvoiceCustomerConfirmModalOpen,
		setIsInvoiceCustomerConfirmModalOpen,
	] = useState<boolean>(false)

	const shipmentProfileLink = '/shipments-v3/shipment-profile/' + shipmentId

	const [brokerInvoiceCharges, setBrokerInvoiceCharges] = useState<
		apiTypes.BrokerInvoiceChargeResponse[]
	>(() => [])

	const [chargeCodeBookId, setChargeCodeBookId] = useState<string>(null)
	const [providerChargeCodes, setProviderChargeCodes] = useState<
		apiTypes.ChargeCodePageResponse[]
	>(() => [])

	const [brokerChargeCodes, setBrokerChargeCodes] = useState<
		apiTypes.ChargeCodePageResponse[]
	>(() => [])

	const [totalCost, setTotalCost] = useState<number>(0) // THIS IS TEMPORARY ONLY AS PER TICKET TMS-3913

	useOnce(async () => {
		const brokerShipmentResponse = await apiBroker.fetchBrokerShipment(
			() => {},
			shipmentId,
			false,
			true,
		)

		if (brokerShipmentResponse.data) {
			setBrokerShipment(brokerShipmentResponse.data)
		} else if (brokerShipmentResponse.error) {
			sosToast.sendApiErrorResponseToast(brokerShipmentResponse)
		}
	})

	const getChargeCodeBookId = async (): Promise<void> => {
		const bookResponse = await apiCharges.getBookList()

		if (bookResponse.error) {
			sosToast.sendApiErrorResponseToast(bookResponse)
		} else {
			setChargeCodeBookId(bookResponse.data[0]?.id)
		}
	}

	useEffect(() => {
		fireAndForget(
			async () => getChargeCodeBookId(),
			`Fetching ChargeCode Bookd Id`,
		)
	}, [chargeCodeBookId]) // eslint-disable-line react-hooks/exhaustive-deps

	const onSearchChargeCodes = (): void => {
		fireAndForget(async () => {
			const response: IRequestState<apiTypes.ChargeCodePageListResponse> = await apiCharges.getChargeCodePageList(
				chargeCodeBookId,
				{
					take: 10,
					skip: 0,
				},
				true,
			)

			if (response.error) {
				sosToast.sendApiErrorResponseToast(response)

				return []
			} else {
				const providerCharges = response.data.entities.filter(function (
					charges,
				) {
					return charges.chargeCodeType === 'provider'
				})

				const customerCharges = response.data.entities.filter(function (
					charges,
				) {
					return charges.chargeCodeType === 'customer'
				})

				setProviderChargeCodes(providerCharges)
				setBrokerChargeCodes(customerCharges)
			}
		}, 'Searching Charge Codes')
	}

	useEffect(() => {
		if (chargeCodeBookId) {
			onSearchChargeCodes()
		}
	}, [chargeCodeBookId]) // eslint-disable-line react-hooks/exhaustive-deps

	const getBrokerInvoice = async (): Promise<void> => {
		setIsFetchingBrokerInvoices(true)

		const shipmentInvoiceResponse = await apiInvoices.getInvoicesByShipmentId(
			shipmentId,
		)

		if (shipmentInvoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(shipmentInvoiceResponse)
		} else {
			const brokerInvoice = shipmentInvoiceResponse.data.brokerInvoices.find(
				(brokerInvoice) => brokerInvoice.id === brokerInvoiceId,
			)

			// TODO: This forces the invoiceStatus in review, This should be removed once the API returns the correct invoiceStatus
			// const brokerInvoiceInReviewStatus: apiTypes.BrokerInvoiceResponse = {
			// 	...brokerInvoice,
			// 	invoiceStatus: 'review',
			// }

			// setBrokerInvoice(brokerInvoiceInReviewStatus)
			// setProviderInvoices(shipmentInvoiceResponse.data.providerInvoices)
			// setPaymentAllocations(shipmentInvoiceResponse.data.paymentAllocations)

			// setBrokerInvoiceCharges(brokerInvoiceInReviewStatus.charges)
			// setBrokerInvoiceNumber(brokerInvoiceInReviewStatus.invoiceNumber)

			setBrokerInvoice(brokerInvoice)
			setProviderInvoices(shipmentInvoiceResponse.data.providerInvoices)
			setPaymentAllocations(shipmentInvoiceResponse.data.paymentAllocations)

			setBrokerInvoiceCharges(brokerInvoice.charges)
			setBrokerInvoiceNumber(brokerInvoice.invoiceNumber)
		}

		setIsFetchingBrokerInvoices(false)
	}

	const approveInvoice = async (): Promise<void> => {
		const providerInvoiceCharges = l.flatMap(
			providerInvoices,
			(providerInvoice) => providerInvoice.charges,
		)

		const hasProviderInvoiceCharge = providerInvoiceCharges.every(
			(providerInvoice) => {
				return !!(
					providerInvoice.chargeCode && providerInvoice.chargeDescription
				)
			},
		)

		const hasBrokerInvoiceCharge = brokerInvoiceCharges.every(
			(invoiceCharge) => {
				return !!(invoiceCharge.chargeCode && invoiceCharge.chargeDescription)
			},
		)

		if (
			providerInvoiceCharges.length > 0 &&
			brokerInvoiceCharges.length > 0 &&
			hasProviderInvoiceCharge &&
			hasBrokerInvoiceCharge
		) {
			await updateBrokerInvoice('approved')
		} else {
			sosToast.sendToast({
				header: tString('approveInvoiceErrorMessage', tPrefix),
				type: 'danger',
			})
		}
	}

	const updateBrokerInvoice = async (
		updatedInvoiceStatus: apiTypes.InvoiceStatus = null,
	): Promise<void> => {
		setIsLoading(true)

		const invoiceRequest: apiTypes.BrokerInvoiceUpdateRequest = {
			...brokerInvoice,
			clientConfigId: brokerShipment.contractId,
			invoiceStatus: updatedInvoiceStatus || brokerInvoice.invoiceStatus,
			invoiceNumber: brokerInvoice.invoiceNumber || '',
			invoiceDate: brokerInvoice.invoiceDate || '',
		}

		const invoiceResponse = await apiBrokerInvoice.updateBrokerInvoice(
			() => {},
			brokerInvoiceId,
			invoiceRequest,
		)

		if (invoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(invoiceResponse)
		} else {
			setBrokerInvoice(invoiceResponse.data)
			setBrokerInvoiceCharges(invoiceResponse.data.charges)
		}

		setIsLoading(false)
	}

	const deleteBrokerInvoice = async (): Promise<void> => {
		setIsLoading(true)

		const invoiceResponse = await apiBrokerInvoice.deleteBrokerInvoice(() => {},
		brokerInvoiceId)

		if (invoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(invoiceResponse)
		} else {
			setBrokerInvoice(invoiceResponse.data)
			setBrokerInvoiceCharges(invoiceResponse.data.charges)
		}

		setIsDeleting(false)
		setIsLoading(false)

		sosRouter.navigate(shipmentProfileLink)
	}

	const createPayment = async (): Promise<void> => {
		setIsLoading(true)

		const paymentRequest: apiTypes.PaymentRequest = {
			paidAmount: l.sum(
				brokerInvoice.charges.map((charge) => charge.totalPrice),
			),
			checkNumber: '',
			source: 'credit',
			currency: 'USD',
		}

		const paymentResponse = await apiPayment.createPayment(paymentRequest)

		if (paymentResponse.error) {
			sosToast.sendApiErrorResponseToast(paymentResponse)
		} else {
			brokerInvoice.charges.map(
				async (charge) =>
					await createPaymentAllocation(
						paymentResponse.data.id,
						charge.id,
						charge.unitPrice * charge.qty,
					),
			)
		}

		setIsLoading(false)
	}

	const createPaymentAllocation = async (
		paymentId: string,
		chargeId: string,
		totalPrice: number,
	): Promise<void> => {
		setIsLoading(true)

		const paymentAllocationRequest: apiTypes.PaymentAllocationCreateRequest = {
			chargeId: chargeId,
			amount: totalPrice,
			currency: 'USD',
			shipmentId: shipmentId,
			brokerInvoiceId: brokerInvoiceId,
		}

		const paymentAllocationResponse = await apiPaymentAllocation.createPaymentAllocation(
			paymentId,
			paymentAllocationRequest,
		)

		if (paymentAllocationResponse.error) {
			sosToast.sendApiErrorResponseToast(paymentAllocationResponse)
		} else {
			if (paymentAllocations) {
				paymentAllocations.unshift(paymentAllocationResponse.data)
				setPaymentAllocations(paymentAllocations)
			} else {
				setPaymentAllocations([paymentAllocationResponse.data])
			}

			await updateBrokerInvoice()
		}

		setIsLoading(false)
	}

	const updatePaymentAllocation = async (
		paymentId: string,
		paymentAllocationId: string,
	): Promise<void> => {
		setIsLoading(true)

		const paymentAllocationRequest: apiTypes.PaymentAllocationUpdateRequest = {
			amount: 0,
			currency: 'USD',
		}

		const paymentAllocationResponse = await apiPaymentAllocation.updatePaymentAllocation(
			paymentId,
			paymentAllocationId,
			paymentAllocationRequest,
		)

		if (paymentAllocationResponse.error) {
			sosToast.sendApiErrorResponseToast(paymentAllocationResponse)
		} else {
			if (paymentAllocations) {
				paymentAllocations.unshift(paymentAllocationResponse.data)
				setPaymentAllocations(paymentAllocations)
			} else {
				setPaymentAllocations([paymentAllocationResponse.data])
			}

			// await updateBrokerInvoice('invoiced')
			await updateBrokerInvoice('invoiced')
		}

		setIsLoading(false)
	}

	const downloadPDF = async (): Promise<void> => {
		setIsLoading(true)
		const result = await apiBrokerInvoice.fetchBrokerInvoicePDF(brokerInvoiceId)
		setIsLoading(false)
		const arrayBuffer = (await result.data.arrayBuffer()) as ArrayBuffer
		const base64 = Buffer.from(arrayBuffer).toString('base64')

		const a = document.createElement('a') //Create <a>
		a.href = 'data:application/pdf;base64,' + base64 //Image Base64 Goes here
		a.download = brokerInvoice.invoiceNumber || brokerInvoice.id //File name Here
		a.click() //Downloaded file
	}

	useEffect(() => {
		fireAndForget(async () => getBrokerInvoice(), `Fetching Broker Invoices`)
	}, [brokerInvoiceId]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Layout>
			<div className={classes.subwayContainer}>
				<BrokerShipmentProfileInvoicesSubway testid invoice={brokerInvoice} />
			</div>
			<Row>
				<Col
					xs={8}
					className={`${classes.pageTitle} ${classes.customerInvoicePageCol}`}
				>
					<div>
						<h1>{t('customerInvoice', tPrefix)}:</h1>
						<Input
							value={brokerInvoiceNumber}
							onChange={(newVal: string) => setBrokerInvoiceNumber(newVal)}
							autofocus={editBrokerInvoiceNumber}
							readOnly={!editBrokerInvoiceNumber}
						/>
					</div>
					<div>
						{brokerInvoice?.invoiceStatus !== 'void' && (
							<React.Fragment>
								<IconButton
									icon={
										!editBrokerInvoiceNumber
											? solidIcons.faPencilAlt
											: solidIcons.faCheck
									}
									color={editBrokerInvoiceNumber ? 'green' : 'black'}
									spin={isLoading && !isDeleting}
									iconClassName={classes.editSaveIcon}
									onClick={async () => {
										if (editBrokerInvoiceNumber) {
											brokerInvoice.invoiceNumber = brokerInvoiceNumber

											// await updateBrokerInvoice('review')
											await updateBrokerInvoice()
										}
										setEditBrokerInvoiceNumber(!editBrokerInvoiceNumber)
									}}
									testId={'customer-invoice-edit-save'}
								/>
								<IconButton
									icon={solidIcons.faTimes}
									color='red'
									iconClassName={classes.cancelIcon}
									onClick={() => {
										setIsDeleting(true)
									}}
									testId={'customer-invoice-delete'}
								/>
							</React.Fragment>
						)}
						{brokerInvoice?.invoiceStatus === 'void' && (
							<p className={classes.voidedInvoiceText}>VOIDED INVOICE</p>
						)}
					</div>
				</Col>
				<Col xs={4} className={classes.invoiceStatusButton}>
					{brokerInvoice?.invoiceStatus === 'review' && (
						<React.Fragment>
							<Button
								color={'red'}
								onClick={async () => {
									setVoidInvoiceModal(true)
								}}
							>
								{t('voidCustomerInvoice', tPrefix)}
							</Button>
							<Button color={'green'} onClick={approveInvoice}>
								{t('approveInvoice', tPrefix)}
							</Button>
						</React.Fragment>
					)}
					{brokerInvoice?.invoiceStatus === 'approved' && (
						<React.Fragment>
							<div className={classes.brokerInvoiceButtons}>
								<Button
									color={'green'}
									onClick={async () => {
										await updateBrokerInvoice()
									}}
									isDisabled={isLoading}
								>
									{t('reviewInvoice', tPrefix)}
								</Button>
							</div>
							<div>
								<Button
									color={'red'}
									onClick={async () => {
										setVoidInvoiceModal(true)
									}}
								>
									{t('voidCustomerInvoice', tPrefix)}
								</Button>
							</div>
							<Button
								color={'green'}
								onClick={() => {
									setIsInvoiceCustomerConfirmModalOpen(true)
								}}
							>
								{t('invoiceCustomer', tPrefix)}
							</Button>
						</React.Fragment>
					)}

					{['invoiced', 'partially_paid'].includes(
						brokerInvoice?.invoiceStatus,
					) && (
						<React.Fragment>
							<Button
								color={'blue'}
								onClick={downloadPDF}
								isSpinning={isLoading}
							>
								{t('downloadPDF', tPrefix)}
							</Button>
							<Button
								color={'green'}
								onClick={async () => {
									await createPayment()
								}}
							>
								{t('applyPayment', tPrefix)}
							</Button>
						</React.Fragment>
					)}
					{brokerInvoice?.invoiceStatus === 'paid_in_full' && (
						<React.Fragment>
							<div className={classes.brokerInvoiceButtons}>
								<Button
									color={'green'}
									onClick={async () => {
										paymentAllocations.map(
											async (paymentAllocation) =>
												await updatePaymentAllocation(
													// payment.id,
													paymentAllocation.paymentId,
													paymentAllocation.id,
												),
										)
									}}
								>
									{t('adjustPayment', tPrefix)}
								</Button>
							</div>
							<Button
								color={'blue'}
								onClick={downloadPDF}
								isSpinning={isLoading}
							>
								{t('downloadPDF', tPrefix)}
							</Button>
						</React.Fragment>
					)}
				</Col>
				<Modal
					content={() => (
						<div data-testid={'customer-invoice-delete-modal'}>
							<Center>
								<p>{t('deleteThisInvoice?', tPrefix)}</p>
							</Center>

							<AlignRight>
								<OkCancelButtons
									isValid={true}
									ok={t('ok', tPrefix)}
									okColor={'green'}
									okTestId={'customer-invoice-delete-modal-ok'}
									isSpinning={isLoading}
									onOk={async () => {
										setEditBrokerInvoiceNumber(false)

										await deleteBrokerInvoice()
									}}
									cancel={t('cancel', tPrefix)}
									onCancel={() => setIsDeleting(false)}
									cancelTestId={'customer-invoice-delete-modal-cancel'}
								/>
							</AlignRight>
						</div>
					)}
					isOpen={isDeleting}
					title={t('confirmDelete', tPrefix)}
				/>
			</Row>
			<Spacer />
			<Row>
				<Col xs={8} className={classes.customerInvoicePageCol}>
					<Card
						title={t('customerInvoice', tPrefix)}
						color={'darkBlue'}
						testId={'customer-invoice-parent-card'}
					>
						<CustomerInvoiceDetails
							brokerInvoice={brokerInvoice}
							providerInvoices={providerInvoices}
							setTotalCost={setTotalCost} // THIS IS TEMPORARY ONLY AS PER TICKET TMS-3913
							brokerInvoiceCharges={brokerInvoiceCharges}
						/>
						<CustomerCharges
							shipmentId={shipmentId}
							isFetchingBrokerInvoices={isFetchingBrokerInvoices}
							brokerInvoice={brokerInvoice}
							brokerChargeCodes={brokerChargeCodes}
							brokerInvoiceCharges={brokerInvoiceCharges}
							setBrokerInvoiceCharges={setBrokerInvoiceCharges}
						/>
						<CustomerInvoiceNotes
							brokerInvoice={brokerInvoice}
							onUpdate={(note: string) => {
								setBrokerInvoice(
									Object.assign({}, brokerInvoice, { notes: note }),
								)
							}}
						/>
					</Card>
				</Col>
				<Col xs={4}>
					<ReferenceInformation
						brokerShipment={brokerShipment}
						totalCost={totalCost} // THIS IS TEMPORARY ONLY AS PER TICKET TMS-3913
					/>
					<Spacer />
					<ProviderInvoice
						shipmentId={shipmentId}
						clientConfigId={brokerShipment?.contractId}
						brokerInvoiceId={brokerInvoiceId}
						providerChargeCodes={providerChargeCodes}
						brokerInvoiceStatus={brokerInvoice?.invoiceStatus}
					/>
				</Col>
			</Row>
			<InvoiceCustomerConfirmModal
				isModalOpen={isInvoiceCustomerConfirmModalOpen}
				onModalClose={() => {
					setIsInvoiceCustomerConfirmModalOpen(false)
				}}
				brokerInvoiceId={brokerInvoiceId}
				brokerInvoice={brokerInvoice}
				setBrokerInvoice={setBrokerInvoice}
			/>

			<Modal
				content={() => (
					<div data-testid={'void-customer-invoice-modal'}>
						<Center>
							<p>{t('voidCustomerInvoice?', tPrefix)}</p>
						</Center>

						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'void-customer-invoice-modal-ok'}
								isSpinning={isLoading}
								onOk={async () => {
									// await updateBrokerInvoice(
									// 	{ ...brokerInvoice, invoiceStatus: 'void' },
									// 	// Object.assign({}, brokerInvoice, { invoiceStatus: 'void' }),
									// )
									// await updateBrokerInvoice('void')
									await updateBrokerInvoice('void')
									setVoidInvoiceModal(false)
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => setVoidInvoiceModal(false)}
								cancelTestId={'void-customer-invoice-modal-cancel'}
							/>
						</AlignRight>
					</div>
				)}
				isOpen={voidInvoice}
				title={t('confirmVoidStatus', tPrefix)}
			/>
			<Debug data={{ shipmentId }} label={'shipmentId'} />
			<Debug data={brokerInvoice} label={'brokerInvoice'} />
		</Layout>
	)
}
