import { FC } from 'app/FunctionalComponent'
import React, { ReactNode, useState } from 'react'
import { Marker, Polyline } from 'react-leaflet'
import { apiRouting, apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button/Button'
import { Debug } from 'ui/components/dev'
import { watch } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import {
	LeafletMap,
	LeafletMapBounds,
} from 'ui/components/shared/leaflet-map/LeafletMap'
import { markerIconFactory } from 'ui/components/shared/leaflet-map/utils/leafletMapIcons'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import {
	extractRoutePolylineAndMarkers,
	RoutingElements,
} from 'ui/lib/valhalla-routing'
import { getValhallaStopSequence } from 'ui/lib/valhalla-routing/getValhallaStopSequence'
import { sosMakeOfferPage } from 'ui/pages/spot-quote'
import { sosUser } from 'ui/state'
import { theme } from 'ui/theme'
import * as commonClasses from 'ui/theme/common.module.scss'
import { getMapHeight } from './shared/functions/getMapHeight'
import * as classes from './ShipmentProfileLayout.module.scss'

const tPrefix = 'page.shipmentProfile.layout'

export type ToggleableShipmentProfilePiece =
	| 'rates'
	| 'invites'
	| 'tracking'
	| 'bol'
	| 'docs'
	| 'labels'
	| 'invoices'

const ShipmentProfileSection: FC = (props: {
	children: ReactNode
	noBoxShadow?: boolean
	className?: string
}) => {
	let className = theme.addClass(
		classes.mapSection,
		theme.getBackgroundWithTextColor('white'),
	)
	className = theme.addClassIf(
		props.noBoxShadow,
		classes.noBoxShadow,
		className,
	)
	className = theme.addClassIf(
		!l.isNil(props.className),
		props.className,
		className,
	)

	return <div className={className}>{props.children}</div>
}

export const ShipmentProfileLayout: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse | apiTypes.ShipmentResponse
	summary: React.ReactNode
	subway: React.ReactNode
	shipmentInteractionButtons: React.ReactNode
	shipmentDetails: React.ReactNode
	rates: React.ReactNode
	invites: React.ReactNode
	tracking: React.ReactNode
	bol: React.ReactNode
	labels: React.ReactNode
	hideLogOffer?: boolean
	docs: React.ReactNode
	invoices?: React.ReactNode
	bookUnbookModals?: React.ReactNode
}) => {
	const { shipment } = props

	const toggleableShipmentProfilePieces: ToggleableShipmentProfilePiece[] = [
		'rates',
	]

	if (props.invites) {
		toggleableShipmentProfilePieces.push('invites')
	}

	if (props.labels && shipment.mode === 'parcel') {
		toggleableShipmentProfilePieces.push('labels')
	}

	if (shipment.shipmentStatus === 'booked') {
		if (['ltl', 'truckload', 'volume'].includes(shipment.bookedRate?.method)) {
			toggleableShipmentProfilePieces.push('bol')
		}
	}

	if (props.tracking) {
		toggleableShipmentProfilePieces.push('tracking')
	}

	if (props.invoices && shipment.shipmentStatus === 'booked') {
		toggleableShipmentProfilePieces.push('invoices')
	}

	const [
		showingShipmentProfileSection,
		setShowingShipmentProfileSection,
	] = useState<ToggleableShipmentProfilePiece>(null)

	const [showShipmentDetails, setShowShipmentDetails] = useState<boolean>(true)

	const toggleShowingSection = (
		section: ToggleableShipmentProfilePiece,
	): void => {
		if (section === showingShipmentProfileSection) {
			setShowingShipmentProfileSection(null)
		} else {
			setShowingShipmentProfileSection(section)
		}
	}

	const toggleShowShipmentDetails = (): void => {
		setShowShipmentDetails((showShipmentDetails) => !showShipmentDetails)
	}

	const [routingElements, setRoutingElements] = useState<RoutingElements>(null)
	const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 })
	const [mapBounds, setMapBounds] = useState<LeafletMapBounds>(null)

	watch(() => {
		fireAndForget(async () => {
			if (shipment.shipmentStatus === 'booked') {
				if (
					['ltl', 'truckload', 'volume'].includes(shipment.bookedRate?.method)
				) {
					setShowingShipmentProfileSection('bol')
				} else if (!sosUser.isUserBroker() && shipment.mode === 'parcel') {
					setShowingShipmentProfileSection('labels')
				}
			} else {
				setShowingShipmentProfileSection('rates')
			}

			const stops = getValhallaStopSequence(props.shipment)
			const route = await apiRouting.fetchRoutingInfo(() => {}, {
				locations: stops,
				costing: 'truck',
			})

			const routeData = route?.data?.trip?.legs[0]

			if (!routeData) {
				return
			}

			const routeAndMarkers = extractRoutePolylineAndMarkers(
				[],
				routeData.shape,
			)
			setRoutingElements(routeAndMarkers)
			const centerLat =
				(routeData.summary?.max_lat + routeData.summary?.min_lat) / 2
			const centerLng =
				(routeData.summary?.max_lon + routeData.summary?.min_lon) / 2
			setMapCenter({ lat: centerLat, lng: centerLng })
			setMapBounds({
				topLeft: [routeData.summary?.max_lat, routeData.summary?.min_lon],
				bottomRight: [routeData.summary?.min_lat, routeData.summary?.max_lon],
			})
		}, 'fetching routing info for shipment profile map')
	}, [props.shipment.id])

	let displayOptionClassName = classes.displayOption
	displayOptionClassName = theme.addClass(
		commonClasses.clickable,
		displayOptionClassName,
	)

	const mapHeight = getMapHeight(
		shipment,
		showShipmentDetails,
		showingShipmentProfileSection,
	)

	let shipmentDetailsButtonClassName = displayOptionClassName
	shipmentDetailsButtonClassName = theme.addClass(
		classes.shipmentDetailsButton,
		shipmentDetailsButtonClassName,
	)

	const shipmentSummaryClassName = theme.addClass('d-flex', classes.summaryBar)

	const shipmentInteractionButtonsClassName = theme.addClass(
		theme.getBorderColor('lightGray'),
		classes.shipmentDetailsInteractionButtons,
	)

	return (
		<div className={classes.container}>
			{shipment && shipment.shipmentStatus !== 'void' && !props.hideLogOffer && (
				<div className={classes.logOffer}>
					<Button
						color='blue'
						onClick={() => {
							fireAndForget(
								() =>
									sosMakeOfferPage.navigateToMakeOfferPage(
										shipment.id,
										null,
										true,
									),
								`navigation to log offer ${shipment.id}`,
							)
						}}
					>
						{t('logOffer', tPrefix)}
					</Button>
				</div>
			)}
			<LeafletMap
				height={`${mapHeight}px`}
				zoomPosition='bottomright'
				center={mapCenter}
				bounds={mapBounds}
				testId='shipment-profile-map'
			>
				{routingElements && (
					<>
						<Polyline
							positions={routingElements.polylinePositions}
							dashArray={props.shipment.mode !== 'ltl' ? null : '5'}
						/>
						<Marker
							position={routingElements.truckMarkerPosition}
							icon={markerIconFactory(props.shipment.mode, 'normal', 'normal')}
						/>
						{l.map(routingElements.routeMarkersMetadata, (marker, idx) => (
							<Marker key={idx} position={marker.position} icon={marker.icon} />
						))}
					</>
				)}
			</LeafletMap>

			<div
				className={`${classes.profileSectionsContainer} bootstrap-wrapper`}
				style={{ height: `${mapHeight}px` }}
			>
				<div className={shipmentSummaryClassName}>
					<ShipmentProfileSection className={classes.summaryBarSpacing}>
						{props.summary}
					</ShipmentProfileSection>

					{props.shipmentInteractionButtons &&
						shipment.shipmentStatus !== 'void' && (
							<ShipmentProfileSection
								className={shipmentInteractionButtonsClassName}
							>
								{props.shipmentInteractionButtons}
							</ShipmentProfileSection>
						)}
				</div>

				<div className={classes.subwaySection}>
					<ShipmentProfileSection className={classes.subwaySectionSpacing}>
						{props.subway}
					</ShipmentProfileSection>
				</div>

				<div className={classes.displayOptionContainer}>
					<ShipmentProfileSection
						noBoxShadow={showShipmentDetails}
						className={classes.displayShipmentDetailsOptionSpacing}
					>
						<div
							onClick={() => toggleShowShipmentDetails()}
							className={theme.addClass(
								showShipmentDetails === true
									? classes.shipmentDetailsButtonActive
									: classes.shipmentDetailsButtonInactive,
								shipmentDetailsButtonClassName,
							)}
							data-testid={'toggle-shipmentDetails'}
						>
							{t('shipmentDetails', tPrefix)}
						</div>
					</ShipmentProfileSection>

					<ShipmentProfileSection
						className={classes.displayOptionContainerSpacing}
					>
						<div className={classes.navButtons}>
							{toggleableShipmentProfilePieces.map(
								(section: ToggleableShipmentProfilePiece) => {
									return (
										<div
											key={section}
											data-testid={'toggle-' + section}
											onClick={() => toggleShowingSection(section)}
											className={theme.addClass(
												showingShipmentProfileSection === section
													? classes.navButtonsActive
													: theme.getHoverBackgroundColors('white'),
												displayOptionClassName,
											)}
										>
											{t(section, tPrefix)}
										</div>
									)
								},
							)}
							<div
								onClick={() => {
									setShowingShipmentProfileSection(null)
									setShowShipmentDetails(false)
								}}
								className={theme.addClass(
									theme.getHoverBackgroundColors('white'),
									displayOptionClassName,
								)}
								data-testid={'show-map'}
							>
								{t('map', tPrefix)}
							</div>
						</div>
					</ShipmentProfileSection>
				</div>
				<div className={classes.profileSections}>
					{showShipmentDetails && props.shipmentDetails && (
						<div>
							<ShipmentProfileSection
								className={classes.mainProfileSectionSpacing}
							>
								{props.shipmentDetails}
							</ShipmentProfileSection>
							<Spacer />
						</div>
					)}
					{showingShipmentProfileSection === 'rates' && props.rates && (
						<div>
							<ShipmentProfileSection
								className={classes.mainProfileSectionSpacing}
							>
								{props.rates}
							</ShipmentProfileSection>
							<Spacer />
						</div>
					)}
					{showingShipmentProfileSection === 'invites' && props.invites && (
						<div>
							<ShipmentProfileSection
								className={classes.mainProfileSectionSpacing}
							>
								{props.invites}
							</ShipmentProfileSection>
						</div>
					)}

					{showingShipmentProfileSection === 'tracking' && props.tracking && (
						<div>
							<ShipmentProfileSection
								className={classes.mainProfileSectionSpacing}
							>
								{props.tracking}
							</ShipmentProfileSection>
						</div>
					)}

					{showingShipmentProfileSection === 'bol' && props.bol && (
						<ShipmentProfileSection
							className={classes.mainProfileSectionSpacing}
						>
							{props.bol}
						</ShipmentProfileSection>
					)}
					{showingShipmentProfileSection === 'labels' && props.labels && (
						<ShipmentProfileSection
							className={classes.mainProfileSectionSpacing}
						>
							{props.labels}
						</ShipmentProfileSection>
					)}

					{showingShipmentProfileSection === 'docs' && props.docs && (
						<ShipmentProfileSection
							className={classes.mainProfileSectionSpacing}
						>
							{props.docs}
						</ShipmentProfileSection>
					)}

					{showingShipmentProfileSection === 'invoices' && props.invoices && (
						<ShipmentProfileSection
							className={classes.mainProfileSectionSpacing}
						>
							{props.invoices}
						</ShipmentProfileSection>
					)}
				</div>
				{props.bookUnbookModals}
			</div>
			<Debug data={shipment} label='shipment' />
		</div>
	)
}
