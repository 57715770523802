import { apiCommon, apiTypes } from './'
import { IRequestState } from './requestState'

export interface PrintNodeComputerResponse {
	id: number
	name: string
	inet: string
	inet6: string
	hostname: string
	version: string
	jre: string
	createTimestamp: string
	state: string
}
export interface PrintNodeScalesResponse {
	mass: number[]
	computerId: string
	vendor: string
	vendorId: number
	product: string
	productId: number
	port: string
	deviceName: string
	deviceNum: number
	count: number
	measurement: {
		g: number
		kg: number
		lb: number
		oz: number
	}
	clientReportedCreateTimestamp: string
	ntpOffset: number
	ageOfData: number
}

export interface PrintNodeAPIResponse {
	id: number
	computer: {
		id: number
		name: string
		inet: string
		inet6: string
		hostname: string
		version: string
		jre: string
		createTimestamp: string
		state: string
	}
	name: string
	description: string
	capabilities: {
		bins: string[]
		collate: boolean
		color: boolean
		copies: number
		dpis: string[]
		duplex: boolean
		extent: []
		medias: []
		nup: number[]
		papers: {}
		printrate: {}
		supports_custom_paper_size: boolean
	}
	default: boolean
	createTimestamp: string
	state: string
}

export interface UIPrinterResponse extends apiTypes.PrinterResponse {
	state?: string
}

export interface UIPrinterRequest extends apiTypes.PrinterRequest {
	state?: string
}

export async function getCredentialsList(
	onProgress: (
		rs: IRequestState<apiTypes.PrintNodeCredentialsResponse[]>,
	) => void,
	locationId: string,
): Promise<IRequestState<apiTypes.PrintNodeCredentialsResponse[]>> {
	const url = `printNode/credentials`
	const queryParams = { locationId }
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
		queryParams,
	)

	return response
}

export async function getCredentialsById(
	onProgress: (
		rs: IRequestState<apiTypes.PrintNodeCredentialsResponse>,
	) => void,
	credentialsId: string,
): Promise<IRequestState<apiTypes.PrintNodeCredentialsResponse>> {
	const url = `printNode/credentials/${credentialsId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
	)

	return response
}

export async function updateCredentials(
	onProgress: (
		rs: IRequestState<apiTypes.PrintNodeCredentialsResponse>,
	) => void,
	id: string,
	data: apiTypes.PrintNodeCredentialsRequest,
): Promise<IRequestState<apiTypes.PrintNodeCredentialsResponse>> {
	const url = `printNode/credentials/${id}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'PUT',
			data,
		},
		url,
	)

	return response
}

export async function createCredentials(
	onProgress: (
		rs: IRequestState<apiTypes.PrintNodeCredentialsResponse>,
	) => void,
	data: apiTypes.PrintNodeCredentialsRequest,
	createAccount?: boolean,
): Promise<IRequestState<apiTypes.PrintNodeCredentialsResponse>> {
	const url =
		`printNode/credentials` + (createAccount ? '?createAccount=true' : '')
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data,
		},
		url,
	)

	return response
}

export async function updatePrinterConfig(
	onProgress: (rs: IRequestState<apiTypes.PrinterResponse>) => void,
	credentialsId: string,
	printerId: string,
	data: apiTypes.PrinterRequest,
): Promise<IRequestState<apiTypes.PrinterResponse>> {
	const url = `printNode/credentials/${credentialsId}/printers/${printerId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'PUT',
			data,
		},
		url,
	)

	return response
}

export async function getPrinterList(
	onProgress: (rs: IRequestState<apiTypes.PrinterResponse[]>) => void,
	credentialsId: string,
): Promise<IRequestState<apiTypes.PrinterResponse[]>> {
	const url = `printNode/credentials/${credentialsId}/printers`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
	)

	return response
}

export async function createPrinter(
	onProgress: (rs: IRequestState<apiTypes.PrinterResponse>) => void,
	data: apiTypes.PrinterRequest,
	credentialsId: string,
): Promise<IRequestState<apiTypes.PrinterResponse>> {
	const url = `printNode/credentials/${credentialsId}/printers`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data,
		},
		url,
	)

	return response
}

export async function getPrinterGroupList(
	onProgress: (rs: IRequestState<apiTypes.PrinterGroupResponse[]>) => void,
	credentialsId: string,
): Promise<IRequestState<apiTypes.PrinterGroupResponse[]>> {
	const url = `printNode/credentials/${credentialsId}/printerGroups`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
	)

	return response
}

export async function createPrinterGroup(
	onProgress: (rs: IRequestState<apiTypes.PrinterGroupResponse>) => void,
	data: apiTypes.PrinterGroupRequest,
	credentialsId: string,
): Promise<IRequestState<apiTypes.PrinterGroupResponse>> {
	const url = `printNode/credentials/${credentialsId}/printerGroups`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data,
		},
		url,
	)

	return response
}

export async function updatePrinterGroup(
	onProgress: (rs: IRequestState<apiTypes.PrinterGroupResponse>) => void,
	data: apiTypes.PrinterGroupRequest,
	credentialsId: string,
	printerGroupId: string,
): Promise<IRequestState<apiTypes.PrinterGroupResponse>> {
	const url = `printNode/credentials/${credentialsId}/printerGroups/${printerGroupId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'PUT',
			data,
		},
		url,
	)

	return response
}

export async function deletePrinterGroup(
	onProgress: (rs: IRequestState<apiTypes.PrinterGroupResponse>) => void,
	credentialsId: string,
	printerGroupId: string,
): Promise<IRequestState<apiTypes.PrinterGroupResponse>> {
	const url = `printNode/credentials/${credentialsId}/printerGroups/${printerGroupId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'DELETE',
		},
		url,
	)

	return response
}

export function getAuthorization(apiKey: string): string {
	const authorization = `Basic ${Buffer.from(`${apiKey}:`).toString('base64')}`

	return authorization
}

export async function getScaleList(
	credential: apiTypes.PrintNodeCredentialsResponse,
): Promise<IRequestState<PrintNodeScalesResponse[]>[]> {
	const url = 'https://api.printnode.com/computer'
	const response = await apiCommon.externalFetch(null, url + 's', {
		method: 'GET',
		headers: {
			Authorization: getAuthorization(credential.apiKey),
		},
	})

	const promises = await Promise.all(
		(response.data as PrintNodeComputerResponse[]).map((item) =>
			apiCommon.externalFetch(null, url + `/${item.id}/scales`, {
				method: 'GET',
				headers: {
					Authorization: getAuthorization(credential.apiKey),
				},
			}),
		),
	)

	return promises.map((response) => response.data)
}

export async function getPrinterListFromPrintNodeAPI(
	credential: apiTypes.PrintNodeCredentialsResponse,
): Promise<IRequestState<PrintNodeAPIResponse[]>> {
	const url = 'https://api.printnode.com/printers'
	const response = await apiCommon.externalFetch<PrintNodeAPIResponse[]>(
		null,
		url,
		{
			method: 'GET',
			headers: {
				Authorization: getAuthorization(credential.apiKey),
			},
		},
	)

	return response
}
