import { FC } from 'app/FunctionalComponent'
import { Table } from 'common/components/table/Table'
import { DateTime } from 'luxon'
import { default as React, useState } from 'react'
import { ColumnDescription } from 'react-bootstrap-table-next'
import DatePicker from 'react-datepicker'
import { Button } from 'ui/components/common/button'
import { IconButton } from 'ui/components/common/icon/Icon'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { ISelectOptions, Select } from 'ui/components/common/select'
import { useOnce } from 'ui/components/hooks/useOnce'
import { tProviderName } from 'ui/components/i18n/commonTranslations'
import { t, tString } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow/FlexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { LocationSelector } from 'ui/components/shared/location-selector/LocationSelector'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import 'ui/pages/control-tower/map-view/ReactDatePicker.scss'
import { Layout } from 'ui/pages/layout/Layout'
import * as commonClasses from 'ui/theme/common.module.scss'
import { addClass, addClassIf, getTextColor } from 'ui/theme/theme'
import { stringArrayRenderer } from '../shipments/ShipmentsListHeaderRenderers'
import { getShipmentQuery } from './functions'
import { manifestProviders } from './manifestProviders'
import { ManifestShipmentsTable } from './ManifestShipmentsTable'
import * as classes from './ManifestsTable.module.scss'
import { providersRequiringLocation } from './providersRequiringLocation'
import { providersWithDatePicker } from './providersWithDatePicker'
import { IFormattedManifest } from './state/processManifests'
import * as sosManifests from './state/sosManifests'

export const tPrefixManifests = 'page.manifests'

const manifestTableDateFormat = 'MM/dd/yyyy'

const ManifestsPage: FC = (props: {}) => {
	const state = sos2.useSubscription(sosManifests.getSos())
	const [isFetchingProviders, setIsFetchingProviders] = useState(false)
	const [isGeneratingManifest, setIsGeneratingManifest] = useState(false)

	const locationId = sosManifests.getLocationId()

	useOnce(() => {
		fireAndForget(
			() =>
				sosManifests.fetchManifests(state.selectedProvider, 25, 0, locationId),
			'fetch manifests',
		)
		fireAndForget(async () => {
			setIsFetchingProviders(true)
			await sosManifests.fetchProviders(manifestProviders)
			setIsFetchingProviders(false)
		}, 'fetch providers')
	})

	// Poll for manifestable shipments
	React.useEffect(() => {
		const pollIntervalMs = 2000

		const { providers, selectedProvider } = state
		const selectedProviderResponse = l.find(
			providers,
			(provider) =>
				provider.providerName.toLowerCase() === selectedProvider.toLowerCase(),
		)

		const intervalId = setInterval(async () => {
			if (
				sosManifests.isPollingTurnedOn() &&
				selectedProviderResponse &&
				state.showPendingShipments
			) {
				await sosManifests.fetchShipments(
					getShipmentQuery(
						state.selectedProvider,
						selectedProviderResponse.id,
						state.newManifestDate,
						locationId,
					),
				)
			}
		}, pollIntervalMs)

		return () => {
			clearInterval(intervalId)
		}
	})

	const upsManifestsTableHeaders: ColumnDescription[] = [
		{
			dataField: 'createdDate',
			text: tString('dateGenerated', tPrefixManifests),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'dateGenerated',
			}),
			formatter: (cell, row) => {
				return (
					<div className={commonClasses.boldText}>
						{DateTime.fromISO(cell).toFormat(manifestTableDateFormat)}
					</div>
				)
			},
		},
		{
			dataField: 'providerName',
			text: tString('providerName', tPrefixManifests),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'providerName',
			}),
			formatter: (cell, row) => {
				return <div>{tProviderName(cell)}</div>
			},
		},
		{
			dataField: 'uploadStatus',
			text: tString('uploadStatus', tPrefixManifests),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'uploadStatus',
			}),
			formatter: (cell, row) => {
				return (
					<div
						className={addClassIf(
							cell === 'fatalError',
							getTextColor('red'),
							'',
						)}
					>
						{t(cell, tPrefixManifests)}
					</div>
				)
			},
		},
		{
			dataField: 'packageCount',
			text: tString('packages', tPrefixManifests),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'packages',
			}),
		},
		// {
		// 	dataField: 'upsErrors',
		// 	text: tString('upsErrors', tPrefixUpsHundredWeight),
		// 	//@ts-ignore
		// 	attrs: () => ({
		// 		'data-testid': 'upsErrors',
		// 	}),
		// 	formatter: idArrayRenderer,
		// },

		{
			dataField: 'swanleapErrors',
			text: tString('swanleapErrors', tPrefixManifests),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'swanleapErrors',
			}),
			formatter: stringArrayRenderer,
		},
		{
			dataField: 'voidDate',
			text: tString('dateVoided', tPrefixManifests),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'voidDate',
			}),
			formatter: (cell, row) => {
				return (
					<div className={commonClasses.boldText}>
						{cell && DateTime.fromISO(cell).toFormat(manifestTableDateFormat)}
					</div>
				)
			},
		},
		{
			dataField: '',
			text: '',
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'buttons',
			}),
			formatter: (cell, row: IFormattedManifest) => {
				return (
					<FlexRow>
						<Button
							bootstrapStyles={true}
							color='red'
							onClick={() => sosManifests.voidManifest(row.id)}
							isSpinning={state.voidingManifests.indexOf(row.id) > -1}
							isDisabled={!l.isNil(row.voidDate)}
							testId='voidManifestButton'
						>
							{t('void', tPrefixManifests)}
						</Button>
						<Button
							bootstrapStyles={true}
							color='blue'
							onClick={() => sosManifests.viewManifestDetails(row.id)}
							isDisabled={
								!l.isNil(row.voidDate) ||
								state.voidingManifests.indexOf(row.id) > -1
							}
							testId='detailsManifestButton'
						>
							{t('details', tPrefixManifests)}
						</Button>
						{(row.image || row.providerFile) && (
							<Button
								bootstrapStyles={true}
								color='blue'
								onClick={() => sosManifests.downloadManifest(row)}
								isDisabled={
									!l.isNil(row.voidDate) ||
									state.voidingManifests.indexOf(row.id) > -1
								}
								testId='downloadManifestButton'
							>
								{t('download', tPrefixManifests)}
							</Button>
						)}
					</FlexRow>
				)
			},
		},
	]

	const providerOptions: ISelectOptions[] = l.map(
		manifestProviders,
		(provider) => {
			return { value: provider, label: tProviderName(provider) }
		},
	)

	const showDatePicker = l.includes(
		providersWithDatePicker,
		state.selectedProvider,
	)
	const showLocationSelector = l.includes(
		providersRequiringLocation,
		state.selectedProvider,
	)

	const canGenerateManifest =
		!isFetchingProviders &&
		(!showDatePicker || !l.isNil(state.newManifestDate)) &&
		(!showLocationSelector || !l.isNil(state.locationId))

	return (
		<Layout>
			<div className='bootstrap-wrapper'>
				<FlexRow verticalAlign='center'>
					<div>
						<Select
							value={state.selectedProvider}
							options={providerOptions}
							onChange={(provider) => sosManifests.setProvider(provider)}
							testId='manifestProviderSelector'
							readOnly={isFetchingProviders || isGeneratingManifest}
							className={classes.searchInput}
						/>
					</div>
					<div>
						{showDatePicker && (
							<div
								className={addClass(
									classes.datePicker,
									'date-picker-wrapper bootstrap-wrapper',
								)}
								data-testid='manifestDateSelector'
							>
								<DatePicker
									maxDate={new Date()}
									selected={new Date(state.newManifestDate)}
									onChange={(date: Date) => {
										sosManifests.setNewManifestDate(
											DateTime.fromJSDate(date).toFormat(
												manifestTableDateFormat,
											),
										)
									}}
									className='form-control form-control-sm'
									placeholderText={tString('selectDate', tPrefixManifests)}
								/>
							</div>
						)}
					</div>
					<div>
						{showLocationSelector && (
							<LocationSelector
								onChange={(location) =>
									sosManifests.setLocationId(location?.value)
								}
								includeAll={false}
								onlyShowLeafLocations={true}
							/>
						)}
					</div>
					<div>
						<Button
							color='blue'
							bootstrapStyles={true}
							className='text-nowrap'
							onClick={async () => {
								setIsGeneratingManifest(true)
								await sosManifests.generateManifest(state.locationId)
								setIsGeneratingManifest(false)
							}}
							isDisabled={!canGenerateManifest}
							isSpinning={isGeneratingManifest}
							isSmall={true}
							testId='generateManifestButton'
						>
							{t(
								showDatePicker
									? 'manuallyGenerateManifestForAboveDate'
									: 'manuallyGenerateManifest',
								tPrefixManifests,
							)}
						</Button>
					</div>
				</FlexRow>
				<Spacer />
				<div>
					<FlexRow>
						<IconButton
							icon={
								state.showPendingShipments
									? solidIcons.faAngleDown
									: solidIcons.faAngleUp
							}
							onClick={() =>
								sosManifests.setShowPendingShipments(
									!state.showPendingShipments,
								)
							}
						/>
						<div>{t('pendingShipments', tPrefixManifests)}</div>
					</FlexRow>
				</div>
				{state.showPendingShipments && (
					<ManifestShipmentsTable shipments={state.pendingShipments} />
				)}
				<Spacer height='20px' />
				<div>{t('manifests', tPrefixManifests)}</div>
				<div data-testid='manifestsTable'>
					<Table
						columns={upsManifestsTableHeaders}
						data={state.manifests}
						isLoading={null}
						totalSize={state.manifestCount}
						keyField='id'
						verticalCenterCells={true}
						testId='upsManifestsTable'
						onTableChange={(_type, newState) =>
							sosManifests.fetchManifests(
								state.selectedProvider,
								newState.sizePerPage,
								newState.sizePerPage * (newState.page - 1),
								locationId,
							)
						}
					/>
				</div>
			</div>
		</Layout>
	)
}

export { ManifestsPage }
