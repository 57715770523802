import React, { useState } from 'react'
import { t, tCurrency, tDate } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { InfiniteLoader } from 'ui/components/common/loader'
import { apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'
import * as tableClasses from 'ui/components/rates-table/RatesTable.module.scss'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { BrokerInvoiceChargeResponse } from 'ui/api/apiTypes'
import { InvoicesFilter } from '.'

type Order = 'asc' | 'desc'

interface SortOrder {
	key?: string
	order?: Order
}

const defaultSort: SortOrder = {
	key: 'creationDate',
	order: 'desc',
}

export const InvoicesTable = (props: {
	data: apiTypes.BrokerInvoiceResponse[]
	isLoading?: boolean
	testId?: string
	onOpenDetail: (invoice: apiTypes.BrokerInvoiceResponse) => void
	onSort: (column: string, order: Order) => void
	tPrefix: string
}): JSX.Element => {
	const { data, isLoading, tPrefix } = props
	const [sortOrder, setOrder] = useState<SortOrder>(defaultSort)
	const [invoiceFilter, setInvoiceFilter] = useState<string>('all')

	const translate = (key: string): JSX.Element => t(key, tPrefix)

	const onHeaderClicked = (key: string): void => {
		const order =
			sortOrder.order === 'desc' && sortOrder.key === key ? 'asc' : 'desc'

		props.onSort(key, order)
		setOrder({ key, order })
	}

	function getTotalCharges(charges: BrokerInvoiceChargeResponse[]): number {
		return l.sum(charges.map((charge) => charge.totalPrice))
	}

	const filteredInvoices =
		invoiceFilter === 'all'
			? props.data
			: props.data.filter((invoice) => invoice.invoiceStatus === invoiceFilter)

	return (
		<div data-testid={props.testId || 'rates-table'}>
			{!isLoading && (
				<InvoicesFilter
					selectedFilter={invoiceFilter}
					onChangeFilter={setInvoiceFilter}
					tPrefix={tPrefix}
				/>
			)}
			<InfiniteLoader isLoading={isLoading} />
			<Spacer />
			<table
				className={tableClasses.tableRates}
				cellPadding={0}
				cellSpacing={0}
			>
				<thead>
					<tr>
						<th />
						<th
							className={tableClasses.pointerHeader}
							onClick={() => onHeaderClicked('invoiceNumber')}
						>
							{translate('invoiceNumber')}
						</th>
						<th
							className={tableClasses.pointerHeader}
							onClick={() => onHeaderClicked('status')}
						>
							{translate('status')}
						</th>
						<th
							className={tableClasses.pointerHeader}
							onClick={() => onHeaderClicked('invoiceDate')}
						>
							{translate('invoiceDate')}
						</th>
						<th
							className={tableClasses.pointerHeader}
							onClick={() => onHeaderClicked('dueDate')}
						>
							{translate('dueDate')}
						</th>
						<th
							className={tableClasses.pointerHeader}
							onClick={() => onHeaderClicked('currency')}
						>
							{translate('currency')}
						</th>
						<th
							className={tableClasses.pointerHeader}
							onClick={() => onHeaderClicked('totalCharges')}
						>
							{translate('totalCharges')}
						</th>
					</tr>
				</thead>
				<tbody>
					{l.map(
						filteredInvoices,
						(invoice: apiTypes.BrokerInvoiceResponse, index: number) => (
							<tr key={index}>
								<td className={'pl-3'}>
									<IconButton
										onClick={(event: React.MouseEvent<HTMLElement>) => {
											event.stopPropagation()
											event.preventDefault()
											props.onOpenDetail(invoice)
										}}
										icon={solidIcons.faSearchPlus}
										testId={`invoices-table-${invoice.invoiceNumber}-zoom`}
									/>
								</td>
								<td>{invoice.invoiceNumber}</td>
								<td>{translate(invoice.invoiceStatus)}</td>
								<td>{tDate(invoice.invoiceDate)}</td>
								<td>{tDate(invoice.invoiceDue)}</td>
								<td>{invoice.currency}</td>
								<td>
									{tCurrency(
										getTotalCharges(invoice.charges),
										invoice.currency,
									)}
								</td>
							</tr>
						),
					)}
					{data.length === 0 && !isLoading && (
						<tr>
							<td colSpan={9} className={tableClasses.center}>
								{t('noRecords', tPrefix)}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}
