import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { Card } from 'ui/components/common/card/Card'
import { t, tString } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { SimpleAddress } from 'ui/components/shared/address'
import { SimpleDateTime } from 'ui/components/shared/dateTime'
import {
	IKeyValueOptions,
	KeyValue,
	KeyValueSeparator,
	KeyValueSpacer,
	ManualKeyValue,
	SimpleKeyValueTable,
} from 'ui/components/table'
import { idx } from 'ui/lib'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import {
	getBolNumbers,
	getPayloadLevelAccessorials,
	getPickupDate,
	getShipmentLevelAccessorials,
} from 'ui/lib/shipments/shipmentUtils'
import { newQuoteEquipmentTypesMetadata } from 'ui/pages/new-quote/EquipmentTypes'
import {
	FormattedAccessorial,
	FormattedStopAccessorials,
} from 'ui/pages/spot-quote/FormattedAccessorials'
import { HeaderText } from 'ui/pages/spot-quote/MakeOfferPage'
import { LabelText } from '../new-quote'
import { StaticMap } from './map'

// TODO: add missing fields
export interface IShipmentDetails {
	swanleapShipmentId: string
	bolNumbers: string
	pickupDate: string
	equipmentType: string
	accessorials: string[]
	billTo: string
	shipmentFlow: string
	paymentType: string
	totalMiles: number
	totalWeight: number
	accountingStatus: string
	bidDuration: number
	sealNumber: string
	trailerLength: number
	customerInvoicingStatus: string
}

export const MakeOfferShipmentDetails: FC = (props: {
	shipment: apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse
	fillHeight?: boolean
	showMap?: boolean
}) => {
	const { shipment, fillHeight } = props

	if (!shipment) {
		return null
	}
	if (!shipment.payloads || !shipment.payloads.length) {
		return null
	}

	const pickup = shipment.payloads[0].originStop
	const delivery = shipment.payloads[0].destinationStop

	let details: IShipmentDetails = null

	const billTo = shipment.billTo ? shipment.billTo : {}

	let origAddress, destAddress

	details = {
		swanleapShipmentId: shipment.identifier,
		bolNumbers: l.join(getBolNumbers(shipment), ', '),
		pickupDate: getPickupDate(shipment),
		equipmentType: idx(
			() => newQuoteEquipmentTypesMetadata[shipment.equipmentType].label,
		),
		accessorials: l.concat(
			getPayloadLevelAccessorials(shipment),
			getShipmentLevelAccessorials(shipment),
		),
		billTo: billTo.billToThisParty
			? tString(
					billTo.billToThisParty,
					'page.shipmentProfile.cardDetails.paymentType', // TODO: yes this is switched with billTo
			  )
			: '',
		paymentType: billTo.billToThisParty
			? tString(
					billTo.billToThisParty,
					'page.shipmentProfile.cardDetails.billTo', // TODO: yes this is switched with paymentType
			  )
			: '',

		shipmentFlow: billTo.clientRole
			? tString(
					billTo.clientRole,
					'page.shipmentProfile.cardDetails.shipmentFlow',
			  )
			: '',
		customerInvoicingStatus:
			tString(
				(shipment as apiTypes.BrokerShipmentResponse).customerInvoicingStatus,
				'page.shipmentProfile.cardDetails.customerInvoicingStatus',
			) || undefined,
		totalMiles: shipment.estimatedDistance,
		totalWeight: shipment.totalWeight,
		accountingStatus: shipment.paymentStatus,
		bidDuration: shipment.bidDuration,
		sealNumber: shipment.sealNumber,
		trailerLength: shipment.trailerLength,
	}

	if (shipment.origin) {
		origAddress = r.clone(shipment.origin)
		origAddress.isOrigin = true
	}

	if (shipment.destination) {
		destAddress = r.clone(shipment.destination)
		destAddress.isDestination = true
	}

	const kvOptions: IKeyValueOptions = {
		tPrefix: 'page.shipmentProfile.details',
		//labelMinWidth: '200px',
	}
	return (
		<Card
			title={t('page.shipmentProfile.cardDetails')}
			color='darkBlue'
			fillHeight={fillHeight}
		>
			<FlexRow>
				<FlexItem>
					<SimpleKeyValueTable borderless={true}>
						<HeaderText large={true} inTable={true}>
							Pickup
						</HeaderText>
						<KeyValueSeparator />
						<KeyValue
							options={kvOptions}
							field='address'
							data={pickup}
							renderer={(c) => (
								<div style={{ minHeight: '140px' }}>
									<SimpleAddress address={c} />
								</div>
							)}
						/>

						<KeyValue
							options={kvOptions}
							field={'desiredDateTimeInfo'}
							data={pickup.metaData}
							label={t('page.shipmentProfile.cardDetails.pickupTime')}
							renderer={(c: apiTypes.DateTimeInfo = {}) => {
								return (
									<SimpleDateTime
										mode='pickup'
										type={c.timeType}
										beginDate={c.initialDate}
										endDate={c.latestDate}
										beginTime={c.initialTime}
										endTime={c.latestTime}
									/>
								)
							}}
						/>

						<KeyValue
							options={kvOptions}
							data={pickup.metaData}
							field={'accessorials'}
							renderer={() => {
								return (
									<FormattedStopAccessorials stop={pickup} stopType='pickup' />
								)
							}}
						/>

						<KeyValue
							options={kvOptions}
							data={pickup.metaData}
							field={'specialInstructions'}
						/>
					</SimpleKeyValueTable>
				</FlexItem>

				<FlexItem>
					<SimpleKeyValueTable borderless={true}>
						<HeaderText large={true} inTable={true}>
							Delivery
						</HeaderText>
						<KeyValueSeparator />
						<KeyValue
							options={kvOptions}
							field='address'
							data={delivery}
							renderer={(c) => {
								return (
									<div style={{ minHeight: '140px' }}>
										<SimpleAddress address={c} />
									</div>
								)
								// return JSON.stringify(pickup)
							}}
						/>

						<KeyValue
							options={kvOptions}
							field={'desiredDateTimeInfo'}
							data={delivery.metaData}
							label={t('page.shipmentProfile.cardDetails.deliveryTime')}
							renderer={(c: apiTypes.DateTimeInfo = {}) => {
								return (
									<SimpleDateTime
										mode='delivery'
										type={c.timeType}
										beginDate={c.initialDate}
										endDate={c.latestDate}
										beginTime={c.initialTime}
										endTime={c.latestTime}
									/>
								)
							}}
						/>

						<KeyValue
							options={kvOptions}
							data={delivery.metaData}
							field={'accessorials'}
							renderer={() => {
								return (
									<FormattedStopAccessorials
										stop={delivery}
										stopType='delivery'
									/>
								)
							}}
						/>
						<KeyValue
							options={kvOptions}
							data={delivery.metaData}
							field={'specialInstructions'}
						/>
					</SimpleKeyValueTable>
				</FlexItem>

				{props.showMap && (
					<FlexItem maxWidth='220px'>
						<StaticMap
							addresses={[origAddress, destAddress]}
							width={200}
							height={200}
						/>
					</FlexItem>
				)}

				<FlexItem>
					<SimpleKeyValueTable
						borderless={true}
						testId='shipment-details-simple-table'
					>
						<KeyValue
							options={kvOptions}
							field='swanleapShipmentId'
							data={details}
						/>
						<KeyValueSeparator />
						{details.accountingStatus && (
							<KeyValue
								options={kvOptions}
								field='accountingStatus'
								data={details}
							/>
						)}
						{details.customerInvoicingStatus && (
							<KeyValue
								options={kvOptions}
								field='customerInvoicingStatus'
								data={details}
							/>
						)}
						<KeyValueSpacer />
						<KeyValue options={kvOptions} field='pickupDate' data={details} />
						<KeyValue options={kvOptions} field='bolNumbers' data={details} />
						<KeyValue
							options={kvOptions}
							field='equipmentType'
							data={details}
						/>
						<KeyValue
							options={kvOptions}
							field='trailerLength'
							data={details}
						/>
						<KeyValue
							options={kvOptions}
							field='accessorials'
							data={details}
							renderer={(c) => {
								return (
									<div>
										{l.map(details.accessorials, (accessorialKey, dIdx) => (
											<div key={dIdx}>
												<FormattedAccessorial
													accessorialKey={accessorialKey}
													stopType='delivery'
												/>
											</div>
										))}
									</div>
								)
							}}
						/>
						<KeyValue options={kvOptions} field='bidDuration' data={details} />
						<KeyValue options={kvOptions} field='sealNumber' data={details} />
						<KeyValueSpacer />
						<KeyValue options={kvOptions} field='billTo' data={details} />
						<KeyValue options={kvOptions} field='paymentType' data={details} />
						<KeyValue options={kvOptions} field='shipmentFlow' data={details} />
						<KeyValue options={kvOptions} field='totalWeight' data={details} />
						<KeyValue
							options={kvOptions}
							field='totalMiles'
							data={details}
							renderer={(val, data) => {
								if (!val) {
									return ''
								}
								return '' + val
							}}
						/>
						<ManualKeyValue
							options={kvOptions}
							label={''}
							val={shipment.estimatedDistanceService}
							renderer={(c) => {
								if (!c) {
									return null
								}
								return (
									<LabelText smaller={true}>
										{t(
											'mileageProvidedBy',
											'page.shipmentProfile.cardDetails.distanceService',
										)}{' '}
										{t(c, 'page.shipmentProfile.cardDetails.distanceService')}
									</LabelText>
								)
							}}
						/>
					</SimpleKeyValueTable>
				</FlexItem>
			</FlexRow>
		</Card>
	)
}
