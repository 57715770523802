import React, { ReactElement, useEffect, useState } from 'react'
import { Layout } from 'ui/pages/layout'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { useOnce } from 'ui/components/hooks'
import { fetchTopLevelLocation, saveLocationAddress } from './state'
import { sosTopLevelLocationConfiguration } from './state'
import { EditableAddressCard } from './EditableAddressCard'
import { sosToast, Toast, ToastState } from 'common/components/toast'
import { t, tString } from 'ui/components/i18n/i18n'
import { sos2 } from 'ui/lib/state/sos2'
import { FileDropZoneData } from 'ui/components/shared/csvValidator/file-drop-zone'
import { saveLocationLogo } from './state/locationLogo'
import { PictureUpload } from 'ui/components/common/images/PictureUpload'
import { ChargeCodeCatalog } from './charge-code-catalog'
import { apiBrokerConfig, apiCharges, apiTmsServiceConfig } from 'ui/api'
import { fireAndForget } from 'ui/lib/async'
import { Center } from 'ui/components/layout/center'
import { Loader } from 'ui/components/common/loader'
import { Spacer } from 'ui/components/layout/spacer'
import { GenerateCodeCard } from './generate-code-card'

export const tPrefixCompanyManagement = 'page.companyManagement'

export const tPrefix = 'page.companyManagement.tabs'

type TCompanyMangementTabs = 'company' | 'branding' | 'chargeCode'

export const emptyCodeRequest: apiTmsServiceConfig.UniqueCodeConfigResponse = {
	autoGenerate: false,
	prefix: '',
	currentValue: 0,
	suffix: '',
	numberOfDigits: 1,
}

export const TopLevelLocationConfigurationPage = (): ReactElement => {
	const state = sos2.useSubscription(sosTopLevelLocationConfiguration.getSos())
	const [chargeCodeBookId, setChargeCodeBookId] = useState<string>(null)
	const [isFetchingChardCodeBookId, setIsFetchingChardCodeBookId] = useState<
		boolean
	>(false)

	const [providerCode, setProviderCode] = useState<
		apiTmsServiceConfig.UniqueCodeConfigResponse
	>(null)
	const [isFetchingProviderCode, setIsFetchingProviderCode] = useState<boolean>(
		false,
	)

	const [customerCode, setCustomerCode] = useState<
		apiBrokerConfig.UniqueCodeConfigResponse
	>(null)
	const [isFetchingCustomerCode, setIsFetchingCustomerCode] = useState<boolean>(
		false,
	)

	useOnce(async () => {
		await fetchTopLevelLocation()
	})

	const successToast: ToastState = {
		type: 'success',
		body: tString('locationSuccess', tPrefixCompanyManagement),
	}

	const errorToast: ToastState = {
		type: 'danger',
		header: tString('locationError', tPrefixCompanyManagement),
		body: state.apiError,
	}

	const [key, setKey] = useState<TCompanyMangementTabs>('company')

	// TODO: Remove this in the future, this is used to create chargebook for the customer/provider invoicing
	// const createChargeBook = async (): Promise<void> => {
	// 	const chargesBookResponse = await apiCharges.createChargesBook('chargeBook')

	// 	if (chargesBookResponse.error) {
	// 		sosToast.sendApiErrorResponseToast(chargesBookResponse)
	// 	} else {
	// 		console.log(chargesBookResponse.data)
	// 	}
	// }
	// useOnce(async () => await createChargeBook())

	const getChargeBookList = async (): Promise<void> => {
		setIsFetchingChardCodeBookId(true)
		const bookResponse = await apiCharges.getBookList()

		if (bookResponse.error) {
			sosToast.sendApiErrorResponseToast(bookResponse)
		} else {
			if (bookResponse.data[0] === undefined || bookResponse.data[0] === null) {
				const createResponse = await apiCharges.createChargesBook(
					'Charge Codes Book'
				);
				setChargeCodeBookId(createResponse.data.id)
			} else {
				setChargeCodeBookId(bookResponse.data[0].id)
			}
		}
		setIsFetchingChardCodeBookId(false)
	}

	const getTmsServiceConfig = async (): Promise<void> => {
		setIsFetchingProviderCode(true)
		const response = await apiTmsServiceConfig.getTmsServiceConfig()

		let data = null
		if (response.error) {
			if (response.error.toString().match('400')) {
				const createResponse = await apiTmsServiceConfig.createTmsServiceConfig()
				if (createResponse.data) {
					data = createResponse.data
				} else {
					sosToast.sendApiErrorResponseToast(createResponse)
				}
			} else {
				sosToast.sendApiErrorResponseToast(response)
			}
		} else if (response.data) {
			data = response.data
		}
		if (data) {
			setProviderCode(data.providerCodeConfig)
		}
		setIsFetchingProviderCode(false)
	}

	const getBrokerConfig = async (): Promise<void> => {
		setIsFetchingCustomerCode(true)
		const response = await apiBrokerConfig.getBrokerConfig()

		if (response.error) {
			if (response.error.toString().match('400')) {
				const creationResponse = await apiBrokerConfig.createBrokerConfig()
				if (creationResponse.data) {
					setCustomerCode(creationResponse.data.clientConfigCode)
				} else {
					sosToast.sendApiErrorResponseToast(creationResponse)
				}
			} else {
				sosToast.sendApiErrorResponseToast(response)
			}
		} else {
			if (response.data) {
				setCustomerCode(response.data.clientConfigCode)
			}
		}
		setIsFetchingCustomerCode(false)
	}

	useEffect(() => {
		fireAndForget(async () => {
			await getTmsServiceConfig()
		}, `Fetching Provider Code`)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		fireAndForget(async () => {
			await getBrokerConfig()
		}, `Fetching Customer Code`)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		sosTopLevelLocationConfiguration.getSos().change((ds) => {
			ds.apiSuccess = false
		})
	}, [state.apiSuccess])

	useEffect(() => {
		sosTopLevelLocationConfiguration.getSos().change((ds) => {
			ds.apiError = null
		})
	}, [state.apiError])

	useOnce(async () => await getChargeBookList())

	return (
		<Layout>
			<div className='bootstrap-wrapper' style={{ width: '900px' }}>
				<Tabs
					id='company-management-tab'
					activeKey={key}
					onSelect={(k: any) => setKey(k)}
					defaultActiveKey='company'
					variant='pills'
				>
					<Tab eventKey='company' title={t('company', tPrefix)}>
						<EditableAddressCard
							tPrefix={tPrefixCompanyManagement}
							header='billTo'
							addressData={
								state.location?.defaults?.defaultBillingAddress?.address
							}
							fieldToUpdate='defaults.defaultBillingAddress.address'
							onSave={saveLocationAddress}
						/>
					</Tab>
					<Tab eventKey='branding' title={t('branding', tPrefix)}>
						<PictureUpload
							onPictureUpload={async (fileData: FileDropZoneData) => {
								await saveLocationLogo(fileData)
							}}
							existingImageUrl={state.location.brandingImageUrl}
							headerText={t('companyLogo', tPrefixCompanyManagement)}
						/>
					</Tab>
					<Tab eventKey='chargeCode' title={t('chargeCode', tPrefix)}>
						<Row>
							{chargeCodeBookId === null && !isFetchingChardCodeBookId ? (
								<Col xs={12}>
									<Spacer height={'10px'} />
									<Center>{t('noLinkedChargeCodes', tPrefix)}</Center>
									<Spacer height={'10px'} />
								</Col>
							) : isFetchingChardCodeBookId ? (
								<Col xs={12}>
									<Spacer height={'10px'} />
									<Center>
										<Loader isLoading={isFetchingChardCodeBookId} />
									</Center>
									<Spacer height={'10px'} />
								</Col>
							) : (
								<Col xs={12}>
									<ChargeCodeCatalog chargeCodeBookId={chargeCodeBookId} />
								</Col>
							)}
							<Spacer height={'20px'} />
						</Row>

						<Row>
							<Col xs={6}>
								{!isFetchingProviderCode && (
									<GenerateCodeCard
										codeType={'provider'}
										code={providerCode}
										setCode={setProviderCode}
										tPrefix={'page.companyManagement.tabs.chargeCodeCatalog'}
									/>
								)}
							</Col>
							<Col xs={6}>
								{!isFetchingCustomerCode && (
									<GenerateCodeCard
										codeType={'customer'}
										code={customerCode}
										setCode={setCustomerCode}
										tPrefix={'page.companyManagement.tabs.chargeCodeCatalog'}
									/>
								)}
							</Col>
						</Row>
					</Tab>
				</Tabs>

				{/* Add these next two cards in once the data is available from the API */}
				{/* <EditableAddressCard
					tPrefix={tPrefix}
					header='remitTo'
					addressData={state.remitTo}
					onSave={() => {
						console.log('potato')
					}}
				/> */}
				{/* <EditableAddressCard
					tPrefix={tPrefix}
					header='companyHQ'
					addressData={state.companyHQ}
					onSave={() => {
						console.log('potato')
					}}
				/> */}
				{state.apiSuccess && <Toast toast={successToast} />}
				{state.apiError && <Toast toast={errorToast} />}
			</div>
		</Layout>
	)
}
