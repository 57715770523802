import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { apiCharges, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosToast } from 'ui/common/components/toast'
import { Button } from 'ui/components/common/button'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Loader } from 'ui/components/common/loader'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedPillSwitch,
	FormStackedSelect,
	FormStackedTextInput,
	FormTextInput,
	IFormData,
} from 'ui/components/form'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Center } from 'ui/components/layout/center'
import { Spacer } from 'ui/components/layout/spacer'
import { FC } from 'ui/FunctionalComponent'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import * as classes from './ChargeCodeCatalog.module.scss'
// import { SearchInput } from 'ui/components/common/search'
import { ElasticSearchPager } from 'ui/components/common/pager'
import { IStatePager } from 'ui/state/paging'
// import { Autocomplete } from 'ui/components/common/autocomplete'
// import { ISelectOptions } from 'ui/components/common/select'

export const emptyChargeRequest: apiTypes.ChargeCodePageRequest = {
	chargeCode: '',
	chargeCodeDescription: '',
	chargeCodeType: 'customer',
}

// const chargeCodeTypes = ['customer', 'provider', 'both']

export const ChargeCodeCatalog: FC = (props: { chargeCodeBookId: string }) => {
	const { chargeCodeBookId } = props
	const [isFetchingChargeCodes, setIsFetchingChargeCodes] = useState<boolean>(
		false,
	)
	const tPrefix = 'page.companyManagement.tabs.chargeCodeCatalog'

	const [rowToEdit, setRowToEdit] = useState<number>(null)
	const [rowToDelete, setRowToDelete] = useState<number>(null)
	const [isAddingCharge, setIsAddingCharge] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [charges, setCharges] = useState<apiTypes.ChargeCodePageResponse[]>(
		() => [],
	)

	// const [searchChargeCode, setSearchChargeCode] = useState<string>()
	// const [searchChargeDescription, setSearchChargeDescription] = useState<
	// 	string
	// >()
	// const [searchChargeType, setSearchChargeType] = useState<string>()

	const [chargeCodeRequest, setChargeCodeRequest] = useState<
		apiTypes.ChargeCodePageRequest
	>(emptyChargeRequest)

	// const [searchObject, setSearchObject] = useState<any>({
	// 	chargeCode: '',
	// 	chargeDescription: '',
	// 	invoiceType: '',
	// })

	const [pager, setPager] = useState<IStatePager>({
		fetchingPageNumber: 0,
		pageNumber: 0,
		pageCount: 0,
		pageSize: 10,
		total: 0,
		hasMore: true,
	})

	const addChargeForm: IFormData<apiTypes.ChargeCodePageRequest> = {
		form: chargeCodeRequest,
		metadata: {
			chargeCode: { required: true },
			chargeCodeDescription: { required: true },
			chargeCodeType: {
				options: [
					{ value: 'customer', label: tString('customer', tPrefix) },
					{ value: 'provider', label: tString('provider', tPrefix) },
				],
			},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(chargeCodeRequest)
			updatedFormData[field] = value
			setChargeCodeRequest(updatedFormData)
		},
		tPrefix,
	}

	const fetchChargeCodes = async (page?: number): Promise<void> => {
		setIsFetchingChargeCodes(true)

		const chargePageListResponse = await apiCharges.getChargeCodePageList(
			chargeCodeBookId,
			{ take: 10, skip: page * 10 },
			true,
		)

		if (chargePageListResponse.error) {
			sosToast.sendApiErrorResponseToast(chargePageListResponse)
		} else {
			setPager({
				...pager,
				fetchingPageNumber: page,
				pageCount: chargePageListResponse.data.pageCount,
				pageNumber: page,
				total: chargePageListResponse.data.total,
			})

			setCharges(chargePageListResponse.data.entities)
		}

		setIsFetchingChargeCodes(false)
	}

	const updateChargeCode = async (
		mode: 'add' | 'edit' | 'delete',
		chargeCodeId?: string,
	): Promise<void> => {
		setIsLoading(true)

		let chargePageResponse: IRequestState<apiTypes.ChargeCodePageResponse> = null

		if (mode === 'add') {
			chargePageResponse = await apiCharges.createChargeCode(
				chargeCodeBookId,
				chargeCodeRequest,
			)
		} else if (mode === 'edit') {
			chargePageResponse = await apiCharges.updateChargeCode(
				chargeCodeBookId,
				chargeCodeId,
				chargeCodeRequest,
			)
		} else if (mode === 'delete') {
			chargePageResponse = await apiCharges.deleteChargeCode(
				chargeCodeBookId,
				chargeCodeId,
			)
		}

		if (chargePageResponse.error) {
			sosToast.sendApiErrorResponseToast(chargePageResponse)
		}

		setIsLoading(false)
	}

	useEffect(() => {
		fireAndForget(async () => {
			await fetchChargeCodes(0)
		}, `Get ChargeCodes`)
	}, [chargeCodeBookId]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Card>
			{chargeCodeBookId === null ? (
				<div>{t('noLinkedChargeCodes', tPrefix)}</div>
			) : (
				<Card.Body>
					<div className={classes.addChargeCodeButton}>
						<Button
							color={'green'}
							onClick={() => {
								setChargeCodeRequest(emptyChargeRequest)
								setIsAddingCharge(true)
								setRowToEdit(null)
							}}
							isDisabled={isAddingCharge}
						>
							{t('addChargeCode', tPrefix)}
						</Button>
					</div>
					<Row>
						<Col xs={12}>
							<Table bordered className={classes.chargeCodeCatalogTable}>
								<thead>
									<tr>
										<th>
											<Row>
												<Col xs={12}>{t('chargeCode', tPrefix)}</Col>
											</Row>
											{/*<Spacer height={'4px'} />
											<Row>
												<Col xs={12}>
													 <SearchInput
														testId='charge-code-catalog-search-charge-code'
														value={searchChargeCode || ''}
														placeholder={tString('searchChargeCode', tPrefix)}
														onChange={async (query: string) => {
															setSearchChargeCode(searchChargeCode)

															setSearchObject({
																...searchObject,
																chargeCode: query,
															})

															await fetchChargeCodes(0)
														}}
														width='200px'
														className={classes.searchBox}
														readOnly={isFetchingChargeCodes}
													/>
												</Col> 
											</Row>*/}
										</th>
										<th>
											<Row>
												<Col xs={12}>{t('chargeDescription', tPrefix)}</Col>
											</Row>
											{/* <Spacer height={'4px'} />
											<Row>
												<Col xs={12}>
													 <SearchInput
														testId='charge-code-catalog-search-charge-description'
														value={searchChargeDescription || ''}
														placeholder={tString(
															'searchChargeDescription',
															tPrefix,
														)}
														onChange={async (query: string) => {
															setSearchChargeDescription(
																searchChargeDescription,
															)

															setSearchObject({
																...searchObject,
																chargeDescription: query,
															})

															await fetchChargeCodes(0)
														}}
														width='240px'
														className={classes.searchBox}
														readOnly={isFetchingChargeCodes}
													/> 
												</Col>
											</Row>*/}
										</th>
										<th>
											<Row>
												<Col xs={12}>{t('invoiceType', tPrefix)}</Col>
											</Row>
											{/*<Spacer height={'4px'} />
											<Row>
												<Col xs={12}>
													 <Autocomplete
														options={chargeCodeTypes.map(
															(chargeCodeType): ISelectOptions => ({
																value: l.startCase(l.toLower(chargeCodeType)),
																label: l.startCase(l.toLower(chargeCodeType)),
															}),
														)}
														onChange={async (query: string) => {
															setSearchChargeType(query)

															setSearchObject({
																...searchObject,
																invoiceType: query,
															})

															await fetchChargeCodes(0)
														}}
														value={searchChargeType}
														type='select'
														isClearable={false}
														allowEmptyValue={false}
														placeholder={tString(
															'selectChargeCodeType',
															tPrefix,
														)}
														width={'240px'}
														className={classes.selectBox}
														testId='charge-code-catalog-search-charge-type'
													/> 
												</Col>
											</Row>*/}
										</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{isFetchingChargeCodes ? (
										<tr>
											<td colSpan={4}>
												<Center>
													<Loader isLoading={isFetchingChargeCodes} />
												</Center>
											</td>
										</tr>
									) : (
										l.map(charges, (charge, i) => {
											const chargeCodeData: IFormData<apiTypes.ChargeCodePageRequest> = {
												form: charge,

												metadata: {
													chargeCode: { required: true },
													chargeCodeDescription: { required: true },
													chargeCodeType: {
														options: ['customer', 'provider'],
													},
												},

												onUpdateForm: (field: string, value: any) => {
													const updatedCharges = l.cloneDeep(charges)
													const updatedCharge = l.cloneDeep(charge)
													updatedCharge[field] = value

													updatedCharges.splice(i, 1, updatedCharge)

													setChargeCodeRequest(updatedCharge)
													setCharges(updatedCharges)
												},

												tPrefix,
											}

											return (
												<tr key={i}>
													<td>
														<FormTextInput
															form={chargeCodeData.form}
															field={'chargeCode'}
															onUpdateForm={chargeCodeData.onUpdateForm}
															readOnly={i !== rowToEdit}
															testId={`chargecode-catalog-chargeCode-${i}`}
														/>
													</td>
													<td>
														<FormTextInput
															form={chargeCodeData.form}
															field={'chargeCodeDescription'}
															onUpdateForm={chargeCodeData.onUpdateForm}
															readOnly={i !== rowToEdit}
															testId={`chargecode-catalog-chargeCodeDescription-${i}`}
														/>
													</td>
													<td>
														<FormStackedSelect
															formData={chargeCodeData}
															field={'chargeCodeType'}
															hideOptional={true}
															readOnly={i !== rowToEdit}
															className={classes.noLabel}
															width={'110px'}
															testId={`chargecode-catalog-chargeCodeType-${i}`}
															tPrefix={tPrefix}
														/>
													</td>
													<td>
														<IconButton
															icon={
																i === rowToEdit
																	? solidIcons.faSave
																	: solidIcons.faPencilAlt
															}
															color={'black'}
															onClick={async () => {
																if (i === rowToEdit) {
																	await updateChargeCode('edit', charge.id)
																	setChargeCodeRequest(emptyChargeRequest)
																	setRowToEdit(null)
																} else {
																	setChargeCodeRequest(charge)
																	setRowToEdit(i)
																}
															}}
															spin={i === rowToEdit && isLoading}
															testId={'chargecode-catalog-edit-save'}
														></IconButton>

														<IconButton
															icon={solidIcons.faTrash}
															color={'black'}
															onClick={async () => {
																setRowToDelete(i)
																await updateChargeCode('delete', charge.id)
																setRowToEdit(null)
															}}
															testId={'chargecode-catalog-delete'}
															spin={i === rowToDelete && isLoading}
														></IconButton>
													</td>
												</tr>
											)
										})
									)}
								</tbody>
							</Table>
						</Col>
					</Row>
					<ElasticSearchPager
						pager={pager}
						onClickPage={async (pageNum: number) => {
							await fetchChargeCodes(pageNum)
						}}
					/>
				</Card.Body>
			)}

			<Modal
				content={() => (
					<div
						className={classes.addChargeCodeCatalogModal}
						data-testid={'chargecode-catalog-add-modal'}
					>
						<Spacer height='10px' />
						<FormStackedTextInput
							formData={addChargeForm}
							field={'chargeCode'}
							testId={'chargecode-catalog-add-modal-chargeCode'}
						/>
						<Spacer height='10px' />
						<FormStackedTextInput
							formData={addChargeForm}
							field={'chargeCodeDescription'}
							testId={'chargecode-catalog-add-modal-chargeCodeDescription'}
						/>
						<Spacer height='10px' />
						<FormStackedPillSwitch
							formData={addChargeForm}
							field={'chargeCodeType'}
							label={t('chargeCodeType', tPrefix)}
							hideOptional={true}
							testId={'chargecode-catalog-add-modal-chargeCodeType'}
						/>

						<Spacer height='30px' />
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'chargecode-catalog-add-modal-ok'}
								isSpinning={isLoading}
								onOk={async () => {
									await updateChargeCode('add')

									setIsAddingCharge(false)
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => setIsAddingCharge(false)}
								cancelTestId={'chargecode-catalog-add-modal-cancel'}
							></OkCancelButtons>
						</AlignRight>
					</div>
				)}
				isOpen={isAddingCharge}
				title={t('addChargeCode', tPrefix)}
				closeModalX={true}
				onModalClose={() => setIsAddingCharge(false)}
			/>
		</Card>
	)
}
