import { apiCommon, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'

export async function getBookList(): Promise<
	IRequestState<apiTypes.ChargeCodeBookListResponse>
> {
	return await apiCommon.apiFetch(
		() => {},
		{ method: 'GET' },
		'books/charge-code',
	)
}

export async function getChargeCodePageList(
	bookId: string,
	query: { take: number; skip: number },
	entireResponse = false,
): Promise<IRequestState<apiTypes.ChargeCodePageListResponse>> {
	return await apiCommon.apiFetch(
		() => {},
		{ method: 'GET', entireResponse },
		`books/charge-code/${bookId}/pages`,
		query,
	)
}

export const createChargesBook = async (
	name: string,
): Promise<IRequestState<apiTypes.ChargeCodeBookResponse>> => {
	const url = `books/charge-code`
	return apiCommon.apiFetch<apiTypes.ChargeCodeBookResponse>(
		() => {},
		{ method: 'POST', data: { name } },
		url,
	)
}

export const getChargesCode = async (
	bookId: string,
): Promise<IRequestState<apiTypes.ChargeCodePageResponse[]>> => {
	const url = `books/charge-code/${bookId}/pages`
	return apiCommon.apiFetch<apiTypes.ChargeCodePageResponse[]>(
		() => {},
		{ method: 'GET' },
		url,
	)
}

export const createChargeCode = async (
	bookId: string,
	data: apiTypes.ChargeCodePageRequest,
): Promise<IRequestState<apiTypes.ChargeCodePageResponse>> => {
	const url = `books/charge-code/${bookId}/pages`
	return apiCommon.apiFetch<apiTypes.ChargeCodePageRequest>(
		() => {},
		{
			method: 'POST',
			data,
		},
		url,
	)
}

export const updateChargeCode = async (
	bookId: string,
	pageId: string,
	data: apiTypes.ChargeCodePageRequest,
): Promise<IRequestState<apiTypes.ChargeCodePageResponse>> => {
	const url = `books/charge-code/${bookId}/pages/${pageId}`
	return apiCommon.apiFetch<apiTypes.ChargeCodePageRequest>(
		() => {},
		{
			method: 'PUT',
			data,
		},
		url,
	)
}

export const deleteChargeCode = async (
	bookId: string,
	pageId: string,
): Promise<IRequestState<apiTypes.ChargeCodePageResponse>> => {
	const url = `books/charge-code/${bookId}/pages/${pageId}`
	return apiCommon.apiFetch<apiTypes.ChargeCodePageRequest>(
		() => {},
		{
			method: 'DELETE',
		},
		url,
	)
}
