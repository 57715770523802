import { sosToast } from 'common/components/toast'
import { DateTime } from 'luxon'
import {
	apiManifests,
	apiProviders,
	apiServiceDiscovery,
	apiShipments,
	apiTypes,
} from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosRouter2 } from 'ui/components/common/router'
import { windowExists } from 'ui/components/common/router/windowUtils'
import { asyncForEachParallel } from 'ui/lib/async'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import { logError } from 'ui/lib/log/log'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { shipmentsListPageInfo } from 'ui/pages/shipments/state/sosShipmentsList'
import { sosUser } from 'ui/state'
import { isInDev } from 'ui/theme/theme'
import { getShipmentQuery } from '../functions'
import { providersRequiringLocation } from '../providersRequiringLocation'
import { IFormattedManifest, processManifests } from './processManifests'

export interface IStateManifests {
	manifests: IFormattedManifest[]
	newManifestDate: string
	manifestCount: number
	voidingManifests: string[]
	selectedProvider: apiTypes.ApiProviderName
	showPendingShipments: boolean
	pendingShipments: apiTypes.ShipmentResponse[]
	providers: Partial<apiTypes.ProviderResponse>[]
	locationId: string
	websocketUrl: string
}

export const getSos = createLazySos2<IStateManifests>(
	'sosManifests',
	2,
	() => ({
		manifests: { default: [], localStorage: false },
		newManifestDate: { default: DateTime.local().toISO(), localStorage: false },
		manifestCount: { default: 0, localStorage: false },
		voidingManifests: { default: [], localStorage: false },
		selectedProvider: { default: 'Speedee' },
		showPendingShipments: { default: false, localStorage: true },
		pendingShipments: { default: [] },
		providers: { default: [] },
		locationId: { default: null },
		websocketUrl: { default: null },
	}),
)

export const setLocationId = (locationId: string): void => {
	getSos().change((ds) => {
		ds.locationId = locationId
	})

	const state = getSos().getState()
	const providerId = l.find(
		state.providers,
		(provider) =>
			provider.providerName?.toLowerCase() ===
			state.selectedProvider?.toLowerCase(),
	)?.id

	fireAndForget(
		async () =>
			Promise.all([
				fetchShipments(
					getShipmentQuery(
						state.selectedProvider,
						providerId,
						state.newManifestDate,
						locationId,
					),
				),
				fetchManifests(
					state.selectedProvider,
					undefined,
					undefined,
					locationId,
				),
			]),
		'fetch shipments and manifests with new location',
	)
}

export const getLocationId = (): string => {
	const state = getSos().getState()
	const locationId = providersRequiringLocation.includes(state.selectedProvider)
		? state.locationId
		: undefined

	return locationId
}

export const setNewManifestDate = (newManifestDate: string): void => {
	let selectedProvider: apiTypes.ApiProviderName
	getSos().change((ds) => {
		ds.newManifestDate = newManifestDate
		selectedProvider = ds.selectedProvider
	})
	const providerId = l.find(
		getSos().getState().providers,
		(provider) =>
			provider.providerName?.toLowerCase() === selectedProvider?.toLowerCase(),
	)?.id

	fireAndForget(
		async () =>
			fetchShipments(
				getShipmentQuery(
					selectedProvider,
					providerId,
					newManifestDate,
					getLocationId(),
				),
			),
		'fetch shipments with new manifest date',
	)
}

export const setProvider = (
	selectedProvider: apiTypes.ApiProviderName,
): void => {
	let newManifestDate: string
	getSos().change((ds) => {
		ds.selectedProvider = selectedProvider
		newManifestDate = ds.newManifestDate
	})
	const providerId = l.find(
		getSos().getState().providers,
		(provider) =>
			provider.providerName?.toLowerCase() === selectedProvider?.toLowerCase(),
	)?.id

	fireAndForget(
		async () =>
			Promise.all([
				fetchShipments(
					getShipmentQuery(
						selectedProvider,
						providerId,
						newManifestDate,
						getLocationId(),
					),
				),
				fetchManifests(selectedProvider, undefined, undefined, getLocationId()),
			]),
		'fetch shipments and manifests with new provider',
	)
}

export const setShowPendingShipments = (show: boolean): void => {
	getSos().change((ds) => {
		ds.showPendingShipments = show
	})
}

async function getWebsocketURL(retries = 0): Promise<string> {
	if (retries > 2) {
		logError('*', 'UNABLE TO GET A VALID WEBSOCKET URL')
		return null
	}
	const websocketUrlReqeust: IRequestState<{
		value: any
	}> = await apiServiceDiscovery.getWebsocketUrl(() => {}, 'generateManifest')
	if (websocketUrlReqeust.error) {
		return await getWebsocketURL(retries + 1)
	} else {
		getSos().change((ds) => {
			ds.websocketUrl = websocketUrlReqeust.data.value
		})
		return websocketUrlReqeust.data.value
	}
}

export async function generateManifest(
	dispatch: any,
	locationId?: string,
): Promise<void> {
	const { newManifestDate, selectedProvider, providers } = getSos().getState()
	const providerId = l.find(
		providers,
		(provider) =>
			provider.providerName?.toLowerCase() === selectedProvider?.toLowerCase(),
	)?.id

	const shipmentQuery = getShipmentQuery(
		selectedProvider,
		providerId,
		newManifestDate,
		locationId,
	)

	const manifestRequest: apiTypes.ShipmentServiceManifestRequest = {
		manifestVersion: '505',
		providerName: selectedProvider,
		shipmentQuery,
	}

	const onProgressManifest = (
		websocketData: apiTypes.ManifestResponse,
	): void => {}

	const onProgressMessage = async (websocketData: string): Promise<void> => {
		if (websocketData.toLowerCase().includes('complete')) {
			await Promise.all([
				await fetchManifests(
					selectedProvider,
					undefined,
					undefined,
					getLocationId(),
				),
				await fetchShipments(
					getShipmentQuery(
						selectedProvider,
						providerId,
						newManifestDate,
						getLocationId(),
					),
				),
			])
		}
	}

	const onProgressMajorError = async (): Promise<void> => {
		await restGenerateManifest(dispatch, locationId)
	}

	const state = getSos().getState()
	let websocketUrl = state.websocketUrl

	if (!websocketUrl) {
		websocketUrl = await getWebsocketURL()
	}

	await apiManifests.trickleGenerateManifest(
		onProgressManifest,
		onProgressMessage,
		onProgressMajorError,
		manifestRequest,
		websocketUrl,
	)
}

export const restGenerateManifest = async (
	dispatch: any,
	locationId?: string,
): Promise<void> => {
	const { newManifestDate, selectedProvider, providers } = getSos().getState()
	const providerId = l.find(
		providers,
		(provider) =>
			provider.providerName?.toLowerCase() === selectedProvider?.toLowerCase(),
	)?.id

	const shipmentQuery = getShipmentQuery(
		selectedProvider,
		providerId,
		newManifestDate,
		locationId,
	)

	const manifestRequest: apiTypes.ShipmentServiceManifestRequest = {
		manifestVersion: '505',
		providerName: selectedProvider,
		shipmentQuery,
	}
	const response = await apiManifests.createManifest(
		(rs: IRequestState<apiTypes.ManifestResponse>) => {},
		manifestRequest,
	)

	if (response.error) {
		sosToast.sendApiErrorResponseToast(response)
	}
	await Promise.all([
		await fetchManifests(
			selectedProvider,
			undefined,
			undefined,
			getLocationId(),
		),
		await fetchShipments(
			getShipmentQuery(
				selectedProvider,
				providerId,
				newManifestDate,
				getLocationId(),
			),
		),
	])
}

export const fetchManifests = async (
	provider: apiTypes.ApiProviderName,
	take = 25,
	skip = 0,
	locationId?: string,
): Promise<void> => {
	const response = await apiManifests.getManifests(
		(rs: IRequestState<apiTypes.ManifestListResponse>) => {},
		{
			take,
			skip,
			providerName: provider,
			locationId,
			sort: 'createdDate:asc',
		},
	)
	if (response.data) {
		const processedManifests: IFormattedManifest[] = processManifests(
			response.data.entities,
		)
		const manifestRows = l.orderBy(
			processedManifests,
			(row) => row.createdDate,
			'desc',
		)
		getSos().change((ds) => {
			ds.manifests = manifestRows
			ds.manifestCount = response.data.total
		})
	} else if (response.error) {
		sosToast.sendApiErrorResponseToast(response)
	}
}

export const downloadManifest = (manifestRow: IFormattedManifest): void => {
	if (manifestRow.image) {
		const image = new Image()
		image.src = 'data:image/gif;base64,' + manifestRow.image
		if (windowExists) {
			const newWindow = window.open('')
			newWindow.document.write(image.outerHTML)
		}
	} else if (manifestRow.providerFile) {
		const el = document.createElement('a')
		const file = new Blob([manifestRow.providerFile], { type: 'text/plain' })
		document.body.appendChild(el)
		el.href = URL.createObjectURL(file)
		el.download = 'manifest.txt'
		document.body.appendChild(el)
		el.click()
		el.remove()
	}
}

export const viewManifestDetails = (manifestId: string): void => {
	const { manifests } = getSos().getState()
	const manifest = l.find(manifests, (manifest) => manifest.id === manifestId)
	const shipmentsQuery = 'manifestId:' + manifest.id
	sosRouter2.navigateTo(shipmentsListPageInfo, {
		externalQuery: shipmentsQuery,
	})
}

export const isPollingTurnedOn = (): boolean => {
	const { pollOnLivePages: pollOnShipmentProfile } = sosUser.getSos().getState()
	return isInDev() ? pollOnShipmentProfile : true
}

export const voidManifest = async (manifestId: string): Promise<void> => {
	getSos().change((ds) => {
		ds.voidingManifests.push(manifestId)
	})

	await apiManifests.voidManifest(() => {}, manifestId)
	await fetchManifests(
		getSos().getState().selectedProvider,
		undefined,
		undefined,
		getLocationId(),
	)

	getSos().change((ds) => {
		l.pull(ds.voidingManifests, manifestId)
	})

	const { providers, selectedProvider, newManifestDate } = getSos().getState()
	const selectedProviderResponse = l.find(
		providers,
		(provider) =>
			provider.providerName.toLowerCase() === selectedProvider.toLowerCase(),
	)

	await fetchShipments(
		getShipmentQuery(
			selectedProvider,
			selectedProviderResponse.id,
			newManifestDate,
			getLocationId(),
		),
	)
}

export const fetchShipments = async (shipmentQuery: string): Promise<void> => {
	const requestBody: apiTypes.ApiListRequest = {
		query: shipmentQuery,
		take: 25,
		skip: 0,
		sort: 'createdDate:asc',
	}

	const result = await apiShipments.fetchShipments(() => {}, requestBody)

	if (result.data) {
		getSos().change((ds) => {
			ds.pendingShipments = l.orderBy(
				result.data.entities,
				(shipment) => shipment.createdDate,
				'desc',
			)
		})
	} else if (result.error) {
		sosToast.sendApiErrorResponseToast(result)
	}
}

export const fetchProviders = async (
	providerNames: apiTypes.ApiProviderName[],
): Promise<void> => {
	await asyncForEachParallel(providerNames, async (providerName) => {
		const result = await apiProviders.getProviderByName(() => {}, providerName)
		if (result.data) {
			getSos().change((ds) => {
				ds.providers.push({
					id: result.data.id,
					providerName: result.data.providerName,
				})
			})
		}
	})
}
