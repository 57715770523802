import { IStateUser } from 'ui/state/sosUser'
import { apiTypes } from 'ui/api'
import { LocationDropdownSourceType } from '../address'
import { DefaultAddressType } from 'ui/pages/new-quote/state/newQuoteUtils/locationDropdownUtils'
import { IRequestState } from 'ui/api/requestState'
import { ISelectOptions } from 'ui/components/common/select'
import { l } from 'ui/lib/lodashImports'
import { idx } from 'ui/lib'

export function getLocations(
	stateUser: IStateUser,
	locationDropdownSource: LocationDropdownSourceType,
	ensureLocationHasDefaultAddressType?: DefaultAddressType,
): IRequestState<ISelectOptions[]> {
	const companyLocations: apiTypes.LocationResponse[] =
		stateUser.requestLocationsInfo.data
	if (!companyLocations || companyLocations.length === 0) {
		return {
			isFetching: stateUser.requestLocationsInfo.isFetching,
			error: stateUser.requestLocationsInfo.error,
		}
	}
	let error: string

	let storedLocations: apiTypes.LocationResponse[] = []

	if (locationDropdownSource === 'companyLocations') {
		error = stateUser.requestLocationsInfo.error
		storedLocations = companyLocations
	}
	if (locationDropdownSource === 'organizationRelationshipShipToLocations') {
		error = stateUser.requestFirstPartyLocations.error
		storedLocations = stateUser.requestFirstPartyLocations.data
	}
	if (locationDropdownSource === 'organizationRelationshipShipFromLocations') {
		error = l.join(
			l.compact([
				stateUser.requestOrganizationRelationship.error,
				stateUser.requestLocationsInfo.error,
			]),
			', ',
		)
		const secondPartyIds = idx(
			() =>
				stateUser.requestOrganizationRelationship.data.secondPartyLocationIds,
		)
		storedLocations = l.filter(companyLocations, (location) =>
			l.includes(secondPartyIds, location.id),
		)
	}
	if (error) {
		return { error }
	}
	if (ensureLocationHasDefaultAddressType) {
		storedLocations = l.filter(
			storedLocations,
			(c: apiTypes.LocationResponse) =>
				c.defaults[ensureLocationHasDefaultAddressType] != null,
		)
	}
	const options = []
	storedLocations.forEach((location) => {
		if (location.allDescendantOrganizationsIds?.length === 0) {
			options.push({ value: location.id, label: location.name })
		}
	})
	return { data: l.sortBy(options, (c) => c.label) }
}
