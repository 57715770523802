import { apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'
import {
	NewQuoteAccessorialsForm,
	NewQuoteAddressForm,
	NewQuoteBillToForm,
	NewQuotePackageForm,
} from 'ui/pages/new-quote/parcel/components/newQuoteForms'

export const createParcelShipmentRequest = (shipment: {
	pickupAddressForm: NewQuoteAddressForm
	deliveryAddressForm: NewQuoteAddressForm
	accessorialsForm: NewQuoteAccessorialsForm
	packagesForm: NewQuotePackageForm[]
	billToForm: NewQuoteBillToForm
	orderPickTicketNumber: string
}): apiTypes.ShipmentRequest => {
	const { accessorialsForm, billToForm, orderPickTicketNumber } = shipment

	const billTo = {
		// address: billToForm.address,
		address: null,
		carrierAccountNumber: billToForm.accountNumber,
		carrierName: billToForm.carrier,
		billToThisParty: billToForm.billToParty,
		carrierService: billToForm.serviceLevel,
		locationId: billToForm.locationId,
	}

	switch (billToForm.billToParty) {
		case 'consignee':
			billTo.address = shipment.deliveryAddressForm
			break
		case 'shipper':
			billTo.address = shipment.pickupAddressForm
			break
		default:
			billTo.address = billToForm.address
			break
	}

	const shipmentData: apiTypes.ShipmentRequest = {
		flags: null,
		shippingMode: 'parcel',
		billTo: billTo,
		payloads: [
			{
				originStop: {
					address: shipment.pickupAddressForm,
					metaData: {
						locationId: shipment.pickupAddressForm.locationId,
					},
				},
				destinationStop: {
					address: shipment.deliveryAddressForm,
					metaData: {
						locationId: shipment.deliveryAddressForm.locationId,
					},
				},
				containers: [
					{
						count: 1,
						length: 48,
						width: 40,
						height: 40,
						containerWeightEach: 40,
						packedWeight: 40,
						freightLevel: 'freight',
						containers: shipment.packagesForm.map((packageForm) => ({
							count: packageForm.quantity,
							labelReferenceValue1: packageForm.reference1,
							labelReferenceValue2: packageForm.reference2,
							declaredValue: packageForm.declaredValue,
							packagePageId: packageForm.packagePageId,
							length: packageForm.length,
							width: packageForm.width,
							height: packageForm.height,
							packedWeight: packageForm.weight,
							freightLevel: 'parcel',
							accessorials: {
								cod: accessorialsForm.cod,
							},
							accessorialsInfo: {
								codCollectionAmount: accessorialsForm.codAmount,
								codMoneyOrderOnly: accessorialsForm.moneyOrderOnly,
							},
						})),
					},
				],
				accessorials: {
					signature: accessorialsForm.signatureRequired,
					holdAtLocation: accessorialsForm.holdAtCarrier,
					saturdayDelivery: accessorialsForm.saturdayDelivery,
					fedexSmartPost: accessorialsForm.fedexSmartPost,
				},
				accessorialsInfo: {
					smartPostHub: accessorialsForm.smartPostHub,
					signatureType: accessorialsForm.signatureType,
					holdPhone: accessorialsForm.holdAtCarrierPhone,
					holdStreet: accessorialsForm.holdAtCarrierStreet,
					holdCity: accessorialsForm.holdAtCarrierCity,
					holdCode: accessorialsForm.holdAtCarrierZip,
					holdState: accessorialsForm.holdAtCarrierState,
					holdCountry: accessorialsForm.holdAtCarrierCountry,
					codCollectionAmount: accessorialsForm.codAmount,
				},
				pickTicketNumber: orderPickTicketNumber,
			},
		],
		accessorials: {
			protectFromFreeze: accessorialsForm.protectFromFreeze,
		},
	}
	return shipmentData
}

export const orderToLoad = (
	order: apiTypes.OrderResponse,
): {
	pickUpAddress: NewQuoteAddressForm
	deliveryAddress: NewQuoteAddressForm
	accessorials: NewQuoteAccessorialsForm
	packages: NewQuotePackageForm[]
	billTo: NewQuoteBillToForm
	pickTicketNumber: string
	autoRateCarrier: string
	autoRateService: string
} => {
	let pickUpAddress: apiTypes.Address
	let deliveryAddress: apiTypes.Address
	let accessorialsRequired: apiTypes.AccessorialsRequired
	let accessorialsInfoContainer: apiTypes.ContainerAccessorialsInfo = {}
	let accessorialsPayload: apiTypes.PayloadAccessorials
	let accessorialsInfoPayload: apiTypes.PayloadAccessorialsInfo
	let containers: apiTypes.ContainerResponse[]
	let billTo: apiTypes.BillTo
	let pickTicketNumber: string
	let autoRateCarrier: string
	let autoRateService: string

	if (order?.shipment) {
		pickUpAddress = order.shipment.payloads[0].originStop.address
		deliveryAddress = order.shipment.payloads[0].destinationStop.address

		accessorialsRequired = order.shipment.accessorials
		order.shipment.payloads[0].containers.forEach((container) => {
			accessorialsInfoContainer = l.assign(
				accessorialsInfoContainer,
				container.accessorialsInfo,
			)
		})
		accessorialsPayload = order.shipment.payloads[0].accessorials
		accessorialsInfoPayload = order.shipment.payloads[0].accessorialsInfo

		containers = order.shipment.payloads[0].containers
		billTo = order.shipment.billTo
		pickTicketNumber = order.shipment.payloads[0].pickTicketNumber
		autoRateCarrier = order.shipment.autoRateCarrier
		autoRateService = order.shipment.autoRateService
	} else if (order?.payload) {
		pickUpAddress = order.payload.originStop.address
		deliveryAddress = order.payload.destinationStop.address

		accessorialsRequired = {}
		order.payload.containers.forEach((container) => {
			accessorialsInfoContainer = l.assign(
				accessorialsInfoContainer,
				container.accessorialsInfo,
			)
		})
		accessorialsPayload = order.payload.accessorials
		accessorialsInfoPayload = order.payload.accessorialsInfo
		billTo = {}
		containers = order.payload.containers
		pickTicketNumber = order.payload.pickTicketNumber
		autoRateCarrier =
			order.payload.erpRequestedShipmentSettings?.autoRateCarrier
		autoRateService =
			order.payload.erpRequestedShipmentSettings?.autoRateService
	} else {
		return
	}

	return {
		pickUpAddress: {
			name: pickUpAddress.name || '',
			street1: pickUpAddress.street1 || '',
			street2: pickUpAddress.street2 || '',
			city: pickUpAddress.city || '',
			state: pickUpAddress.state || '',
			zip: pickUpAddress.zip || '',
			country: pickUpAddress.country || '',
			addressType: pickUpAddress.addressType || null,
			contactName: pickUpAddress.name || '',
			phone: pickUpAddress.phone || '',
			email: pickUpAddress.email || '',
		},

		deliveryAddress: {
			name: deliveryAddress.name || '',
			street1: deliveryAddress.street1 || '',
			street2: deliveryAddress.street2 || '',
			city: deliveryAddress.city || '',
			state: deliveryAddress.state || '',
			zip: deliveryAddress.zip || '',
			country: deliveryAddress.country || '',
			addressType: deliveryAddress.addressType || null,
			contactName: deliveryAddress.name || '',
			phone: deliveryAddress.phone || '',
			email: deliveryAddress.email || '',
		},

		accessorials: {
			cod: accessorialsRequired?.cod || null,
			codAmount: accessorialsInfoPayload?.codCollectionAmount || null,
			moneyOrderOnly: accessorialsInfoContainer?.codMoneyOrderOnly || null,

			fedexSmartPost: accessorialsPayload?.fedexSmartPost || null,
			smartPostHub: accessorialsInfoPayload?.smartPostHub || '',

			holdAtCarrier: accessorialsPayload?.holdAtLocation || null,
			holdAtCarrierPhone: accessorialsInfoPayload?.holdPhone || '',
			holdAtCarrierStreet: accessorialsInfoPayload?.holdStreet || '',
			holdAtCarrierCity: accessorialsInfoPayload?.holdCity || '',
			holdAtCarrierState: accessorialsInfoPayload?.holdState || '',
			holdAtCarrierZip: accessorialsInfoPayload?.holdCode || '',
			holdAtCarrierCountry: accessorialsInfoPayload?.holdCountry || '',

			protectFromFreeze: accessorialsRequired?.protectFromFreeze || null,

			saturdayDelivery: accessorialsPayload?.saturdayDelivery || null,

			signatureRequired: accessorialsRequired?.signature || null,
			signatureType: accessorialsInfoPayload?.signatureType || null,
		},

		packages: containers.map((container) => {
			return {
				quantity: container.count || 1,
				reference1: container.labelReferenceValue1 || '',
				reference2: container.labelReferenceValue2 || '',
				declaredValue: container.declaredValue || null,
				packagePageId: container.id || '',
				length: container.length || null,
				width: container.width || null,
				height: container.height || null,
				weight: container.packedWeight || null,
			}
		}),

		billTo: {
			billToParty: billTo?.billToThisParty || null,
			carrier: billTo?.carrierName || null,
			serviceLevel: billTo?.carrierService || null,
			accountNumber: billTo?.carrierAccountNumber || '',
			address: {
				name: billTo?.address?.name || '',
				street1: billTo?.address?.street1 || '',
				street2: billTo?.address?.street2 || '',
				city: billTo?.address?.city || '',
				state: billTo?.address?.state || '',
				zip: billTo?.address?.zip || '',
				country: billTo?.address?.country || '',
				addressType: billTo?.address?.addressType || null,
				contactName: billTo?.address?.name || '',
				phone: billTo?.address?.phone || '',
				email: billTo?.address?.email || '',
			},
		},

		pickTicketNumber: pickTicketNumber,

		autoRateCarrier: autoRateCarrier,
		autoRateService: autoRateService,
	}
}

export const shipmentToParcelForm = (
	shipment: apiTypes.ShipmentResponse,
): {
	pickupAddressForm: NewQuoteAddressForm
	deliveryAddressForm: NewQuoteAddressForm
	accessorialsForm: NewQuoteAccessorialsForm
	packagesForm: NewQuotePackageForm[]
	billToForm: NewQuoteBillToForm
} => {
	const pickupAddressForm: NewQuoteAddressForm = {
		...shipment.payloads[0].originStop.address,
		locationId: shipment.payloads[0].originStop.metaData?.locationId,
	}
	const deliveryAddressForm: NewQuoteAddressForm = {
		...shipment.payloads[0].destinationStop.address,
		locationId: shipment.payloads[0].destinationStop.metaData?.locationId,
	}
	const accessorialsForm: NewQuoteAccessorialsForm = {
		cod: shipment.payloads[0].containers[0]?.containers[0]?.accessorials?.cod,
		codAmount:
			shipment.payloads[0].containers[0]?.containers[0]?.accessorialsInfo
				?.codCollectionAmount,
		moneyOrderOnly:
			shipment.payloads[0].containers[0]?.containers[0]?.accessorialsInfo
				?.codMoneyOrderOnly,
		fedexSmartPost: shipment.payloads[0].accessorials?.fedexSmartPost,
		smartPostHub: shipment.payloads[0].accessorialsInfo?.smartPostHub,
		holdAtCarrier: shipment.payloads[0].accessorials?.holdAtLocation,
		holdAtCarrierPhone: shipment.payloads[0].accessorialsInfo?.holdPhone,
		holdAtCarrierStreet: shipment.payloads[0].accessorialsInfo?.holdStreet,
		holdAtCarrierCity: shipment.payloads[0].accessorialsInfo?.holdCity,
		holdAtCarrierState: shipment.payloads[0].accessorialsInfo?.holdState,
		holdAtCarrierZip: shipment.payloads[0].accessorialsInfo?.holdCode,
		holdAtCarrierCountry: shipment.payloads[0].accessorialsInfo?.holdCountry,
		protectFromFreeze: shipment.accessorials?.protectFromFreeze,
		saturdayDelivery: shipment.payloads[0].accessorials?.saturdayDelivery,
		signatureRequired: shipment.payloads[0].accessorials?.signature,
		signatureType: shipment.payloads[0].accessorialsInfo?.signatureType,
	}

	const packagesForm: NewQuotePackageForm[] = shipment.payloads[0].containers[0].containers.map(
		(container) => ({
			quantity: container.count,
			reference1: container.labelReferenceValue1,
			reference2: container.labelReferenceValue2,
			declaredValue: container.declaredValue,
			packagePageId: container.id,
			length: container.length,
			width: container.width,
			height: container.height,
			weight: container.packedWeight,
		}),
	)

	const billToForm: NewQuoteBillToForm = {
		address: shipment.billTo.address,
		accountNumber: shipment.billTo.carrierAccountNumber,
		carrier: shipment.billTo.carrierName,
		billToParty: shipment.billTo.billToThisParty,
		serviceLevel: shipment.billTo.carrierService,
	}

	return {
		pickupAddressForm,
		billToForm,
		deliveryAddressForm,
		accessorialsForm,
		packagesForm,
	}
}
