import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { LocationResponse } from 'ui/api/apiTypes'
import { getEmptyAddress } from 'ui/components/shared/address'

export interface StateTopLevelLocationConfiguration {
	location: LocationResponse
	apiError: string
	apiSuccess: boolean
}

export const getSos = createLazySos2<StateTopLevelLocationConfiguration>(
	'topLevelLocationConfiguration',
	1,
	() => ({
		location: {
			default: {
				name: null,
				isOrganizationalUnit: null,
				defaults: { defaultBillingAddress: { address: getEmptyAddress() } },
				allDescendantOrganizationsIds: [],
			},
		},
		apiError: { default: null },
		apiSuccess: { default: false },
	}),
)
