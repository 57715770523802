import { FC } from 'app/FunctionalComponent'
import { TypeaheadOption } from 'common/components/typeahead/TypeaheadOption'
import React, { useState } from 'react'
import { FilterItem, Filters } from 'ui/components/common/filters'
import { Loader } from 'ui/components/common/loader'
import { StackedItem } from 'ui/components/form'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { IOnSelectLocation } from 'ui/components/shared/address/IOnSelectLocation'
import { UpdateAddressStateType } from 'ui/components/shared/address/UpdateAddressStateType'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { DefaultAddressType } from 'ui/pages/new-quote/state/newQuoteUtils/locationDropdownUtils'
import { StopType } from 'ui/pages/new-quote/state/sosNewQuote'
import { sosUser } from 'ui/state'
import { LocationSelector } from '../location-selector/LocationSelector'
import { EditAddressContactInfoForm } from './EditAddressContactInfoForm'
import { EditAddressFormStacked } from './EditAddressFormStacked'
import { IAddressState, LocationDropdownSourceType } from './IAddressState'
import { SimpleAddress } from './SimpleAddress'
// import { sos2 } from 'ui/lib/state/sos2'

export type AddressSelectorType =
	| 'companyLocation'
	| 'customAddress'
	| 'addressBook'
	| 'supplierLocation'
	| 'customerDelivery'
	| 'dropShip'

export const Address: FC = (props: {
	addressState: IAddressState
	updateAddressState: UpdateAddressStateType
	onSelectLocation?: IOnSelectLocation
	organizationRelationshipId?: string
	stopType?: StopType
	filters?: AddressSelectorType[]
	updateAddressFilter?: (filter: AddressSelectorType, addressId: string) => void
	showMap?: boolean
	showAddressValidation?: boolean
	showSaveAddress?: boolean
	onSaveAddress?: (addressState: IAddressState) => Promise<boolean>
	addressSaving?: boolean
	locationsSource?: LocationDropdownSourceType
	defaultLocation?: TypeaheadOption
}) => {
	const { addressState, filters, updateAddressFilter, locationsSource } = props
	const [currentlySelectedLocation, setCurrentlySelectedLocation] = useState(
		null,
	)

	useOnce(async () => {
		if (filters && !l.includes(filters, addressState.selectedAddressFilter)) {
			updateAddressFilter(l.first(filters), addressState.id)
		}
		if (props.stopType === 'pickup') {
			const userState = sosUser.getSos().getState()

			if (userState.requestUserInfo.data) {
				const location = await sosUser.getLocation(
					userState.requestUserInfo.data.locationId,
				)
				if (
					userState.requestUserInfo.data.company.companyName ===
					userState.requestUserInfo.data.managedCompany?.companyName
				) {
					setCurrentlySelectedLocation({
						label: location.name,
						value: location.id,
					})
				}
			}
		}
	})

	if (!addressState) {
		return <div>{t('shared.address.noAddress')}</div>
	}

	const { address, addressEditMode } = addressState

	// let canDisplayMap = (address.city && address.state) || address.zip

	const isCompanyLocationMode = addressEditMode === 'companyLocation'
	const isCustomAddressEditMode = addressEditMode === 'customAddress'

	const defaultAddressTypeMap: {
		[k in StopType]: DefaultAddressType
	} = {
		pickup: 'defaultPickupAddress',
		delivery: 'defaultDeliveryAddress',
	}
	const defaultStopType: DefaultAddressType =
		defaultAddressTypeMap[props.stopType]

	return (
		<div>
			{props.filters && (
				<Filters>
					{props.filters.map((filter) => (
						<FilterItem
							key={filter}
							isSelected={props.addressState.selectedAddressFilter === filter}
							onClick={() => {
								props.updateAddressFilter(filter, props.addressState.id)
							}}
						>
							<span
								data-testid={
									'filterItem-' + filter + '-' + props.addressState.id
								}
							>
								{t(
									sosUser.isUserBroker() && filter === 'companyLocation'
										? 'customerLocation'
										: filter,
									'shared.address',
								)}
							</span>
						</FilterItem>
					))}
				</Filters>
			)}

			<div>
				{isCompanyLocationMode && (
					<div>
						<StackedItem
							label={t('companyLocationShort', 'shared.address')}
							showRequired={true}
						>
							<LocationSelector
								onChange={(selected: TypeaheadOption) => {
									if (selected) {
										props.onSelectLocation(
											selected.value,
											props.updateAddressState,
											props.organizationRelationshipId,
											addressState.id,
										)
									}
								}}
								includeAll={false}
								locationsSource={locationsSource || 'companyLocations'}
								neededDefaultAddressType={defaultStopType}
								onlyShowLeafLocations={true}
								currentlySelectedLocation={currentlySelectedLocation}
							/>
						</StackedItem>

						<Loader isLoading={addressState.isLocationLoading} />
						{r.not(addressState.isLocationLoading) &&
							addressState.locationId &&
							address && (
								<>
									<SimpleAddress
										address={address}
										showNoAddress={false}
										showContactInfo={false}
									/>

									<Spacer />
									<div>
										<EditAddressContactInfoForm
											addressState={addressState}
											updateAddressState={props.updateAddressState}
											stopType={props.stopType}
										/>
									</div>
								</>
							)}
						{!address && <div>{t('shared.address.noAddress')}</div>}
					</div>
				)}{' '}
				{isCustomAddressEditMode && (
					<div>
						<EditAddressFormStacked
							addressState={addressState}
							updateAddressState={props.updateAddressState}
							nonContactReadOnly={addressEditMode === 'companyLocation'}
							showSave={props.showSaveAddress}
							onSave={props.onSaveAddress}
							isSubmitting={props.addressSaving}
						/>
					</div>
				)}
				{/* In the future we can use this to allow spot editing of addresses */}
				{/* {canToggleEdit && editMode === 'readOnly' && (
							<HoverHighlight
								onClick={() =>
									props.updateAddressState(
										{
											isEditing: true,
										},
										props.addressState.id,
									)
								}
								color='blue'
								tooltip={<Icon icon={solidIcons.faPencilAlt} />}
								tooltipPosition='topRight'
							>
								<SimpleAddress address={address} />
							</HoverHighlight>
						)} */}
				{/* {isReadOnlyMode && <SimpleAddress address={address} />} */}
				{/* Disabled functionality for now */}
				{/* <Spacer />
						{showAddressValidation && (
							<ValidateAddressButton
								addressState={addressState}
								updateAddressState={props.updateAddressState}
							/>
						)} */}
			</div>
		</div>
	)
}
