import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiTypes, apiPrintNode } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormVerticalCheckbox,
	FormStackedTextInput,
	FormStackedPillSwitch,
	IFormData,
} from 'ui/components/form'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import * as classes from './PrinterConfigModal.module.scss'

interface PrinterConfigurationForm {
	nickname: string
	printerType: apiTypes.printerType
	useDocTab: boolean
}

const defaultPrinterConfigurationForm: PrinterConfigurationForm = {
	nickname: null,
	printerType: null,
	useDocTab: null,
}

export const tPrefix =
	'page.companyManagement.printersAndScales.tabs.printer.config'

const spacerHeight = '10px'

export const PrinterConfigModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: (printer: apiTypes.PrinterResponse) => void
	selectedPrinterConfig: apiTypes.PrinterResponse
	locationId: string
	userCredentials: apiTypes.PrintNodeCredentialsResponse
}) => {
	const {
		isModalOpen,
		onModalClose,
		selectedPrinterConfig,
		locationId,
		userCredentials,
	} = props
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [
		printerConfigurationSaveForm,
		setPrinterConfigurationSaveForm,
	] = useState<PrinterConfigurationForm>(defaultPrinterConfigurationForm)

	useEffect(() => {
		if (selectedPrinterConfig) {
			setPrinterConfigurationSaveForm({
				nickname: selectedPrinterConfig.nickname,
				printerType: selectedPrinterConfig.printerType,
				useDocTab: selectedPrinterConfig.useDocTab,
			})
		}
	}, [selectedPrinterConfig])

	const onClose = (): void => {
		onModalClose(null)
	}

	const onSave = async (): Promise<void> => {
		setIsLoading(true)

		const printerConfigRequest: apiTypes.PrinterRequest = {
			nickname: printerConfigurationSaveForm.nickname,
			printNodeId: selectedPrinterConfig?.printNodeId,
			paperType: selectedPrinterConfig?.paperType,
			printerType: printerConfigurationSaveForm.printerType,
			locationId: locationId,
			computerName: selectedPrinterConfig?.computerName,
			useDocTab: printerConfigurationSaveForm.useDocTab,
		}

		const printerConfigResponse: IRequestState<apiTypes.PrinterResponse> = await apiPrintNode.updatePrinterConfig(
			() => {},
			userCredentials.id,
			selectedPrinterConfig.id,
			printerConfigRequest,
		)

		if (printerConfigResponse.data) {
			onModalClose(printerConfigResponse.data)
		} else if (printerConfigResponse.error) {
			sosToast.sendApiErrorResponseToast(printerConfigResponse)
		}

		setIsLoading(false)
	}

	const printerForm: IFormData<PrinterConfigurationForm> = {
		form: printerConfigurationSaveForm,
		metadata: {
			nickname: { required: true },
			printerType: {
				options: [
					{ value: 'label', label: tString('labelPrinter', tPrefix) },
					{ value: 'office', label: tString('officePrinter', tPrefix) },
				],
			},
			useDocTab: {},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(printerConfigurationSaveForm)

			if (field === 'printerType' && value === 'office') {
				updatedFormData.useDocTab = false
			}

			updatedFormData[field] = value
			setPrinterConfigurationSaveForm(updatedFormData)
		},
		tPrefix,
	}

	return (
		<Modal
			content={() => (
				<div className={classes.printerConfigModal}>
					{selectedPrinterConfig?.nickname}
					<Spacer height={spacerHeight} />
					<FormStackedTextInput
						formData={printerForm}
						field={'nickname'}
						testId={'printer-config-nickname'}
					/>
					<Spacer height={spacerHeight} />
					<FormStackedPillSwitch
						formData={printerForm}
						field={'printerType'}
						label={t('printerType', tPrefix)}
						hideOptional={true}
					/>
					<Spacer height={spacerHeight} />
					{printerConfigurationSaveForm.printerType === 'label' && (
						<FormVerticalCheckbox formData={printerForm} field={'useDocTab'} />
					)}
					<Spacer height={spacerHeight} />
					<AlignRight>
						<OkCancelButtons
							ok={t('save', tPrefix)}
							onOk={onSave}
							okColor='green'
							cancel={t('cancel', tPrefix)}
							onCancel={onClose}
							isSpinning={isLoading}
							isValid={true}
							okTestId={'printer-config-save-button'}
							cancelTestId={'printer-config-cancel-button'}
						/>
					</AlignRight>
				</div>
			)}
			isOpen={isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('printerConfiguration', tPrefix)}
		/>
	)
}
