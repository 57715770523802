import { Toast, ToastState } from 'common/components/toast'
import { DateTime } from 'luxon'
import React, { ReactElement, ReactNode, useState } from 'react'
import { apiTypes } from 'ui/api'
import { ndash } from 'ui/components/common'
import { Button } from 'ui/components/common/button'
import { Checkbox } from 'ui/components/common/checkbox'
import { Icon, regularIcons } from 'ui/components/common/icon'
import { LinkButton } from 'ui/components/common/link'
import { Loader } from 'ui/components/common/loader'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { ElasticSearchPager } from 'ui/components/common/pager'
import { Popup } from 'ui/components/common/popup'
import { SearchInput } from 'ui/components/common/search'
import { useOnce } from 'ui/components/hooks'
import {
	tEquipmentType,
	tMode,
	tStopType,
} from 'ui/components/i18n/commonTranslations'
import { t, tArgz, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Center } from 'ui/components/layout/center'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { CalendarInput } from 'ui/components/shared/ShipmentStopCard'
import { DataTable, IDataTableHeader } from 'ui/components/table'
import { idx } from 'ui/lib'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { DockSchedulerLocationSelector } from 'ui/pages/dock-scheduler/components/DockSchedulerLocationSelector'
import { HeaderText } from 'ui/pages/spot-quote'
import { theme } from 'ui/theme'
import * as commonClasses from 'ui/theme/common.module.scss'
import { AppointmentWithoutShipmentCreateModal } from './AppointmentWithoutShipmentCreateModal'
import * as classes from '../DockScheduler.module.scss'
import { DockSchedulerAppointmentFilters } from '../components/DockSchedulerAppointmentFilters'
import {
	DockSchedulerTimeSlots,
	IScheduleTimeSlot,
} from '../components/DockSchedulerTimeSlots'
import { validateDockWithEquipmentTypeFlowMode } from '../components/dockSchedulerTimeSlotsUtils'
import { sosDockScheduler, sosDockSchedulerScheduleTab } from '../state'
import { IShipmentWithoutAppointmentRow } from '../state/sosDockSchedulerScheduleTab'

type DockConfigDetails = {
	equipmentTypes: apiTypes.ShipmentResponse['equipmentType'][]
	stopTypes: apiTypes.AppointmentResponse['stopType'][]
	modes: apiTypes.ShipmentResponse['mode'][]
	count: number
}

const tPrefix = 'page.dockScheduler.schedule'

const NumberList = (props: { list: string }): ReactElement => {
	const [isOpen, setIsOpen] = useState(false)
	const { list } = props
	if (!list) {
		return <span>{ndash}</span>
	}
	const individualNumbers = list.split(', ')
	if (individualNumbers.length < 4) {
		return <span>{list}</span>
	} else {
		return (
			<Popup isOpen={isOpen} content={list} position={'bottomRight'}>
				<div
					onClick={() => setIsOpen(!isOpen)}
					onMouseEnter={() => setIsOpen(true)}
					onMouseLeave={() => setIsOpen(false)}
				>
					{individualNumbers.slice(0, 3).join(', ')}, ...
				</div>
			</Popup>
		)
	}
}

const TimeslotDockConfigurationDetails = (props: {
	timeslot: IScheduleTimeSlot
	docks: apiTypes.DockResponse[]
	date: string
	onClose: () => void
}): ReactElement => {
	const { timeslot, docks, date, onClose } = props
	const dockConfigurationDetails: DockConfigDetails[] = []
	docks.forEach((dock) => {
		const dockConfig: DockConfigDetails = {
			equipmentTypes: dock.equipmentTypes as apiTypes.ShipmentResponse['equipmentType'][],
			stopTypes: dock.stopTypes as apiTypes.AppointmentResponse['stopType'][],
			modes: dock.modes as apiTypes.ShipmentResponse['mode'][],
			count: 1,
		}
		const matchingDockConfig = l.find(
			dockConfigurationDetails,
			(config) =>
				l.xor(config.equipmentTypes, dockConfig.equipmentTypes).length === 0 &&
				l.xor(config.stopTypes, dockConfig.stopTypes).length === 0 &&
				l.xor(config.modes, dockConfig.modes).length === 0,
		)
		if (matchingDockConfig) {
			matchingDockConfig.count++
		} else {
			dockConfigurationDetails.push(dockConfig)
		}
	})
	const startTime: DateTime = DateTime.fromObject({
		hour: timeslot.startHours,
		minute: timeslot.startMinutes,
	})
	const endTime: DateTime = startTime.plus({ minute: timeslot.durationMinutes })
	return (
		<div
			className={classes.scheduleTabTimeSlotDockDetails}
			data-testid='timeslot-dock-configuration-pane'
		>
			<div
				className={theme.addClass(classes.closeButton, commonClasses.clickable)}
				onClick={onClose}
			>
				&times;
			</div>
			<div className={classes.timeSlotDockDetailsTitle}>
				{t('timeSlotDetails', tPrefix)}
			</div>
			<div className={classes.timeSlotDockDetailsTime}>
				{`${date} ${startTime.toFormat('t')} ${ndash} ${endTime.toFormat('t')}`}
			</div>
			<div className={classes.timeSlotDockDetailsBody}>
				{l.map(dockConfigurationDetails, (dockConfig, dockConfigIdx) => {
					return (
						<div key={dockConfigIdx}>
							<div className={classes.timeSlotDockDetailsDockCount}>
								{tArgz(
									dockConfig.count > 1 ? 'availableDocks' : 'availableDock',
									{ count: dockConfig.count },
									tPrefix,
								)}
							</div>
							<ul>
								<li>
									{l
										.map(dockConfig.stopTypes, (stopType) =>
											tStopType(stopType),
										)
										.join(', ')}
								</li>
								<li>
									{l.map(dockConfig.modes, (mode) => tMode(mode)).join(', ')}
								</li>
								<li>
									{l
										.map(dockConfig.equipmentTypes, (equipmentType) =>
											tEquipmentType(equipmentType),
										)
										.join(', ')}
								</li>
							</ul>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export const DockSchedulerSchedule = (): ReactElement => {
	const state = sos2.useSubscription(sosDockSchedulerScheduleTab.getSos())
	const dockSchedulerState = sosDockScheduler.getSos().getState()
	useOnce(() => {
		fireAndForget(
			sosDockSchedulerScheduleTab._fetchShipmentsWithoutAppointments,
			'fetching shipments without appointments list from dock scheduler schedule',
		)
		fireAndForget(
			sosDockSchedulerScheduleTab._fetchAppointmentsForLocationOnSelectedDay,
			'fetching appointments for dock scheduler schedule',
		)
	})
	const successToast: ToastState = {
		type: 'success',
		body: tString('appointmentSuccess', tPrefix),
	}
	const errorToast: ToastState = {
		type: 'danger',
		header: tString('appointmentError', tPrefix),
		body: state.appointmentCreateError,
	}
	const headers: IDataTableHeader<IShipmentWithoutAppointmentRow>[] = [
		{
			field: 'id',
			hideLabel: true,
			renderer: (
				data: string,
				row: IShipmentWithoutAppointmentRow,
			): ReactNode => {
				if (
					state.creatingAppointment &&
					state.selectedShipmentWithoutAppointment.id === row.id
				) {
					return (
						<Center>
							<Loader testId={'assign-shipment-spinner'} isLoading={true} />
						</Center>
					)
				} else {
					return (
						<Center>
							<div
								data-testid={'assign-shipment-button'}
								onClick={(): void =>
									sosDockSchedulerScheduleTab.setSelectedShipmentWithoutAppointment(
										row,
									)
								}
							>
								<Icon icon={regularIcons.faPlusSquare} />
							</div>
						</Center>
					)
				}
			},
			sort: false,
		},
		{ field: 'slid', sort: false },
		{ field: 'carrier', sort: false },
		{
			field: 'mode',
			renderer: (
				data: apiTypes.ShipmentResponse['mode'],
				row: IShipmentWithoutAppointmentRow,
			): ReactNode => {
				return <span>{tMode(data)}</span>
			},
			sort: false,
		},
		{
			field: 'equipmentType',
			renderer: (
				data: apiTypes.ShipmentResponse['equipmentType'],
				row: IShipmentWithoutAppointmentRow,
			): ReactNode => {
				return <span>{tEquipmentType(data)}</span>
			},
			sort: false,
		},
		{
			field: 'flow',
			renderer: (
				data: apiTypes.AppointmentResponse['stopType'],
				row: IShipmentWithoutAppointmentRow,
			): ReactNode => {
				return <span>{tStopType(data)}</span>
			},
			sort: false,
		},
		{ field: 'date', sort: false },
		{
			field: 'poNumber',
			renderer: (
				data: string,
				row: IShipmentWithoutAppointmentRow,
			): ReactNode => <NumberList list={data} />,
			sort: false,
		},
		{
			field: 'soNumber',
			renderer: (
				data: string,
				row: IShipmentWithoutAppointmentRow,
			): ReactNode => <NumberList list={data} />,
			sort: false,
		},
		{
			field: 'proNumber',
			sort: false,
		},
		{
			field: 'id',
			hideLabel: true,
			renderer: (id: string): ReactNode => {
				return (
					<Center>
						<LinkButton
							onClick={(): void =>
								sosDockSchedulerScheduleTab.goToShipmentDetails(id)
							}
						>
							{t('detailsLink', tPrefix)}
						</LinkButton>
					</Center>
				)
			},
			sort: false,
		},
		{
			field: 'id',
			hideLabel: true,
			renderer: (
				id: string,
				row: IShipmentWithoutAppointmentRow,
			): ReactNode => {
				const isToggling = state.shipmentsTogglingArchives.includes(id)
				return (
					<Center>
						{isToggling && (
							<Loader
								testId={'dockScheduler-archiving-spinner'}
								isLoading={true}
							/>
						)}
						{!isToggling && (
							<LinkButton
								onClick={(): Promise<void> =>
									sosDockSchedulerScheduleTab.setShipmentArchived(
										id,
										!row.archived,
									)
								}
							>
								{t(row.archived ? 'unarchiveLink' : 'archiveLink', tPrefix)}
							</LinkButton>
						)}
					</Center>
				)
			},
			sort: false,
		},
	]
	const fadedRowIdxs = []
	if (state.selectedTimeSlot && !state.selectedShipmentWithoutAppointment) {
		const docks = dockSchedulerState.docks.filter(
			(dock) => state.selectedTimeSlot.dockIds.indexOf(dock.id) > -1,
		)
		for (let i = 0; i < state.shipmentsWithoutAppointmentsList.length; i++) {
			const shipmentWithoutAppointment =
				state.shipmentsWithoutAppointmentsList[i]
			const validDocks = docks.filter((dock) =>
				l.isNil(
					validateDockWithEquipmentTypeFlowMode(
						dock,
						shipmentWithoutAppointment.equipmentType,
						shipmentWithoutAppointment.flow,
						shipmentWithoutAppointment.mode,
					),
				),
			)
			if (validDocks.length === 0) {
				fadedRowIdxs.push(i)
			}
		}
	}
	return (
		<div className={classes.schedulePageContainer}>
			<div
				className={theme.addClassIf(
					!l.isNil(state.timeslotDockDetailsToShow),
					classes.mainContentSharing,
					classes.scheduleTabMainContent,
				)}
			>
				<DockSchedulerLocationSelector />
				<Spacer height='10px' />
				<HeaderText large={true} bold={true}>
					{t('shipmentsTableHeader', tPrefix)}
				</HeaderText>
				<FlexRow verticalAlign='bottom' fullWidth={true}>
					<FlexItem fitContent={true}>
						<SearchInput
							testId={'scheduleTabSearchInput'}
							value={state.shipmentsWithoutAppointmentsSearchTerm}
							onChange={
								sosDockSchedulerScheduleTab.updateShipmentsWithoutAppointmentsSearchTerm
							}
							readOnly={state.fetchingShipmentsWithoutAppointments}
							className={classes.scheduleTabSearchInput}
						/>
					</FlexItem>
					<FlexItem fitContent={true}>
						<DockSchedulerAppointmentFilters
							onUpdateFlow={
								sosDockSchedulerScheduleTab.updateScheduleTabFlowFilter
							}
							onUpdateMode={
								sosDockSchedulerScheduleTab.updateScheduleTabModeFilter
							}
							selectedFlow={state.flowFilter}
							selectedMode={state.modeFilter}
						/>
					</FlexItem>
					<FlexItem>
						<AlignRight>
							<Checkbox
								value={state.showArchived}
								onChange={(): void =>
									sosDockSchedulerScheduleTab.toggleShowArchived(
										!state.showArchived,
									)
								}
								fontSize='fontMedium'
							>
								{t('showArchived', tPrefix)}
							</Checkbox>
						</AlignRight>
					</FlexItem>
				</FlexRow>
				{state.fetchingShipmentsWithoutAppointments && (
					<FlexRow verticalAlign='bottom'>
						<FlexItem fitContent={true}>
							<Loader isLoading={true} />
						</FlexItem>
						<FlexItem fitContent={true}>
							{t('fetchingShipmentsWithoutAppointments', tPrefix)}
						</FlexItem>
					</FlexRow>
				)}
				<DataTable
					testId={'dockScheduler-schedule-tab'}
					tPrefix={tPrefix}
					headers={headers}
					data={state.shipmentsWithoutAppointmentsList}
					state={{}}
					spacerCell={false}
					highlightedRows={[
						state.selectedShipmentWithoutAppointment
							? state.selectedShipmentWithoutAppointment.id
							: '',
					]}
					fadedRowIdxs={fadedRowIdxs}
					fontSize={'fontMedium'}
				/>
				<ElasticSearchPager
					pager={state.shipmentsWithoutAppointmentsPager}
					onClickPage={async (pageNum): Promise<void> => {
						await sosDockSchedulerScheduleTab.updateShipmentsWithoutAppointmentsCurrentPage(
							pageNum,
						)
					}}
				/>
				<Spacer height='25px' />
				{state.fetchingAppointments && (
					<FlexRow verticalAlign='bottom'>
						<FlexItem fitContent={true}>
							<Loader isLoading={true} />
						</FlexItem>
						<FlexItem fitContent={true}>
							{t('fetchingAppointments', tPrefix)}
						</FlexItem>
					</FlexRow>
				)}
				<FlexRow verticalAlign='center' fullWidth={true} noChildMargins={true}>
					<FlexItem fitContent={true}>
						<HeaderText large={true} bold={true}>
							{t('appointmentSlotsHeader', tPrefix)}
						</HeaderText>
					</FlexItem>
					<FlexItem fitContent={true}>
						<CalendarInput
							isOpen={state.calendarOpen}
							dateString={state.selectedDate}
							onChangeOpen={(isOpen): void =>
								sosDockSchedulerScheduleTab.toggleCalendarShowing(isOpen)
							}
							onChangeText={(newVal: string): void =>
								sosDockSchedulerScheduleTab.setCalendarDate(newVal)
							}
							popupPosition={'topRight'}
							className={classes.scheduleTabDateSpacer}
						/>
					</FlexItem>
					<FlexItem>
						<AlignRight>
							<Button
								isDisabled={
									l.isNil(state.selectedTimeSlot) ||
									!l.isNil(state.selectedShipmentWithoutAppointment)
								}
								color={'darkBlue'}
								onClick={() =>
									sosDockSchedulerScheduleTab.setAppointmentWithoutShipmentModalOpen(
										true,
									)
								}
								testId={'add-appointment-button'}
								className={classes.scheduleTabAddAppointmentButton}
							>
								{t('addAppointment', tPrefix)}
							</Button>
						</AlignRight>
					</FlexItem>
				</FlexRow>
				<Spacer />
				<div>
					<DockSchedulerTimeSlots
						docks={dockSchedulerState.docks}
						date={state.selectedDate}
						appointments={state.appointments}
						selectedTimeSlot={state.selectedTimeSlot}
						zipcode={idx(
							() =>
								dockSchedulerState.currentLocation.defaults.defaultPickupAddress
									.address.zip,
						)}
						equipmentType={idx(
							() => state.selectedShipmentWithoutAppointment.equipmentType,
						)}
						flow={idx(() => state.selectedShipmentWithoutAppointment.flow)}
						mode={idx(() => state.selectedShipmentWithoutAppointment.mode)}
						onSelectTimeSlot={(slot: IScheduleTimeSlot): void => {
							sosDockSchedulerScheduleTab.setSelectedTimeslot(slot)
						}}
					/>
				</div>
				<Modal
					isOpen={!l.isNil(state.scheduleErrorReason)}
					onModalClose={() =>
						state.selectedItemThatCausedScheduleError === 'timeslot'
							? sosDockSchedulerScheduleTab.setSelectedTimeslot(null)
							: sosDockSchedulerScheduleTab.setSelectedShipmentWithoutAppointment(
									null,
							  )
					}
					title={t('confirmInvalidTimeSlot', tPrefix)}
					content={() => (
						<div data-testid={'confirmInvalidTimeSlotModal'}>
							<div>{t('selectedTimeSlotInvalidForReason', tPrefix)}</div>
							<div>{state.scheduleErrorReason}</div>
							<div>{t('scheduleForSlotAnywaysQuestion', tPrefix)}</div>
							<AlignRight>
								<OkCancelButtons
									isValid={true}
									onOk={() =>
										fireAndForget(
											() =>
												sosDockSchedulerScheduleTab.createAppointmentForShipmentAndTimeSlot(
													true,
												),
											'scheduling appointment after user confirmed to use bad time slot',
										)
									}
									okTestId='confirmSlotButton'
									isSpinning={state.creatingAppointment}
									ok={t('scheduleIntoInvalidSlot', tPrefix)}
									onCancel={() =>
										state.selectedItemThatCausedScheduleError === 'timeslot'
											? sosDockSchedulerScheduleTab.setSelectedTimeslot(null)
											: sosDockSchedulerScheduleTab.setSelectedShipmentWithoutAppointment(
													null,
											  )
									}
								/>
							</AlignRight>
						</div>
					)}
				/>
			</div>
			{state.timeslotDockDetailsToShow && (
				<TimeslotDockConfigurationDetails
					timeslot={state.timeslotDockDetailsToShow}
					date={state.selectedDate}
					docks={dockSchedulerState.docks.filter(
						(dock) =>
							state.timeslotDockDetailsToShow.dockIds.indexOf(dock.id) > -1,
					)}
					onClose={sosDockSchedulerScheduleTab.clearTimeslotDockDetailsToShow}
				/>
			)}
			<AppointmentWithoutShipmentCreateModal
				isOpen={state.appointmentWithoutShipmentCreateModalOpen}
				appointmentCreateForm={state.appointmentWithoutShipmentCreateForm}
				onSubmit={() =>
					fireAndForget(
						sosDockSchedulerScheduleTab.createAppointmentWithoutShipment,
						'creating an appointment without a shipment',
					)
				}
				onModalClose={() =>
					sosDockSchedulerScheduleTab.setAppointmentWithoutShipmentModalOpen(
						false,
					)
				}
				onUpdateForm={
					sosDockSchedulerScheduleTab.updateAppointmentWithoutShipmentForm
				}
				errorText={state.appointmentWithoutShipmentCreateError}
				submitting={state.creatingAppointmentWithoutShipment}
			/>
			{state.appointmentCreateSuccess && <Toast toast={successToast} />}
			{state.appointmentCreateError && <Toast toast={errorToast} />}
		</div>
	)
}
