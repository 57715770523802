import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { LoginModeType, sosRouter2 } from 'ui/components/common/router'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import * as commonClasses from 'ui/theme/common.module.scss'
import { addClass } from 'ui/theme/theme'
import { filterLinks, ILink, tSidebarPrefix } from './Sidebar'
import styles from './Sidebar.module.scss'
import { SidebarSubItem } from './SidebarSubItem'

export const SidebarItem: FC = (props: {
	pathname: string
	isSelected: boolean
	selectedSubMenuPathname?: string
	subLinks?: ILink[]
	children: React.ReactNode
	loginMode: LoginModeType
}) => {
	let className = styles.sidebarItem
	className = addClass(commonClasses.clickable, className)
	if (props.isSelected) {
		className += ' ' + styles.selectedItem
	}

	const filteredLinks = filterLinks(props.loginMode, props.subLinks)

	return (
		<div
			className={className}
			onClick={() => {
				sosRouter2.navigateTo(
					{ url: props.pathname, params: {}, queries: {} },
					{},
				)
			}}
		>
			{props.children}

			{props.isSelected && (
				<div>
					{l.map(l.filter(filteredLinks), (d, dIdx) => (
						<React.Fragment key={dIdx}>
							<SidebarSubItem
								pathname={d.pathname}
								isSelected={d.pathname === props.selectedSubMenuPathname}
							>
								{t(d.name, tSidebarPrefix)}
							</SidebarSubItem>
						</React.Fragment>
					))}
				</div>
			)}
		</div>
	)
}
