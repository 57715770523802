import { apiTypes } from 'ui/api'
import { idx } from 'ui/lib/idx'
import { extractContainersFromShipment } from 'ui/lib/shipments'
import { l } from 'ui/lib/lodashImports'
import { tString } from 'ui/components/i18n/i18n'
import { getGoodsFromContainer } from 'ui/lib/shipments/shipmentUtils'

const tPrefix = 'page.newQuote.validation'
const tPrefixEditAddress = 'shared.address.editAddress'

export const validateAddress = (
	address: apiTypes.Address,
	requiredFields: (keyof apiTypes.Address)[] = [
		'street1',
		'city',
		'state',
		'zip',
		'country',
	],
): string[] => {
	if (!address) {
		return ['noAddress']
	}
	return l.filter(requiredFields, (field) => !address[field])
}

export const validateZipOrCityState = (address: apiTypes.Address): string[] => {
	if (!address) {
		return ['noAddress']
	}
	const validationErrors: string[] = []
	if (!address.zip && !(address.city && address.state)) {
		validationErrors.push('invalidAddress')
	}
	return validationErrors
}

export const validateStops = (
	shipment: apiTypes.ShipmentRequest,
	requiredFields?: (keyof apiTypes.Address)[],
	zipOrCityState?: boolean,
): string[] => {
	if (!shipment) {
		return [tString('shipmentRequired', tPrefix)]
	}

	const validate = zipOrCityState
		? validateZipOrCityState
		: (address: apiTypes.Address) => validateAddress(address, requiredFields)

	return l.flatMap(shipment.payloads, (payload) => {
		let originValidationErrors = validate(payload.originStop.address)
		let destinationValidationErrors = validate(payload.destinationStop.address)
		originValidationErrors = originValidationErrors.map(
			(error) =>
				tString(error, tPrefixEditAddress) +
				' ' +
				tString('requiredInPickupAddressOfPayload', tPrefix),
		)
		destinationValidationErrors = destinationValidationErrors.map(
			(error) =>
				tString(error, tPrefixEditAddress) +
				' ' +
				tString('requiredInDestinationAddressOfPayload', tPrefix),
		)
		return [...originValidationErrors, ...destinationValidationErrors]
	})
}

const validateContainers = (shipment: apiTypes.ShipmentRequest): string[] => {
	if (!shipment) {
		return [tString('shipmentRequired', tPrefix)]
	}

	const containers = extractContainersFromShipment(shipment, false)
	const requiredFields: (keyof apiTypes.ContainerResponse)[] = [
		'count',
		'length',
		'width',
		'height',
		'packedWeight',
	]
	const validationFailures: string[] = []

	l.forEach(containers, (container) => {
		l.forEach(requiredFields, (field) => {
			if (!idx(() => container[field])) {
				validationFailures.push(
					tString(field, 'page.shipmentProfile.commodities') +
						' ' +
						tString('requiredForEveryCommodityAndPiece', tPrefix),
				)
			}
		})

		l.forEach(getGoodsFromContainer(container), (good) => {
			const requiredHazMatFields: (keyof apiTypes.GoodResponse)[] = []
			if (good?.hazmat) {
				requiredHazMatFields.push(
					'hazmatClass',
					'hazmatUnCode',
					'hazmatPackingGroup',
				)
			}

			l.forEach(requiredHazMatFields, (field) => {
				if (!idx(() => good[field])) {
					validationFailures.push(
						tString(field, 'page.shipmentProfile.commodities') +
							' ' +
							tString('requiredForEveryHazMatCommodityAndPiece', tPrefix),
					)
				}
			})
		})
	})
	return validationFailures
}

const validateBillToAddress = (
	shipment: apiTypes.ShipmentRequest,
	requiredFields: (keyof apiTypes.Address)[] = ['city', 'state', 'zip'],
	zipOrCityState?: boolean,
): string[] => {
	if (!shipment) {
		return [tString('shipmentRequired', tPrefix)]
	}

	const validate = zipOrCityState
		? validateZipOrCityState
		: (address: apiTypes.Address) => validateAddress(address, requiredFields)

	const errors = validate(shipment.billTo.address)
	return errors.map(
		(error) =>
			tString(error, tPrefixEditAddress) +
			' ' +
			tString('requiredInBillingAddress', tPrefix),
	)
}

const validateBillTo = (shipment: apiTypes.ShipmentRequest): string[] => {
	const errors: string[] = []
	if (
		shipment.billTo.billToThisParty === 'consignee' ||
		shipment.billTo.billToThisParty === 'thirdParty'
	) {
		if (!shipment.billTo.carrierName) {
			errors.push('billToCarrierIsRequired')
		}

		if (!shipment.billTo.carrierService) {
			errors.push('billToServiceLevelIsRequired')
		}
	}

	if (shipment.billTo.billToThisParty === 'thirdParty') {
		errors.push(...validateAddress(shipment.billTo.address))
	}

	return errors.map((error) => tString(error, tPrefix))
}

export const validateAccessorials = (
	shipment: apiTypes.ShipmentRequest,
): string[] => {
	const errors: string[] = []

	const holdAtCarrierAddressKeys: (keyof apiTypes.PayloadAccessorialsInfo)[] = [
		'holdPhone',
		'holdStreet',
		'holdCity',
		'holdState',
		'holdCode',
		'holdCountry',
	]
	shipment.payloads.forEach((payload) => {
		if (
			payload.accessorials?.holdAtLocation &&
			holdAtCarrierAddressKeys.some((key) => !payload.accessorialsInfo[key])
		) {
			errors.push('invalidCarrierLocation')
		}

		if (
			payload.accessorials?.fedexSmartPost &&
			!payload.accessorialsInfo?.smartPostHub
		) {
			errors.push('smartPostHubIsRequired')
		}

		if (
			payload.accessorials?.signature &&
			!payload.accessorialsInfo?.signatureType
		) {
			errors.push('signatureTypeIsRequired')
		}
	})

	const containers = extractContainersFromShipment(shipment)
	containers.forEach((container) => {
		if (
			container.accessorials?.cod &&
			!container.accessorialsInfo?.codCollectionAmount
		) {
			errors.push('codAmountIsRequired')
		}
	})

	return errors.map((error) => tString(error, tPrefix))
}

export const defaultLtlShipmentValidations: ((
	shipment: apiTypes.ShipmentRequest,
) => string[])[] = [
	(shipment: apiTypes.ShipmentRequest) => validateStops(shipment, null, true),
	validateContainers,
	(shipment: apiTypes.ShipmentRequest) =>
		validateBillToAddress(shipment, null, true),
]

export const defaultParcelShipmentValidations: ((
	shipment: apiTypes.ShipmentRequest,
) => string[])[] = [
	(shipment: apiTypes.ShipmentRequest) => validateStops(shipment),
	validateBillTo,
	validateAccessorials,
	validateContainers,
]
