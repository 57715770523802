import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { ISelectOptions } from 'ui/components/common/select'
import {
	FormStackedLayout,
	FormStackedSelect,
	FormStackedTextInput,
	IFormData,
	IFormMetadataCollection,
} from 'ui/components/form'
import { useOnce } from 'ui/components/hooks'
import { tAddressType } from 'ui/components/i18n/commonTranslations'
import { FlexRow } from 'ui/components/layout/flexRow'
import { addressTypes } from 'ui/data'
import {
	getCountryNameAndCodeList,
	getRegionsForCountry,
} from 'ui/data/countryAndRegionUtils'
import { l } from 'ui/lib/lodashImports'
import { NewQuoteAddressForm } from 'ui/pages/new-quote/parcel/components/newQuoteForms'

const tPrefix = 'page.newQuote.parcel.address'

export const CustomAddressForm: FC = (props: {
	addressForm: NewQuoteAddressForm
	testIdPrefix: string
	onUpdate: (form: any) => void
	mode: 'pickupDelivery' | 'billTo'
	isReadOnly?: boolean
}) => {
	const { testIdPrefix, addressForm, onUpdate, isReadOnly } = props

	const [regions, setRegions] = useState([])

	const addressFormMetaData: IFormMetadataCollection<NewQuoteAddressForm> = {
		name: {},
		street1: {},
		street2: {},
		city: {},
		state: {},
		zip: { required: true },
		country: {
			options: getCountryNameAndCodeList().map((countryData) => {
				return {
					label: countryData.countryName,
					value: countryData.countryCode,
				}
			}),
		},
		addressType: {
			options: addressTypes.map((addressType) => {
				return { label: tAddressType(addressType), value: addressType }
			}),
		},
		contactName: {},
		phone: {},
		email: {},
		locationId: {},
	}

	if (props.mode === 'billTo') {
		addressFormMetaData.street1.required = true
		addressFormMetaData.city.required = true
		addressFormMetaData.state.required = true
		addressFormMetaData.country.required = true
		addressFormMetaData.addressType.required = true
	}

	useOnce(() => {
		if (addressForm.country) {
			setRegions(getRegionsForCountry(addressForm.country, true, true))
		}
	})

	const formData: IFormData<NewQuoteAddressForm> = {
		form: addressForm,
		metadata: addressFormMetaData,
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(addressForm)
			updatedFormData[field] = value

			// Reset the state / region if the country has changed
			if (field === 'country' && addressForm.country !== value) {
				const newStateOptions: ISelectOptions[] = getRegionsForCountry(
					updatedFormData.country,
					true,
					true,
				) as ISelectOptions[]

				setRegions(newStateOptions)

				if (
					!l.find(newStateOptions, (stateOption) => {
						return stateOption.value === addressForm.state
					})
				) {
					updatedFormData.state = ''
				}
			}
			onUpdate(updatedFormData)
		},
		tPrefix,
	}

	return (
		<FormStackedLayout>
			<FlexRow>
				<div>
					<FormStackedTextInput
						formData={formData}
						field={'name'}
						testId={`new-quote-parcel-${testIdPrefix}-address-name`}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'street1'}
						testId={`new-quote-parcel-${testIdPrefix}-address-street1`}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'street2'}
						testId={`new-quote-parcel-${testIdPrefix}-address-street2`}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'city'}
						testId={`new-quote-parcel-${testIdPrefix}-address-city`}
						readOnly={isReadOnly}
					/>
					<FormStackedSelect
						formData={formData}
						field={'country'}
						testId={`new-quote-parcel-${testIdPrefix}-address-country`}
						numListOptionsBeforeScroll={5}
						readOnly={isReadOnly}
					/>
					<FormStackedSelect
						formData={formData}
						field={'state'}
						options={regions}
						testId={`new-quote-parcel-${testIdPrefix}-address-state`}
						numListOptionsBeforeScroll={5}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'zip'}
						testId={`new-quote-parcel-${testIdPrefix}-address-zip`}
						readOnly={isReadOnly}
					/>
				</div>
				<div>
					<FormStackedSelect
						formData={formData}
						field={'addressType'}
						testId={`new-quote-parcel-${testIdPrefix}-address-addressType`}
						numListOptionsBeforeScroll={5}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'contactName'}
						testId={`new-quote-parcel-${testIdPrefix}-address-contactName`}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'phone'}
						testId={`new-quote-parcel-${testIdPrefix}-address-phone`}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'email'}
						testId={`new-quote-parcel-${testIdPrefix}-address-email`}
						readOnly={isReadOnly}
					/>
				</div>
			</FlexRow>
		</FormStackedLayout>
	)
}
