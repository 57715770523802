import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { sosToast } from 'common/components/toast'
import { apiPayload, apiShipments, apiTypes, apiPrintNode } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { Button } from 'ui/components/common/button/Button'
import { Card } from 'ui/components/common/card'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { sosRouter2 } from 'ui/components/common/router'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { RequiresFeatureToggle } from 'ui/components/permissions'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { BillToCard } from 'ui/pages/new-quote/parcel/components/bill-to/BillToCard'
import { BillToDropdown } from 'ui/pages/new-quote/parcel/components/bill-to/BillToDropDown'
import {
	createParcelShipmentRequest,
	orderToLoad,
	shipmentToParcelForm,
} from 'ui/pages/new-quote/state/newQuoteUtils/shipmentMapping'
import { sosUser } from 'ui/state'
import { defaultParcelShipmentValidations } from '../../state/newQuoteUtils/validateShipment/shipmentValidations'
import { validateShipmentData } from '../../state/newQuoteUtils/validateShipment/validateShipmentUtils'
import { getParcelDemoShipmentData } from './getParcelDemoShipmentData'
import { LoadOrder } from './load-order'
import { NewQuotePackages } from './new-quote-packages/NewQuotePackages'
import { NewQuoteAccessorial } from './NewQuoteAccessorial/NewQuoteAccessorial'
import {
	emptyAccessorialForm,
	emptyAddressForm,
	emptyBillToForm,
	emptyPackageForm,
	NewQuoteAccessorialsForm,
	NewQuoteBillToForm,
	NewQuotePackageForm,
} from './newQuoteForms'
import * as classes from './NewQuoteParcel.module.scss'
import { NewQuoteParcelAddress } from './NewQuoteParcelAddress'
import { OverridePrinterLocationDropDown } from './override-printer-location'
import { OverrideScaleLocationDropDown } from './override-scale-location'

interface OrderInfo {
	isSaved: boolean
	id: string
	entity: 'shipment' | 'payload'
}

interface BookOnCreateParams {
	book: boolean
	providerName: string
	serviceLevel: string
}

let bookOnCreateParams: BookOnCreateParams = {
	book: null,
	providerName: '',
	serviceLevel: '',
}

const tPrefix = 'page.newQuote.parcel'

export const NewQuoteParcel: FC = (props: {
	state: apiTypes.ShipmentResponse
}) => {
	const { state } = props

	const [pickupAddressForm, setPickupAddressForm] = useState(() =>
		l.cloneDeep(emptyAddressForm),
	)
	const [deliveryAddressForm, setDeliveryAddressForm] = useState(() =>
		l.cloneDeep(emptyAddressForm),
	)
	const [accessorialsForm, setAccessorialsForm] =
		useState<NewQuoteAccessorialsForm>(() => l.cloneDeep(emptyAccessorialForm))
	const [packagesForm, setPackagesForm] = useState<NewQuotePackageForm[]>(
		() => [l.cloneDeep(emptyPackageForm)],
	)
	const [billToForm, setBillToForm] = useState<NewQuoteBillToForm>(
		() => emptyBillToForm,
	)

	const [pageErrors, setPageErrors] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [orderPickTicketNumber, setOrderPickTicketNumber] = useState<string>('')
	const [orderInfo, setOrderInfo] = useState<OrderInfo>({
		isSaved: false,
		id: '',
		entity: null,
	})
	const [isLoadingOrder, setIsLoadingOrder] = useState<boolean>(false)
	const [autoRateCarrier, setAutoRateCarrier] = useState<string>(null)
	const [autoRateService, setAutoRateService] = useState<string>(null)
	const bookOnCreate = !l.isNil(autoRateCarrier || autoRateService)

	const currentUserLocationId = sosUser.getSos().getState().locationId
	const userSessionScaleId = sosUser.getSos().getState().sessionScaleId
	const [locationId, setLocationId] = useState<string>(currentUserLocationId)
	const [printerList, setPrinterList] = useState<apiTypes.PrinterResponse[]>([])
	const [printNodeScalesList, setPrintNodeScalesList] = useState<
		apiPrintNode.PrintNodeScalesResponse[]
	>([])
	const [printNodeScale, setPrintNodeScale] =
		useState<apiPrintNode.PrintNodeScalesResponse>(null)

	useEffect(() => {
		if (printNodeScalesList.length > 0) {
			const scale = printNodeScalesList.find(
				(scale) => scale.computerId === userSessionScaleId,
			)

			setPrintNodeScale(scale)
		}
	}, [printNodeScalesList, userSessionScaleId])

	useEffect(() => {
		if (state) {
			const mappedShipment = shipmentToParcelForm(state)
			setPackagesForm(mappedShipment.packagesForm)
			setBillToForm(mappedShipment.billToForm)
			setAccessorialsForm(mappedShipment.accessorialsForm)
			setPickupAddressForm(mappedShipment.pickupAddressForm)
			setDeliveryAddressForm(mappedShipment.deliveryAddressForm)
		}
	}, [state])

	useEffect(() => {
		if (locationId) {
			fireAndForget(async () => {
				const userCredentialsResponse = await apiPrintNode.getCredentialsList(
					() => {},
					locationId,
				)

				if (userCredentialsResponse.data) {
					if (userCredentialsResponse.data[0]) {
						const credential = userCredentialsResponse.data[0]

						apiPrintNode
							.getPrinterList(() => {}, credential.id)
							.then((response) => {
								if (response.data) {
									setPrinterList(response.data)
								} else if (response.error) {
									sosToast.sendApiErrorResponseToast(response)
								}
							})
							.catch((error) => {
								sosToast.sendApiErrorResponseToast(error)
							})

						apiPrintNode
							.getScaleList(credential)
							.then((response) => {
								const scalesLists = l.flatMap(
									response,
									(scalesList) =>
										scalesList as apiPrintNode.PrintNodeScalesResponse[],
								)

								const filteredScalesList = scalesLists.filter(
									(scale) => scale !== undefined,
								)

								setPrintNodeScalesList(filteredScalesList)
							})
							.catch((error) => {
								sosToast.sendApiErrorResponseToast(error)
							})
					} else {
						sosToast.sendToast({
							header: tString('noCredentialsFoundForSelectedLocation', tPrefix),
							type: 'warning',
						})
					}
				} else if (userCredentialsResponse.error) {
					sosToast.sendApiErrorResponseToast(userCredentialsResponse)
				} else {
					sosToast.sendToast({
						header: tString('noCredentialsFoundForSelectedLocation', tPrefix),
						type: 'warning',
					})
				}
			}, `Fetching LocationId`)
		}
	}, [locationId])

	const getQuotes = async (): Promise<void> => {
		const shipment: apiTypes.ShipmentRequest = createParcelShipmentRequest({
			pickupAddressForm,
			deliveryAddressForm,
			accessorialsForm,
			packagesForm,
			billToForm,
			orderPickTicketNumber,
		})
		const errors = validateShipmentData(
			shipment,
			defaultParcelShipmentValidations,
		)
		if (errors.length === 0) {
			setIsLoading(true)

			let shipmentResponse: IRequestState<apiTypes.ShipmentResponse>

			if (bookOnCreate) {
				bookOnCreateParams = {
					book: true,
					providerName: autoRateCarrier || '',
					serviceLevel: autoRateService || '',
				}
			}

			if (!orderInfo.isSaved) {
				shipmentResponse = await apiShipments.createShipment(
					shipment,
					false,
					() => {},
				)
			} else if (orderInfo.entity === 'shipment') {
				shipmentResponse = await apiShipments.updateShipment(
					orderInfo.id,
					shipment,
					() => {},
					bookOnCreateParams,
				)
			} else if (orderInfo.entity === 'payload') {
				const payloadUpdateRequest = shipment.payloads[0]

				payloadUpdateRequest.locationId =
					payloadUpdateRequest.originStop.metaData?.locationId ||
					payloadUpdateRequest.destinationStop.metaData?.locationId ||
					sosUser.getSos().getState().requestUserInfo.data.locationId

				const payloadResponse = await apiPayload.updatePayload(
					orderInfo.id,
					payloadUpdateRequest,
					() => {},
				)

				if (payloadResponse.error) {
					setPageErrors([payloadResponse.error])
					return
				}

				const shipmentFromPayloadResponse =
					await apiShipments.createShipmentFromPayloads(
						() => {},
						[orderInfo.id],
						bookOnCreateParams,
					)

				if (shipmentFromPayloadResponse.error) {
					setPageErrors([shipmentFromPayloadResponse.error])
					return
				}

				shipment.payloads = shipmentFromPayloadResponse.data.payloads

				shipmentResponse = await apiShipments.updateShipment(
					shipmentFromPayloadResponse.data.id,
					shipment,
					() => {},
				)
			}

			if (shipmentResponse.error) {
				setPageErrors([shipmentResponse.error])
			} else {
				if (bookOnCreate) {
					setPickupAddressForm(emptyAddressForm)
					setDeliveryAddressForm(emptyAddressForm)
					setAccessorialsForm(emptyAccessorialForm)
					setPackagesForm([emptyPackageForm])
					setBillToForm(emptyBillToForm)
					setOrderPickTicketNumber('')
					setAutoRateCarrier(null)
					setAutoRateService(null)
					setOrderInfo({
						isSaved: false,
						id: '',
						entity: null,
					})
				} else {
					sosRouter2.navigateTo(
						{
							url: '/shipments-v3/shipment-profile/:shipmentId?rerate=1',
							params: {
								shipmentId: {
									name: 'shipmentId',
								},
							},
							queries: {},
						},
						{
							shipmentId: shipmentResponse.data.id,
						},
					)
				}
			}
			setIsLoading(false)
		} else {
			setPageErrors(errors)
		}

		// used to reset the validity of OkCancelButton
		// as setting the validity to true causes the toast to not appear
		setTimeout(() => {
			setPageErrors([])
		}, 1000)
	}

	const setParcelDemoShipment = (): void => {
		const { pickupAddressForm, deliveryAddressForm, packagesForm } =
			getParcelDemoShipmentData()

		setPickupAddressForm(pickupAddressForm)
		setDeliveryAddressForm(deliveryAddressForm)
		setPackagesForm(packagesForm)
	}

	return (
		<div className={classes.newQuoteParcel}>
			<RequiresFeatureToggle featureToggle='demo-shipment-button'>
				<Button
					color={'gray'}
					onClick={setParcelDemoShipment}
					inline={true}
					isDisabled={isLoadingOrder}
				>
					{t('demoShipment', tPrefix)}
				</Button>
			</RequiresFeatureToggle>
			<Row className={classes.newQuoteParcelRow}>
				<Col xs={3} className={classes.newQuoteParcelCol}>
					<LoadOrder
						onLoad={(orderResponse: apiTypes.OrderResponse) => {
							const mappedOrder = orderToLoad(orderResponse)
							setOrderInfo({
								isSaved: orderResponse.isSaved,
								id: orderResponse.shipment?.id || orderResponse.payload.id,
								entity: orderResponse?.shipment ? 'shipment' : 'payload',
							})
							setPickupAddressForm(mappedOrder.pickUpAddress)
							setDeliveryAddressForm(mappedOrder.deliveryAddress)
							setAccessorialsForm(mappedOrder.accessorials)
							setPackagesForm(mappedOrder.packages)
							setBillToForm(mappedOrder.billTo)
							setOrderPickTicketNumber(mappedOrder.pickTicketNumber)
							setAutoRateCarrier(mappedOrder.autoRateCarrier)
							setAutoRateService(mappedOrder.autoRateService)
						}}
						isLoadingOrder={isLoadingOrder}
						setIsLoadingOrder={setIsLoadingOrder}
					/>
				</Col>

				<Col
					xs={9}
					className={`${classes.newQuoteParcelCol} ${classes.newQuoteParcelLoadOrderInput} ${classes.newQuoteParcelColNoPaddingRight}`}
				>
					<BillToDropdown
						value={billToForm.billToParty}
						onUpdate={(billToParty) => {
							setBillToForm(Object.assign({}, billToForm, { billToParty }))
						}}
						isReadOnly={isLoadingOrder}
					/>
					<OverridePrinterLocationDropDown
						onUpdate={(printerLocation) => {
							sosUser.updatePrinterScaleId(printerLocation, true)
						}}
						isReadOnly={isLoadingOrder}
						printers={printerList}
					/>
					<OverrideScaleLocationDropDown
						onUpdate={(scaleLocation) => {
							sosUser.updatePrinterScaleId(scaleLocation, false)

							const scale = printNodeScalesList.find(
								(scale) => scale.computerId === scaleLocation,
							)

							setPrintNodeScale(scale)
						}}
						isReadOnly={isLoadingOrder}
						scales={printNodeScalesList}
					/>
				</Col>
			</Row>
			<Spacer />
			<Row className={classes.newQuoteParcelRow}>
				<Col
					xs={4}
					className={`${classes.newQuoteParcelCol} ${classes.newQuoteParcelColNoPaddingRight}`}
				>
					<Card
						title={t('pickup', tPrefix)}
						color={'darkBlue'}
						fillHeight={true}
						testId={'new-quote-parcel-pickup-address-card'}
					>
						<div data-testid={'new-quote-parcel-pickup-address-form'}>
							<NewQuoteParcelAddress
								addressForm={pickupAddressForm}
								onChangeAddress={setPickupAddressForm}
								stopType={'pickup'}
								isReadOnly={isLoadingOrder}
								setLocationId={setLocationId}
							/>
						</div>
					</Card>
				</Col>
				<Col
					xs={4}
					className={`${classes.newQuoteParcelCol} ${classes.newQuoteParcelColNoPaddingRight}`}
				>
					<Card
						title={t('delivery', tPrefix)}
						color={'darkBlue'}
						fillHeight={true}
						testId={'new-quote-parcel-delivery-address-card'}
					>
						<div data-testid={'new-quote-parcel-delivery-address-form'}>
							<NewQuoteParcelAddress
								addressForm={deliveryAddressForm}
								onChangeAddress={setDeliveryAddressForm}
								stopType={'delivery'}
								isReadOnly={isLoadingOrder}
								setLocationId={setLocationId}
							/>
						</div>
					</Card>
				</Col>
				<Col xs={4} className={classes.newQuoteParcelCol}>
					<NewQuoteAccessorial
						accesorialForm={accessorialsForm}
						setAccessorialsForm={setAccessorialsForm}
						isReadOnly={isLoadingOrder}
					/>
				</Col>
			</Row>
			<Spacer />
			<Row className={classes.newQuoteParcelRow}>
				<Col>
					<NewQuotePackages
						packagesForm={packagesForm}
						setPackagesForm={setPackagesForm}
						isReadOnly={isLoadingOrder}
						printNodeScale={printNodeScale}
					/>
				</Col>
			</Row>
			<Spacer />
			<Row className={classes.newQuoteParcelRow}>
				<Col>
					{billToForm.billToParty !== 'shipper' && (
						<BillToCard
							billTo={billToForm.billToParty}
							form={billToForm}
							onUpdate={setBillToForm}
						/>
					)}
				</Col>
			</Row>
			<div className='d-flex flex-row-reverse bd-highlight'>
				<OkCancelButtons
					ok={
						bookOnCreate
							? tString('bookWithCustomerPreferredRouting', tPrefix)
							: tString('getQuotes', tPrefix)
					}
					onOk={getQuotes}
					okColor={'green'}
					isValid={pageErrors.length === 0 && !isLoadingOrder}
					errors={pageErrors}
					isSpinning={isLoading}
					okTestId={
						bookOnCreate
							? 'new-quote-parcel-book-with-customer-preferred-routing'
							: 'new-quote-parcel-get-quotes'
					}
				/>
			</div>
		</div>
	)
}
