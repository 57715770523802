import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { Modal } from 'ui/components/common/modal/Modal'
import { apiBroker, apiProviderInvoice, apiTypes } from 'ui/api'
import { sosToast } from 'ui/common/components/toast'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Input } from 'ui/components/common/input'
import { Loader } from 'ui/components/common/loader'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { CalendarInput } from 'ui/components/shared/ShipmentStopCard'
import { FC } from 'ui/FunctionalComponent'
import { fireAndForget } from 'ui/lib/async'
import * as classes from './ProviderInvoiceTitle.module.scss'
import { AsyncTypeahead, TypeaheadOption } from 'ui/common/components/typeahead'

export const ProviderInvoiceTitle: FC = (props: {
	shipmentId: string
	providerInvoice: apiTypes.ProviderInvoiceResponse
	fetchProviderInvoices: () => {}
	tPrefix?: string
}) => {
	const { shipmentId, providerInvoice, fetchProviderInvoices, tPrefix } = props

	const [isFetchingProviders, setIsFetchingProviders] = useState<boolean>(false)
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
	const [isDeletingInvoide, setIsDeletingInvoice] = useState<boolean>(false)

	const [selectedProviderName, setSelectedProviderName] = useState<string>('')

	const [providerResponse, setProviderResponse] = useState<
		apiTypes.ProviderName
	>(null)

	const [providerInvoiceNumber, setProviderInvoiceNumber] = useState<string>(
		providerInvoice.invoiceNumber,
	)
	const [providerInvoiceDate, setProviderInvoiceDate] = useState<string>('')

	const [editProviderInvoiceHeader, setEditProviderInvoiceHeader] = useState<
		boolean
	>(false)

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)

	const convertDate = (date: string): string => {
		const dateArray = date.split('-')
		const newDate = dateArray[1] + '-' + dateArray[2] + '-' + dateArray[0]
		return newDate
	}

	const getProviderNameByShipmentId = async (): Promise<void> => {
		setIsFetchingProviders(true)

		const shipmentResponse = await apiBroker.fetchBrokerShipment(() => {},
		shipmentId)

		if (shipmentResponse.error) {
			sosToast.sendApiErrorResponseToast(shipmentResponse)
		} else {
			setProviderResponse(shipmentResponse.data.bookedRate?.providerName)
		}

		setIsFetchingProviders(false)
	}

	const updateProviderInvoice = async (): Promise<void> => {
		setIsLoading(true)

		const updatedProviderInvoice = {
			...providerInvoice,
			invoiceNumber: providerInvoiceNumber,
			invoiceDate: providerInvoiceDate,
		}

		const providerInvoiceResponse = await apiProviderInvoice.updateProviderInvoice(
			providerInvoice.id,
			updatedProviderInvoice,
		)

		if (providerInvoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(providerInvoiceResponse)
		} else {
			fetchProviderInvoices()
		}

		setIsLoading(false)
	}

	const deleteProviderInvoice = async (): Promise<void> => {
		setIsDeletingInvoice(true)

		const providerInvoiceResponse = await apiProviderInvoice.deleteProviderInvoice(
			() => {},
			providerInvoice.id,
		)

		if (providerInvoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(providerInvoiceResponse)
		} else {
			fetchProviderInvoices()
		}

		setIsDeletingInvoice(false)
		setIsDeleteModalOpen(false)
	}

	useEffect(() => {
		if (providerInvoice.invoiceDate) {
			const newDate = convertDate(providerInvoice.invoiceDate)
			setProviderInvoiceDate(newDate)
		}
	}, [providerInvoice])

	useEffect(() => {
		fireAndForget(
			async () => getProviderNameByShipmentId(),
			`Get ProviderName By ShipmentId`,
		)
	}, [shipmentId]) // eslint-disable-line react-hooks/exhaustive-deps

	let providerNameTypehead: TypeaheadOption

	if (selectedProviderName !== null) {
		providerNameTypehead = {
			label: selectedProviderName,
			value: selectedProviderName,
		}
	}

	return (
		<div className={classes.providerInvoiceTitle}>
			<Table>
				<tbody>
					<tr>
						<td>
							{isFetchingProviders ? (
								<Loader isLoading={isFetchingProviders} />
							) : (
								<AsyncTypeahead
									testId={'cutomer-invoice-providerName-input'}
									size={'small'}
									options={[]}
									onSearch={async () => {
										let responseOptions: TypeaheadOption[] = []

										responseOptions = [
											{
												value: providerResponse,
												label: providerResponse,
											},
										]

										return responseOptions
									}}
									onChange={(selected: TypeaheadOption) => {
										if (providerResponse === selected?.value) {
											setSelectedProviderName(selected?.value)
										}
									}}
									isClearable={true}
									useCache={true}
									disabled={!editProviderInvoiceHeader}
									value={providerNameTypehead}
									placeholder={providerResponse}
									className={classes.providerName}
								/>
							)}
						</td>
						<td>
							<Input
								value={`${tString('invoiceNumber', tPrefix)}:`}
								readOnly={true}
								className={classes.disabledInput}
							/>
						</td>
						<td>
							<Input
								value={providerInvoiceNumber}
								onChange={(newVal: string) => setProviderInvoiceNumber(newVal)}
								autofocus={editProviderInvoiceHeader}
								readOnly={!editProviderInvoiceHeader}
								className={classes.invoiceNumber}
							/>
						</td>
						<td>
							<Input
								value={`${tString('dueDate', tPrefix)}:`}
								readOnly={true}
								className={classes.disabledInput}
							/>
						</td>
						<td>
							<CalendarInput
								dateString={providerInvoiceDate}
								isOpen={isCalendarOpen}
								onChangeText={(newVal: string) => {
									setIsCalendarOpen(false)

									const newDate = convertDate(newVal)
									setProviderInvoiceDate(newDate)
								}}
								onChangeOpen={(isOpen: boolean) => {
									setIsCalendarOpen(!isCalendarOpen)
								}}
								readOnly={!editProviderInvoiceHeader}
								hasToday={false}
							/>
						</td>
						<td>
							<div className={classes.iconButtons}>
								{providerInvoice.invoiceStatus === 'review' && (
									<IconButton
										icon={
											editProviderInvoiceHeader
												? solidIcons.faCheck
												: solidIcons.faPencilAlt
										}
										buttonClassName={
											editProviderInvoiceHeader ? classes.save : classes.edit
										}
										color={editProviderInvoiceHeader ? 'green' : 'black'}
										onClick={async () => {
											if (editProviderInvoiceHeader) {
												await updateProviderInvoice()
												setEditProviderInvoiceHeader(false)
											} else {
												setEditProviderInvoiceHeader(true)
											}
										}}
										spin={isLoading}
										disabled={isDeleteModalOpen}
										testId={'provider-invoice-header-edit-save'}
									></IconButton>
								)}

								<IconButton
									icon={solidIcons.faTimes}
									buttonClassName={classes.cancel}
									color={'red'}
									onClick={async () => {
										if (editProviderInvoiceHeader) {
											setEditProviderInvoiceHeader(false)
										} else {
											setIsDeleteModalOpen(true)
										}
									}}
									testId={'provider-invoice-header-cancel'}
								></IconButton>
							</div>
						</td>
						<td></td>
					</tr>
				</tbody>
			</Table>
			<Modal
				content={() => (
					<div data-testid={'provider-invoice-delete-modal'}>
						<p>{t('deleteThisInvoice?', tPrefix)}</p>
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'provider-invoice-delete-modal-ok'}
								isSpinning={isDeletingInvoide}
								onOk={async () => {
									await deleteProviderInvoice()
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => {
									setIsDeleteModalOpen(false)
								}}
								cancelTestId={'provider-invoice-delete-modal-cancel'}
							></OkCancelButtons>
						</AlignRight>
					</div>
				)}
				isOpen={isDeleteModalOpen}
				onModalClose={() => {}}
				title={t('confirmDelete', tPrefix)}
			/>
		</div>
	)
}
