import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiTypes, apiPrintNode } from 'ui/api'
import { Button } from 'ui/components/common/button'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { t } from 'ui/components/i18n/i18n'
import { Center } from 'ui/components/layout/center'
import { l } from 'ui/lib/lodashImports'
import { AddPrinterModal } from '../add-printer-modal'
import { PrinterConfigModal } from '../printer-config-modal'
import * as classes from '../PrinterScalesListTable.module.scss'
import { Spacer } from 'ui/components/layout/spacer'
import { useOnce } from 'ui/components/hooks'
import { fireAndForget } from 'ui/lib/async'

export enum StatusEnum {
	online = 'green',
	offline = 'gray',
	error = 'red',
	out_of_paper = 'orange',
	idle = 'yellow',
	undefined = 'gray',
}

export const tPrefix = 'page.companyManagement.printersAndScales.tabs.printer'

export const PrinterListTable: FC = (props: {
	printerList: apiPrintNode.UIPrinterResponse[]
	setPrinterList: React.Dispatch<
		React.SetStateAction<apiPrintNode.UIPrinterResponse[]>
	>
	locationId: string
	userCredentials: apiTypes.PrintNodeCredentialsResponse
}) => {
	const [showOfflinePrinters, setShowOfflinePrinters] = useState<boolean>(false)
	const [isScanningPrintNode, setIsScanningPrintNode] = useState<boolean>(false)
	const [idOfPrinterToTest, setIdOfPrinterToTest] = useState<string>(null)
	const [isPrinterConfigModalOpen, setIsPrinterConfigModalOpen] = useState<
		boolean
	>(false)
	const [selectedPrinterConfig, setSelectedPrinterConfig] = useState<
		apiTypes.PrinterResponse
	>(null)
	const [isAddPrinterModalOpen, setIsAddPrinterModalOpen] = useState<boolean>(
		false,
	)

	useOnce(() => {
		fireAndForget(scanPrintNode, 'Fetching Printer List')
	})

	const setPrintersList = (
		printerListResponse: apiTypes.PrinterResponse[],
		printNodeApiResponse: apiPrintNode.PrintNodeAPIResponse[],
	): void => {
		const printers = printerListResponse.map(
			(printer: apiTypes.PrinterResponse) => ({
				...printer,
				state: printNodeApiResponse.find(
					(printNodePrinter) => printNodePrinter.id === +printer.printNodeId,
				)?.state,
			}),
		)

		props.setPrinterList(printers)
	}

	const scanPrintNode = async (): Promise<void> => {
		setIsScanningPrintNode(true)

		const printerListResponse = await apiPrintNode.getPrinterList(() => {},
		props.userCredentials.id)

		if (printerListResponse.data) {
			const printNodeApiResponse = await apiPrintNode.getPrinterListFromPrintNodeAPI(
				props.userCredentials,
			)

			if (printNodeApiResponse.data) {
				setPrintersList(printerListResponse.data, printNodeApiResponse.data)

				const printNodePrinters = printNodeApiResponse.data.filter(
					(printNodePrinter) =>
						!printerListResponse.data.some(
							(swanLeapPrinter) =>
								printNodePrinter.id === +swanLeapPrinter.printNodeId,
						),
				)

				if (printNodePrinters.length > 0) {
					const printNodePrintersResponse = await Promise.all(
						printNodePrinters.map((printNodePrinter) => {
							let printerType: apiTypes.printerType

							if (
								printNodePrinter.capabilities.dpis.some((dpi) =>
									dpi.match('203x203'),
								)
							) {
								printerType = 'label'
							} else {
								printerType = 'office'
							}

							const printerListRequest: apiPrintNode.UIPrinterRequest = {
								nickname: printNodePrinter.name,
								printNodeId: printNodePrinter.id.toString(),
								paperType: 'a4', // DEFAULT FOR NOW
								printerType: printerType,
								locationId: props.locationId,
								computerName: printNodePrinter.computer.name,
								useDocTab: false, // DEFAULT FOR NOW
								state: printNodePrinter.state,
							}

							return apiPrintNode.createPrinter(
								null,
								printerListRequest,
								props.userCredentials.id,
							)
						}),
					)

					if (printNodePrintersResponse) {
						const printerListResponse = await apiPrintNode.getPrinterList(
							() => {},
							props.userCredentials.id,
						)

						if (printerListResponse.data) {
							setPrintersList(
								printerListResponse.data,
								printNodeApiResponse.data,
							)
						}
					}
				}
			} else if (printNodeApiResponse.error) {
				sosToast.sendApiErrorResponseToast(printNodeApiResponse)
			}
		} else if (printerListResponse.error) {
			sosToast.sendApiErrorResponseToast(printerListResponse)
		}

		setIsScanningPrintNode(false)
	}

	const testPrinter = (id: string): void => {
		setIdOfPrinterToTest(id)

		setTimeout(() => {
			setIdOfPrinterToTest(null)
		}, 2000)
	}

	const filteredPrinters = props.printerList.filter(
		(printer) => printer.state === 'online' || showOfflinePrinters,
	)

	return (
		<div
			className={classes.printersListScalesTable}
			data-testid={'printer-list-container'}
		>
			<div className={classes.printerScaleListButtonContainer}>
				{/* <Button
					color={'blue'}
					isSpinning={false}
					testId={'printer-list-add-printer'}
					onClick={() => {
						setIsAddPrinterModalOpen(true)
					}}
				>
					{t('addPrinter', tPrefix)}
				</Button> */}
				<Button
					color={'blue'}
					isSpinning={false}
					testId={'printer-list-toggle-offline-printers'}
					onClick={() => {
						setShowOfflinePrinters(!showOfflinePrinters)
					}}
				>
					{showOfflinePrinters
						? t('hideOfflinePrinters', tPrefix)
						: t('showOfflinePrinters', tPrefix)}
				</Button>
				<Button
					color={'green'}
					isSpinning={isScanningPrintNode}
					testId={'printer-list-scan-for-printer-changes'}
					onClick={scanPrintNode}
				>
					{t('scanPrinterChanges', tPrefix)}
				</Button>
			</div>
			<table
				className={classes.printerScalesTable}
				cellPadding={0}
				cellSpacing={0}
			>
				<thead>
					<tr>
						<th>{t('online', tPrefix)}</th>
						<th>{t('computerName', tPrefix)}</th>
						<th>{t('deviceName', tPrefix)}</th>
						<th>{t('printNodeId', tPrefix)}</th>
						<th>{t('printerType', tPrefix)}</th>
						<th>{t('configuration', tPrefix)}</th>
					</tr>
				</thead>
				<tbody>
					{l.map(filteredPrinters, (printer, idx) => {
						const color = StatusEnum[printer.state]

						return (
							<tr
								key={printer.printNodeId}
								data-testid={`printerListDataTable-${idx}`}
							>
								<td>
									<Center>
										<Icon
											color={color}
											icon={solidIcons.faCircle}
											className={classes.onlineIndicator}
										/>
									</Center>
								</td>
								<td>{printer.computerName}</td>
								<td>{printer.nickname}</td>
								<td>{printer.printNodeId}</td>
								<td>{printer.printerType}</td>
								<td>
									<div>
										<Button
											color={'blue'}
											isSpinning={printer.printNodeId === idOfPrinterToTest}
											testId={'printer-list-test'}
											onClick={() => {
												testPrinter(printer.printNodeId)
											}}
										>
											{t('test', tPrefix)}
										</Button>

										<Button
											color={'blue'}
											isSpinning={false}
											testId={'printer-list-printer-config'}
											onClick={() => {
												setSelectedPrinterConfig(printer)
												setIsPrinterConfigModalOpen(true)
											}}
										>
											{t('printerConfig', tPrefix)}
										</Button>
									</div>
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>

			<Spacer height={'30px'} />

			<PrinterConfigModal
				isModalOpen={isPrinterConfigModalOpen}
				onModalClose={(printer: apiTypes.PrinterResponse) => {
					if (printer) {
						const idx = props.printerList.findIndex(
							(item) => item.id === printer.id,
						)
						const printers = [...props.printerList]
						printers[idx] = printer
						props.setPrinterList(printers)
					}
					setIsPrinterConfigModalOpen(false)
				}}
				selectedPrinterConfig={selectedPrinterConfig}
				locationId={props.locationId}
				userCredentials={props.userCredentials}
			/>

			<AddPrinterModal
				isModalOpen={isAddPrinterModalOpen}
				onModalClose={() => {
					setIsAddPrinterModalOpen(false)
				}}
				printerList={props.printerList}
				setPrinterList={props.setPrinterList}
				locationId={props.locationId}
				credentialsId={props.userCredentials.id}
			/>
		</div>
	)
}
