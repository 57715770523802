import React, { useEffect, useState } from 'react'
import { apiTypes } from 'ui/api'
import { FC } from 'ui/FunctionalComponent'
import { Card } from 'ui/components/common/card'
import { t, tCurrency, tCurrencyFromCents } from 'ui/components/i18n/i18n'
import classes from './ReferenceInformation.module.scss'

interface ReferenceInformation {
	brokerOffer: string
	providerQuote: string
	providerInvoice: string
	shipmentId: string
}

const defaultReferenceInformationData: ReferenceInformation = {
	brokerOffer: '',
	providerQuote: '',
	providerInvoice: '',
	shipmentId: '-',
}

const tPrefix = 'page.customerInvoice.referenceInformation'
const currencyInitial = ' USD'

export const ReferenceInformation: FC = (props: {
	shipmentId?: string
	brokerShipment: apiTypes.BrokerShipmentResponse
	totalCost: number // THIS IS TEMPORARY ONLY AS PER TICKET TMS-3913
}) => {
	const { brokerShipment, totalCost } = props
	const [referenceInformationData, setReferenceInformationData] = useState<
		ReferenceInformation
	>(defaultReferenceInformationData)

	useEffect(() => {
		if (brokerShipment) {
			console.log(brokerShipment?.offers[0]?.flatOffer?.grossRate)
			setReferenceInformationData({
				brokerOffer:
					tCurrencyFromCents(
						brokerShipment?.offers[0]?.flatOffer?.grossRate || 0,
					) + currencyInitial,
				providerQuote:
					tCurrencyFromCents(brokerShipment?.bookedRate?.costTotal) +
					currencyInitial,
				providerInvoice: tCurrency(totalCost) + currencyInitial,
				shipmentId: brokerShipment?.identifier,
			})
		}
	}, [brokerShipment, totalCost])

	return (
		<Card
			title={t('referenceInformation', tPrefix)}
			color={'darkBlue'}
			testId={'customer-invoice-reference-information'}
		>
			<div className={classes.referenceInformationTable}>
				<table>
					<tbody>
						<tr>
							<th className={classes.referenceInformationLabel}>
								{t('brokerOffer', tPrefix)}:
							</th>
							<td>{referenceInformationData.brokerOffer}</td>
						</tr>
						<tr>
							<th className={classes.referenceInformationLabel}>
								{t('providerQuote', tPrefix)}:
							</th>
							<td>{referenceInformationData.providerQuote}</td>
						</tr>
						<tr>
							<th className={classes.referenceInformationLabel}>
								{t('providerInvoice', tPrefix)}:
							</th>
							<td>{referenceInformationData.providerInvoice}</td>
						</tr>
					</tbody>
				</table>
				<table>
					<tbody>
						<tr>
							<th className={classes.referenceInformationLabel}>
								{t('shipmentId', tPrefix)}:
							</th>
							<td>{referenceInformationData.shipmentId}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Card>
	)
}
