import { FC } from 'app/FunctionalComponent'
import { apiPrintNode } from 'ui/api'
import { sosToast } from 'common/components/toast'
import React, { useState } from 'react'
import {
	getPackageCatalogEntries,
	getPackageCatalogs,
} from 'ui/api/apiPackageCatalog'
import { PackagePageResponse } from 'ui/api/apiTypes'
import { Button } from 'ui/components/common/button'
import { Card } from 'ui/components/common/card'
import { IconButton } from 'ui/components/common/icon/Icon'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { Modal } from 'ui/components/common/modal/Modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedItem,
	FormStackedSelect,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { emptyPackageForm, NewQuotePackageForm } from '../newQuoteForms'
import * as classes from './NewQuotePackages.module.scss'
import { NewQuotePackagingCatalog } from './NewQuotePackagingModal'

const tPrefix = 'page.newQuote.parcel.newQuotePackageForm'

export const NewQuotePackages: FC = (props: {
	packagesForm: NewQuotePackageForm[]
	setPackagesForm: React.Dispatch<React.SetStateAction<NewQuotePackageForm[]>>
	isReadOnly?: boolean
	printNodeScale: apiPrintNode.PrintNodeScalesResponse
}) => {
	const { packagesForm, setPackagesForm, isReadOnly, printNodeScale } = props
	const [indexOfRowToDelete, setIndexOfRowToDelete] = useState<number>(null)
	const [bookId, setBookId] = useState<string>(null)
	const [packagingPages, setPackagingPages] = useState<PackagePageResponse[]>(
		null,
	)
	const [
		isPackagingCatalogModalOpen,
		setIsPackagingCatalogModalOpen,
	] = useState<boolean>(false)

	let formData: IFormData<NewQuotePackageForm>

	const fetchPackageCatalogs = (): void => {
		fireAndForget(async () => {
			const packageCatalogs = await getPackageCatalogs(() => {})

			if (packageCatalogs.data[0]) {
				setBookId(packageCatalogs.data[0].id)

				const packageCatalogEntries = await getPackageCatalogEntries(
					packageCatalogs.data[0].id,
					() => {},
				)

				if (packageCatalogEntries.data) {
					const packageCatalogEntriesData = packageCatalogEntries.data
					setPackagingPages(packageCatalogEntriesData)
				} else if (packageCatalogEntries.error) {
					sosToast.sendApiErrorResponseToast(packageCatalogEntries)
				}
			} else if (packageCatalogs.error) {
				sosToast.sendApiErrorResponseToast(packageCatalogs)
			}
		}, 'FETCHING PACKAGING PAGES')
	}

	useOnce(async () => {
		fetchPackageCatalogs()
	})

	return (
		<div data-test-id={'new-quote-package-container'}>
			<NewQuotePackagingCatalog
				bookId={bookId}
				packagingPages={packagingPages}
				setPackagingPages={setPackagingPages}
				fetchPackageCatalogs={fetchPackageCatalogs}
				isModalOpen={isPackagingCatalogModalOpen}
				onModalClose={() => {
					fetchPackageCatalogs()
					setIsPackagingCatalogModalOpen(false)
				}}
			/>
			<Modal
				content={() => (
					<div
						className={classes.confirmDeleteModal}
						data-testid={'new-quote-package-delete-modal'}
					>
						<p>{t('deleteThisPackage?', tPrefix)}</p>
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'new-quote-package-delete-modal-ok'}
								onOk={() => {
									const retrievedPackages = l.cloneDeep(packagesForm)
									retrievedPackages.splice(indexOfRowToDelete, 1)
									setPackagesForm(retrievedPackages)
									setIndexOfRowToDelete(null)
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => {
									setIndexOfRowToDelete(null)
								}}
								cancelTestId={'new-quote-package-delete-modal-cancel'}
							></OkCancelButtons>
						</AlignRight>
					</div>
				)}
				isOpen={!l.isNil(indexOfRowToDelete)}
				onModalClose={() => {}}
				title={t('confirmDelete', tPrefix)}
			/>

			<Card
				title={
					<div className={classes.titleButtonContainer}>
						{t('packages', tPrefix)}
						<div className={classes.buttonContainer}>
							<Button
								color={'lightBlue'}
								testId={'new-quote-packaging-catalog-open'}
								onClick={() => {
									setIsPackagingCatalogModalOpen(true)
								}}
								isDisabled={isReadOnly}
							>
								{t('packagingCatalog', tPrefix)}
							</Button>
							<Button
								color={'red'}
								testId={'new-quote-package-add'}
								onClick={() => {
									const newPackage = l.cloneDeep(emptyPackageForm)
									const updatedPackages = l.cloneDeep(packagesForm)

									updatedPackages.push(newPackage)
									setPackagesForm(updatedPackages)
								}}
								isDisabled={isReadOnly}
							>
								{t('addPackage', tPrefix)}
							</Button>
						</div>
					</div>
				}
				color={'darkBlue'}
				testId={'new-quote-parcel-packages-card'}
			>
				{l.map(packagesForm, (packageRow, idx) => {
					formData = {
						form: packageRow,
						metadata: {
							quantity: {},
							reference1: {},
							reference2: {},
							declaredValue: {},
							packagePageId: {
								options:
									packagingPages?.map((type) => {
										return {
											label: type.name,
											value: type.id,
										}
									}) || [],
							},
							length: {},
							width: {},
							height: {},
							weight: {},
						},

						onUpdateForm: (field: string, value: any) => {
							const retrievedPackages = l.cloneDeep(packagesForm)

							const updatedPackage = packageRow
							updatedPackage[field] = value

							if (field === 'packagePageId') {
								const packagingPage = l.find(
									packagingPages,
									(page) => page.id === updatedPackage.packagePageId,
								)

								if (!l.isNil(packagingPage)) {
									updatedPackage['length'] = packagingPage.length
									updatedPackage['width'] = packagingPage.width
									updatedPackage['height'] = packagingPage.height
								}
							}

							retrievedPackages.splice(idx, 1, updatedPackage)
							setPackagesForm(retrievedPackages)
						},
						tPrefix,
					}

					return (
						<div
							data-testid={'new-quote-package-list'}
							key={idx}
							className={classes.flexContainer}
						>
							<FormStackedTextInput
								formData={formData}
								field={'quantity'}
								hideOptional={true}
								className={classes.medInputBox}
								testId={`new-quote-packages-quantity-${idx}`}
								readOnly={isReadOnly}
							/>
							<FormStackedTextInput
								formData={formData}
								field={'reference1'}
								hideOptional={true}
								className={classes.medInputBox}
								testId={`new-quote-packages-reference1-${idx}`}
								readOnly={isReadOnly}
							/>
							<FormStackedTextInput
								formData={formData}
								field={'reference2'}
								hideOptional={true}
								className={classes.medInputBox}
								testId={`new-quote-packages-reference2-${idx}`}
								readOnly={isReadOnly}
							/>
							<FormStackedTextInput
								formData={formData}
								field={'declaredValue'}
								hideOptional={true}
								className={classes.medInputBox}
								testId={`new-quote-packages-declaredValue-${idx}`}
								readOnly={isReadOnly}
							/>
							<FormStackedSelect
								formData={formData}
								field={'packagePageId'}
								numListOptionsBeforeScroll={3}
								hideOptional={true}
								className={classes.medInputBox}
								testId={`new-quote-packages-packagePageId-${idx}`}
								readOnly={isReadOnly}
							/>
							<FormStackedItem
								formData={formData}
								field={'length'}
								hideOptional={true}
							>
								<div className={classes.flexContainer}>
									<FormStackedTextInput
										formData={formData}
										field={'length'}
										hideOptional={true}
										label={' '}
										className={classes.smlInputBox}
										testId={`new-quote-packages-length-${idx}`}
										readOnly={isReadOnly}
									/>
									<FormStackedTextInput
										formData={formData}
										field={'width'}
										hideOptional={true}
										label={' '}
										className={classes.smlInputBox}
										testId={`new-quote-packages-width-${idx}`}
										readOnly={isReadOnly}
									/>
									<FormStackedTextInput
										formData={formData}
										field={'height'}
										hideOptional={true}
										label={' '}
										className={classes.smlInputBox}
										testId={`new-quote-packages-height-${idx}`}
										readOnly={isReadOnly}
									/>
								</div>
							</FormStackedItem>
							<FormStackedTextInput
								formData={formData}
								field={'weight'}
								hideOptional={true}
								className={classes.medInputBox}
								testId={`new-quote-packages-weight-${idx}`}
								readOnly={isReadOnly}
							/>
							<div className={classes.flexContainer}>
								<IconButton
									icon={solidIcons.faBalanceScale}
									color={'blue'}
									onClick={() => {
										const printNodeScaleMeasurement =
											printNodeScale?.measurement

										const divideBy = 1000000000

										const lb = printNodeScaleMeasurement?.lb / divideBy
										const oz = printNodeScaleMeasurement?.oz / divideBy / 16
										const grams = printNodeScaleMeasurement?.g / divideBy / 454
										const kiloGrams =
											printNodeScaleMeasurement?.kg / divideBy / 2.205

										const printNodeScaleWeight = lb || oz || grams || kiloGrams

										const updatedPackage = {
											...packageRow,
											weight: printNodeScaleWeight,
										}

										const retrievedPackages = l.cloneDeep(packagesForm)
										retrievedPackages.splice(idx, 1, updatedPackage)
										setPackagesForm(retrievedPackages)
									}}
									large={true}
									iconClassName={classes.iconButtonContainer}
									testId={'new-quote-package-modal-scale'}
								/>
								<IconButton
									icon={solidIcons.faTrash}
									color={'red'}
									onClick={() => {
										setIndexOfRowToDelete(idx)
									}}
									large={true}
									iconClassName={classes.iconButtonContainer}
									disabled={packagesForm.length === 1}
									testId={'new-quote-package-delete-button'}
								/>
							</div>
						</div>
					)
				})}
			</Card>
		</div>
	)
}
