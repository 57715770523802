import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { ClientSelector } from 'ui/components/broker'
import { Card } from 'ui/components/common/card'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { Debug } from 'ui/components/dev'
import { StackedItem } from 'ui/components/form'
import { useOnce, watch } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { Accessorials } from 'ui/components/shared/accessorials'
import { ShipmentStopCard } from 'ui/components/shared/ShipmentStopCard'
import { sos2 } from 'ui/lib/state/sos2'
import {
	deliveryAccessorials,
	pickupAccessorials,
} from 'ui/pages/new-quote/accessorials'
import classes from 'ui/pages/new-quote/newQuotePage.module.scss'
import { IStateNewQuote } from 'ui/pages/new-quote/state/sosNewQuote'
import { sosUser } from 'ui/state'
import { BillTo, FormDetails } from '.'
import { CommoditiesForm } from './CommoditiesForm'
import { sosNewQuote } from './state'

// import { BrokerOfferSell } from './BrokerOfferSell'
// import { PackingSlipUpload } from 'ui/components/shared'

const tPrefix = 'page.newQuote'

export const NewQuoteFreight: FC = (props: {
	state: IStateNewQuote
	isEditing?: boolean
}) => {
	const { state } = props

	const userState = sos2.useSubscription(sosUser.getSos())
	const [validationErrors, setValidationErrors] = useState<string[]>([])
	const [activeValidation, setActiveValidation] = useState(false)

	useOnce(() => {
		sosNewQuote.resetState()
		sosNewQuote.setCompanyDefaults()
	})

	watch(() => {
		if (activeValidation) {
			setValidationErrors(sosNewQuote.validateShipment())
		}
	}, [state])

	const showNewQuote =
		!sosUser.isUserBroker() ||
		(userState.selectedClientConfig && userState.selectedClientConfig.id)

	return (
		<div>
			{sosUser.isUserBroker() && (
				<div className={classes.clientSelector}>
					<StackedItem
						label={t('selectClientContract', tPrefix)}
						showRequired={true}
					>
						<ClientSelector isClearable={false} readOnly={props.isEditing} />
					</StackedItem>
				</div>
			)}
			{showNewQuote && (
				<div>
					<div className={classes.shipmentCardNewQuote}>
						<ShipmentStopCard
							mode='pickup'
							stopId={state.stops[0].stopId}
							stopCardState={{
								filters: ['companyLocation', 'customAddress', 'addressBook'],
								updateAddressFilter: sosNewQuote.selectAddressFilter,
								addressState: state.stops[0].addressState,
								updateAddress: sosNewQuote.updateStopByAddressId,
								addressSaving: state.isSubmitting,
								saveAddress: sosNewQuote.saveAddress, //TODO: Change this when TMS address book saving is functional
								showSaveAddress: sosUser.isUserBroker(),
								onSelectLocation: sosNewQuote.onSelectLocation,
								specialInstructions: state.stops[0].specialInstructions,
								updateSpecialInstructions: sosNewQuote.updateStopInstructions,
								dateTimeDesiredState: state.stops[0].dateTimeDesiredState,
								updateDateTimeDesired: sosNewQuote.updateDateTimeDesired,
							}}
							accessorials={
								<div className={classes.shipmentStopCardAccessorialsNewQuote}>
									<Accessorials
										metadata={pickupAccessorials}
										accessorialObject={state.stops[0].addressState.accessorials}
										updateState={sosNewQuote.updateStopAccessorials}
										tPrefix='page.newQuote.pickupAccessorials'
										stopId={state.stops[0].stopId}
										mode='pickup'
									/>
								</div>
							}
							locationsSource='companyLocations'
						/>
					</div>
					<Spacer />
					<div>
						<div className={classes.shipmentCardNewQuote}>
							<ShipmentStopCard
								mode='delivery'
								stopId={state.stops[1].stopId}
								stopCardState={{
									filters: ['companyLocation', 'customAddress', 'addressBook'],
									updateAddressFilter: sosNewQuote.selectAddressFilter,
									addressState: state.stops[1].addressState,
									updateAddress: sosNewQuote.updateAddress,
									addressSaving: state.isSubmitting,
									saveAddress: sosNewQuote.saveAddress,
									showSaveAddress: sosUser.isUserBroker(),
									onSelectLocation: sosNewQuote.onSelectLocation,
									specialInstructions: state.stops[1].specialInstructions,
									updateSpecialInstructions: sosNewQuote.updateStopInstructions,
									dateTimeDesiredState: state.stops[1].dateTimeDesiredState,
									updateDateTimeDesired: sosNewQuote.updateDateTimeDesired,
								}}
								accessorials={
									<div className={classes.shipmentStopCardAccessorialsNewQuote}>
										<Accessorials
											metadata={deliveryAccessorials}
											accessorialObject={
												state.stops[1].addressState.accessorials
											}
											updateState={sosNewQuote.updateStopAccessorials}
											tPrefix='page.newQuote.deliveryAccessorials'
											stopId={state.stops[1].stopId}
											mode='delivery'
										/>
									</div>
								}
								locationsSource='companyLocations'
							/>
						</div>
					</div>
					<Spacer />
					<div className={classes.shipmentCardNewQuote}>
						{/* <CommoditiesAdvanced /> */}
						<CommoditiesForm
							commodities={state.commodities}
							linearFeet={state.linearFeet}
						/>
					</div>
					<Spacer />
					<div className={classes.flexWrapNewQuote}>
						<div className={classes.billToNewQuoteModule}>
							<Card
								title={t('billTo', tPrefix)}
								color='darkBlue'
								fillHeight={true}
							>
								<BillTo
									state={state}
									billToLocations={sosNewQuote.getLocationsBySource(
										'companyLocations',
									)}
									onSelectLocation={sosNewQuote.onSelectLocation}
								/>
							</Card>
						</div>
						<Spacer />
						<div className={classes.detailsNewQuoteModule}>
							<Card
								title={t('page.newQuote.details')}
								color='darkBlue'
								fillHeight={true}
							>
								<FormDetails state={state} />
							</Card>
						</div>
					</div>
					{/* {sosUser.isUserBroker() && (
						<>
							<Spacer />
							<div className={classes.flexWrapNewQuote}>
								<div className={classes.brokerSellRateModule}>
									<Card
										title={t('offerDetails', tPrefix)}
										color='darkBlue'
										fillHeight={true}
									>
										<BrokerOfferSell state={state} />
									</Card>
								</div>
							</div>
						</>
					)} */}

					{/*<Spacer />*/}
					{/*<div>*/}
					{/*<PackingSlipUpload state={state} />*/}
					{/*</div>*/}
					<FlexRow>
						<div style={{ marginTop: '10px' }}>
							<OkCancelButtons
								ok={
									sosNewQuote.isInEditMode()
										? t('page.newQuote.edit.submit')
										: t('page.newQuote.getQuotes.submit')
								}
								onOk={async () => {
									const errors = sosNewQuote.validateShipment()
									setValidationErrors(errors)
									if (!errors.length) {
										await sosNewQuote.createShipmentWorkflow(activeValidation)
									} else {
										setActiveValidation(true)
									}
								}}
								okColor={'green'}
								isValid={validationErrors.length === 0}
								errors={[...validationErrors, ...state.createShipmentErrors]}
								isSpinning={state.loadingQuotes}
								okTestId='get-quotes-ok'
							/>
						</div>
						<Debug data={state} label={'State'} hide={false} />
					</FlexRow>
				</div>
			)}
		</div>
	)
}
