import { apiCommon, apiTypes } from '.'
import { IRequestState } from './requestState'

export async function updatePayload(
	id: string,
	data: apiTypes.PayloadUpdatePayloadRequest,
	onProgress: (rs: IRequestState<apiTypes.PayloadResponse>) => void,
): Promise<IRequestState<apiTypes.PayloadResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'PUT', data },
		`payloads/${id}`,
	)
	return result
}
