import React, { useEffect, useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { t } from 'ui/components/i18n/i18n'
import * as classes from './CustomerCharges.module.scss'
import { l } from 'ui/lib/lodashImports'
import { FormTextInput, IFormData } from 'ui/components/form'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Button } from 'ui/components/common/button'
import { Input } from 'ui/components/common/input'
import { apiBroker, apiBrokerInvoice, apiTypes } from 'ui/api'
import { sosToast } from 'ui/common/components/toast'
import { AsyncTypeahead, TypeaheadOption } from 'ui/common/components/typeahead'
import { Table } from 'react-bootstrap'
import { IRequestState } from 'ui/api/requestState'
import { Modal } from 'ui/components/common/modal'
import { AlignRight } from 'ui/components/layout/alignRight'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { Center } from 'ui/components/layout/center'
import { Loader } from 'ui/components/common/loader'
import { fireAndForget } from 'ui/lib/async'

const tPrefix = 'page.customerInvoice.customerCharges'

export const CustomerCharges: FC = (props: {
	isFetchingBrokerInvoices: boolean
	shipmentId: string
	brokerInvoice: apiTypes.BrokerInvoiceResponse
	brokerChargeCodes: apiTypes.ChargeCodePageResponse[]
	brokerInvoiceCharges: apiTypes.BrokerInvoiceChargeResponse[]
	setBrokerInvoiceCharges: React.Dispatch<
		React.SetStateAction<apiTypes.BrokerInvoiceChargeResponse[]>
	>
}) => {
	const {
		isFetchingBrokerInvoices,
		shipmentId,
		brokerInvoice,
		brokerChargeCodes,
		brokerInvoiceCharges,
		setBrokerInvoiceCharges,
	} = props

	const [brokerInvoiceChargeId, setBrokerInvoiceChargeId] = useState<string>(
		null,
	)

	const [brokerInvoiceChargeRequest, setBrokerInvoiceChargeRequest] = useState<
		apiTypes.BrokerInvoiceChargeRequest
	>(null)

	const [toggleChargeCode, setToggleChargeCode] = useState<boolean>(false)
	const [toggleChargeDescription, setToggleChargeDescription] = useState<
		boolean
	>(false)

	const [selectedChargeCode, setSelectedChargeCode] = useState<string>(null)
	const [selectedChargeDescription, setSelectedChargeDescription] = useState<
		string
	>(null)

	const [selectedProviderName, setSelectedProviderName] = useState<
		apiTypes.ProviderName
	>(null)

	const [isAddingCharge, setIsAddingCharge] = useState<boolean>(false)
	const [isUpdatingCharge, setIsUpdatingCharge] = useState<boolean>(false)
	const [isEditingChargeRow, setIsEditingChargeRow] = useState<number>(null)

	const [isDeletingChargeRow, setIsDeletingChargeRow] = useState<number>(null)
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
	const [isDeletingCharge, setIsDeletingCharge] = useState<boolean>(false)

	const updateBrokerInvoiceChargeForm = (
		providerInvoiceChargeResponse: IRequestState<
			apiTypes.BrokerInvoiceChargeResponse
		>,
		updateMode: 'upsert' | 'delete',
	): void => {
		const updateInvoiceChargesResponse = l.cloneDeep(brokerInvoiceCharges)

		if (updateMode === 'upsert') {
			updateInvoiceChargesResponse.splice(
				isEditingChargeRow,
				1,
				providerInvoiceChargeResponse.data,
			)
		} else {
			updateInvoiceChargesResponse.splice(isDeletingChargeRow, 1)
		}

		setBrokerInvoiceCharges(updateInvoiceChargesResponse)
	}

	const addBrokerInvoiceCharge = async (): Promise<void> => {
		const newBrokerInvoiceChargeResponse: apiTypes.BrokerInvoiceChargeResponse = {
			chargeCode: '',
			chargeDescription: '',
			qty: 1,
			unitPrice: 0,
			providerCost: 0,
			id: '',
			shipmentId: shipmentId,
			totalPrice: 0,
			providerId: '',
		}

		if (brokerInvoiceCharges) {
			brokerInvoiceCharges.unshift(newBrokerInvoiceChargeResponse)
			setBrokerInvoiceCharges(brokerInvoiceCharges)
		} else {
			setBrokerInvoiceCharges([newBrokerInvoiceChargeResponse])
		}

		await createBrokerInvoiceCharge()

		setIsEditingChargeRow(0)
	}

	const createBrokerInvoiceCharge = async (): Promise<void> => {
		setIsAddingCharge(true)

		const newBrokerInvoiceChargeRequest: apiTypes.BrokerInvoiceChargeRequest = {
			chargeCode: '',
			chargeDescription: '',
			qty: 1,
			unitPrice: 0,
			providerCost: 0,
		}

		const brokerInvoiceChargeResponse = await apiBrokerInvoice.createBrokerInvoiceCharge(
			brokerInvoice.id,
			newBrokerInvoiceChargeRequest,
		)

		if (brokerInvoiceChargeResponse.error) {
			sosToast.sendApiErrorResponseToast(brokerInvoiceChargeResponse)
		} else {
			updateBrokerInvoiceChargeForm(brokerInvoiceChargeResponse, 'upsert')

			setBrokerInvoiceChargeId(brokerInvoiceChargeResponse.data.id)
			setBrokerInvoiceChargeRequest(newBrokerInvoiceChargeRequest)
		}

		setIsAddingCharge(false)
	}

	const updateBrokerInvoiceCharge = async (): Promise<void> => {
		setIsUpdatingCharge(true)

		const updatedBrokerInvoiceChargeRequest = {
			...brokerInvoiceChargeRequest,
			chargeCode:
				chargeCodeTypehead?.value === undefined
					? updateChargeCode(chargeDescriptionTypehead, isEditingChargeRow)
					: chargeCodeTypehead?.value,
			chargeDescription:
				chargeDescriptionTypehead?.value === undefined
					? updateChargeDescription(chargeCodeTypehead, isEditingChargeRow)
					: chargeDescriptionTypehead?.value,
			totalPrice: (
				brokerInvoiceChargeRequest.qty * brokerInvoiceChargeRequest.unitPrice
			).toFixed(2),
		}

		const brokerInvoiceChargeResponse = await apiBrokerInvoice.updateBrokerInvoiceCharge(
			brokerInvoice.id,
			brokerInvoiceChargeId,
			updatedBrokerInvoiceChargeRequest,
		)

		if (brokerInvoiceChargeResponse.error) {
			sosToast.sendApiErrorResponseToast(brokerInvoiceChargeResponse)
		} else {
			updateBrokerInvoiceChargeForm(brokerInvoiceChargeResponse, 'upsert')
		}

		setIsUpdatingCharge(false)
	}

	const deleteBrokerInvoiceCharge = async (): Promise<void> => {
		setIsDeletingCharge(true)

		const brokerInvoiceChargeResponse = await apiBrokerInvoice.deleteBrokerInvoiceCharge(
			brokerInvoice.id,
			brokerInvoiceChargeId,
		)

		if (brokerInvoiceChargeResponse.error) {
			sosToast.sendApiErrorResponseToast(brokerInvoiceChargeResponse)
		} else {
			updateBrokerInvoiceChargeForm(brokerInvoiceChargeResponse, 'delete')
		}

		setIsDeletingCharge(false)
	}

	const getTotalPrice = (qty: number, unitPrice: number): number => {
		return qty * unitPrice
	}

	const updateChargeCode = (str: TypeaheadOption, idx: number): string => {
		if (selectedChargeDescription !== null && idx === isEditingChargeRow) {
			const filteredCharges = l.find(
				brokerChargeCodes,
				(c) => c.chargeCodeDescription === str.value,
			)
			return filteredCharges?.chargeCode
		}
	}

	const updateChargeDescription = (
		str: TypeaheadOption,
		idx: number,
	): string => {
		if (selectedChargeCode !== null && idx === isEditingChargeRow) {
			const filteredCharges = l.find(
				brokerChargeCodes,
				(c) => c.chargeCode === str.value,
			)
			return filteredCharges?.chargeCodeDescription
		}
	}

	let chargeCodeTypehead: TypeaheadOption
	let chargeDescriptionTypehead: TypeaheadOption

	if (selectedChargeCode !== null) {
		chargeCodeTypehead = {
			label: selectedChargeCode,
			value: selectedChargeCode,
		}
	}

	if (selectedChargeDescription !== null) {
		chargeDescriptionTypehead = {
			label: selectedChargeDescription,
			value: selectedChargeDescription,
		}
	}

	const getProviderNameByShipmentId = async (): Promise<void> => {
		const shipmentResponse = await apiBroker.fetchBrokerShipment(() => {},
		shipmentId)

		if (shipmentResponse.error) {
			sosToast.sendApiErrorResponseToast(shipmentResponse)
		} else {
			setSelectedProviderName(shipmentResponse.data.bookedRate?.providerName)
		}
	}

	useEffect(() => {
		fireAndForget(
			async () => getProviderNameByShipmentId(),
			`Get ProviderName By ShipmentId`,
		)
	}, [shipmentId]) // eslint-disable-line react-hooks/exhaustive-deps

	const getMarkup = (totalPrice: number, providerCost: number): number => {
		const markup = (100 * ((totalPrice - providerCost) / providerCost)) / 100

		if (isNaN(markup) || markup < 0 || markup === Infinity) {
			return 0
		}

		return markup
	}

	return (
		<div className={classes.customerCharges}>
			<div className={classes.title}>
				<h3>{t('customerCharges', tPrefix)}</h3>

				{brokerInvoice?.invoiceStatus === 'review' && (
					<Button
						color={'green'}
						onClick={() => addBrokerInvoiceCharge()}
						isSpinning={isAddingCharge}
					>
						{t('addCharge', tPrefix)}
					</Button>
				)}
			</div>

			<Table bordered>
				<thead>
					<tr>
						<th>{t('chargeCode', tPrefix)}</th>
						<th>{t('chargeDescription', tPrefix)}</th>
						<th>{t('qty', tPrefix)}</th>
						<th>{t('unitPrice', tPrefix)}</th>
						<th>{t('totalPrice', tPrefix)}</th>
						<th>{t('amountPaid', tPrefix)}</th>
						<th>{t('provider', tPrefix)}</th>
						<th>{t('providerCost', tPrefix)}</th>
						<th> {t('markup', tPrefix)}</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{isFetchingBrokerInvoices ? (
						<tr>
							<td colSpan={10}>
								<Center>
									<Loader isLoading={isFetchingBrokerInvoices} />
								</Center>
							</td>
						</tr>
					) : (
						l.map(brokerInvoiceCharges, (brokerInvoiceCharge, idx) => {
							const formData: IFormData<apiTypes.BrokerInvoiceChargeResponse> = {
								form: brokerInvoiceCharge,

								metadata: {
									id: {},
									chargeCode: {},
									chargeDescription: {},
									qty: {},
									unitPrice: {},
									providerCost: {},
									shipmentId: {},
									totalPrice: {},
									providerId: {},
								},

								onUpdateForm: (field: string, value: any) => {
									const updateInvoiceChargesRequest = l.cloneDeep(
										brokerInvoiceCharges,
									)

									const updateInvoiceRequest = l.cloneDeep(brokerInvoiceCharge)

									updateInvoiceRequest[field] = value

									updateInvoiceChargesRequest.splice(
										idx,
										1,
										updateInvoiceRequest,
									)

									setBrokerInvoiceChargeRequest(updateInvoiceRequest)
									setBrokerInvoiceCharges(updateInvoiceChargesRequest)
								},

								tPrefix,
							}

							return (
								<tr key={`${brokerInvoiceCharge.chargeCode}-${idx}`}>
									<td>
										{toggleChargeCode && idx === isEditingChargeRow ? (
											<Input
												value={
													idx === isEditingChargeRow && toggleChargeDescription
														? chargeCodeTypehead?.value
														: updateChargeCode(chargeDescriptionTypehead, idx)
												}
												onFocus={() => setToggleChargeCode(false)}
												onBlur={() => setToggleChargeCode(false)}
												className={
													idx === isEditingChargeRow
														? `${classes.enabledInput} ${classes.alignCenter}`
														: `${classes.disabledInput} ${classes.alignCenter}`
												}
												readOnly={true}
											/>
										) : (
											<AsyncTypeahead
												testId={'cutomer-invoice-chargeCode-input'}
												size={'small'}
												options={[]}
												onSearch={async () => {
													let responseOptions: TypeaheadOption[] = []

													if (brokerChargeCodes) {
														responseOptions = brokerChargeCodes.map((c) => ({
															value: c.chargeCode,
															label: c.chargeCode,
														}))
													}

													return responseOptions
												}}
												onChange={(selected: TypeaheadOption) =>
													setSelectedChargeCode(selected?.value)
												}
												onFocus={() => setToggleChargeDescription(true)}
												onBlur={() => setToggleChargeDescription(false)}
												isClearable={true}
												useCache={true}
												className={`${classes.asyncTypehead} ${classes.asyncTypeheadChargeCode}`}
												disabled={idx !== isEditingChargeRow}
												value={
													idx === isEditingChargeRow && toggleChargeDescription
														? chargeCodeTypehead
														: updateChargeCode(chargeDescriptionTypehead, idx)
												}
												defaultInputValue={brokerInvoiceCharge.chargeCode}
											/>
										)}
									</td>
									<td>
										{toggleChargeDescription && idx === isEditingChargeRow ? (
											<Input
												value={
													idx === isEditingChargeRow && toggleChargeCode
														? chargeDescriptionTypehead?.value
														: updateChargeDescription(chargeCodeTypehead, idx)
												}
												onFocus={() => {
													setToggleChargeDescription(false)
												}}
												onBlur={() => {
													setToggleChargeDescription(false)
												}}
												className={
													idx === isEditingChargeRow
														? classes.enabledInput
														: classes.disabledInput
												}
												readOnly={true}
											/>
										) : (
											<AsyncTypeahead
												testId={'customer-invoice-chargeDescription-input'}
												size={'small'}
												options={[]}
												onSearch={async () => {
													let responseOptions: TypeaheadOption[] = []

													if (brokerChargeCodes) {
														responseOptions = brokerChargeCodes.map((c) => ({
															value: c.chargeCodeDescription,
															label: c.chargeCodeDescription,
														}))
													}

													return responseOptions
												}}
												onChange={(selected: TypeaheadOption) => {
													setSelectedChargeDescription(selected?.value)
												}}
												onFocus={() => setToggleChargeCode(true)}
												onBlur={() => setToggleChargeCode(false)}
												isClearable={true}
												useCache={true}
												className={classes.asyncTypehead}
												disabled={idx !== isEditingChargeRow}
												value={
													idx === isEditingChargeRow && toggleChargeCode
														? chargeDescriptionTypehead
														: updateChargeDescription(chargeCodeTypehead, idx)
												}
												defaultInputValue={
													brokerInvoiceCharge.chargeDescription
												}
											/>
										)}
									</td>
									<td>
										<FormTextInput
											form={formData.form}
											field={'qty'}
											onUpdateForm={formData.onUpdateForm}
											readOnly={idx !== isEditingChargeRow}
											className={classes.alignRight}
										/>
									</td>
									<td>
										{idx !== isEditingChargeRow ? (
											<Input
												value={`$ ${brokerInvoiceCharge.unitPrice.toFixed(2)}`}
												readOnly={true}
												className={classes.alignRight}
											/>
										) : (
											<FormTextInput
												form={formData.form}
												field={'unitPrice'}
												onUpdateForm={formData.onUpdateForm}
												className={classes.alignRight}
											/>
										)}
									</td>
									<td>
										<Input
											value={`$ ${getTotalPrice(
												brokerInvoiceCharge.qty,
												brokerInvoiceCharge.unitPrice,
											).toFixed(2)}`}
											readOnly={true}
											className={classes.alignRight}
										/>
									</td>
									<td>
										<Input
											value={
												brokerInvoice.invoiceStatus === 'paid_in_full'
													? `$ ${getTotalPrice(
															brokerInvoiceCharge.qty,
															brokerInvoiceCharge.unitPrice,
													  ).toFixed(2)}`
													: `$0.00`
											}
											readOnly={true}
											className={
												brokerInvoice.invoiceStatus === 'paid_in_full'
													? classes.paidInFull
													: classes.alignRight
											}
										/>
									</td>
									<td>
										<Input value={selectedProviderName} readOnly={true} />
									</td>
									<td>
										{idx !== isEditingChargeRow ? (
											<Input
												value={`$ ${brokerInvoiceCharge.providerCost.toFixed(
													2,
												)}`}
												readOnly={true}
												className={classes.alignRight}
											/>
										) : (
											<FormTextInput
												form={formData.form}
												field={'providerCost'}
												onUpdateForm={formData.onUpdateForm}
												readOnly={idx !== isEditingChargeRow}
												className={classes.alignRight}
											/>
										)}
									</td>
									<td>
										<Input
											value={`${getMarkup(
												brokerInvoiceCharge.qty * brokerInvoiceCharge.unitPrice,
												brokerInvoiceCharge.providerCost,
											).toFixed()}%`}
											readOnly={true}
											className={classes.alignRight}
										/>
									</td>
									<td>
										{brokerInvoice?.invoiceStatus === 'review' && (
											<div className={classes.iconButtons}>
												<IconButton
													icon={
														idx === isEditingChargeRow
															? solidIcons.faCheck
															: solidIcons.faPencilAlt
													}
													buttonClassName={
														idx === isEditingChargeRow
															? classes.save
															: classes.edit
													}
													color={idx === isEditingChargeRow ? 'green' : 'black'}
													onClick={async () => {
														if (idx === isEditingChargeRow) {
															await updateBrokerInvoiceCharge()
															setIsEditingChargeRow(null)
														} else {
															setBrokerInvoiceChargeId(brokerInvoiceCharge.id)
															setBrokerInvoiceChargeRequest(brokerInvoiceCharge)
															setIsEditingChargeRow(idx)
														}
													}}
													spin={idx === isEditingChargeRow && isUpdatingCharge}
													testId={'cutomer-invoice-charge-edit-save'}
												></IconButton>

												<IconButton
													icon={solidIcons.faTimes}
													buttonClassName={classes.cancel}
													color={'red'}
													onClick={() => {
														if (isEditingChargeRow !== null) {
															setIsEditingChargeRow(null)
														} else {
															setBrokerInvoiceChargeId(brokerInvoiceCharge.id)
															setIsDeletingChargeRow(idx)
															setIsDeleteModalOpen(true)
														}
													}}
													testId={'cutomer-invoice-charge-cancel'}
												></IconButton>
											</div>
										)}
									</td>
								</tr>
							)
						})
					)}
				</tbody>
			</Table>
			<Modal
				content={() => (
					<div data-testid={'customer-invoice-charge-delete-modal'}>
						<p>{t('deleteThisInvoiceCharge?', tPrefix)}</p>
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'customer-invoice-charge-delete-modal-ok'}
								isSpinning={isDeletingCharge}
								onOk={async () => {
									await deleteBrokerInvoiceCharge()
									setIsDeletingChargeRow(null)
									setIsDeleteModalOpen(false)
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => {
									setIsDeletingChargeRow(null)
									setIsDeleteModalOpen(false)
								}}
								cancelTestId={'customer-invoice-charge-delete-modal-cancel'}
							></OkCancelButtons>
						</AlignRight>
					</div>
				)}
				isOpen={isDeleteModalOpen}
				onModalClose={() => {}}
				title={t('confirmDelete', tPrefix)}
			/>
		</div>
	)
}
