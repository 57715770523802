import { FC } from 'app/FunctionalComponent'
import { sosToast, ToastState } from 'common/components/toast'
import React, { useState } from 'react'
import { ISelectOptions } from 'ui/components/common/select'
import { SmallButton } from 'ui/components/common/small-button'
import {
	FormStacked,
	FormStackedLayout,
	FormStackedSelect,
	FormStackedTextInput,
} from 'ui/components/form'
import { IFormData } from 'ui/components/form/IFormData'
import { useOnce } from 'ui/components/hooks'
import { t, tString } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow'
import { getRegionsForCountry } from 'ui/data/countryAndRegionUtils'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { validateAddress } from 'ui/pages/new-quote/state/newQuoteUtils/validateShipment/shipmentValidations'
import { editAddressFormMetadata } from '.'
import { IAddress } from './IAddress'
import { IAddressState } from './IAddressState'

const tPrefix = 'shared.address.editAddress'

export const EditAddressFormStacked: FC = (props: {
	addressState: IAddressState
	updateAddressState: (changes: Partial<IAddressState>, id?: string) => void
	nonContactReadOnly?: boolean
	onSave?: (IAddressState) => Promise<boolean>
	showSave?: boolean
	isSubmitting?: boolean
}) => {
	const {
		addressState: state,
		updateAddressState: updateState,
		nonContactReadOnly,
	} = props
	const [regions, setRegions] = useState([])
	useOnce(() => {
		if (state.address.country) {
			setRegions(getRegionsForCountry(state.address.country, true, true))
		}
	})

	const formData: IFormData<IAddress> = {
		form: state.address,
		metadata: editAddressFormMetadata,
		onUpdateForm: (key: string, val: string) => {
			const newAddress = r.clone(state.address)
			newAddress[key] = val

			// Reset the state/region if the country changed
			if (key === 'country' && state.address.country !== val) {
				const newStateOptions: ISelectOptions[] = getRegionsForCountry(
					newAddress.country,
					true,
					true,
				) as ISelectOptions[]
				setRegions(newStateOptions)
				if (
					!l.find(newStateOptions, (stateOption) => {
						return stateOption.value === state.address.state
					})
				) {
					newAddress.state = ''
				}
			}

			updateState({ address: newAddress }, props.addressState.id)
		},
		tPrefix,
	}

	const isValid = validateAddress(state.address).length === 0

	return (
		<div data-testid='edit-address-form-stacked'>
			<FormStacked>
				<FormStackedLayout>
					<FlexRow>
						<div>
							<FormStackedTextInput
								formData={formData}
								field='company'
								readOnly={nonContactReadOnly}
							/>
							<FormStackedTextInput
								formData={formData}
								field='street1'
								readOnly={nonContactReadOnly}
								testId={'street1'}
							/>
							<FormStackedTextInput
								formData={formData}
								field='street2'
								readOnly={nonContactReadOnly}
								testId={'street2'}
							/>
							<FormStackedTextInput
								formData={formData}
								field='city'
								readOnly={nonContactReadOnly}
								testId={'city'}
							/>
							<FormStackedSelect
								formData={formData}
								field='state'
								options={regions}
								readOnly={nonContactReadOnly}
								testId={'state'}
								label={
									state.address.country !== 'US'
										? t('province', formData.tPrefix)
										: t('state', formData.tPrefix)
								}
								width={'100%'}
							/>
							<FormStackedTextInput
								formData={formData}
								field='zip'
								readOnly={nonContactReadOnly}
								testId={'zip'}
								label={
									state.address.country !== 'US'
										? t('postalCode', formData.tPrefix)
										: t('zipCode', formData.tPrefix)
								}
							/>
							<FormStackedSelect
								formData={formData}
								field='country'
								readOnly={nonContactReadOnly}
								autocomplete={true}
								width={'100%'}
							/>
						</div>
						<div>
							<FormStackedSelect
								formData={formData}
								field='addressType'
								readOnly={nonContactReadOnly}
								tPrefix={formData.tPrefix}
								width={'100%'}
							/>

							<FormStackedTextInput
								formData={formData}
								field='name'
								testId={'name'}
							/>
							<FormStackedTextInput
								formData={formData}
								field='phone'
								testId={'phone'}
							/>
							<FormStackedTextInput
								formData={formData}
								field='email'
								testId={'email'}
							/>
						</div>
					</FlexRow>
				</FormStackedLayout>
			</FormStacked>
			{props.showSave && (
				<SmallButton
					color={isValid ? 'blue' : 'gray'}
					isSpinning={props.isSubmitting}
					onClick={async () => {
						const toastOptions: ToastState = {
							header: tString('shared.address.toast.invalidAddress'),
							type: 'danger',
						}
						if (isValid) {
							const success = await props.onSave(props.addressState)

							if (success) {
								toastOptions.header = tString(
									'shared.address.toast.addressSaved',
								)
								toastOptions.type = 'success'
							} else {
								toastOptions.header = tString('shared.address.toast.error')
								toastOptions.type = 'danger'
							}
						}
						sosToast.sendToast(toastOptions)
					}}
				>
					{t('shared.address.saveAddressBook')}
				</SmallButton>
			)}
		</div>
	)
}
