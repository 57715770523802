import { apiTypes } from 'ui/api'
import { classDensityBreaks } from 'ui/data/classDensityBreaks'
import { idx } from 'ui/lib'
import { l } from 'ui/lib/lodashImports'
import { uniqueTruthyMap } from '../misc'
import { INewQuoteCommoditiesForm } from 'ui/pages/new-quote/INewQuoteCommoditiesForm'

export const getBolNumbers = (
	shipment: apiTypes.ShipmentResponse,
): string[] => {
	return uniqueTruthyMap(shipment.payloads, (payload) => payload.bolIdentifier)
}

export const pullFromGoods = (
	goods: (apiTypes.GoodResponse | apiTypes.Good)[],
	key: keyof apiTypes.GoodResponse,
): string => l.uniq(l.map(goods, (g) => g[key])).join(', ')

export const getPickTicketNumbers = (
	shipment: apiTypes.ShipmentResponse,
): string[] => {
	return uniqueTruthyMap(
		shipment.payloads,
		(payload) => payload.pickTicketNumber,
	)
}

export const getPickupAccessorialsFromShipment = (
	shipment: apiTypes.ShipmentResponse,
): string[] => {
	if (!shipment) {
		return null
	}

	let pickupAccessorials: string[] = []
	l.forEach(shipment.payloads, (payload) => {
		pickupAccessorials = l.concat(
			pickupAccessorials,
			getPickupAccessorialsFromPayload(payload),
		)
	})
	return pickupAccessorials
}

export const getDeliveryAccessorialsFromShipment = (
	shipment: apiTypes.ShipmentResponse,
): string[] => {
	if (!shipment) {
		return null
	}

	let deliveryAccessorials: string[] = []
	l.forEach(shipment.payloads, (payload) => {
		deliveryAccessorials = l.concat(
			deliveryAccessorials,
			getDeliveryAccessorialsFromPayload(payload),
		)
	})
	return deliveryAccessorials
}

export const getPickupAccessorialsFromPayload = (
	payload: apiTypes.PayloadResponse,
): string[] => {
	if (!payload) {
		return null
	}
	const pickupAccessorialsList: string[] = []
	const pickupAccessorials =
		idx(() => payload.originStop.metaData.accessorials) || {}
	l.forEach(Object.keys(pickupAccessorials), (accessorial) => {
		if (pickupAccessorials[accessorial]) {
			pickupAccessorialsList.push(accessorial)
		}
	})
	return pickupAccessorialsList
}

export const getDeliveryAccessorialsFromPayload = (
	payload: apiTypes.PayloadResponse,
): string[] => {
	if (!payload) {
		return null
	}
	const deliveryAccessorialsList: string[] = []
	const deliveryAccessorials =
		idx(() => payload.destinationStop.metaData.accessorials) || {}
	l.forEach(Object.keys(deliveryAccessorials), (accessorial) => {
		if (deliveryAccessorials[accessorial]) {
			deliveryAccessorialsList.push(accessorial)
		}
	})
	return deliveryAccessorialsList
}

export const getPayloadLevelAccessorials = (
	shipment: apiTypes.ShipmentResponse,
): string[] => {
	if (!shipment) {
		return null
	}

	const accessorials: string[] = []
	l.forEach(shipment.payloads, (payload) => {
		if (payload.accessorials != null) {
			l.forEach(Object.keys(payload.accessorials), (c) => {
				if (payload.accessorials[c]) {
					accessorials.push(c)
				}
			})
		}
	})
	return accessorials
}

export const getShipmentLevelAccessorials = (
	shipment: apiTypes.ShipmentResponse,
): string[] => {
	if (!shipment) {
		return null
	}

	const accessorials = []
	if (shipment.accessorials != null) {
		l.forEach(Object.keys(shipment.accessorials), (c) => {
			if (shipment.accessorials[c] === true) {
				accessorials.push(c)
			}
		})
	}
	return accessorials
}

export const getNestedContainers = (
	containers: apiTypes.ContainerResponse[] | apiTypes.Container[],
): (apiTypes.ContainerResponse | apiTypes.Container)[] => {
	if (!containers) {
		return []
	}

	const allContainers = [containers]
	l.forEach(containers, (container) => {
		if (container.containers) {
			allContainers.push(getNestedContainers(container.containers))
		}
	})
	return l.flatten(allContainers)
}

export const getGoodsFromContainer = (
	container: apiTypes.ContainerResponse | apiTypes.Container,
): (apiTypes.GoodResponse | apiTypes.Good)[] => {
	const goods: (apiTypes.GoodResponse | apiTypes.Good)[] = l.flatten(
		getNestedContainers(container.containers)
			.filter((c) => c.goods)
			.map((c) => c.goods),
	)

	if (container.goods) {
		goods.push(...container.goods)
	}

	return goods
}

export const getBottomLevelContainers = (
	shipment: apiTypes.ShipmentResponse,
): apiTypes.ContainerResponse[] => {
	return l.flatten(l.map(shipment.payloads, (load) => load.containers))
}

export const getAllContainersFromShipment = (
	shipment: apiTypes.ShipmentResponse,
): (apiTypes.ContainerResponse | apiTypes.Container)[] => {
	return getNestedContainers(getBottomLevelContainers(shipment))
}

// Not yet supported by API
// const getContainerAccessorials = (shipment: apiTypes.ShipmentResponse): string[] => {
// 	let containerAccessorials = []
// 	let containers = getNestedContainers(
// 		l.flatten(l.map(shipment.payloads, (load) => load.containers)),
// 	)
// 	l.forEach(containers, (container) => {
// 		if (container.accessorials) {
// 			l.forEach(Object.keys(container.accessorials), (accessorial) => {
// 				if (container.accessorials[accessorial] === true) {
// 					containerAccessorials.push(accessorial)
// 				}
// 			})
// 		}
// 	})
// 	return containerAccessorials
// }

export const getAllAccessorialsFromShipment = (
	shipment: apiTypes.ShipmentResponse,
): string[] => {
	if (!shipment) {
		return null
	}
	return l.concat(
		[],
		getPickupAccessorialsFromShipment(shipment),
		getDeliveryAccessorialsFromShipment(shipment),
		getPayloadLevelAccessorials(shipment),
		getShipmentLevelAccessorials(shipment),
	)
}

export const getAllAccessorialsFromPayload = (
	payload: apiTypes.PayloadResponse,
): string[] => {
	if (!payload) {
		return null
	}
	let accessorials: string[] = []
	accessorials = l.concat(
		accessorials,
		getPickupAccessorialsFromPayload(payload),
	)
	accessorials = l.concat(
		accessorials,
		getDeliveryAccessorialsFromPayload(payload),
	)
	return accessorials
}

export const formatAccessorials = (accessorials: string[]): string => {
	return l.join(l.map(accessorials, l.startCase), ', ')
}

export const calculateClass = (
	commodity: Partial<INewQuoteCommoditiesForm>,
): apiTypes.Container['class'] => {
	if (!commodity) {
		return null
	}
	const { count, packedWeight, length, width, height } = commodity

	const unitVolume = Math.ceil(
		(Number(length) / 12) * (Number(width) / 12) * (Number(height) / 12),
	)
	const totalVolume = Number(count) * unitVolume
	const totalDensity = Number(packedWeight) / totalVolume

	const matchingBreak = l.find(
		classDensityBreaks,
		(densityBreak, densityBreakIdx) =>
			l.inRange(
				totalDensity,
				Number(densityBreak[0]),
				idx(() => Number(classDensityBreaks[densityBreakIdx - 1][0])) ||
					Infinity,
			),
	)
	const calculatedClass = idx(() => matchingBreak[1])
	return (calculatedClass
		? '' + calculatedClass
		: '') as apiTypes.Container['class']
}

export const getPickupDate = (shipment: apiTypes.ShipmentResponse): string => {
	const actualPickupDate = idx(() => {
		return shipment.payloads[0].originStop.metaData.actualDateTimeInfo
			.initialDate
	})
	return actualPickupDate
		? actualPickupDate
		: idx(() => {
				return shipment.payloads[0].originStop.metaData.desiredDateTimeInfo
					.initialDate
		  })
}

// used to make code for po/so/bol numbers cleaner
export const concatenateOrderNumbers = (
	payloads: apiTypes.PayloadResponse[],
	numberType: 'purchaseOrders' | 'salesOrders' | 'bolIdentifier',
): string => {
	let numbers: string[] = []
	payloads.forEach((payload) => {
		numbers = numbers.concat(payload[numberType])
	})
	return l.join(l.uniq(numbers), ', ')
}
