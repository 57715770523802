import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { apiTypes } from 'ui/api'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import {
	CommoditiesReadOnly,
	INewQuoteCommoditiesForm,
} from 'ui/pages/new-quote'
import { BrokerShipmentDetailsCard } from 'ui/pages/shipment-profile/broker/components/shipment-cards'
import { StopCard } from 'ui/pages/shipment-profile/shared/components/shipmentCards'
import { theme } from 'ui/theme'
import { combineStopData } from 'ui/lib/shipments/combineStopData'
import * as classes from './BrokerShipmentDetailsSection.module.scss'

interface StopInfo {
	stop: apiTypes.OriginStopResponse | apiTypes.DestinationStopResponse
	stopType: 'pickup' | 'delivery' | 'deliveryAndPickup'
}

const getStopInfoFromShipment = (
	shipment: apiTypes.BrokerShipmentResponse,
): StopInfo[] => {
	return shipment.stopSequence.map((stopHash) => {
		const matchingOriginStops: apiTypes.OriginStopResponse[] = []
		const matchingDestinationStops: apiTypes.DestinationStopResponse[] = []
		shipment.payloads.forEach((payload) => {
			if (payload.originStop.metaData.addressHash === stopHash) {
				matchingOriginStops.push(payload.originStop)
			} else if (payload.destinationStop.metaData.addressHash === stopHash) {
				matchingDestinationStops.push(payload.destinationStop)
			}
		})
		if (matchingOriginStops.length > 0 && matchingDestinationStops.length > 0) {
			return {
				stop: combineStopData([
					...matchingDestinationStops,
					...matchingOriginStops,
				]),
				stopType: 'deliveryAndPickup',
			}
		} else if (matchingOriginStops.length > 0) {
			return { stop: combineStopData(matchingOriginStops), stopType: 'pickup' }
		} else if (matchingDestinationStops.length > 0) {
			return {
				stop: combineStopData(matchingDestinationStops),
				stopType: 'delivery',
			}
		} else {
			return null
		}
	})
}

const getCommoditiesFromShipment = (
	shipment: apiTypes.BrokerShipmentResponse,
): INewQuoteCommoditiesForm[] => {
	const commodities: INewQuoteCommoditiesForm[] = []
	shipment.payloads.forEach((payload) => {
		payload.containers.forEach((container) => {
			const innerContainer: apiTypes.ContainerResponse = container.containers
				? container.containers[0]
				: null
			const good: apiTypes.GoodResponse = innerContainer?.goods
				? innerContainer.goods[0]
				: null
			const commodity: INewQuoteCommoditiesForm = {
				id: null,
				count: String(container.count),
				class: container.class,
				length: String(container.length),
				width: String(container.width),
				height: String(container.height),
				packedWeight: String(container.packedWeight),
				description: container.description,
				type: container.type,
				piecesCount: String(innerContainer?.count || '1'),
				piecesType: innerContainer?.type,
				piecesLength: String(innerContainer?.length || ''),
				piecesWidth: String(innerContainer?.width || ''),
				piecesHeight: String(innerContainer?.height || ''),
				hazmat: null,
				nmfc: good?.nmfcCode,
				nmfcSub: null,
				hazmatClass: null,
				unNumber: null,
				packagingGroup: null,
				bol: null,
				so: container.salesOrders?.join(', '),
				po: container.purchaseOrders?.join(', '),
				notes: null,
			}
			if (shipment.stopSequence.length > 2) {
				commodity.pickupStopNumber =
					l.findIndex(
						shipment.stopSequence,
						(stopHash) => stopHash === payload.originStop.metaData.addressHash,
					) + 1
				commodity.deliveryStopNumber =
					l.findIndex(
						shipment.stopSequence,
						(stopHash) =>
							stopHash === payload.destinationStop.metaData.addressHash,
					) + 1
			}
			commodities.push(commodity)
		})
	})
	return commodities
}

export const BrokerShipmentDetailsSection = (props: {
	shipment: apiTypes.BrokerShipmentResponse
}): React.ReactElement => {
	const { shipment } = props
	const stopsInOrder: StopInfo[] = l.compact(getStopInfoFromShipment(shipment))
	const commodities: INewQuoteCommoditiesForm[] = getCommoditiesFromShipment(
		shipment,
	)

	return (
		<div>
			<Card className={classes.cardBody}>
				<Row>
					<Col md={4}>
						<BrokerShipmentDetailsCard shipment={shipment} />
					</Col>
					{stopsInOrder.map((stopInfo, idx) => (
						<Col
							md={4}
							key={stopInfo.stop.metaData.addressHash}
							className={theme.addClassIf(idx > 1, classes.nonFirstRowCard, '')}
						>
							<StopCard
								stopNumber={idx + 1}
								stopType={stopInfo.stopType}
								stop={stopInfo.stop}
								brokerBooked={shipment.bookedRate ? true : false}
								brokerShipment={shipment}
							/>
						</Col>
					))}
				</Row>
				<Spacer />
				<CommoditiesReadOnly commodities={commodities} fullWidth={true} />
			</Card>
		</div>
	)
}
