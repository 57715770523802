import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { apiBroker, apiTypes } from 'ui/api'
import { ndash } from 'ui/components/common'
import { Button } from 'ui/components/common/button/Button'
import { Icon, IconButton, solidIcons } from 'ui/components/common/icon'
import { Loader } from 'ui/components/common/loader'
import { Select } from 'ui/components/common/select'
import { useOnce } from 'ui/components/hooks'
import {
	tTrackingEventSource,
	tTrackingStatus,
} from 'ui/components/i18n/commonTranslations'
import { t, tDate, tString, tTime } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import * as tableClasses from 'ui/components/rates-table/RatesTable.module.scss'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { AddTrackingEventModal } from 'ui/pages/shipment-profile/broker/components/add-tracking-events'
import * as classes from './ShipmentProfileTracking.module.scss'

const tPrefix = 'page.shipmentProfile.broker.tracking'

interface TrackingRow {
	ids: string[]
	eventTime: string
	trackingStatus: apiTypes.TrackingStatus
	event: string
	eventSource: apiTypes.TrackingEventSource
	location: string
	internal: boolean
	username: string
}

const findTrackingEventInRows = (
	trackingRows: TrackingRow[],
	event: apiTypes.BrokerTrackingEventResponse | apiTypes.TrackingEventResponse,
): TrackingRow =>
	l.find(
		trackingRows,
		(row) => row.eventTime === event.timestamp && row.event === event.event,
	)

export const ShipmentProfileTracking: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse | apiTypes.ShipmentResponse
}) => {
	const { shipment } = props
	const [addEventModalOpen, setEventModalOpen] = useState(false)
	const [selectedTrackingNumber, setSelectedTrackingNumber] = useState<string>(
		null,
	)
	const isParcelShipment = shipment.bookedRate?.method === 'parcel'
	useOnce(() => {
		if (isParcelShipment) {
			setSelectedTrackingNumber(shipment.payloads[0].masterTrackingNumber)
		}
	})
	let trackingRows: TrackingRow[] = [] 
	shipment.payloads.forEach((payload) => {
		if (
			!selectedTrackingNumber ||
			payload.trackingNumber === selectedTrackingNumber
		) {
			payload.trackingEvents?.forEach((trackingEvent) => {
				const matchingTrackingEvent = findTrackingEventInRows(
					trackingRows,
					trackingEvent,
				)
				if (matchingTrackingEvent) {
					matchingTrackingEvent.ids.concat(trackingEvent.id)
				} else {
					const trackingRow = {
						ids: [trackingEvent.id],
						eventTime: trackingEvent.timestamp,
						trackingStatus: trackingEvent.status,
						event: trackingEvent.event,
						eventSource: trackingEvent.eventSource,
						location:
							trackingEvent.address?.city || trackingEvent.address?.state
								? `${trackingEvent.address.city}, ${trackingEvent.address.state}`
								: null,
						internal: false,
						username: trackingEvent.username,
					}
					if ('internalOnly' in trackingEvent){
						trackingRow.internal = (trackingEvent as apiTypes.BrokerTrackingEventResponse).internalOnly;
					}
					trackingRows.push(trackingRow)
				}
			})
		}
	})
	trackingRows = l.sortBy(trackingRows, (row) => row.eventTime)
	return (
		<div>
			<AddTrackingEventModal
				isOpen={addEventModalOpen}
				onClose={() => setEventModalOpen(false)}
				shipment={shipment}
			/>
			<Row>
				<Col xs={2}>
					<div className={classes.trackingEventsHeader}>
						{t('trackingEvents', tPrefix)}
					</div>
				</Col>
				<Col xs={6}>
					{isParcelShipment && (
						<Select
							value={selectedTrackingNumber}
							options={l.uniq(
								l.compact(
									(shipment.payloads as (apiTypes.PayloadResponse | apiTypes.BrokerPayloadResponse)[])
										.map((payload) => payload.trackingNumber)
										.concat(shipment.payloads[0].masterTrackingNumber)
								),
							)}
							onChange={(val) => setSelectedTrackingNumber(val)}
							testId={'tracking-number-select'}
						/>
					)}
				</Col>
				<Col xs={4}>
					<AlignRight>
						<Button
							bootstrapStyles={true}
							color='green'
							onClick={() => setEventModalOpen(true)}
							className={classes.addEventButton}
							testId={'create-tracking-event-button'}
						>
							{t('addEvent', tPrefix)}
						</Button>
					</AlignRight>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<table
						className={tableClasses.tableRates}
						cellPadding={0}
						cellSpacing={0}
						data-testid='tracking-table'
					>
						<thead>
							<tr>
								<th>{t('eventDate', tPrefix)}</th>
								<th className={classes.trackingEventTimeColumn}>
									{t('eventTime', tPrefix)}
								</th>
								<th>{t('trackingStatus', tPrefix)}</th>
								<th className={classes.trackingEventDescriptionColumn}>
									{t('event', tPrefix)}
								</th>
								<th>{t('eventSource', tPrefix)}</th>
								<th>{t('location', tPrefix)}</th>
								<th className={tableClasses.center}>
									{t('internal', tPrefix)}
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{trackingRows.map((trackingRow, trackingRowIdx) => {
								return (
									<tr
										key={trackingRowIdx}
										data-testid={`tracking-table-${trackingRowIdx}`}
									>
										<td data-testid={'tracking-table-date'}>
											{tDate(trackingRow.eventTime) || ndash}
										</td>
										<td data-testid={'tracking-table-time'}>
											{tTime(trackingRow.eventTime) || ndash}
										</td>
										<td data-testid={'tracking-table-status'}>
											{tTrackingStatus(trackingRow.trackingStatus) || ndash}
										</td>
										<td
											className={classes.trackingEventDescriptionColumn}
											data-testid={'tracking-table-event'}
										>
											{trackingRow.event || ndash}
										</td>
										<td data-testid={'tracking-table-eventSource'}>
											{(trackingRow.eventSource === 'userInput'
												? trackingRow.username
												: tTrackingEventSource(trackingRow.eventSource)) ||
												ndash}
										</td>
										<td data-testid={'tracking-table-location'}>
											{trackingRow.location || ndash}
										</td>
										<td
											className={tableClasses.center}
											data-testid={'tracking-table-internal'}
										>
											<Icon
												icon={
													trackingRow.internal
														? solidIcons.faCheckCircle
														: solidIcons.faTimesCircle
												}
												color={trackingRow.internal ? 'green' : 'red'}
												large={true}
											/>
										</td>
										<td
											className={theme.addClass(
												tableClasses.center,
												classes.deleteIcon,
											)}
											data-testid={'tracking-table-delete'}
										>
											<TrackingEventDeleteButton
												row={trackingRow}
												shipment={shipment}
											/>
										</td>
									</tr>
								)
							})}
							{trackingRows.length === 0 && (
								<tr>
									<td
										className={tableClasses.center}
										colSpan={7}
										data-testid={'no-tracking-events-message'}
									>
										{t('noTrackingEvents', tPrefix)}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</Col>
			</Row>
		</div>
	)
}

const TrackingEventDeleteButton: FC = (props: {
	row: TrackingRow
	shipment: apiTypes.BrokerShipmentResponse | apiTypes.ShipmentResponse
}) => {
	const { row, shipment } = props
	const [isDeleting, setIsDeleting] = useState(false)
	if (isDeleting) {
		return (
			<Loader isLoading={true} testId={'deleting-tracking-event-spinner'} />
		)
	} else if (row.eventSource === 'userInput') {
		return (
			<IconButton
				onClick={async () => {
					setIsDeleting(true)
					const results = await Promise.all(
						row.ids.map(async (id: string) => {
							const payloadId = shipment.payloads.find((payload) =>
								payload.trackingEvents.find(
									(trackingEvent) => trackingEvent.id === id,
								),
							).id

							return apiBroker.deleteTrackingEvent(
								() => {},
								shipment.id,
								payloadId,
								id,
							)
						}),
					)
					const apiCallWithError = l.compact(
						results.map((result) => (result.error ? result : null)),
					)[0]
					if (apiCallWithError) {
						sosToast.sendApiErrorResponseToast(
							apiCallWithError,
							tString('deleteTrackingEventError', tPrefix),
						)
					} else {
						await sosShipmentProfileBroker.fetchShipment(shipment.id)
					}
					setIsDeleting(false)
				}}
				icon={solidIcons.faTimes}
				large={true}
				color={'red'}
				testId={'delete-tracking-event-button'}
			/>
		)
	} else {
		return null
	}
}
