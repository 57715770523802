import React, { useEffect, useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import * as classes from './MultiSelectFilterModal.module.scss'
import { Button } from 'ui/components/common/button'
import { Card } from 'ui/components/common/card'
import { Row, Col, ListGroup } from 'react-bootstrap'
import { TypeaheadOption } from 'ui/common/components/typeahead'
import { Loader } from 'ui/components/common/loader'

const spacerHeight = '10px'

export const MultiSelectFilterModal: FC = (props: {
	options: TypeaheadOption[]
	isModalOpen: boolean
	onModalClose: () => void
	tPrefix: string
	selectedFilters: string[]
	onFilterApplied: (filters: string[]) => void
	title: string
	cardTitle: string
}) => {
	const {
		options,
		isModalOpen,
		onModalClose,
		tPrefix,
		selectedFilters,
		title,
		cardTitle,
	} = props

	const tPrefixModal = tPrefix + '.' + title + 'Modal'
	const testIdPrefix = title + '-modal'

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isFetchingList, setIsFetchingList] = useState<boolean>(false)
	const [availableOptions, setAvailableOptions] = useState<TypeaheadOption[]>(
		[],
	)
	const [selectedOptions, setSelectedOptions] = useState<TypeaheadOption[]>([])
	const [defaultAvailableOptions, setDefaultAvailableOptions] = useState<
		TypeaheadOption[]
	>([])

	const capitalizedCardTitle =
		cardTitle.charAt(0).toUpperCase() + cardTitle.slice(1)
	const rightCardTitle = 'shown' + capitalizedCardTitle

	let searchRef = null

	useEffect(() => {
		setDefaultAvailableOptions(options) // TO-DO
	}, [options])

	useEffect(() => {
		if (isModalOpen) {
			fetchList()
		}
	}, [isModalOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	const fetchList = (searchValue?: string): void => {
		setIsFetchingList(true)

		let filteredAvailableOptions: TypeaheadOption[]

		if (searchValue) {
			filteredAvailableOptions = defaultAvailableOptions.filter((option) =>
				option.label.toLowerCase().includes(searchValue?.toLowerCase()),
			)
		} else {
			filteredAvailableOptions = defaultAvailableOptions
		}

		let newSelectedFilters = selectedFilters || []

		newSelectedFilters = newSelectedFilters.concat(
			selectedOptions
				.filter((option) => !newSelectedFilters.includes(option.value))
				.map((option) => option.value),
		)

		const updatedAvailableOptions = filteredAvailableOptions.map((option) => ({
			...option,
			selected: newSelectedFilters?.includes(option.value),
		}))

		const updatedSelectedOptions = selectedOptions.length
			? selectedOptions.filter((option) => option.value !== 'All')
			: updatedAvailableOptions.filter((option) =>
					newSelectedFilters.includes(option.value),
			  )

		if (
			newSelectedFilters.some((selected) => selected === 'All') &&
			newSelectedFilters.length < 2 &&
			searchValue === undefined
		) {
			addAllOptions()
		} else {
			setAvailableOptions(updatedAvailableOptions)
			setSelectedOptions(updatedSelectedOptions)
		}

		setIsFetchingList(false)
	}

	const addAllOptions = (): void => {
		const allOption = [
			{
				label: 'All',
				value: 'All',
				selected: true,
			},
		]

		setAvailableOptions(allOption)
		setSelectedOptions(allOption)
	}

	const toggleSelection = (
		option?: TypeaheadOption,
		selected?: boolean,
		addAll?: boolean,
	): void => {
		if (addAll) {
			// ADD ALL
			addAllOptions()
		} else if (addAll === false) {
			// REMOVE ALL
			const updatedCustomerOptions = availableOptions
				.filter((option) => option.value !== 'All')
				.map((selection) => {
					return { ...selection, selected: false }
				})
			if (
				availableOptions.some((selected) => selected.value === 'All') &&
				availableOptions.length < 2 &&
				updatedCustomerOptions.length === 0
			) {
				setAvailableOptions(defaultAvailableOptions)
			} else {
				setAvailableOptions(updatedCustomerOptions)
			}
			setSelectedOptions([])
		} else {
			// ADD ONE / REMOVE ONE
			const updatedCustomerOptions = availableOptions.map((selection) => {
				return selection.value === option.value
					? { ...selection, selected: selected }
					: selection
			})

			setAvailableOptions(updatedCustomerOptions)

			if (
				selected &&
				!selectedOptions?.some(
					(selectedOption) => selectedOption.value === option.value,
				)
			) {
				setSelectedOptions([option].concat(selectedOptions))
			} else {
				const updatedSelectedOptions = selectedOptions.filter(
					(selectedOption) => selectedOption.value !== option.value,
				)

				setSelectedOptions(updatedSelectedOptions)
			}
		}
	}

	const onClose = (): void => {
		setSelectedOptions([])
		onModalClose()
	}

	const onApplyFilter = (): void => {
		setIsLoading(true)

		setTimeout(() => {
			if (props.onFilterApplied) {
				props.onFilterApplied(selectedOptions.map((option) => option.value))
			}
			setIsLoading(false)
			onClose()
		}, 2000)
	}

	return (
		<Modal
			content={() => (
				<div
					className={`bootstrap-wrapper ${classes.selectCustomersModal}`}
					data-testid={testIdPrefix}
				>
					<Spacer height={spacerHeight} />

					<Row>
						<Col xs={6}>
							<div className={classes.customerFilter}>
								<input
									type='text'
									className={'form-control'}
									onChange={(event) => {
										const value = event.target.value
										fetchList(value)
									}}
									ref={(el) => {
										searchRef = el
									}}
								></input>

								<div className={classes.customerFilterIcon}>
									<IconButton
										icon={solidIcons.faTimes}
										color={'black'}
										onClick={() => {
											if (searchRef.value) {
												searchRef.value = ''
												fetchList('')
											}
										}}
									></IconButton>
								</div>
							</div>
						</Col>
					</Row>

					<Spacer height={spacerHeight} />

					<Row>
						<Col xs={6}>
							<Card
								title={
									<div className={classes.selectCustomersModalCardTitle}>
										{t(cardTitle, tPrefixModal)}

										<div className={classes.selectCustomersModalCardButtons}>
											<Button
												color={'blue'}
												onClick={() => {
													toggleSelection(null, false, true)
												}}
												testId={`${testIdPrefix}-card-all-button`}
												isDisabled={false}
											>
												{t('all', tPrefixModal)}
											</Button>

											<Button
												color={'blue'}
												onClick={() => {
													toggleSelection(null, false, false)
												}}
												testId={`${testIdPrefix}-card-none-button`}
												isDisabled={false}
											>
												{t('none', tPrefixModal)}
											</Button>
										</div>
									</div>
								}
								color={'lightGray'}
								fillHeight={true}
								testId={`${testIdPrefix}-card`}
							>
								{isFetchingList ? (
									<Loader isLoading={true} />
								) : (
									<div className={classes.selectCustomersModalCardListGroup}>
										<ListGroup>
											{availableOptions.map((selection, idx) => {
												return (
													<ListGroup.Item
														className={
															selection.selected && classes.selectedCustomer
														}
														action
														key={idx}
														onClick={() => {
															const selected = !selection.selected
															toggleSelection(selection, selected)
														}}
													>
														{selection.label}
													</ListGroup.Item>
												)
											})}
										</ListGroup>
									</div>
								)}
							</Card>
						</Col>

						<Col xs={6}>
							<Card
								title={
									<div className={classes.selectCustomersModalCardTitle}>
										{t(rightCardTitle, tPrefixModal)}

										<div className={classes.selectCustomersModalCardButtons}>
											<Button
												color={'blue'}
												onClick={() => {
													toggleSelection(null, false, false)
												}}
												testId={`${testIdPrefix}-shown-card-clear-button`}
												isDisabled={false}
											>
												{t('clear', tPrefixModal)}
											</Button>
										</div>
									</div>
								}
								color={'lightGray'}
								fillHeight={true}
								testId={`${testIdPrefix}-shown-card`}
							>
								{isFetchingList ? (
									<Loader isLoading={true} />
								) : (
									<div className={classes.selectCustomersModalCardListGroup}>
										<ListGroup>
											{selectedOptions.map((selection, idx) => {
												return (
													<div key={idx}>
														<ListGroup.Item
															action
															key={idx}
															onClick={() => {
																toggleSelection(selection, false)
															}}
														>
															{selection.label}
															<div>
																<IconButton
																	key={idx}
																	icon={solidIcons.faTimes}
																	color={'black'}
																></IconButton>
															</div>
														</ListGroup.Item>
													</div>
												)
											})}
										</ListGroup>
									</div>
								)}
							</Card>
						</Col>
					</Row>

					<Spacer height={spacerHeight} />

					<AlignRight>
						<OkCancelButtons
							ok={t('applyFilter', tPrefixModal)}
							onOk={onApplyFilter}
							okColor='green'
							cancel={t('cancel', tPrefixModal)}
							onCancel={onClose}
							isSpinning={isLoading}
							isValid={selectedOptions.length > 0}
							okTestId={`${testIdPrefix}-apply-filter-button`}
							cancelTestId={`${testIdPrefix}-cancel-button`}
						/>
					</AlignRight>
				</div>
			)}
			isOpen={isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t(title, tPrefixModal)}
		/>
	)
}
