import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	isPathnameAMatch,
	LoginModeType,
	parseHref,
	sosRouter,
	splitPathnameIntoSegments,
} from 'ui/components/common/router'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosFeatureToggles } from 'ui/state'
import classes from './Sidebar.module.scss'
import { SidebarItem } from './SidebarItem'

export interface ILink {
	pathname: string
	indexPathname?: string
	name: string
	subLinks?: ILink[]
	if?: () => boolean
	anonymous?: boolean
	anonymousOnly?: boolean
}

export const tSidebarPrefix = 'component.sidebar'

export const filterLinks = (
	loginMode: LoginModeType,
	links: ILink[],
): ILink[] => {
	let filteredLinks = links
	if (loginMode === 'unknown') {
		filteredLinks = []
	}
	if (loginMode === 'anonymous') {
		filteredLinks = l.filter(
			filteredLinks,
			(c) => c.anonymous || c.anonymousOnly,
		)
	}
	if (loginMode === 'loggedIn') {
		filteredLinks = l.filter(filteredLinks, (c) => !c.anonymousOnly)
	}

	filteredLinks = l.filter(filteredLinks, (f) => !f.if || f.if())

	return filteredLinks
}

export const Sidebar: FC = (props: {
	links: ILink[]
	loginMode: LoginModeType
	testId?: string
}) => {
	const state = sosRouter.getSos().getState()

	// Needed to re-render once we get feature toggles
	sos2.useSubscription(sosFeatureToggles.sos)

	const pathname = parseHref(state.currentLocation).pathname
	const segments = splitPathnameIntoSegments(pathname)

	let selectedMenuPathname = ''
	let selectedSubMenuPathname = ''

	l.forEach(props.links, (link) => {
		if (isPathnameAMatch(segments, link.pathname).match) {
			if (link.pathname.length > selectedMenuPathname.length) {
				selectedMenuPathname = link.pathname
			}
		}

		l.forEach(link.subLinks, (subLink) => {
			if (isPathnameAMatch(segments, subLink.pathname).match) {
				if (subLink.pathname.length > selectedSubMenuPathname.length) {
					selectedSubMenuPathname = subLink.pathname
					selectedMenuPathname = link.pathname
				}
			}
		})
	})

	const filteredLinks = filterLinks(props.loginMode, props.links)

	return (
		<div className={classes.sidebar}>
			{l.map(filteredLinks, (c, cIdx) => (
				<React.Fragment key={cIdx}>
					<SidebarItem
						pathname={c.indexPathname || c.pathname}
						isSelected={c.pathname === selectedMenuPathname}
						selectedSubMenuPathname={selectedSubMenuPathname}
						subLinks={c.subLinks}
						loginMode={props.loginMode}
					>
						{t(c.name, tSidebarPrefix)}
					</SidebarItem>
				</React.Fragment>
			))}
		</div>
	)
}
