import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button/Button'
import { t } from 'ui/components/i18n/i18n'
import { RateRow } from 'ui/components/rates-table'
import classes from 'ui/pages/shipment-profile/shared/components/rates/ShipmentProfileRateInteraction.module.scss'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'

export const ShipperShipmentProfileRateInteraction: FC = (props: {
	rateRow: RateRow
	tPrefix: string
	shipment: apiTypes.ShipmentResponse
	shipmentIsRating: boolean
	shipmentIsBooking: boolean
	setIsBookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
}) => {
	const {
		rateRow,
		tPrefix,
		shipment,
		shipmentIsRating,
		shipmentIsBooking,
		setIsBookCarrierModalOpen,
		setRateBookingDetails,
	} = props

	if (!shipment || !rateRow) {
		return null
	}

	return (
		<div className={classes.interactionOptions}>
			<ShipperRateInteractionButton
				rateRow={rateRow}
				shipment={shipment}
				tPrefix={tPrefix}
				shipmentStatus={shipment.shipmentStatus}
				shipmentIsRating={shipmentIsRating}
				shipmentIsBooking={shipmentIsBooking}
				setIsBookCarrierModalOpen={setIsBookCarrierModalOpen}
				setRateBookingDetails={setRateBookingDetails}
			/>
		</div>
	)
}

export const ShipperRateInteractionButton: FC = (props: {
	rateRow: RateRow
	shipment: apiTypes.ShipmentResponse
	tPrefix: string
	shipmentStatus: apiTypes.ShipmentResponse['shipmentStatus']
	shipmentIsRating: boolean
	shipmentIsBooking: boolean
	setIsBookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
}) => {
	const {
		rateRow,
		shipment,
		tPrefix,
		shipmentStatus,
		shipmentIsRating,
		shipmentIsBooking,
		setIsBookCarrierModalOpen,
		setRateBookingDetails,
	} = props

	switch (rateRow.offerStatus) {
		case 'booked': {
			return (
				<>
					<Button
						color='green'
						isSmall={true}
						bootstrapStyles={true}
						isOutline={true}
						isDisabled={true}
						className={classes.ratesTableBadge}
					>
						<div className={classes.buttonText} data-testid='booked-badge'>
							{t('booked', tPrefix)}
						</div>
					</Button>
				</>
			)
		}

		default: {
			return (
				<>
					<Button
						color='green'
						isSmall={true}
						onClick={() => {
							setRateBookingDetails({
								shipmentId: shipment.id,
								rateId: rateRow.rateId,
								providerName: rateRow.providerName,
								api: rateRow.rateBookingOptions?.api,
								email: rateRow.rateBookingOptions?.email,
								phone: rateRow.rateBookingOptions?.phone,
								isBroker: false,
							})

							setIsBookCarrierModalOpen(true)
						}}
						bootstrapStyles={true}
						isDisabled={
							shipmentIsRating ||
							shipmentIsBooking ||
							shipmentStatus === 'booked'
						}
						testId='book-rate-button'
						className={classes.ratesTableBadge}
					>
						<div className={classes.buttonText}>{t('book', tPrefix)}</div>
					</Button>
				</>
			)
		}
	}
}
