import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button/Button'
import { DocumentViewer } from 'ui/components/common/modal'
import { tDocumentType } from 'ui/components/i18n/commonTranslations'
import { t, tString } from 'ui/components/i18n/i18n'
import * as tableClasses from 'ui/components/rates-table/RatesTable.module.scss'
import { l } from 'ui/lib/lodashImports'
import { stringArrayRenderer } from 'ui/pages/shipments/ShipmentsListHeaderRenderers'
import {
	getDocumentImage,
	voidTrackingNumber,
} from '../../../shipper/functions'

const tPrefixTable = 'page.shipmentProfile.labels'

export const ShipperShipmentProfileLabels: FC = (props: {
	shipment: apiTypes.ShipmentResponse
}) => {
	const { shipment } = props
	const [documentUrl, setDocumentUrl] = useState<string>(null)
	const [isGettingLabelImage, setIsGettingLabelImage] = useState<boolean>(false)
	const [trackingNumbersBeingVoided, setTrackingNumbersBeingVoided] = useState<
		string[]
	>([])

	return (
		<React.Fragment>
			{/* Not Included in ticket */}
			{/*<PrintStationDropdown />   */}
			<table
				className={tableClasses.tableRates}
				cellPadding={0}
				cellSpacing={0}
				data-testid='rates-table'
			>
				<thead>
					<tr>
						<th>{t('weight', tPrefixTable)}</th>
						<th>{t('dimensions', tPrefixTable)}</th>
						<th>{t('tracking', tPrefixTable)}</th>
						<th>{t('labelsAndDocuments', tPrefixTable)}</th>
						<th>{t('packingSlip', tPrefixTable)}</th>
						<th>{t('void', tPrefixTable)}</th>
					</tr>
				</thead>
				<tbody>
					{l.map(shipment.payloads, (payload, payloadIdx) => {
						const containerDimensions = l.map(
							payload.containers,
							(container) =>
								`${container.length}" x ${container.width}" x ${container.height}" x `,
						)

						return (
							<tr key={payloadIdx}>
								<td>{payload.totalWeight}</td>
								<td>{stringArrayRenderer(containerDimensions)}</td>
								<td>{payload.masterTrackingNumber}</td>
								<td>
									{l.map(
										payload.shippingDocuments,
										(shippingDocument, shippingDocIdx) => (
											<div key={shippingDocument.id || shippingDocIdx}>
												<Button
													bootstrapStyles={true}
													isSmall={true}
													color='green'
													isSpinning={isGettingLabelImage}
													onClick={async () => {
														setIsGettingLabelImage(true)
														const image = await getDocumentImage(
															shippingDocument.id,
														)
														setDocumentUrl(image)
														setIsGettingLabelImage(false)
													}}
												>{`${tString('print', tPrefixTable)} ${tDocumentType(
													shippingDocument.type,
												)}`}</Button>
											</div>
										),
									)}
								</td>
								<td></td>
								<td>
									<Button
										bootstrapStyles={true}
										isSmall={true}
										color='red'
										isSpinning={l.includes(
											trackingNumbersBeingVoided,
											payload.masterTrackingNumber,
										)}
										isDisabled={!payload.masterTrackingNumber}
										onClick={async () => {
											setTrackingNumbersBeingVoided(
												trackingNumbersBeingVoided.concat(
													payload.masterTrackingNumber,
												),
											)
											await voidTrackingNumber(
												shipment.id,
												payload.masterTrackingNumber,
											)
											setTrackingNumbersBeingVoided(
												l.pull(
													l.cloneDeep(trackingNumbersBeingVoided),
													payload.masterTrackingNumber,
												),
											)
										}}
									>
										{t('void', tPrefixTable)}
									</Button>
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
			{documentUrl && (
				<DocumentViewer
					isOpen={true}
					documentUrl={documentUrl}
					onModalClose={() => setDocumentUrl(null)}
					base64Specification='application/pdf'
				/>
			)}
		</React.Fragment>
	)
}
