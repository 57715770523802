import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Card } from 'ui/components/common/card'
import { TitledBorderBox } from 'ui/components/common/titled-border-box'
import {
	FormStackedLayout,
	FormStackedSelect,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import { tBillToParty } from 'ui/components/i18n/commonTranslations'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { NewQuoteBillToForm } from 'ui/pages/new-quote/parcel/components/newQuoteForms'
import { CustomAddressForm } from 'ui/pages/new-quote/parcel/components/shared/forms'
import classes from './BillTo.module.scss'
import { FilterItem, Filters } from 'ui/components/common/filters'
import {
	mappedFedexParcelServiceLevels,
	mappedParcelCarrier,
	mappedUpsParcelServiceLevels,
} from 'ui/data/parcelServiceLevels'
import { NewQuoteParcelAddressBookModal } from '../NewQuoteParcelAddressBookModal'
import { ISelectOptions } from 'ui/components/common/select'

const tPrefix = 'page.newQuote.parcel.billTo'

const translate = (
	key: string,
	isString = false,
): string | React.ReactElement =>
	isString ? t(key, tPrefix) : tString(key, tPrefix)

export const BillToCard: FC = (props: {
	billTo: string
	form: NewQuoteBillToForm
	onUpdate: (form: NewQuoteBillToForm) => void
}) => {
	const { billTo, form, onUpdate } = props

	const requiredField = billTo !== 'Shipper'
	const [carrier, setCarrier] = useState<string>('')
	const [isAddressBookModalOpen, setIsAddressBookModalOpen] = useState<boolean>(
		false,
	)

	useEffect(() => {
		// onUpdate(
		// 	Object.assign({}, form, {
		// 		carrier: billTo,
		// 		serviceLevel: null,
		// 		accountNumber: null,
		// 		address: emptyAddressForm,
		// 	}),
		// )
		setCarrier(form.carrier)
	}, [billTo]) // eslint-disable-line react-hooks/exhaustive-deps

	const getServiceLevels = (carrier: string): ISelectOptions[] => {
		if (carrier === 'FedEx Parcel') {
			return mappedFedexParcelServiceLevels
		} else if (carrier === 'UPS Parcel') {
			return mappedUpsParcelServiceLevels
		} else {
			return null
		}
	}

	const formData: IFormData<NewQuoteBillToForm> = {
		form,
		metadata: {
			billToParty: { required: true },
			carrier: {
				required: requiredField,
				options: mappedParcelCarrier,
			},
			serviceLevel: {
				required: requiredField,
				options: getServiceLevels(carrier),
			},
			accountNumber: {},
		},
		onUpdateForm: (key: string, val: any) => {
			if (key === 'carrier') {
				setCarrier(val)
			}

			onUpdate(Object.assign({}, form, { [key]: val }))
		},
		tPrefix,
	}

	return (
		<>
			<Card title={translate('cardTitle')} color='darkBlue'>
				<span className={classes.cardSubTitle} data-testid='bill-to-card-title'>
					{tBillToParty(form.billToParty)}
				</span>
				{form.billToParty === 'thirdParty' && (
					<Filters>
						<FilterItem
							testId={'bill-to-address-book-btn'}
							onClick={() => {
								setIsAddressBookModalOpen(true)
							}}
						>
							{translate('addressBook')}
						</FilterItem>
					</Filters>
				)}
				<Spacer />
				<Row>
					<Col xs={2}>
						<FormStackedLayout>
							<FormStackedSelect
								formData={formData}
								field={'carrier'}
								testId={'bill-to-carrier'}
							/>
							<FormStackedSelect
								formData={formData}
								field={'serviceLevel'}
								testId={'bill-to-serviceLevel'}
								numListOptionsBeforeScroll={3}
							/>
							<FormStackedTextInput
								formData={formData}
								field='accountNumber'
								testId={'bill-to-accountNumber'}
							/>
						</FormStackedLayout>
					</Col>
					{form.billToParty === 'thirdParty' && (
						<Col xs={5}>
							<TitledBorderBox
								title={translate('addressTitle', true)}
								testId={'bill-to-address-form'}
							>
								<CustomAddressForm
									addressForm={form.address}
									testIdPrefix={'bill-to'}
									mode={'billTo'}
									onUpdate={(address) => {
										onUpdate(Object.assign({}, form, { address }))
									}}
								/>
							</TitledBorderBox>
						</Col>
					)}
				</Row>
				<Spacer />
			</Card>

			<NewQuoteParcelAddressBookModal
				isAddressBookModalOpen={isAddressBookModalOpen}
				onClose={() => {
					setIsAddressBookModalOpen(false)
				}}
				onClickRow={(address) => {
					const updatedAddress = { ...form }
					updatedAddress.address = {
						name: address.name,
						street1: address.street1,
						street2: address.street2,
						city: address.city,
						state: address.state,
						zip: address.zip,
						country: address.country,
						addressType: address.addressType,
						contactName: address.name,
						phone: address.phone,
						email: address.email,
					}
					onUpdate(updatedAddress)
					setIsAddressBookModalOpen(false)
				}}
			/>
		</>
	)
}
