import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { sosToast } from 'ui/common/components/toast'
import { Button } from 'ui/components/common/button/Button'
import { TitledBorderBox } from 'ui/components/common/titled-border-box'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { FC } from 'ui/FunctionalComponent'
import { l } from 'ui/lib/lodashImports'
import { toDollars } from 'ui/lib/numbers/toNumber'
import * as classes from './CreditLimit.module.scss'

const tPrefix = 'page.customerManagement.creditLimit'

const currencies = ['USD']
interface ICreditLimitForm {
	creditLimit: number
	creditLimitCurrency: string
	totalBalance: number
	invoicedBalance: number
	inTransitBalance: number
	creditUtilization: number
	canExceedCreditLimit: false
	emailRecipients: string
	emailWhenBeyondWarningLimit: boolean
	warningThreshold: number
}

const defaultCreditLimitForm: ICreditLimitForm = {
	creditLimit: 3000000,
	creditLimitCurrency: 'USD',
	totalBalance: 2446284,
	invoicedBalance: 2020050,
	inTransitBalance: 426234,
	creditUtilization: 82,
	canExceedCreditLimit: false,
	emailWhenBeyondWarningLimit: false,
	emailRecipients: '',
	warningThreshold: 0,
}

export const CreditLimit: FC = (props: {}) => {
	const [canExceedCreditLimit, setCanExceedCreditLimit] = useState<boolean>(
		false,
	)
	const [creditLimitForm, setCreditLimitForm] = useState<ICreditLimitForm>(
		() => defaultCreditLimitForm,
	)

	const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
		const name = e.currentTarget.name

		const value =
			e.currentTarget.type === 'checkbox'
				? e.currentTarget.checked
				: e.currentTarget.value

		if (name === 'canExceedCreditLimit') {
			setCanExceedCreditLimit(value === '0' ? false : true)
		}

		setCreditLimitForm({
			...creditLimitForm,
			[name]: value,
		})
	}

	const handleSubmit = (): any => {
		if (
			creditLimitForm.warningThreshold < 0 ||
			creditLimitForm.warningThreshold > 100
		) {
			return sosToast.sendToast({
				header: tString('warningThresholdErrorMessage', tPrefix),
				type: 'danger',
			})
		} else if (
			creditLimitForm.emailWhenBeyondWarningLimit === true &&
			creditLimitForm.emailRecipients.length === 0
		) {
			return sosToast.sendToast({
				header: tString('emailRecepientErrorMessage', tPrefix),
				type: 'danger',
			})
		} else {
			console.log('SAVE API CALL HERE!')
		}
	}

	const getCreditUtilization = (
		totalBalance: number,
		creditLimit: number,
	): number => {
		return Math.round((totalBalance / creditLimit) * 100)
	}

	return (
		<div className='bootstrap-wrapper'>
			<Card className={classes.cardContainer}>
				<Card.Header>
					<Row>
						<Col className={classes.cardTitle}>{t('creditLimit', tPrefix)}</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col xs={12} md={6}>
							<Row>
								<Col xs={7}>
									<Form.Group controlId='creditLimit'>
										<Form.Label>{t('creditLimit', tPrefix)}</Form.Label>
										<Form.Control
											name='creditLimit'
											type='number'
											onChange={handleChange}
											value={creditLimitForm.creditLimit}
											data-testid='creditLimitForm-creditLimit'
										></Form.Control>
									</Form.Group>
								</Col>
								<Col xs={5}>
									<Spacer height='13px' />
									<Form.Control
										name='creditLimitCurrency'
										as='select'
										custom={true}
										onChange={handleChange}
										value={`$ ${creditLimitForm.creditLimitCurrency}`}
										data-testid='creditLimitForm-creditLimitCurrency'
									>
										{l.map(currencies, (currency, currencyIdx) => (
											<option key={currencyIdx}>{currency}</option>
										))}
									</Form.Control>
								</Col>
							</Row>
							<Form.Group controlId='totalBalance'>
								<Form.Label>{t('totalBalance', tPrefix)}</Form.Label>
								<InputGroup>
									<InputGroup.Prepend>
										<InputGroup.Text>$</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control
										name='totalBalance'
										type='text'
										onChange={handleChange}
										value={toDollars(creditLimitForm.totalBalance).toFixed(2)}
										data-testid='creditLimitForm-totalBalance'
										disabled={true}
									></Form.Control>
								</InputGroup>
							</Form.Group>
							<Form.Group controlId='invoicedBalance'>
								<Form.Label>{t('invoicedBalance', tPrefix)}</Form.Label>
								<InputGroup>
									<InputGroup.Prepend>
										<InputGroup.Text>$</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control
										name='invoicedBalance'
										type='text'
										onChange={handleChange}
										value={toDollars(creditLimitForm.invoicedBalance).toFixed(
											2,
										)}
										data-testid='creditLimitForm-invoicedBalance'
										disabled={true}
									></Form.Control>
								</InputGroup>
							</Form.Group>
							<Form.Group controlId='inTransitBalance'>
								<Form.Label>{t('inTransitBalance', tPrefix)}</Form.Label>
								<InputGroup>
									<InputGroup.Prepend>
										<InputGroup.Text>$</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control
										name='inTransitBalance'
										type='text'
										onChange={handleChange}
										value={toDollars(creditLimitForm.inTransitBalance).toFixed(
											2,
										)}
										data-testid='creditLimitForm-inTransitBalance'
										disabled={true}
									></Form.Control>
								</InputGroup>
							</Form.Group>
							<Form.Group controlId='creditUtilization'>
								<Form.Label>{t('creditUtilization', tPrefix)}</Form.Label>
								<InputGroup>
									<Form.Control
										name='creditUtilization'
										type='text'
										onChange={handleChange}
										value={getCreditUtilization(
											creditLimitForm.totalBalance,
											creditLimitForm.creditLimit,
										)}
										data-testid='creditLimitForm-creditUtilization'
										disabled={true}
									></Form.Control>
									<InputGroup.Append>
										<InputGroup.Text>%</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col xs={12} md={6}>
							<div className={classes.cardRadioBox}>
								<Form.Group controlId='canExceedCreditLimit'>
									<Form.Label>{t('canExceedCreditLimit', tPrefix)}</Form.Label>
									<div>
										<Form.Check
											name='canExceedCreditLimit'
											inline={true}
											label={t('allow', tPrefix)}
											type='radio'
											value='1'
											checked={canExceedCreditLimit === true}
											onChange={handleChange}
										/>
										<Form.Check
											name='canExceedCreditLimit'
											inline={true}
											label={t('doNotAllow', tPrefix)}
											type='radio'
											value='0'
											checked={canExceedCreditLimit === false}
											onChange={handleChange}
										/>
									</div>
								</Form.Group>
							</div>

							<TitledBorderBox title={t('highBalanceAlerts', tPrefix)}>
								<div className={classes.cardCheckBox}>
									<Form.Group controlId='emailWhenBeyondWarningLimit'>
										<Form.Check
											name='emailWhenBeyondWarningLimit'
											label={t('emailWhenBeyondWarningLimit', tPrefix)}
											type='checkbox'
											id='checkbox1'
											checked={creditLimitForm.emailWhenBeyondWarningLimit}
											onChange={handleChange}
										/>
									</Form.Group>
								</div>

								<Form.Group controlId='emails'>
									<Form.Label>{t('emailRecipients', tPrefix)}</Form.Label>
									<Form.Control
										name='emailRecipients'
										type='text'
										onChange={handleChange}
										value={creditLimitForm.emailRecipients}
										placeholder='example@gmail.com, @example@gmail.com'
										data-testid='creditLimitForm-emails'
										disabled={!creditLimitForm.emailWhenBeyondWarningLimit}
									></Form.Control>
								</Form.Group>

								<Form.Group controlId='warningThreshold'>
									<Form.Label>{t('warningThreshold', tPrefix)}</Form.Label>
									<Row className={classes.warningTreshhold}>
										<Col>
											<InputGroup>
												<Form.Control
													name='warningThreshold'
													type='number'
													onChange={handleChange}
													value={creditLimitForm.warningThreshold}
													placeholder={tString('percent', tPrefix)}
													data-testid='creditLimitForm-warningThreshold'
												></Form.Control>
												<InputGroup.Append>
													<InputGroup.Text>%</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>
										</Col>
										<Col>
											<Form.Label>{t('ofCreditLimit', tPrefix)}</Form.Label>
										</Col>
									</Row>
								</Form.Group>
							</TitledBorderBox>

							<div className={classes.flexEnd}>
								<Button
									bootstrapStyles={true}
									color='green'
									className={classes.saveButton}
									onClick={() => {
										handleSubmit()
										// validateInput()
									}}
								>
									{t('save', tPrefix)}
								</Button>
							</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</div>
	)
}
