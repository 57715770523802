import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { apiProviders, apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button/Button'
import { Card } from 'ui/components/common/card/Card'
import { Modal } from 'ui/components/common/modal/Modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { IFormData } from 'ui/components/form/IFormData'
import { findFormDataErrors } from 'ui/components/form/validateFormData'
import { watch } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Center } from 'ui/components/layout/center'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { idx } from 'ui/lib/idx'
import { sos2 } from 'ui/lib/state/sos2'
import classes from 'ui/pages/spot-quote/MakeOfferPage.module.scss'
import { sosUser } from 'ui/state'
import { sosMakeOfferPage } from '.'
import { tPrefix } from '../shipment-profile/shared/components'
import { OfferDetailsOkCancelButtons } from './OfferDetailsOkCancelButtons'
import { OfferInformationForm } from './OfferInformationForm'
import { ProviderInformationForm } from './ProviderInformationForm'
import { IOfferInformation, IProviderInformation } from './sosMakeOfferPage'

export const tPrefixMakeOffer = 'page.makeOffer'

export const OfferDetailsCard: FC = (props: {
	logOffer?: boolean
	isBroker?: boolean
}) => {
	const state = sos2.useSubscription(sosMakeOfferPage.sos)
	const { logOffer, isBroker } = props
	const {
		offerInformation,
		shipmentIs,
		shipWith,
		providerInformation,
		errorMessage,
	} = state
	const offerStatus = state.savedRate?.offerStatus
	const providerInfoReadOnly =
		(offerStatus && !(offerStatus === 'active')) ||
		state.shipment.shipmentStatus === 'booked'

	const offerInfoReadOnly =
		(!(idx(() => state.savedRate.offerStatus) === 'active') && !logOffer) ||
		state.shipment.shipmentStatus === 'booked'

	const readOnlyContactFields = providerInfoReadOnly && !logOffer

	const formData: IFormData<IOfferInformation & IProviderInformation> = {
		form: {
			// provider details
			company: providerInformation?.company,
			name: providerInformation.name,
			phone: providerInformation.phone,
			cellPhone: providerInformation.cellPhone,
			email: providerInformation.email,
			mcNumber: providerInformation.mcNumber,
			usDotNumber: providerInformation.usDotNumber,
			scac: providerInformation.scac,
			carrier: providerInformation.carrier,
			freightForwarder: providerInformation.freightForwarder,
			broker: providerInformation.broker,
			notifyByEmail: providerInformation.notifyByEmail,
			fleet: providerInformation.fleet,
			otherCarrier: providerInformation.otherCarrier,
			carrierQuoteNumber: providerInformation.carrierQuoteNumber,
			truckload: providerInformation.truckload,
			ltlVolume: providerInformation.ltlVolume,
			billTo: providerInformation.billTo,

			// offer details
			transitDays: offerInformation.transitDays,
			linehaulCost: offerInformation.linehaulCost,
			linehaulCostType: offerInformation.linehaulCostType,
			fuelCost: offerInformation.fuelCost,
			fuelCostType: offerInformation.fuelCostType,
			currencyCode: offerInformation.currencyCode,
			otherCharge: offerInformation.otherCharge,
			estimatedMilesDistance: offerInformation.estimatedMilesDistance,
			priceExpires: offerInformation.priceExpires,
			message: offerInformation.message,
			quoteNumber: offerInformation.quoteNumber,
		},
		metadata: {
			// provider details
			company: { required: false, readOnly: readOnlyContactFields },
			name: { required: false, readOnly: readOnlyContactFields },
			phone: { required: true, readOnly: readOnlyContactFields },
			cellPhone: { required: false, readOnly: readOnlyContactFields },
			email: { required: true, readOnly: false },
			mcNumber: { required: true, readOnly: providerInfoReadOnly },
			usDotNumber: { required: false, readOnly: providerInfoReadOnly },
			scac: { required: false, readOnly: providerInfoReadOnly },
			carrier: { required: false, readOnly: providerInfoReadOnly },
			freightForwarder: { required: false, readOnly: providerInfoReadOnly },
			broker: { required: false, readOnly: providerInfoReadOnly },
			notifyByEmail: { required: false, readOnly: providerInfoReadOnly },
			fleet: { required: false, readOnly: providerInfoReadOnly },
			otherCarrier: { required: false, readOnly: providerInfoReadOnly },
			carrierQuoteNumber: { required: false, readOnly: providerInfoReadOnly },
			truckload: { required: false, readOnly: providerInfoReadOnly },
			ltlVolume: { required: false, readOnly: providerInfoReadOnly },
			billTo: { required: false, readOnly: false },

			// offer details
			transitDays: { required: true, readOnly: offerInfoReadOnly },
			linehaulCost: { required: true, readOnly: offerInfoReadOnly },
			linehaulCostType: { required: false, readOnly: offerInfoReadOnly },
			fuelCost: {
				required: false,
				readOnly:
					offerInformation.fuelCostType === 'included' || offerInfoReadOnly,
			},
			fuelCostType: { required: false, readOnly: offerInfoReadOnly },
			currencyCode: { required: false, readOnly: offerInfoReadOnly },
			otherCharge: { required: false, readOnly: offerInfoReadOnly },
			estimatedMilesDistance: { required: true, readOnly: offerInfoReadOnly },
			priceExpires: { required: false, readOnly: offerInfoReadOnly },
			message: { required: false, readOnly: offerInfoReadOnly },
			quoteNumber: {
				required:
					shipmentIs === 'ltlVolume' &&
					!(providerInformation.broker && shipWith === 'otherCarrier'),
				readOnly: offerInfoReadOnly,
			},
		},
		onUpdateForm: null,
		tPrefix: tPrefixMakeOffer,
	}
	
	const [mcNumberErrorModal, updateMcNumberErrorModal] = useState(false)
	const [contractCheckModal, updateContractCheckModal] = useState(false)
	const [activeValidation, setActiveValidation] = useState(false)
	const [validationErrors, setValidationErrors] = useState<string[]>([])
	const [selectedProvider, setSelectedProvider] = useState<apiTypes.ProviderResponse>()

	const activeBrokerName = sosUser.getSos().getState().requestUserInfo?.data?.company?.companyName

	watch(() => {
		if (activeValidation) {
			setValidationErrors(
				findFormDataErrors(formData).map((c) => `${c.field} ${c.reason}`),
			)
		}
	}, [state])



	return (
		<Card
			title={t('offerDetails', tPrefixMakeOffer)}
			color='darkBlue'
			fullWidth={true}
		>
			<FlexRow fullWidth={true}>
				<FlexItem>
					<ProviderInformationForm 
					logOffer={logOffer} 
					formData={formData}
					onProviderSelected = {(provider:apiTypes.ProviderResponse)=>{setSelectedProvider(provider)}}
					 />
				</FlexItem>
				<FlexItem>
					<OfferInformationForm
						readOnly={offerInfoReadOnly}
						formData={formData}
					/>
				</FlexItem>
			</FlexRow>
			<AlignRight>
				<OfferDetailsOkCancelButtons
					offerStatus={idx(() => state.savedRate.offerStatus)}
					isBroker={isBroker}
					formData={formData}
					errors={validationErrors}
					setValidationErrors={setValidationErrors}
					isValid={!state.editingConnection}
				/>
				{props.logOffer && (
					<OkCancelButtons
						ok={t('logOffer', tPrefixMakeOffer)}
						cancel={t('cancel', tPrefixMakeOffer)}
						onCancel={sosMakeOfferPage.cancelLogOffer}
						errors={validationErrors}
						okColor='green'
						isSpinning={state.isMakingOffer}
						okTestId='make-offer-ok'
						isValid={validationErrors.length === 0 && !state.editingConnection}
						inlineErrors={true}
						onOk={async () => {
							const errors = findFormDataErrors(formData).map(
								(c) => `${c.field} ${c.reason}`,
							)
							
							setValidationErrors(errors)
							
							if (errors.length) {
								setActiveValidation(true)
								return
							}
							const validateExistingContract:any = async (providerCompany)=>{
								const contractCheck = await apiProviders.checkExistingContract(null, providerCompany)
								if (contractCheck.error){
									updateContractCheckModal(true)
									return 
								}		
							}
							 
							if (!selectedProvider){
							
								const response = await apiProviders.getProviders(
									(rs) => {},
									1,
									0,
									`mcNumber:${providerInformation.mcNumber}`
								)
	
								const {data} = response;
								const companySearch = data.entities[0];
								if (response.data && companySearch){
									sosMakeOfferPage.setProvider(companySearch)
									validateExistingContract(companySearch.id)		
									return						
								} else  {
									updateMcNumberErrorModal(true)
									return
								}
							} else {
								if (!selectedProvider.mcNumber?.includes(providerInformation.mcNumber)){
									updateMcNumberErrorModal(true)
									return
								} else {
									validateExistingContract(selectedProvider.id)
									return
								}
							}
						}}
					/>
				)}
			</AlignRight>
			<Modal
				title={t('finalizeShipment', tPrefixMakeOffer)}
				onModalClose={() => sosMakeOfferPage.toggleModal('accept')}
				content={() => (
					<div className={classes.modalShipment}>
						{t('acceptShipmentMessage', tPrefixMakeOffer)}
						<Center>
							<div className={classes.modalShipmentButton}>
								<Button
									color='green'
									isSpinning={state.isUpdatingOffer}
									onClick={() =>
										sosMakeOfferPage.setOfferStatus('booked', isBroker)
									}
								>
									{t('acceptShipment', tPrefixMakeOffer)}
								</Button>
							</div>
						</Center>
					</div>
				)}
				isOpen={state.acceptModalOpen}
			/>
			<Modal
				title={'Decline Shipment'}
				onModalClose={() => sosMakeOfferPage.toggleModal('decline')}
				content={() => (
					<div className={classes.modalShipment}>
						<Center>
							{t('declineShipment', tPrefixMakeOffer)}

							<div className={classes.modalShipmentButton}>
								<Button
									color='red'
									isSpinning={state.isUpdatingOffer}
									onClick={() =>
										sosMakeOfferPage.setOfferStatus('declined', isBroker)
									}
								>
									{t('declineShipment', tPrefixMakeOffer)}
								</Button>
							</div>
						</Center>
					</div>
				)}
				isOpen={state.declineModalOpen}
			/>
			<Modal
				title={'Status'}
				onModalClose={() => sosMakeOfferPage.toggleModal('success')}
				content={() => (
					<div className={classes.modalShipment}>
						<Center>
							{t('success', tPrefixMakeOffer)}
							<div className={classes.modalShipmentButton}>
								<Button
									color='green'
									onClick={() => sosMakeOfferPage.toggleModal('success')}
								>
									{t('okay', tPrefixMakeOffer)}
								</Button>
							</div>
						</Center>
					</div>
				)}
				isOpen={state.successModalOpen}
			/>
			<Modal
				title='Error'
				onModalClose={() => sosMakeOfferPage.toggleModal('failure')}
				content={() => (
					<div className={classes.modalShipment}>
						{t('operationFailed', tPrefixMakeOffer)}
						<div>{errorMessage}</div>
						<Center>
							<div className={classes.modalShipmentButton}>
								<Button
									color='orange'
									onClick={() => sosMakeOfferPage.toggleModal('failure')}
								>
									{t('okay', tPrefixMakeOffer)}
								</Button>
							</div>
						</Center>
					</div>
				)}
				isOpen={state.failureModalOpen}
			/>
			<Modal
				title='Error'
				onClose={() => updateMcNumberErrorModal(false)}
				content={() => (
					<div className={classes.modalShipment}>
						<div>
							{
							'This MC Number does not match a provider. Please correct the MC Number, or ask your administrator to add the provider to the TMS'
							}
						</div>
						<Center>
							<div className={classes.modalShipmentButton}>
								<Button
									color='orange'
									onClick={() => updateMcNumberErrorModal(false)
									}
								>
									{t('okay', tPrefixMakeOffer)}
								</Button>
							</div>
						</Center>
					</div>
				)}
				isOpen={mcNumberErrorModal}
			/>
			<Modal
				title='Notice'
				onClose={() => updateContractCheckModal(false)}
				content={() => (
					<div className={classes.modalShipment}>
						<div>
							{
							`This is the first time ${activeBrokerName} has tendered with ${providerInformation.company}. 
							Please verify that the correct provider was chosen for this rate. 
							Do you want to ${shipmentIs==='truckload'?'award':'book'} this shipment with ${providerInformation.company} anyway?`
							}
						</div>
						<AlignRight>
							<OkCancelButtons
								isValid = {true}
								ok={t('ok',tPrefix)}
								okColor='green'
								okTestId={'contract-check-modal-ok'}
								onOk={ async ()=>{
									await sosMakeOfferPage.logOffer(isBroker)
								}}
								cancel={t('cancel',tPrefix)}
								onCancel={()=>{
									updateContractCheckModal(false)
								}}
							/>
							
						</AlignRight>
					</div>
				)}
				isOpen={contractCheckModal}
			/>
		</Card>
	)
}


