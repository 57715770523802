import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { testId } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import commonStyles, * as commonClasses from 'ui/theme/common.module.scss'
import { FontSizes } from 'ui/theme/theme'
import { Icon, regularIcons, solidIcons } from '../icon'
import classes from './Checkbox.module.scss'

export const Checkbox: FC = (props: {
	value: boolean
	indeterminate?: boolean
	onChange: (newValue: boolean) => void
	testId?: string
	children: React.ReactNode
	readOnly?: boolean
	fontSize?: FontSizes
}) => {
	const onChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
		props.onChange(props.indeterminate ? false : ev.target.checked)
	}

	const checkboxStyle = `${classes.checkbox} ${commonStyles.noTextSelect}`
	let icon = regularIcons.faSquare

	if (props.indeterminate) {
		icon = regularIcons.faMinusSquare
	} else if (props.value) {
		icon = solidIcons.faCheckSquare
	}

	return (
		<div className={checkboxStyle}>
			<label className={props.readOnly ? '' : commonStyles.clickable}>
				<div className={classes.whiteBackground}>
					<input
						type='checkbox'
						checked={props.value}
						onChange={onChange}
						disabled={props.readOnly}
						data-testid={props.testId}
						data-readonly={props.readOnly}
					/>
				</div>

				<div className={classes.icon}>
					<Icon icon={icon} />
				</div>
				<Spacer />
				<div className={commonClasses[props.fontSize]}>{props.children}</div>

				{testId(props.testId)}
				{/* <span className={prettyStyle} /> */}
			</label>
		</div>
	)
}
