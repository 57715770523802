import { apiTypes } from 'ui/api'

export interface NewQuoteAddressForm {
	name?: string
	street1?: string
	street2?: string
	city?: string
	state: string
	zip?: string
	country?: string
	addressType?: apiTypes.Address['addressType']
	contactName?: string
	phone?: string
	email?: string
	locationId?: string
}

export interface NewQuoteAccessorialsForm {
	cod: boolean
	codAmount: number
	moneyOrderOnly: boolean

	fedexSmartPost: boolean
	smartPostHub: string

	holdAtCarrier: boolean
	holdAtCarrierPhone: string
	holdAtCarrierStreet: string
	holdAtCarrierCity: string
	holdAtCarrierState: string
	holdAtCarrierZip: string
	holdAtCarrierCountry: string

	protectFromFreeze: boolean

	saturdayDelivery: boolean

	signatureRequired: boolean
	signatureType: apiTypes.ShipmentAccessorialsInfo['signatureType']
}

export interface NewQuotePackageForm {
	quantity: number
	reference1: any
	reference2: any
	declaredValue: number
	packagePageId: string
	length: number
	width: number
	height: number
	weight: number
}

export interface NewQuoteBillToForm {
	billToParty: apiTypes.BillTo['billToThisParty']
	carrier: string
	serviceLevel: apiTypes.ServiceLevel
	accountNumber: string
	address?: NewQuoteAddressForm
	locationId?: string
}

export const emptyAddressForm: NewQuoteAddressForm = {
	name: '',
	street1: '',
	street2: '',
	city: '',
	state: '',
	zip: '',
	country: 'US',
	addressType: null,
	contactName: '',
	phone: '',
	email: '',
	locationId: '',
}

export const emptyAccessorialForm: NewQuoteAccessorialsForm = {
	cod: false,
	codAmount: null,
	moneyOrderOnly: false,

	fedexSmartPost: false,
	smartPostHub: null,

	holdAtCarrier: false,
	holdAtCarrierPhone: '',
	holdAtCarrierStreet: '',
	holdAtCarrierCity: '',
	holdAtCarrierState: '',
	holdAtCarrierZip: '',
	holdAtCarrierCountry: '',

	protectFromFreeze: false,

	saturdayDelivery: false,

	signatureRequired: false,
	signatureType: null,
}

export const emptyPackageForm: NewQuotePackageForm = {
	quantity: null,
	reference1: '',
	reference2: '',
	declaredValue: null,
	packagePageId: null,
	length: null,
	width: null,
	height: null,
	weight: null,
}

export const emptyBillToForm: NewQuoteBillToForm = {
	billToParty: 'shipper',
	carrier: null,
	serviceLevel: null,
	accountNumber: '',
	address: emptyAddressForm,
}
