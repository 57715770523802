import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import { AsyncTypeahead } from 'common/components/typeahead/AsyncTypeahead'
import { TypeaheadOption } from 'common/components/typeahead/TypeaheadOption'
import React, { useState } from 'react'
import { apiProviders, apiTypes } from 'ui/api'
import { ProviderListResponse } from 'ui/api/apiTypes'
import { IRequestState } from 'ui/api/requestState'
import { tProviderName } from 'ui/components/i18n/commonTranslations'
import { tString } from 'ui/components/i18n/i18n'
import { processSearchString } from 'ui/lib/elasticSearch'
import { l } from 'ui/lib/lodashImports'

export const ProviderSelector: FC = (props: {
	onChange?: (selected: apiTypes.ProviderResponse) => void
	currentlySelectedProvider?: TypeaheadOption
	tPrefix?: string
	width?: string
}) => {
	const [providers, setProviders] = useState<apiTypes.ProviderResponse[]>([])

	return (
		<AsyncTypeahead
			testId='provider-list-search'
			size='small'
			width={props.width || '300px'}
			options={[]}
			onSearch={async (searchTerm: string): Promise<TypeaheadOption[]> => {
				const processedSearchTerm = processSearchString(searchTerm)
				const response: IRequestState<ProviderListResponse> = await apiProviders.getProviders(
					(rs) => {},
					25,
					0,
					`providerName:${processedSearchTerm} OR name:${processedSearchTerm}`,
				)

				if (response.error) {
					sosToast.sendApiErrorResponseToast(
						response,
						tString('providerFetchError', 'common'),
					)

					return []
				}

				setProviders(response.data.entities)

				let responseOptions: TypeaheadOption[] = []
				if (response.data) {
					responseOptions = response.data.entities.map((c) => ({
						value: c.id,
						label: tProviderName(c.providerName) || c.name || c.id,
					}))
				}

				return responseOptions
			}}
			value={props.currentlySelectedProvider}
			onChange={(selected: TypeaheadOption) => {
				props.onChange(l.find(providers, (p) => p.id === selected?.value))
			}}
			placeholder={tString('filterByProvider', props.tPrefix)}
			isClearable={true}
			useCache={false}
		/>
	)
}
