import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import { apiProviderInvoice, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosToast } from 'ui/common/components/toast'
import { AsyncTypeahead, TypeaheadOption } from 'ui/common/components/typeahead'
import { Button } from 'ui/components/common/button'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Input } from 'ui/components/common/input'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { FormTextInput, IFormData } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { FC } from 'ui/FunctionalComponent'
import { l } from 'ui/lib/lodashImports'
import * as classes from './ProviderCharges.module.scss'

export const ProviderCharges: FC = (props: {
	shipmentId: string
	providerChargeCodes: apiTypes.ChargeCodePageResponse[]
	providerInvoice: apiTypes.ProviderInvoiceResponse
	providerInvoiceCharges: apiTypes.ProviderInvoiceChargeResponse[]
	setProviderInvoiceCharges: React.Dispatch<
		React.SetStateAction<apiTypes.ProviderInvoiceChargeResponse[]>
	>
	tPrefix?: string
}) => {
	const {
		shipmentId,
		providerChargeCodes,
		providerInvoice,
		providerInvoiceCharges,
		setProviderInvoiceCharges,
		tPrefix,
	} = props

	const [providerInvoiceChargeId, setProviderInvoiceChargeId] = useState<
		string
	>(null)

	const [
		providerInvoiceChargeRequest,
		setProviderInvoiceChargeRequest,
	] = useState<apiTypes.ProviderInvoiceChargeRequest>(null)

	const [toggleChargeCode, setToggleChargeCode] = useState<boolean>(false)
	const [toggleChargeDescription, setToggleChargeDescription] = useState<
		boolean
	>(false)
	const [selectedChargeCode, setSelectedChargeCode] = useState<string>('')
	const [selectedChargeDescription, setSelectedChargeDescription] = useState<
		string
	>(null)

	const [isUpdatingCharge, setIsUpdatingCharge] = useState<boolean>(false)
	const [isAddingCharge, setIsAddingCharge] = useState<boolean>(false)

	const [isEditingChargeRow, setIsEditingChargeRow] = useState<number>(null)
	const [isDeletingChargeRow, setIsDeletingChargeRow] = useState<number>(null)

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
	const [isDeletingCharge, setIsDeletingCharge] = useState<boolean>(false)

	const updateProviderInvoiceChargeForm = (
		providerInvoiceChargeResponse: IRequestState<
			apiTypes.ProviderInvoiceChargeResponse
		>,
		updateMode: 'upsert' | 'delete',
	): void => {
		const updateInvoiceChargesResponse = l.cloneDeep(providerInvoiceCharges)

		updateInvoiceChargesResponse.splice(
			updateMode === 'upsert' ? isEditingChargeRow : isDeletingChargeRow,
			1,
			providerInvoiceChargeResponse.data,
		)

		setProviderInvoiceCharges(updateInvoiceChargesResponse)
	}

	const addProviderCharge = async (): Promise<void> => {
		const newProviderInvoiceChargeResponse: apiTypes.ProviderInvoiceChargeResponse = {
			shipmentId: shipmentId,
			chargeCode: '',
			chargeDescription: '',
			quantity: 1,
			unitPrice: 0,
			id: '',
			providerInvoiceId: providerInvoice.id,
			totalPrice: 0,
		}

		if (providerInvoiceCharges) {
			providerInvoiceCharges.unshift(newProviderInvoiceChargeResponse)
			setProviderInvoiceCharges(providerInvoiceCharges)
		} else {
			setProviderInvoiceCharges([newProviderInvoiceChargeResponse])
		}

		await createProviderInvoiceCharge()

		setIsEditingChargeRow(0)
	}

	const createProviderInvoiceCharge = async (): Promise<void> => {
		setIsAddingCharge(true)

		const newProviderInvoiceChargeRequest: apiTypes.ProviderInvoiceChargeRequest = {
			shipmentId: shipmentId,
			chargeCode: '',
			chargeDescription: '',
			quantity: 1,
			unitPrice: 0,
		}

		const providerInvoiceChargeResponse = await apiProviderInvoice.createProviderInvoiceCharge(
			() => {},
			providerInvoice.id,
			newProviderInvoiceChargeRequest,
		)

		if (providerInvoiceChargeResponse.error) {
			sosToast.sendApiErrorResponseToast(providerInvoiceChargeResponse)
		} else {
			updateProviderInvoiceChargeForm(providerInvoiceChargeResponse, 'upsert')

			setProviderInvoiceChargeId(providerInvoiceChargeResponse.data.id)
			setProviderInvoiceChargeRequest(newProviderInvoiceChargeRequest)
		}

		setIsAddingCharge(false)
	}

	const updateProviderInvoiceCharge = async (): Promise<void> => {
		setIsUpdatingCharge(true)

		const updatedProviderInvoiceChargeRequest = {
			...providerInvoiceChargeRequest,
			chargeCode:
				chargeCodeTypehead?.value === undefined
					? updateChargeCode(chargeDescriptionTypehead, isEditingChargeRow)
					: chargeCodeTypehead?.value,
			chargeDescription:
				chargeDescriptionTypehead?.value === undefined
					? updateChargeDescription(chargeCodeTypehead, isEditingChargeRow)
					: chargeDescriptionTypehead?.value,
		}

		const providerInvoiceChargeResponse = await apiProviderInvoice.updateProviderInvoiceCharge(
			providerInvoice.id,
			providerInvoiceChargeId,
			updatedProviderInvoiceChargeRequest,
		)

		if (providerInvoiceChargeResponse.error) {
			sosToast.sendApiErrorResponseToast(providerInvoiceChargeResponse)
		} else {
			updateProviderInvoiceChargeForm(providerInvoiceChargeResponse, 'upsert')
		}

		setIsUpdatingCharge(false)
	}

	const deleteProviderInvoiceCharge = async (): Promise<void> => {
		setIsDeletingCharge(true)

		const providerInvoiceChargeResponse = await apiProviderInvoice.deleteProviderInvoiceCharge(
			providerInvoice.id,
			providerInvoiceChargeId,
		)

		if (providerInvoiceChargeResponse.error) {
			sosToast.sendApiErrorResponseToast(providerInvoiceChargeResponse)
		} else {
			updateProviderInvoiceChargeForm(providerInvoiceChargeResponse, 'delete')
		}

		setIsDeletingCharge(false)
	}

	const getTotalPrice = (qty: number, unitPrice: number): number => {
		return qty * unitPrice
	}

	const updateChargeCode = (str: TypeaheadOption, idx: number): string => {
		if (selectedChargeDescription !== null && idx === isEditingChargeRow) {
			const filteredCharges = l.find(
				providerChargeCodes,
				(c) => c.chargeCodeDescription === str.value,
			)
			return filteredCharges?.chargeCode
		}
	}

	const updateChargeDescription = (
		str: TypeaheadOption,
		idx: number,
	): string => {
		if (selectedChargeCode !== null && idx === isEditingChargeRow) {
			const filteredCharges = l.find(
				providerChargeCodes,
				(c) => c.chargeCode === str.value,
			)
			return filteredCharges?.chargeCodeDescription
		}
	}

	let chargeCodeTypehead: TypeaheadOption
	let chargeDescriptionTypehead: TypeaheadOption

	if (selectedChargeCode !== null) {
		chargeCodeTypehead = {
			label: selectedChargeCode,
			value: selectedChargeCode,
		}
	}

	if (selectedChargeDescription !== null) {
		chargeDescriptionTypehead = {
			label: selectedChargeDescription,
			value: selectedChargeDescription,
		}
	}

	return (
		<div className={classes.providerCharges}>
			<div className={classes.titleContainer}>
				<h4>{t('providerCharges', tPrefix)}</h4>
				<Button
					color={'green'}
					onClick={async () => {
						await addProviderCharge()
					}}
					isSpinning={isAddingCharge}
				>
					{t('addCharge', tPrefix)}
				</Button>
			</div>

			<Table striped>
				<thead>
					<tr>
						<th>{t('chargeCode', tPrefix)}</th>
						<th>{t('chargeDescription', tPrefix)}</th>
						<th>{t('quantity', tPrefix)}</th>
						<th>{t('unitPrice', tPrefix)}</th>
						<th>{t('totalCost', tPrefix)}</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{l.map(providerInvoiceCharges || [], (providerInvoiceCharge, idx) => {
						if (
							providerInvoiceCharge.providerInvoiceId === providerInvoice.id
						) {
							const formData: IFormData<apiTypes.ProviderInvoiceChargeResponse> = {
								form: providerInvoiceCharge,

								metadata: {
									shipmentId: {},
									chargeCode: {},
									chargeDescription: {},
									quantity: {},
									unitPrice: {},
									id: {},
									providerInvoiceId: {},
									totalPrice: {},
								},

								onUpdateForm: (field: string, value: any) => {
									const updateInvoiceChargesRequest = l.cloneDeep(
										providerInvoiceCharges,
									)

									const updateInvoiceRequest = l.cloneDeep(
										providerInvoiceCharge,
									)

									updateInvoiceRequest[field] = value

									updateInvoiceChargesRequest.splice(
										idx,
										1,
										updateInvoiceRequest,
									)

									setProviderInvoiceChargeRequest(updateInvoiceRequest)
									setProviderInvoiceCharges(updateInvoiceChargesRequest)
								},

								tPrefix,
							}

							return (
								<tr key={`${providerInvoiceCharge.chargeCode} ${idx}`}>
									<td>
										{toggleChargeCode && idx === isEditingChargeRow ? (
											<Input
												value={
													idx === isEditingChargeRow && toggleChargeDescription
														? chargeCodeTypehead?.value
														: updateChargeCode(chargeDescriptionTypehead, idx)
												}
												onFocus={() => setToggleChargeCode(false)}
												onBlur={() => setToggleChargeCode(false)}
												className={
													idx === isEditingChargeRow
														? classes.enabledInput
														: classes.disabledInput
												}
												readOnly={true}
											/>
										) : (
											<AsyncTypeahead
												testId={'cutomer-invoice-chargeCode-input'}
												size={'small'}
												options={[]}
												onSearch={async () => {
													let responseOptions: TypeaheadOption[] = []

													if (providerChargeCodes) {
														responseOptions = providerChargeCodes.map((c) => ({
															value: c.chargeCode,
															label: c.chargeCode,
														}))
													}

													return responseOptions
												}}
												onChange={(selected: TypeaheadOption) =>
													setSelectedChargeCode(selected?.value)
												}
												onFocus={() => setToggleChargeDescription(true)}
												onBlur={() => setToggleChargeDescription(false)}
												isClearable={true}
												useCache={true}
												className={`${classes.asyncTypehead} ${classes.asyncTypeheadChargeCode}`}
												disabled={idx !== isEditingChargeRow}
												value={
													idx === isEditingChargeRow && toggleChargeDescription
														? chargeCodeTypehead
														: updateChargeCode(chargeDescriptionTypehead, idx)
												}
												defaultInputValue={providerInvoiceCharge.chargeCode}
											/>
										)}
									</td>
									<td>
										{toggleChargeDescription && idx === isEditingChargeRow ? (
											<Input
												value={
													idx === isEditingChargeRow && toggleChargeCode
														? chargeDescriptionTypehead?.value
														: updateChargeDescription(chargeCodeTypehead, idx)
												}
												onFocus={() => {
													setToggleChargeDescription(false)
												}}
												onBlur={() => {
													setToggleChargeDescription(false)
												}}
												className={
													idx === isEditingChargeRow
														? classes.enabledInput
														: classes.disabledInput
												}
												readOnly={true}
											/>
										) : (
											<AsyncTypeahead
												testId={'provider-invoice-chargeDescription-input'}
												size={'small'}
												options={[]}
												onSearch={async () => {
													let responseOptions: TypeaheadOption[] = []

													if (providerChargeCodes) {
														responseOptions = providerChargeCodes.map((c) => ({
															value: c.chargeCodeDescription,
															label: c.chargeCodeDescription,
														}))
													}

													return responseOptions
												}}
												onChange={(selected: TypeaheadOption) => {
													setSelectedChargeDescription(selected?.value)
												}}
												onFocus={() => setToggleChargeCode(true)}
												onBlur={() => setToggleChargeCode(false)}
												isClearable={true}
												useCache={true}
												className={classes.asyncTypehead}
												disabled={idx !== isEditingChargeRow}
												value={
													idx === isEditingChargeRow && toggleChargeCode
														? chargeDescriptionTypehead
														: updateChargeDescription(chargeCodeTypehead, idx)
												}
												defaultInputValue={
													providerInvoiceCharge.chargeDescription
												}
											/>
										)}
									</td>
									<td>
										<FormTextInput
											form={formData.form}
											field={'quantity'}
											onUpdateForm={formData.onUpdateForm}
											className={classes.alignRight}
											readOnly={idx !== isEditingChargeRow}
											testId={'provider-invoice-charge-quantity-input'}
										/>
									</td>
									<td>
										{idx !== isEditingChargeRow ? (
											<Input
												value={`$ ${providerInvoiceCharge.unitPrice.toFixed(
													2,
												)}`}
												readOnly={true}
												className={classes.alignRight}
											/>
										) : (
											<FormTextInput
												form={formData.form}
												field={'unitPrice'}
												onUpdateForm={formData.onUpdateForm}
												className={classes.alignRight}
												testId={'provider-invoice-charge-unitPrice-input'}
											/>
										)}
									</td>
									<td>
										<Input
											value={`$ ${getTotalPrice(
												formData.form.quantity,
												formData.form.unitPrice,
											).toFixed(2)}`}
											readOnly={true}
											className={classes.alignRight}
											testId={'provider-invoice-charge-totalPrice-input'}
										/>
									</td>
									<td>
										<div className={classes.iconButtons}>
											{providerInvoice.invoiceStatus === 'review' && (
												<IconButton
													icon={
														idx === isEditingChargeRow
															? solidIcons.faCheck
															: solidIcons.faPencilAlt
													}
													buttonClassName={
														idx === isEditingChargeRow
															? classes.save
															: classes.edit
													}
													color={idx === isEditingChargeRow ? 'green' : 'black'}
													onClick={async () => {
														if (idx === isEditingChargeRow) {
															await updateProviderInvoiceCharge()
															setIsEditingChargeRow(null)
														} else {
															setProviderInvoiceChargeId(
																providerInvoiceCharge.id,
															)
															setProviderInvoiceChargeRequest(
																providerInvoiceCharge,
															)
															setIsEditingChargeRow(idx)
														}
													}}
													spin={idx === isEditingChargeRow && isUpdatingCharge}
													testId={'provider-invoice-charge-editSave'}
												></IconButton>
											)}
											<IconButton
												icon={solidIcons.faTimes}
												buttonClassName={classes.cancel}
												color={'red'}
												onClick={async () => {
													if (isEditingChargeRow !== null) {
														setIsEditingChargeRow(null)
													} else {
														setProviderInvoiceChargeId(providerInvoiceCharge.id)
														setIsDeletingChargeRow(idx)
														setIsDeleteModalOpen(true)
													}
												}}
												testId={'provider-invoice-charge-cancel'}
											></IconButton>
										</div>
									</td>
								</tr>
							)
						}
					})}
				</tbody>
			</Table>
			<Modal
				content={() => (
					<div data-testid={'provider-invoice-charge-delete-modal'}>
						<p>{t('deleteThisInvoiceCharge?', tPrefix)}</p>
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'provider-invoice-charge-delete-modal-ok'}
								isSpinning={isDeletingCharge}
								onOk={async () => {
									await deleteProviderInvoiceCharge()
									setIsDeletingChargeRow(null)
									setIsDeleteModalOpen(false)
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => {
									setIsDeletingChargeRow(null)
									setIsDeleteModalOpen(false)
								}}
								cancelTestId={'provider-invoice-charge-delete-modal-cancel'}
							></OkCancelButtons>
						</AlignRight>
					</div>
				)}
				isOpen={isDeleteModalOpen}
				onModalClose={() => {}}
				title={t('confirmDelete', tPrefix)}
			/>
		</div>
	)
}
