import React, { useState, useEffect } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { Card } from 'ui/components/common/card'
import { t } from 'ui/components/i18n/i18n'
import { Button } from 'ui/components/common/button'
import * as classes from './ProviderInvoice.module.scss'
import { ProviderCharges } from './provider-charges'
import { ProviderInvoiceTitle } from './provider-invoice-title/ProviderInvoiceTitle'
import { ProviderInvoiceNotes } from './provider-invoice-notes'
import { apiInvoices, apiProviderInvoice, apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'
import { sosToast } from 'ui/common/components/toast'
import { fireAndForget } from 'ui/lib/async'
import { Spacer } from 'ui/components/layout/spacer'
import { Loader } from 'ui/components/common/loader'
import { Center } from 'ui/components/layout/center'

const tPrefix = 'page.customerInvoice.providerInvoice'

export const ProviderInvoice: FC = (props: {
	shipmentId: string
	providerChargeCodes: apiTypes.ChargeCodePageResponse[]
	clientConfigId: string
	brokerInvoiceId: string
}) => {
	const {
		shipmentId,
		clientConfigId,
		brokerInvoiceId,
		providerChargeCodes,
	} = props

	const [providerInvoices, setProviderInvoices] = useState<
		apiTypes.ProviderInvoiceResponse[]
	>(null)

	const [providerInvoiceCharges, setProviderInvoiceCharges] = useState<
		apiTypes.ProviderInvoiceChargeResponse[]
	>(() => [])

	const [isFetchingProviderInvoices, setIsFetchingProviderInvoices] = useState<
		boolean
	>(false)

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const getProviderInvoices = async (): Promise<void> => {
		setIsFetchingProviderInvoices(true)

		const shipmentInvoiceResponse = await apiInvoices.getInvoicesByShipmentId(
			shipmentId,
		)

		if (shipmentInvoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(shipmentInvoiceResponse)
		} else {
			setProviderInvoices(shipmentInvoiceResponse.data.providerInvoices)

			const providerChargesArray = shipmentInvoiceResponse.data.providerInvoices.map(
				(p) => p.charges,
			)

			setProviderInvoiceCharges(l.flatMap(providerChargesArray))
		}

		setIsFetchingProviderInvoices(false)
	}

	useEffect(() => {
		fireAndForget(
			async () => getProviderInvoices(),
			`Get Invoices From Shipment`,
		)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const addProviderInvoice = async (): Promise<void> => {
		setIsLoading(true)

		const providerInvoiceRequest: apiTypes.ProviderInvoiceCreateRequest = {
			shipmentId: shipmentId,
			clientConfigId: clientConfigId,
			brokerInvoiceId: brokerInvoiceId,
			invoiceNumber: '',
			invoiceStatus: 'review',
			invoiceDate: '',
			paymentTerms: 0,
			currency: 'USD',
			notes: '',
			creationSource: 'Customer Invoicing',
			charges: [],
		}

		const providerInvoiceResponse = await apiProviderInvoice.createProviderInvoice(
			() => {},
			providerInvoiceRequest,
		)

		if (providerInvoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(providerInvoiceResponse)
		} else {
			if (providerInvoices) {
				providerInvoices.push(providerInvoiceResponse.data)
				setProviderInvoices(providerInvoices)
			} else {
				setProviderInvoices([providerInvoiceResponse.data])
			}
		}

		setIsLoading(false)
	}

	return (
		<Card
			title={
				<div className={classes.titleContainer}>
					{t('providerInvoice', tPrefix)}
					<Button
						color={'green'}
						onClick={addProviderInvoice}
						isSpinning={isLoading}
						isDisabled={isFetchingProviderInvoices}
					>
						{t('addProviderInvoice', tPrefix)}
					</Button>
				</div>
			}
			color={'darkBlue'}
			testId={'customer-invoice-provider-invoice'}
		>
			{!providerInvoices?.length && !isFetchingProviderInvoices ? (
				<div className={classes.noDataWarning}>
					{t('noLinkedProviderInvoices', tPrefix)}
				</div>
			) : isFetchingProviderInvoices ? (
				<Center>
					<Loader isLoading={isFetchingProviderInvoices} />
				</Center>
			) : (
				l.map(providerInvoices, (providerInvoice, idx) => {
					return (
						<div key={`${providerInvoice} ${idx}`}>
							<Spacer />
							<ProviderInvoiceTitle
								shipmentId={shipmentId}
								providerInvoice={providerInvoice}
								getProviderInvoices={getProviderInvoices}
								fetchProviderInvoices={getProviderInvoices}
								tPrefix={tPrefix}
							/>
							<Spacer height={'20px'} />
							<ProviderCharges
								shipmentId={shipmentId}
								providerChargeCodes={providerChargeCodes}
								providerInvoice={providerInvoice}
								providerInvoiceCharges={providerInvoiceCharges}
								setProviderInvoiceCharges={setProviderInvoiceCharges}
								tPrefix={tPrefix}
							/>
							<Spacer height={'20px'} />
							<ProviderInvoiceNotes
								providerInvoice={providerInvoice}
								getProviderInvoices={getProviderInvoices}
								tPrefix={tPrefix}
							/>
							<Spacer height={'20px'} />
						</div>
					)
				})
			)}
		</Card>
	)
}
