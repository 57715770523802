import { sosToast } from 'common/components/toast'
import { AsyncTypeahead, TypeaheadOption } from 'common/components/typeahead'
import React, { useState } from 'react'
import { apiClientConfig } from 'ui/api/broker'
import { processSearchString } from 'ui/lib/elasticSearch'
import { sos2 } from 'ui/lib/state/sos2'
import { sosUser } from 'ui/state'
import { Loader } from '../common/loader'
import { useOnce } from '../hooks'
import { tString } from '../i18n/i18n'
import { filterFormatAndSortConfigsOptions } from './filterFormatAndSortConfigsOptions'

const allOption: TypeaheadOption = {
	value: null,
	label: 'All',
}

export const clientConfigSearch = async (
	searchTerm: string,
	showAll: boolean,
): Promise<TypeaheadOption[]> => {
	const clientConfigsResponse = await apiClientConfig.getClientConfigList(
		() => {},
		searchTerm ? `tmsCompanyName:${processSearchString(searchTerm)}` : null,
		100,
		0,
	)

	if (clientConfigsResponse.data) {
		sosUser.addClientConfigsToMemory(clientConfigsResponse.data.entities)
		const clientConfigOptions: TypeaheadOption[] = filterFormatAndSortConfigsOptions(
			clientConfigsResponse.data.entities,
		)
		if (showAll) {
			clientConfigOptions.unshift(allOption)
		}
		return clientConfigOptions
	} else {
		sosToast.sendApiErrorResponseToast(
			clientConfigsResponse,
			tString('clientFetchError', 'common'),
		)
		return []
	}
}

export const ClientSelector = (props: {
	isClearable: boolean
	showAll?: boolean
	onChange?: (newVal: string) => void
	readOnly?: boolean
	onSearch?: (selections: TypeaheadOption[]) => void
}): React.ReactElement => {
	const [options, setOptions] = useState<TypeaheadOption[]>([])
	const [initialFetchComplete, setInitialFetchComplete] = useState(false)
	const stateUser = sos2.useSubscription(sosUser.getSos())

	useOnce(async () => {
		const initialOptions = await clientConfigSearch('', props.showAll)
		setOptions(initialOptions)
		setInitialFetchComplete(true)
	})

	if (!initialFetchComplete) {
		return <Loader isLoading={true} />
	}

	let value: TypeaheadOption
	if (stateUser.selectedClientConfig) {
		value = {
			label: stateUser.selectedClientConfig.tmsCompanyName,
			value: stateUser.selectedClientConfig.id,
		}
	} else if (props.showAll) {
		value = allOption
	}

	return (
		<AsyncTypeahead
			testId='client-selector'
			options={options}
			makeInitialFetch={true}
			isClearable={props.isClearable}
			onChange={(selected: TypeaheadOption) => {
				if (selected) {
					if (props.onChange) {
						props.onChange(selected.value)
					}
					sosUser.setSelectedClientConfig(selected.value)
				}
			}}
			onSearch={async (searchTerm: string) => {
				const clientConfigs = await clientConfigSearch(
					searchTerm,
					props.showAll,
				)
				if (props.onSearch) {
					props.onSearch(clientConfigs)
				}
				setOptions(clientConfigs)
				return clientConfigs
			}}
			value={value}
			size={'small'}
			disabled={props.readOnly}
			useCache={false}
		/>
	)
}
