import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { apiShipments, apiTypes } from 'ui/api'
import { Input } from 'ui/components/common/input'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import classes from './LoadOrder.module.scss'

const tPrefix = 'page.newQuote.parcel.loadOrder'

export const LoadOrder: FC = (props: {
	onLoad: (shipment: apiTypes.OrderResponse) => void
	isLoadingOrder: boolean
	setIsLoadingOrder: React.Dispatch<React.SetStateAction<boolean>>
}) => {
	const { onLoad, isLoadingOrder, setIsLoadingOrder } = props

	const [orderNumber, setOrderNumer] = useState<string>('')

	const loadOrder = async (): Promise<void> => {
		setIsLoadingOrder(true)

		const orderResponse = await apiShipments.getOrder(() => {}, orderNumber)

		if (orderResponse.data) {
			onLoad(orderResponse.data)
		} else if (orderResponse.error) {
			sosToast.sendApiErrorResponseToast(orderResponse)
		}

		setIsLoadingOrder(false)
	}

	return (
		<div className={classes.loadOrder}>
			<Spacer />
			<Row>
				<Col>{t('loadAnOrder', tPrefix)}</Col>
			</Row>
			<Row className={classes.loadOrderInputAndButton}>
				<Col className={classes.loadOrderColNoPaddingRight}>
					<Input
						value={orderNumber}
						onChange={setOrderNumer}
						testId='new-quote-parcel-load-order-id-input'
						width={'189px'}
					/>
				</Col>
				<Col>
					<OkCancelButtons
						ok={tString('loadOrder', tPrefix)}
						onOk={loadOrder}
						okColor={'green'}
						isValid={true}
						isSpinning={isLoadingOrder}
						okTestId='new-quote-parcel-get-order'
					/>
				</Col>
			</Row>
		</div>
	)
}
