import { IRequestState } from './requestState'
import { apiTypes, apiCommon } from '.'
import WebSocketAsPromised from 'websocket-as-promised'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { log } from 'ui/lib/log/log'
import { sosUser } from 'ui/state'

export const getManifests = async (
	onProgress: (rs: IRequestState<apiTypes.ManifestListResponse>) => void,
	query: {
		take: number
		skip: number
		providerName: apiTypes.ApiProviderName
		locationId?: string
		sort?: string
	},
): Promise<IRequestState<apiTypes.ManifestListResponse>> => {
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
			entireResponse: true,
		},
		'shipments/manifests',
		query,
	)
	return response
}

export const createManifest = async (
	onProgress: (rs: IRequestState<apiTypes.ManifestResponse>) => void,
	requestBody: apiTypes.ShipmentServiceManifestRequest,
): Promise<IRequestState<apiTypes.ManifestResponse>> => {
	const response = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data: requestBody, entireResponse: true },
		'shipments/manifests',
	)
	return response
}

export const voidManifest = async (
	onProgress: (rs: IRequestState<apiTypes.ManifestResponse>) => void,
	manifestId: string,
): Promise<IRequestState<apiTypes.ManifestResponse>> => {
	const response = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST' },
		`shipments/manifests/${manifestId}/void`,
	)
	return response
}

let manifestWebSocketRequestId = 3000
const internalServerErrorRetryNumber = 2

export async function trickleGenerateManifest(
	onProgressManifest: (websocketData: apiTypes.ManifestResponse) => void,
	onProgressMessage: (websocketData: string) => void,
	onMajorError: () => void,
	manifestRequest: apiTypes.ManifestRequestLegacy,
	websocketUrl: string,
	retries = 0,
): Promise<void> {
	const websocket = new WebSocketAsPromised(websocketUrl)

	manifestWebSocketRequestId++
	const requestId = '' + manifestWebSocketRequestId + new Date().toISOString() // datestring to make sure the requestID is definitely unique
	websocket.onMessage.addListener((message) => {
		const m = JSON.parse(message)
		if (m.message?.toLowerCase().includes('internal server error')) {
			if (retries < internalServerErrorRetryNumber) {
				retries++
				fireAndForget(
					() =>
						trickleGenerateManifest(
							onProgressManifest,
							onProgressMessage,
							onMajorError,
							manifestRequest,
							websocketUrl,
							retries,
						),
					`trickle manifesting retry ${retries}`,
				)
			} else {
				onMajorError()
			}
			return
		}
		if (m.requestId !== requestId) {
			return // This is for a different request, reject it
		}

		if (m.type === 'Error') {
			// TODO display errors?
			log('generate-manifest', 'Error:', m.error)
		}

		if (m.type === 'Manifest') {
			onProgressManifest(m.data)
		}

		if (m.type === 'Message') {
			onProgressMessage(m.data)
		}
	})

	const requestObject = {
		action: 'generateManifest',
		requestId: requestId,
		authorization: `Bearer ${sosUser.getAuthToken()}`,
		manifestRequest,
	}

	await websocket.open()
	websocket.send(JSON.stringify(requestObject))
}
