import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { equipmentTypeOptions } from 'ui/pages/new-quote/EquipmentTypes'

export interface INewQuoteDetailsForm {
	identifier?: string
	pickTicketNumber?: string
	accountingCode?: string
	isExpedited?: string
	specialInstructions?: string
	bidDuration?: string
	sealNumber?: string
	totalMiles?: string
	equipmentTypes?: string
	trailerLength?: string
	brokerReferenceNumber?: string
}

export const freightNewQuoteDetailsFormMetadata: IFormMetadataCollection<INewQuoteDetailsForm> = {
	pickTicketNumber: {
		defaultValue: '5555555555', // TODO: Pass Value from TMS2
	},
	accountingCode: {},
	isExpedited: {},
	specialInstructions: {
		multiline: true,
	},
	bidDuration: { mustBeAnInteger: true },
	sealNumber: {},
	trailerLength: {},
	equipmentTypes: {
		options: equipmentTypeOptions,
		clearable: false,
		defaultValue: 'V',
	},
	totalMiles: { readOnly: true, defaultValue: '0' },
	brokerReferenceNumber: {},
}

export const vendorNewQuoteDetailsFormMetadata: Partial<IFormMetadataCollection<
	INewQuoteDetailsForm
>> = {
	pickTicketNumber: {
		readOnly: true,
	},
	specialInstructions: {
		multiline: true,
	},
}
