// existential protection
export function idx<T>(callback: () => any): any {
	try {
		return callback()
	} catch (e) {
		if (e instanceof TypeError) {
			return
		}
		throw e
	}
}
