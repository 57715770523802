import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { SmallButton } from 'ui/components/common/small-button'
import {
	FormStacked,
	FormStackedLayout,
	FormStackedTextInput,
} from 'ui/components/form'
import { IFormData } from 'ui/components/form/IFormData'
import { r } from 'ui/lib/ramdaImports'
import { StopType } from 'ui/pages/new-quote/state/sosNewQuote'
import { editAddressFormMetadata } from '.'
import { IAddress } from './IAddress'
import { IAddressState } from './IAddressState'

const tPrefix = 'shared.address.editAddress'

export const EditAddressContactInfoForm: FC = (props: {
	addressState: IAddressState
	updateAddressState: (changes: Partial<IAddressState>, id?: string) => void
	stopType?: StopType
	onSave?: () => void
	showSave?: boolean
}) => {
	const { addressState: state, updateAddressState: updateState } = props

	const formData: IFormData<IAddress> = {
		form: state.address,
		metadata: editAddressFormMetadata,
		onUpdateForm: (key: string, val: string) => {
			const newAddress = r.clone(state.address)
			newAddress[key] = val
			updateState({ address: newAddress }, props.addressState.id)
		},
		tPrefix,
	}

	return (
		<div>
			<FormStacked>
				<FormStackedLayout>
					<FormStackedTextInput
						formData={formData}
						field='name'
						testId={'name-' + props.stopType}
					/>
					<FormStackedTextInput
						formData={formData}
						field='phone'
						testId={'phone-' + props.stopType}
					/>
					<FormStackedTextInput
						formData={formData}
						field='email'
						testId={'email-' + props.stopType}
					/>
				</FormStackedLayout>
			</FormStacked>
			{props.showSave && (
				<SmallButton color='blue' onClick={props.onSave}>
					Save
				</SmallButton>
			)}
		</div>
	)
}
