import nanoid from 'nanoid'
import { INewQuoteCommoditiesForm } from 'ui/pages/new-quote/INewQuoteCommoditiesForm'

export const defaultCommodity: INewQuoteCommoditiesForm = {
	id: nanoid(),
	count: '1',
	class: null,
	length: '48',
	width: '40',
	height: '',
	packedWeight: '',
	description: '',
	type: 'palletCstm',
	piecesCount: '',
	piecesType: 'boxes',
	piecesLength: '',
	piecesWidth: '',
	piecesHeight: '',
	hazmat: 'false',
	// stackable: '', (DP-477)
	nmfc: '',
	nmfcSub: '',
	hazmatClass: '',
	unNumber: '',
	packagingGroup: null,
	bol: '',
	so: '',
	po: '',
	notes: '',
}
