import { FC } from 'app/FunctionalComponent'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { apiTypes } from 'ui/api'
import { BigBadge } from 'ui/components/common/badge'
import {
	tMode,
	tProviderName,
	tServiceLevel,
	tTrackingStatus,
} from 'ui/components/i18n/commonTranslations'
import { t, tCurrencyFromCents, tString } from 'ui/components/i18n/i18n'
import { CarrierImage } from 'ui/components/shared/CarrierImage'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import * as classes from './BrokerShipmentSummary.module.scss'

const getProfitFromBookedOffer = (
	shipment: apiTypes.BrokerShipmentResponse,
): string => {
	const bookedOffer = shipment.offers.find(
		(offer) => offer.brokerRateId === shipment.bookedRateId,
	)
	if (!bookedOffer?.flatOffer?.grossRate || !shipment?.bookedRate?.costTotal) {
		return '-'
	}
	const profit = bookedOffer.flatOffer.grossRate - shipment.bookedRate.costTotal
	return tCurrencyFromCents(profit)
}

export const BrokerShipmentSummary: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse
	customerName: string
}) => {
	const { shipment, customerName } = props
	const tPrefix = 'page.shipmentProfile.broker.shipmentSummary'

	let latestTrackingStatus = null
	let latestTrackingTime = null

	shipment.payloads.forEach((payload) => {
		payload.trackingEvents?.forEach((trackingEvent) => {
			if (!latestTrackingTime || trackingEvent.timestamp > latestTrackingTime) {
				latestTrackingStatus = trackingEvent.status
				latestTrackingTime = trackingEvent.timestamp
			}
		})
	})

	let shipmentStatusClassName = classes.needsQuoteBgColor
	switch (shipment.brokerShipmentStatus) {
		case 'needs_quote':
		case 'needs_transport':
			shipmentStatusClassName = classes.needsQuoteBgColor
			break

		case 'quoted':
			shipmentStatusClassName = classes.quotedBgColor
			break

		case 'awarded':
		case 'booked':
		case 'delivered':
			shipmentStatusClassName = classes.awardedBgColor
			break

		case 'in_transit':
			shipmentStatusClassName = classes.inTransitBgColor
			break

		case 'lost_bid':
		case 'canceled':
			shipmentStatusClassName = classes.lostBidBgColor
			break
	}

	return (
		<div
			className={`${classes.shipmentSummaryCard} ${theme.getBorderColor(
				'lightGray',
			)} d-inline-block`}
		>
			<Row>
				<span className={classes.customerName} data-testid={'customer-name'}>
					{customerName}
				</span>
			</Row>
			<Row>
				<Col>
					<span
						className={classes.shipmentIdMargin}
						data-testid={'shipment-identifier'}
					>
						{tString('shipmentId', tPrefix)} # {shipment.identifier}
					</span>

					<BigBadge
						className={theme.addClass(
							classes.shipmentBigBadge,
							shipmentStatusClassName,
						)}
						testId={'summary-brokerShipmentStatus'}
					>
						{l.startCase(shipment.brokerShipmentStatus)}
					</BigBadge>

					<BigBadge
						className={classes.shipmentBigBadge}
						color={'lightBlue'}
						testId={'summary-trackingStatus'}
					>
						{latestTrackingStatus
							? tTrackingStatus(latestTrackingStatus)
							: t('noTracking', tPrefix)}
					</BigBadge>
				</Col>
			</Row>

			{shipment.bookedRate && (
				<Row>
					<Col className='d-flex justify-content-between'>
						<CarrierImage
							carrier={shipment.bookedRate.providerName}
							style={{ borderRadius: '5px', maxHeight: '28px', margin: '5px' }}
						/>
						<BigBadge
							className={classes.shipmentBigBadge}
							color={'purple'}
							testId={'summary-providerName'}
						>
							{tProviderName(shipment.bookedRate.providerName)}
						</BigBadge>
						<BigBadge
							className={classes.shipmentBigBadge}
							color={'darkPurple'}
							testId={'summary-method'}
						>
							{tMode(shipment.bookedRate.method)}
						</BigBadge>
						{shipment.bookedRate.serviceLevel && (
							<BigBadge
								className={classes.shipmentBigBadge}
								color={'lightBlue'}
								testId={'summary-serviceLevel'}
							>
								{tServiceLevel(shipment.bookedRate.serviceLevel)}
							</BigBadge>
						)}
						<BigBadge
							className={classes.shipmentBigBadge}
							color={'blue'}
							testId={'summary-buyCost'}
						>
							{tString('buyCost', tPrefix) +
								': ' +
								tCurrencyFromCents(shipment.bookedRate.costTotal)}
						</BigBadge>
						<BigBadge
							className={classes.shipmentBigBadge}
							color={'green'}
							testId={'summary-profit'}
						>
							{tString('profit', tPrefix) +
								': ' +
								getProfitFromBookedOffer(shipment)}
						</BigBadge>
					</Col>
				</Row>
			)}
		</div>
	)
}
