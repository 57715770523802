import React from 'react'
import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import { DateTime } from 'luxon'
import { Card, Col, Row } from 'react-bootstrap'
import { apiBroker, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { ndash } from 'ui/components/common'
import { AccountingText } from 'ui/components/common/accounting-text'
import { Button } from 'ui/components/common/button'
import { LinkButton } from 'ui/components/common/link/LinkButton'
import {
	tMode,
	tProviderName,
	tRateQuoteType,
} from 'ui/components/i18n/commonTranslations'
import { t, tCurrencyFromCents, tString } from 'ui/components/i18n/i18n'
import { addBusinessDays } from 'ui/lib/dates/businessDays'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { BrokerApiCalls } from 'ui/pages/shipment-profile/broker/sosShipmentProfileBroker'
import * as classes from './BrokerBuySection.module.scss'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'

const tPrefix = 'page.shipmentProfile.brokerBuy'

export const BrokerBuySection: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse
	offer: apiTypes.BrokerOfferResponse
	buyRate: apiTypes.RateResponse
	runningApiCalls: BrokerApiCalls[]
	setIsBookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsUnbookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
	rateBookingDetails: RateBookingDetails
}) => {
	const {
		shipment,
		offer,
		buyRate,
		runningApiCalls,
		setIsBookCarrierModalOpen,
		setIsUnbookCarrierModalOpen,
		setRateBookingDetails,
		rateBookingDetails,
	} = props
	const buyPrice = buyRate?.costTotal
	const sellPrice = offer?.flatOffer?.grossRate
	let markup: number
	if (buyPrice && sellPrice) {
		markup = Math.round(((sellPrice - buyPrice) / buyPrice) * 100)
	}

	let showAward = false,
		showResendAward = false,
		showRetractAward = false,
		showBook = false,
		showResendBook = false,
		showUnbook = false
	if (offer?.clientStatus === 'booked' && buyRate) {
		if (buyRate.offerStatus === 'booked') {
			showResendBook = true
			showUnbook = true
		} else if (buyRate.offerStatus === 'awarded') {
			showResendAward = true
			showRetractAward = true
		} else if (
			buyRate.method === 'truckload' &&
			buyRate.quoteType !== 'loggedOffer'
		) {
			showAward = true
		} else {
			showBook = true
		}
	}

	const makeBrokerApiCall = async <T extends any>(
		apiCallName: BrokerApiCalls,
		apiCallFunction: () => Promise<IRequestState<T>>,
		refetchShipment = false,
	): Promise<void> => {
		sosShipmentProfileBroker.setRunningApiCall(apiCallName)
		const result = await apiCallFunction()
		if (result.error) {
			sosToast.sendApiErrorResponseToast(
				result,
				tString(`${apiCallName}Error`, tPrefix),
			)
		}
		if (refetchShipment) {
			await sosShipmentProfileBroker.fetchShipment(shipment.id)
		}
		sosShipmentProfileBroker.setRunningApiCall(apiCallName, false)
	}

	return (
		<Card
			className={`${theme.getBorderColor('lightGray')} ${
				classes.brokerBuySectionCard
			}`}
		>
			<Row>
				<Col xs={3}>
					<div className={classes.label}>{t('buyPrice', tPrefix)}</div>
					<div>{buyPrice ? tCurrencyFromCents(buyPrice) : ndash}</div>
				</Col>
				<Col xs={3}>
					<div className={classes.label}>{t('markup', tPrefix)}</div>
					<div>
						{markup ? (
							<AccountingText isRed={markup < 0}>{markup + '%'}</AccountingText>
						) : (
							ndash
						)}
					</div>
				</Col>
				<Col xs={3}>
					<div className={classes.label}>{t('profit', tPrefix)}</div>
					<div>
						{buyPrice && sellPrice
							? tCurrencyFromCents(sellPrice - buyPrice)
							: ndash}
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Card
						className={`${theme.getBorderColor(
							'lightGray',
						)} ${theme.getBackgroundWithTextColor('lightGray')} ${
							classes.innerCardDetails
						}`}
					>
						<Row>
							<Col>
								<div className={classes.label}>{t('quoteType', tPrefix)}</div>
								<div>{tRateQuoteType(buyRate?.quoteType) || ndash}</div>
							</Col>
							<Col>
								<div className={classes.label}>{t('carrier', tPrefix)}</div>
								<div>{tProviderName(buyRate?.carrier) || ndash}</div>
							</Col>
							<Col>
								<div className={classes.label}>{t('mode', tPrefix)}</div>
								<div>{tMode(buyRate?.method) || ndash}</div>
							</Col>
							<div className={classes.noPadding}>
								<div className={classes.label}>
									{t('estimatedTransit', tPrefix)}
								</div>
								<div>
									{buyRate?.transit
										? `${buyRate.transit} ${tString('days', 'common')}`
										: ndash}
								</div>
							</div>
							<Col>
								<div className={classes.label}>{t('eta', tPrefix)}</div>
								<div>
									{buyRate?.transit
										? addBusinessDays(
												shipment.pickupDate || DateTime.local().toISO(),
												buyRate.transit - 1, //minus 1 to match that the delivery date calculated on the api counts today as day 1
										  )
										: ndash}
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<div
						className='d-flex justify-content-end'
						data-testid='buy-section-buttons'
					>
						{showAward && (
							<Button
								color={'green'}
								isSmall={true}
								onClick={async () => {
									await makeBrokerApiCall(
										'awardingShipment',
										async () => {
											const rateRequest: apiTypes.RateRequest = l.cloneDeep(
												buyRate,
											)
											rateRequest.offerStatus = 'awarded'
											return await apiBroker.updateConnectionOffer(
												() => {},
												shipment.id,
												rateRequest,
											)
										},
										true,
									)
								}}
								isSpinning={runningApiCalls.includes('awardingShipment')}
								testId={'award-button'}
							>
								{t('award', tPrefix)}
							</Button>
						)}
						{showRetractAward && (
							<LinkButton
								className={classes.linkButtonPadding}
								onClick={async () => {
									await makeBrokerApiCall(
										'retractingAward',
										async () => {
											const rateRequest: apiTypes.RateRequest = l.cloneDeep(
												buyRate,
											)
											rateRequest.offerStatus = 'awardRetracted'
											return await apiBroker.updateConnectionOffer(
												() => {},
												shipment.id,
												rateRequest,
											)
										},
										true,
									)
								}}
								isSpinning={runningApiCalls.includes('retractingAward')}
								testId={'retract-award-button'}
							>
								{t('retractAward', tPrefix)}
							</LinkButton>
						)}
						{showResendAward && (
							<LinkButton
								className={classes.linkButtonPadding}
								onClick={async () => {
									await makeBrokerApiCall('resendingAward', async () => {
										return await apiBroker.updateConnectionOffer(
											() => {},
											shipment.id,
											buyRate,
										)
									})
								}}
								isSpinning={runningApiCalls.includes('resendingAward')}
								testId={'resend-award-button'}
							>
								{t('resendAwardEmail', tPrefix)}
							</LinkButton>
						)}
						{showBook && (
							<Button
								color={'green'}
								onClick={async () => {
									const updatedRateBookingDetails = {
										...rateBookingDetails,
										shipmentId: shipment.id,
										buyRateId: buyRate.id,
									}
									setRateBookingDetails(updatedRateBookingDetails)

									setIsBookCarrierModalOpen(true)
								}}
								isSpinning={runningApiCalls.includes('booking')}
								testId={'book-button'}
							>
								{t('book', tPrefix)}
							</Button>
						)}
						{showResendBook && (
							<LinkButton
								className={classes.linkButtonPadding}
								onClick={async () => {
									await makeBrokerApiCall('resendingBook', async () => {
										return await apiBroker.updateConnectionOffer(
											() => {},
											shipment.id,
											buyRate,
										)
									})
								}}
								isSpinning={runningApiCalls.includes('resendingBook')}
								testId={'resend-book-button'}
							>
								{t('resendBookEmail', tPrefix)}
							</LinkButton>
						)}
						{showUnbook && (
							<Button
								color={'red'}
								onClick={async () => {
									if (shipment.bookedRate?.bookMethod === 'manual') {
										setIsUnbookCarrierModalOpen(true)
									} else {
										await makeBrokerApiCall('unbooking', async () => {
											const unbookResult = await apiBroker.brokerUnbook(
												() => {},
												shipment.id,
											)
											if (unbookResult.data) {
												sosShipmentProfileBroker.setShipmentReference(
													unbookResult.data,
												)
											}
											return unbookResult
										})
									}
								}}
								isSpinning={runningApiCalls.includes('unbooking')}
								testId={'unbook-button'}
							>
								{t('unbook', tPrefix)}
							</Button>
						)}
					</div>
				</Col>
			</Row>
		</Card>
	)
}
