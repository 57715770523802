import React, { useEffect, useState } from 'react'
import { apiProviderInvoice, apiTypes } from 'ui/api'
import { sosToast } from 'ui/common/components/toast'
import { Card } from 'ui/components/common/card'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Input } from 'ui/components/common/input'
import { Debug } from 'ui/components/dev'
import { t } from 'ui/components/i18n/i18n'
import { FC } from 'ui/FunctionalComponent'
import * as classes from './ProviderInvoiceNotes.module.scss'

export const ProviderInvoiceNotes: FC = (props: {
	providerInvoice: apiTypes.ProviderInvoiceResponse
	fetchProviderInvoices: () => {}
	tPrefix: string
}) => {
	const { providerInvoice, fetchProviderInvoices, tPrefix } = props

	const [editProviderInvoiceNotes, setEditProviderInvoiceNotes] = useState<
		boolean
	>(false)

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [providerInvoiceNotes, setProviderInvoiceNotes] = useState<string>(
		providerInvoice?.notes,
	)

	const updateProviderInvoiceNotes = async (): Promise<void> => {
		setIsLoading(true)

		const updatedProviderInvoice = {
			...providerInvoice,
			notes: providerInvoiceNotes,
		}

		const invoiceNotesResponse = await apiProviderInvoice.updateProviderInvoice(
			providerInvoice.id,
			updatedProviderInvoice,
		)
		if (invoiceNotesResponse.error) {
			sosToast.sendApiErrorResponseToast(invoiceNotesResponse)
		} else {
			fetchProviderInvoices()
		}
		setIsLoading(false)
	}

	useEffect(() => {
		if (providerInvoice !== null) setProviderInvoiceNotes(providerInvoice.notes)
	}, [providerInvoice])

	return (
		<Card
			className={classes.providerInvoiceNotes}
			title={
				<div className={classes.providerInvoiceNotesHeader}>
					<div>{t('providerInvoiceNotes', tPrefix)}</div>
					<div>
						{providerInvoice?.invoiceStatus === ('review' || 'approved') && (
							<IconButton
								icon={
									!editProviderInvoiceNotes
										? solidIcons.faPencilAlt
										: solidIcons.faCheck
								}
								color={editProviderInvoiceNotes ? 'green' : 'black'}
								spin={isLoading}
								iconClassName={classes.editSaveIcon}
								onClick={async () => {
									if (editProviderInvoiceNotes) {
										await updateProviderInvoiceNotes()
									}
									setEditProviderInvoiceNotes(!editProviderInvoiceNotes)
								}}
								testId={'customer-invoice-edit-save'}
							></IconButton>
						)}
					</div>
				</div>
			}
			color={'darkBlue'}
			fillHeight={true}
			testId={'customer-invoice-parent-card'}
		>
			<div>
				<Input
					value={providerInvoiceNotes}
					onChange={(newVal: string) => setProviderInvoiceNotes(newVal)}
					autofocus={editProviderInvoiceNotes}
					readOnly={!editProviderInvoiceNotes}
					multiline={true}
					width={'100%'}
				/>
			</div>
			<Debug data={providerInvoice} label='providerInvoice' />
		</Card>
	)
}
