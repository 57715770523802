import React, { ReactElement } from 'react'
import {
	IRoute,
	SimpleRouter,
	LoginModeType,
} from 'ui/components/common/router'
import { DockConfigurationPage } from './dock-configuration'
import { sosUser } from 'ui/state'
import { TopLevelLocationConfigurationPage } from './top-level-location-configuration'
import { PrintersAndScalesPage } from './printers-and-scales'

let companyManagementRoutes: IRoute[] = []
const brokerRoutes = [
	{
		urls: ['/company-management-v3'],
		renderer: () => <TopLevelLocationConfigurationPage />,
	},
]
const tmsRoutes = [
	{
		urls: ['/company-management-v3/dock-configuration'],
		renderer: () => <DockConfigurationPage />,
	},
	{
		urls: ['/company-management-v3/printers-and-scales'],
		renderer: () => <PrintersAndScalesPage />,
	},
]

export const RouterCompanyManagement = (props: {
	currentLocation: string
	loginMode: LoginModeType
}): ReactElement => {
	companyManagementRoutes = sosUser.isUserBroker() ? brokerRoutes : tmsRoutes
	return (
		<SimpleRouter
			routes={companyManagementRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
