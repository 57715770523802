import * as apiTypes from 'ui/api/apiTypes'

export const createDefaultPaymentTerms = (): apiTypes.PaymentTerms => {
	return {
		net: 0,
	}
}

export const createDefaultMarkup = (): apiTypes.MarkupLogic => {
	return {
		markupType: 'flat',
		markupFlat: 1000,
		markupPercent: 10,
		maxMarkupFlat: 0,
		minMarkupFlat: 0,
		maxMarkupPercent: 0,
		minMarkupPercent: 0,
	}
}
