import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedTextInput,
	IFormData,
	IFormMetadataCollection,
} from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { FlexVerticalSeparator } from 'ui/components/layout/flexRow'
import { IAddress } from 'ui/components/shared/address'
import { idx } from 'ui/lib'
import * as classes from './BrokerOfferSell.module.scss'
import { sosNewQuote } from './state'
import { IStateNewQuote } from './state/sosNewQuote'

export interface IBrokerSellForm {
	sellPrice?: string
	transitDays?: string
	marketSellPrice?: string
	marketBuyPrice?: string
}

const brokerSellFormMetadata: IFormMetadataCollection<IBrokerSellForm> = {
	sellPrice: {},
	transitDays: {},
	marketSellPrice: { readOnly: true },
	marketBuyPrice: { readOnly: true },
}

const tPrefix = 'page.newQuote.brokerSellForm'

const getMarketRateButtonIsValid = (state: IStateNewQuote): boolean => {
	const pickupAddress: IAddress = idx(() => state.stops[0].addressState.address)
	//TODO multistop
	const destinationAddress: IAddress = idx(
		() => state.stops[1].addressState.address,
	)
	const equipmentType: string = idx(
		() => state.formNewQuoteDetails.equipmentTypes,
	)

	if (!pickupAddress.state || !pickupAddress.zip || !pickupAddress.country) {
		return false
	}

	if (
		!destinationAddress.state ||
		!destinationAddress.zip ||
		!destinationAddress.country
	) {
		return false
	}

	if (!equipmentType) {
		return false
	}

	return true
}

export const BrokerOfferSell: FC = (props: { state: IStateNewQuote }) => {
	const { state } = props
	if (!state) {
		return <></>
	}
	const formData: IFormData<IBrokerSellForm> = {
		form: state.brokerSellOfferForm,
		metadata: brokerSellFormMetadata,
		onUpdateForm: sosNewQuote.updateBrokerSellForm,
		tPrefix,
	}
	return (
		<div className={classes.container}>
			<div className={classes.columnWidth}>
				<h3>{t('sell', tPrefix)}</h3>
				<FormStackedTextInput formData={formData} field='sellPrice' />
				<FormStackedTextInput formData={formData} field='transitDays' />
			</div>
			<FlexVerticalSeparator />

			<div className={classes.columnWidth}>
				<h3>{t('marketPrice', tPrefix)}</h3>
				<FormStackedTextInput formData={formData} field='marketSellPrice' />
				<FormStackedTextInput formData={formData} field='marketBuyPrice' />
				<OkCancelButtons
					ok={t('getMarketRate', tPrefix)}
					okColor='green'
					isSpinning={state.brokerGetMarketRateIsSpinning}
					onOk={sosNewQuote.getMarketRateForBroker}
					isValid={getMarketRateButtonIsValid(state)}
				/>
			</div>
		</div>
	)
}
