import { apiLightningIntegration } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import {
	ILightningIntegrationConfigurationForm,
	lightningIntegrationConfigurationFormMetadata,
} from 'ui/forms/formLightningIntegrationConfiguration'
import {
	ILightningIntegrationRegistrationForm,
	lightningIntegrationRegistrationFormMetadata,
} from 'ui/forms/formLightningIntegrationRegister'
import {
	ILightningIntegrationSetPasswordForm,
	lightningIntegrationSetPasswordFormMetadata,
} from 'ui/forms/formLightningIntegrationSetPassword'
import { l } from 'ui/lib/lodashImports'
import { formValidator } from 'ui/lib/validation/'
import { router } from 'ui/state/router/'
import { createSos } from './secretaryOfState'

export type WizardTabs = 'register' | 'set-password' | 'configuration'

export interface IStateLightningIntegration {
	//[x: string]: IFetch<any>
	selectedTab: WizardTabs
	registrationForm: ILightningIntegrationRegistrationForm
	setPasswordForm: ILightningIntegrationSetPasswordForm
	configurationForm: ILightningIntegrationConfigurationForm
	fetchCreateRegistration: IRequestState<any>
}

const initialState: IStateLightningIntegration = {
	selectedTab: 'register',
	registrationForm: {
		company: '',
		email: '',
	},
	setPasswordForm: {
		password: '',
		confirmPassword: '',
	},
	configurationForm: {
		upsId: '',
		upsKey: '',
		fedexId: '',
		fedexKey: '',
		uspsId: '',
		uspsKey: '',
		saiaId: '',
		saiaKey: '',
		truckload: '',
	},
	fetchCreateRegistration: {},
}

const { stateManager, useSubscribe } = createSos(
	'lightning-integration',
	'1.0.1',
	initialState,
	{
		useLocalStorage: true,
	},
)
export { useSubscribe }

export function navigateToLightningIntegration(params: {
	selectedTab: WizardTabs
}): void {
	stateManager.produce((ds) => {
		l.defaults(params, {
			selectedTab: ds.selectedTab,
		})
		ds.selectedTab = params.selectedTab
	})
	router.navTo(`/lightning-integration/${params.selectedTab}`)
}

export function updateRegistrationForm(
	key: keyof ILightningIntegrationRegistrationForm,
	newVal: string,
): void {
	stateManager.produce((ds) => {
		ds.registrationForm[key] = newVal
	})
}

export function updateSetPasswordForm(
	key: keyof ILightningIntegrationSetPasswordForm,
	newVal: string,
): void {
	stateManager.produce((ds) => {
		ds.setPasswordForm[key] = newVal
	})
}

export function updateConfigurationForm(
	key: keyof ILightningIntegrationConfigurationForm,
	newVal: string,
): void {
	stateManager.produce((ds) => {
		ds.configurationForm[key] = newVal
	})
}

export async function submitRegistrationForm(): Promise<void> {
	// Do an api call

	const state = stateManager.getState()

	const validationResult = formValidator.validateForm(
		state.registrationForm,
		lightningIntegrationRegistrationFormMetadata,
	)

	if (validationResult.isValid) {
		const result = await apiLightningIntegration.createRegistration(
			(f: IRequestState<any>) => {
				stateManager.setState({ fetchCreateRegistration: f })
			},
			state.registrationForm.email,
			state.registrationForm.company,
		)

		if (!result.error) {
			alert('You registered ' + state.registrationForm.company)
		}
	} else {
		alert('you fool ' + validationResult.error)
	}
}

export async function submitSetPasswordForm(): Promise<void> {
	// Do an api call

	const state = stateManager.getState()

	const validationResult = formValidator.validateForm(
		state.setPasswordForm,
		lightningIntegrationSetPasswordFormMetadata,
	)

	if (validationResult.isValid) {
		const result = await apiLightningIntegration.createRegistration(
			(f: IRequestState<any>) => {
				stateManager.setState({ fetchCreateRegistration: f })
			},
			state.setPasswordForm.password,
			state.setPasswordForm.confirmPassword,
		)

		if (!result.error) {
			alert('Your password has been set!')
		}
	} else {
		alert('you fool ' + validationResult.error)
	}
}

export async function submitConfigurationForm(): Promise<void> {
	// Do an api call

	const state = stateManager.getState()

	const validationResult = formValidator.validateForm(
		state.configurationForm,
		lightningIntegrationConfigurationFormMetadata,
	)

	if (validationResult.isValid) {
		const result = await apiLightningIntegration.createConfiguration(
			(f: IRequestState<any>) => {
				stateManager.setState({ fetchCreateRegistration: f })
			},
			state.configurationForm.upsId,
			state.configurationForm.upsKey,
			// state.configurationForm.fedexId,
			// state.configurationForm.fedexKey,
			// state.configurationForm.uspsId,
			// state.configurationForm.uspsKey,
			// state.configurationForm.saiaId,
			// state.configurationForm.saiaKey,
			// state.configurationForm.truckload,
		)

		if (!result.error) {
			alert('Your password has been set!')
		}
	} else {
		alert('you fool ' + validationResult.error)
	}
}
