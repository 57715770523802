import { apiTypes } from 'ui/api'
import { DateTime, Interval, Duration } from 'luxon'
import { getTimeSlotsByDate, ITimeSlot } from 'ui/lib/time/timeSlots'
import { IScheduleTimeSlot, tPrefixTimeSlots } from './DockSchedulerTimeSlots'
import { l } from 'ui/lib/lodashImports'
import { zipToTimezone } from 'ui/lib/addresses/zipToTimezone'
import { log } from 'ui/lib/log'
import { tString } from 'ui/components/i18n/i18n'
import { idx } from 'ui/lib'
import {
	tEquipmentType,
	tStopType,
	tMode,
} from 'ui/components/i18n/commonTranslations'

interface IScheduleTimeSlotInConstruction extends IScheduleTimeSlot {
	availableDocks: apiTypes.DockResponse[]
}

export type DockRestrictionCategory = 'equipmentType' | 'flow' | 'mode'

export const validateDockWithEquipmentTypeFlowMode = (
	dock: apiTypes.DockResponse,
	equipmentType: apiTypes.AppointmentResponse['shipmentInfo']['equipmentType'],
	flow: apiTypes.AppointmentResponse['stopType'],
	mode: apiTypes.AppointmentResponse['shipmentInfo']['mode'],
): DockRestrictionCategory => {
	if (equipmentType && dock.equipmentTypes?.indexOf(equipmentType) === -1) {
		return 'equipmentType'
	} else if (flow && dock.stopTypes?.indexOf(flow) === -1) {
		return 'flow'
	} else if (mode && dock.modes?.indexOf(mode) === -1) {
		return 'mode'
	}
	return null
}

// TODO This algorithm isn't perfect due to this. Knowing how to assign which appointment to which dock before check in is an extremely complex question that I don't know how to solve
const findLeastFlexibleDock = (
	docks: apiTypes.DockResponse[],
	equipmentType: apiTypes.AppointmentResponse['shipmentInfo']['equipmentType'],
	flow: apiTypes.AppointmentResponse['stopType'],
	mode: apiTypes.AppointmentResponse['shipmentInfo']['mode'],
): apiTypes.DockResponse => {
	const elligibleDocks: apiTypes.DockResponse[] = l.filter(docks, (dock) =>
		l.isNil(
			validateDockWithEquipmentTypeFlowMode(dock, equipmentType, flow, mode),
		),
	)
	if (elligibleDocks.length > 0) {
		return l.minBy(
			elligibleDocks,
			(dock) =>
				dock.equipmentTypes.length * dock.modes.length * dock.stopTypes.length,
		)
	} else {
		return null
	}
}

export const getTimeSlotsFromDocksAndAppointments = (
	docks: apiTypes.DockResponse[],
	appointments: apiTypes.AppointmentResponse[],
	date: string, // DateTime format yyyy-MM-dd
	equipmentType: apiTypes.AppointmentResponse['shipmentInfo']['equipmentType'],
	flow: apiTypes.AppointmentResponse['stopType'],
	mode: apiTypes.AppointmentResponse['shipmentInfo']['mode'],
	zipcode: string,
): {
	hourStarts: number[]
	slotRows: IScheduleTimeSlot[][]
	unplacedAppointments: apiTypes.AppointmentResponse[]
} => {
	const start = Date.now()
	log('time-slot-calculation-timing', 'starting')
	const timezone = zipToTimezone(zipcode)
	let slotRows: IScheduleTimeSlotInConstruction[][] = []
	// ridiculous starting values ensure first time slot will overwrite them
	let minHourStart = 1000000
	let maxHourEnd = -1000000
	docks.forEach((dock) => {
		l.forEach(dock.schedule?.rules, (rule) => {
			const timeSlotsFromRule: ITimeSlot[] = getTimeSlotsByDate(
				rule,
				DateTime.fromFormat(date, 'yyyy-MM-dd').setZone(timezone).toISO(),
			)
			timeSlotsFromRule.forEach((timeSlotFromRule) => {
				const timeSlotFromRuleStartTime: DateTime = DateTime.fromISO(
					timeSlotFromRule.startTime,
				)
				if (timeSlotFromRuleStartTime.get('hour') < minHourStart) {
					minHourStart = timeSlotFromRuleStartTime.get('hour')
				}
				const timeSlotFromRuleEndTime: DateTime = timeSlotFromRuleStartTime.plus(
					{
						minutes: timeSlotFromRule.durationMinutes,
					},
				)

				const hourEnd: number =
					timeSlotFromRuleEndTime.get('hour') +
					(timeSlotFromRuleEndTime.get('minute') > 0 ? 1 : 0)
				if (hourEnd > maxHourEnd) {
					maxHourEnd = hourEnd
				}
				let timeSlotFromRuleInserted = false
				const rowsWithSameDuration: IScheduleTimeSlotInConstruction[][] = l.filter(
					slotRows,
					(slotRow) =>
						slotRow[0].durationMinutes === timeSlotFromRule.durationMinutes,
				)
				rowsWithSameDuration.forEach((rowWithSameDuration) => {
					const matchingTimeSlot: IScheduleTimeSlotInConstruction = l.find(
						rowWithSameDuration,
						(timeSlot) =>
							timeSlot.startHours === timeSlotFromRuleStartTime.hour &&
							timeSlot.startMinutes === timeSlotFromRuleStartTime.minute,
					)
					if (matchingTimeSlot) {
						matchingTimeSlot.availableDocks.push(dock)
						timeSlotFromRuleInserted = true
					}
				})
				if (!timeSlotFromRuleInserted) {
					// check if any rows with same duration have space for this time slot
					for (let i = 0; i < rowsWithSameDuration.length; i++) {
						let rowWithSameDuration: IScheduleTimeSlotInConstruction[] =
							rowsWithSameDuration[i]
						let conflictingTimeSlotInRow = false
						const timeSlotFromRuleInterval: Interval = Interval.after(
							timeSlotFromRuleStartTime,
							{ minutes: timeSlotFromRule.durationMinutes },
						)
						for (let j = 0; j < rowWithSameDuration.length; j++) {
							const existingTimeSlot: IScheduleTimeSlotInConstruction =
								rowWithSameDuration[j]
							const existingTimeSlotInterval: Interval = Interval.after(
								DateTime.fromFormat(date, 'yyyy-MM-dd').set({
									hour: existingTimeSlot.startHours,
									minute: existingTimeSlot.startMinutes,
									second: 0,
									millisecond: 0,
								}),
								{ minutes: existingTimeSlot.durationMinutes },
							)
							if (existingTimeSlotInterval.overlaps(timeSlotFromRuleInterval)) {
								conflictingTimeSlotInRow = true
								break
							}
						}
						if (!conflictingTimeSlotInRow) {
							rowWithSameDuration.push({
								startHours: timeSlotFromRuleStartTime.hour,
								startMinutes: timeSlotFromRuleStartTime.minute,
								slotType: 'available',
								availableDocks: [dock],
								durationMinutes: timeSlotFromRule.durationMinutes,
								dockIds: null, // just a placeholder
							})
							rowWithSameDuration = l.sortBy(
								rowWithSameDuration,
								(row) => row.startHours,
								(row) => row.startMinutes,
							)
							timeSlotFromRuleInserted = true
							break
						}
					}
					if (!timeSlotFromRuleInserted) {
						// must create new row
						slotRows.push([
							{
								startHours: timeSlotFromRuleStartTime.hour,
								startMinutes: timeSlotFromRuleStartTime.minute,
								slotType: 'available',
								availableDocks: [dock],
								durationMinutes: timeSlotFromRule.durationMinutes,
								dockIds: null, // just a placeholder
							},
						])
						timeSlotFromRuleInserted = true
					}
				}
			})
		})
	})
	const hourStarts: number[] = []
	if (slotRows.length > 0) {
		for (let i = minHourStart; i < maxHourEnd; i++) {
			hourStarts.push(i)
		}
	}
	slotRows = l.map(slotRows, (slotRow: IScheduleTimeSlotInConstruction[]) =>
		l.sortBy(
			slotRow,
			(slot: IScheduleTimeSlotInConstruction) => slot.startHours,
			(slot: IScheduleTimeSlotInConstruction) => slot.startMinutes,
		),
	)
	// make sure every scheduled appointment blocks off the corresponding time frames on the docks
	// WORKING UNDER THE ASSUMPTION THAT A SINGLE DOCK DOES NOT HAVE 2 POSSIBLE TIME SLOTS AT THE SAME TIME (i.e. 8-9AM and 8-10AM)
	const unplacedAppointments: apiTypes.AppointmentResponse[] = []
	appointments.forEach((appointment) => {
		const appointmentStart: DateTime = DateTime.fromISO(
			appointment.startTime,
		).setZone(timezone)
		let slotFound = false
		let appointmentPlaced = false
		for (let i = 0; i < slotRows.length; i++) {
			const slotRow: IScheduleTimeSlotInConstruction[] = slotRows[i]
			if (slotRow[0].durationMinutes === appointment.scheduledDuration) {
				for (let j = 0; j < slotRow.length; j++) {
					const slot: IScheduleTimeSlotInConstruction = slotRow[j]
					if (
						slot.startHours === appointmentStart.hour &&
						slot.startMinutes === appointmentStart.minute
					) {
						slotFound = true
						let leastFlexibleMatchingDock = findLeastFlexibleDock(
							slot.availableDocks,
							idx(() => appointment.shipmentInfo.equipmentType),
							idx(() => appointment.stopType),
							idx(() => appointment.shipmentInfo.mode),
						)
						if (!leastFlexibleMatchingDock) {
							// TODO in the future show an error that the appointment is not placed into a dock that meets its specs
							leastFlexibleMatchingDock = findLeastFlexibleDock(
								slot.availableDocks,
								undefined,
								undefined,
								undefined,
							)
						}
						appointmentPlaced = true
						slot.availableDocks = l.pull(
							slot.availableDocks,
							leastFlexibleMatchingDock,
						)
						if (slot.availableDocks.length === 0) {
							slot.slotType = 'unavailable'
							slot.slotUnavailableReason = tString(
								'allDocksFilled',
								tPrefixTimeSlots,
							)
						}
						break
					}
				}
				if (slotFound) {
					break
				}
			}
		}
		if (!appointmentPlaced) {
			unplacedAppointments.push(appointment)
			// TODO this shouldn't happen, but it might if mistakes happen
			// (editing a dock after assigning it an appointment, this algorithm has a bug, etc)
			// show this appointment as unassigned again?
		}
	})
	// now apply the passed in constraints
	slotRows.forEach((slotRow) => {
		slotRow.forEach((slot) => {
			if (slot.availableDocks.length > 0) {
				const validDocksForConditions: apiTypes.DockResponse[] = l.filter(
					slot.availableDocks,
					(dock) =>
						l.isNil(
							validateDockWithEquipmentTypeFlowMode(
								dock,
								equipmentType,
								flow,
								mode,
							),
						),
				)
				if (validDocksForConditions.length === 0) {
					slot.slotType = 'unavailable'
					const invalidCategory: DockRestrictionCategory = validateDockWithEquipmentTypeFlowMode(
						slot.availableDocks[0],
						equipmentType,
						flow,
						mode,
					)
					let invalidValue: string
					if (invalidCategory === 'equipmentType') {
						invalidValue = tEquipmentType(equipmentType)
					} else if (invalidCategory === 'flow') {
						invalidValue = tStopType(flow)
					} else if (invalidCategory === 'mode') {
						invalidValue = tMode(mode)
					}
					slot.slotUnavailableReason = `${tString(
						'noAvailableDocksFor',
						tPrefixTimeSlots,
					)} ${invalidValue}`
				}
				slot.availableDocks = validDocksForConditions
			}
		})
	})
	// create blank slots for where there are no appointment slots
	slotRows.forEach((slotRow) => {
		let startTime: DateTime = DateTime.fromFormat(date, 'yyyy-MM-dd')
			.set({
				hour: hourStarts[0],
				minute: 0,
				second: 0,
				millisecond: 0,
			})
			.setZone(timezone)

		for (let i = 0; i < slotRow.length; i++) {
			const timeSlot: IScheduleTimeSlotInConstruction = slotRow[i]
			const timeSlotStart: DateTime = DateTime.fromFormat(date, 'yyyy-MM-dd')
				.set({
					hour: timeSlot.startHours,
					minute: timeSlot.startMinutes,
					second: 0,
					millisecond: 0,
				})
				.setZone(timezone)

			const timeDifference: Duration = timeSlotStart.diff(startTime, 'minute')
			if (timeDifference.minutes > 0) {
				slotRow.splice(i, 0, {
					startHours: startTime.hour,
					startMinutes: startTime.minute,
					slotType: 'void',
					durationMinutes: timeDifference.minutes,
					availableDocks: [],
					dockIds: null, // just a placeholder
				})
				i++ // so we do not iterate over the same element again after we splice
			}
			startTime = startTime.plus({
				minutes: timeSlot.durationMinutes + timeDifference.minutes,
			})
		}
		const lastTimeSlotTimeDifference = DateTime.fromFormat(date, 'yyyy-MM-dd')
			.set({
				hour: hourStarts[hourStarts.length - 1] + 1,
				minute: 0,
				second: 0,
				millisecond: 0,
			})
			.setZone(timezone)
			.diff(startTime, 'minute')
		if (lastTimeSlotTimeDifference.minutes > 0) {
			slotRow.push({
				startHours: startTime.hour,
				startMinutes: startTime.minute,
				slotType: 'void',
				durationMinutes: lastTimeSlotTimeDifference.minutes,
				availableDocks: [],
				dockIds: null, // just a placeholder
			})
		}
	})

	const returnedSlotRows: IScheduleTimeSlot[][] = slotRows.map(
		(slotRow: IScheduleTimeSlotInConstruction[]): IScheduleTimeSlot[] =>
			slotRow.map(
				(slot: IScheduleTimeSlotInConstruction): IScheduleTimeSlot => {
					return {
						startHours: slot.startHours,
						startMinutes: slot.startMinutes,
						durationMinutes: slot.durationMinutes,
						slotType: slot.slotType,
						dockIds: slot.availableDocks.map((dock) => dock.id),
						slotUnavailableReason: slot.slotUnavailableReason,
					}
				},
			),
	)

	log('time-slot-calculation-timing', `finshed, took ${Date.now() - start} ms`)
	return { slotRows: returnedSlotRows, hourStarts, unplacedAppointments }
}

export const formatHourStartIntoHeaderText = (
	hourStart: number,
	tPrefix: string,
): string => {
	let hourText: string
	if (hourStart === 0) {
		hourText = '12' + tString('am', tPrefix)
	} else if (hourStart === 12) {
		hourText = '12' + tString('pm', tPrefix)
	} else if (hourStart > 12) {
		hourText = String(hourStart - 12) + tString('pm', tPrefix)
	} else {
		hourText = String(hourStart) + tString('am', tPrefix)
	}
	return hourText
}
