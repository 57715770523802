import { apiCommon, apiTypes } from '.'
import { IRequestState } from './requestState'

// /users/me
export async function fetchUser(
	onProgress: (rs: IRequestState<apiTypes.UserResponse>) => void,
): Promise<IRequestState<apiTypes.UserResponse>> {
	return await apiCommon.apiFetch(onProgress, {}, `users/me`)
}

export async function fetchBrokerUser(
	onProgress: (rs: IRequestState<apiTypes.UserResponse>) => void,
	userId: string,
	alternateApiKey: string,
): Promise<IRequestState<apiTypes.UserResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ alternateApiKey },
		`users/${userId}`,
	)
}
