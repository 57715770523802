import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiTypes, apiPrintNode } from 'ui/api'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedLayout,
	FormStackedSelect,
	FormStackedCheckbox,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { l } from 'ui/lib/lodashImports'
import { validateForm } from 'ui/lib/validation/formValidator'
import { PrinterCreateForm, defaultPrinterCreateForm } from './AddPrinterModal'
import { v4 as uuid } from 'uuid'
import * as classes from './AddPrinterModal.module.scss'

const tPrefix =
	'page.companyManagement.printersAndScales.tabs.addPrinterModal.addPrinterModalForm'

export const AddPrinterModalForm: FC = (props: {
	onClose: () => void
	printerList: apiTypes.PrinterResponse[]
	setPrinterList: React.Dispatch<
		React.SetStateAction<apiTypes.PrinterResponse[]>
	>
	locationId: string
	credentialsId: string
	printerCreateForm: PrinterCreateForm
	setPrinterCreateForm: React.Dispatch<React.SetStateAction<PrinterCreateForm>>
}) => {
	const {
		onClose,
		printerList,
		setPrinterList,
		locationId,
		credentialsId,
		printerCreateForm,
		setPrinterCreateForm,
	} = props
	const [isAddingPrinter, setIsAddingPrinter] = useState<boolean>(false)

	const formData: IFormData<PrinterCreateForm> = {
		form: printerCreateForm,
		metadata: {
			nickname: {},
			printNodeId: {},
			paperType: {},
			printerType: {
				options: [
					{ value: 'label', label: tString('labelPrinter', tPrefix) },
					{ value: 'office', label: tString('officePrinter', tPrefix) },
				],
			},
			locationId: {},
			computerName: {},
			useDocTab: {},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(printerCreateForm)

			if (field === 'printerType' && value === 'office') {
				updatedFormData.useDocTab = false
			}

			updatedFormData[field] = value
			setPrinterCreateForm(updatedFormData)
		},
		tPrefix,
	}

	const formIsValid = validateForm(formData.form, formData.metadata).isValid

	const addPrinter = async (): Promise<void> => {
		setIsAddingPrinter(true)

		const printerListRequest: apiTypes.PrinterRequest = {
			nickname: printerCreateForm.nickname,
			printNodeId: uuid(),
			paperType: printerCreateForm.paperType,
			printerType: printerCreateForm.printerType,
			locationId: locationId,
			computerName: printerCreateForm.computerName,
			useDocTab: printerCreateForm.useDocTab,
		}

		const printerListResponse = await apiPrintNode.createPrinter(
			() => {},
			printerListRequest,
			credentialsId,
		)

		if (printerListResponse.data) {
			if (printerList) {
				printerList.push(printerListResponse.data)
				setPrinterList(printerList)
			} else {
				setPrinterList([printerListResponse.data])
			}

			setPrinterCreateForm(defaultPrinterCreateForm)
			onClose()
		} else {
			sosToast.sendApiErrorResponseToast(printerListResponse)
		}

		setIsAddingPrinter(false)
	}

	return (
		<>
			<div
				className={classes.addPrinterModalBody}
				data-testid={'add-printer-modal-form'}
			>
				<Container>
					<div>
						<FormStackedLayout>
							<Row>
								<Col xs={12}>
									<FormStackedTextInput
										formData={formData}
										field={'nickname'}
										testId={'printer-modal-nickname'}
									/>
									<FormStackedTextInput
										formData={formData}
										field={'paperType'}
										testId={'printer-modal-paperType'}
									/>
									<FormStackedSelect
										formData={formData}
										field={'printerType'}
										testId={'printer-modal-printerType'}
										numListOptionsBeforeScroll={2}
									/>
									<FormStackedTextInput
										formData={formData}
										field={'computerName'}
										testId={'printer-modal-computerName'}
									/>
									<FormStackedCheckbox
										formData={formData}
										field={'useDocTab'}
									/>
								</Col>
							</Row>
						</FormStackedLayout>
					</div>
				</Container>
			</div>

			<div className={classes.addPrinterModalFooter}>
				<Container>
					<AlignRight>
						<OkCancelButtons
							ok={t('addPrinter', tPrefix)}
							onOk={addPrinter}
							okColor='blue'
							onCancel={onClose}
							isSpinning={isAddingPrinter}
							isValid={formIsValid}
							okTestId={'submit-add-printer-button'}
						/>
					</AlignRight>
				</Container>
			</div>
		</>
	)
}
