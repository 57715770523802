import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import styles from './Address.module.scss'
import { IAddress } from './IAddress'

export const renderCityState = (city?: string, state?: string): string => {
	if (city && state) {
		return `${city}, ${state}`
	}
	if (city) {
		return city
	}
	if (state) {
		return state
	}
	return null
}

export const CityStateZip: FC = (props: { address: IAddress }) => {
	const { address } = props
	const { state, zip, city } = address
	return (
		<>
			{renderCityState(city, state)} {zip}
		</>
	)
}

export const formatPhoneNumber = (phoneNumber: string): string => {
	// 5093755463
	if (phoneNumber.length === 10) {
		return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
			3,
			6,
		)}-${phoneNumber.substring(6, 10)}`
	}
	return phoneNumber
}

export const addressHasContactInfo = (address: IAddress): string => {
	return address.addressType || address.phone || address.email
}
export const addressHasNoAddress = (address: IAddress): boolean => {
	return (
		!address.street1 &&
		!address.street2 &&
		!address.street3 &&
		!address.city &&
		!address.zip
	)
}

export const SimpleAddress: FC = (props: {
	inset?: boolean
	showAddressType?: boolean
	showNoAddress?: boolean
	showContactInfo?: boolean
	address: IAddress
	testId?: string
}) => {
	const { address } = props

	const showNoAddress = l.defaultTo(props.showNoAddress, true)
	const showContactInfo = l.defaultTo(props.showContactInfo, true)

	if (!address && showNoAddress) {
		return <div>{t('noAddress', 'shared.address')}</div>
	}

	const hasContactInfo = addressHasContactInfo(address)

	const hasNoAddress = addressHasNoAddress(address)

	return (
		<div data-testid={props.testId}>
			{address.company && (
				<span className={styles.contactName}>{address.company}</span>
			)}
			{showContactInfo && address.attentionTo && (
				<strong>{address.attentionTo}</strong>
			)}
			<div>{address.street1}</div>
			{address.street2 && <div>{address.street2}</div>}
			{address.street3 && <div>{address.street3}</div>}
			<div>
				<CityStateZip address={address} />
			</div>
			{hasNoAddress && showNoAddress && (
				<div>{t('noAddress', 'shared.address')}</div>
			)}
			{r.not(hasNoAddress) && <div>{address.country}</div>}
			{address.addressType && (
				<div>
					<Spacer />
					{t(address.addressType, 'shared.address.addressType')}{' '}
					{t('shared.address.addressType')}
				</div>
			)}

			{showContactInfo && hasContactInfo && (
				<div>
					<Spacer />
					{showContactInfo && address.name && <div>{address.name} </div>}
					{showContactInfo && address.phone && (
						<div>{formatPhoneNumber(address.phone)}</div>
					)}
					{showContactInfo && address.email && <div>{address.email}</div>}
				</div>
			)}
		</div>
	)
}
