import { FC } from 'app/FunctionalComponent'
import { TypeaheadOption } from 'common/components/typeahead'
import React, { useEffect, useState } from 'react'
import { apiTypes } from 'ui/api'
import { FilterItem, Filters } from 'ui/components/common/filters'
import { Loader } from 'ui/components/common/loader'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { SimpleAddress } from 'ui/components/shared/address'
import { LocationSelector } from 'ui/components/shared/location-selector'
import { CustomAddressForm } from 'ui/pages/new-quote/parcel/components/shared/forms'
import { sosUser } from 'ui/state'
import { emptyAddressForm, NewQuoteAddressForm } from '../newQuoteForms'
import { NewQuoteParcelAddressBookModal } from '../NewQuoteParcelAddressBookModal/NewQuoteParcelAddressBookModal'
import * as classes from './NewQuoteParcelAddress.module.scss'
import { useOnce } from 'ui/components/hooks'

type AddressSelectionMode = 'companyLocation' | 'customAddress' | 'addressBook'
type StopType = 'pickup' | 'delivery'

const tPrefix = 'page.newQuote.parcel.address'

export const NewQuoteParcelAddress: FC = (props: {
	addressForm: NewQuoteAddressForm
	onChangeAddress: React.Dispatch<React.SetStateAction<NewQuoteAddressForm>>
	stopType: StopType
	isReadOnly?: boolean
	setLocationId: React.Dispatch<React.SetStateAction<string>>
}) => {
	const {
		addressForm,
		onChangeAddress,
		stopType,
		isReadOnly,
		setLocationId,
	} = props

	const [addressSelectionMode, setAddressSelectionMode] = useState<
		AddressSelectionMode
	>(stopType === 'pickup' ? 'companyLocation' : 'customAddress')
	const [isFetchingLocationAddress, setIsFetchingLocationAddress] = useState<
		boolean
	>(false)
	const [userSelectedLocation, setUserSelectedLocation] = useState<boolean>(
		false,
	)
	const [isAddressBookModalOpen, setIsAddressBookModalOpen] = useState<boolean>(
		false,
	)
	const [currentlySelectedLocation, setCurrentlySelectedLocation] = useState(
		null,
	)
	// const [defaultSelectedLocation, setDefaultSelectedLocation] = useState(null)

	useOnce(async () => {
		if (stopType === 'pickup') {
			const userState = sosUser.getSos().getState()
			if (userState.requestUserInfo.data) {
				if (
					userState.requestUserInfo.data.company.companyName ===
						userState.requestUserInfo.data.managedCompany?.companyName &&
					userState.requestUserInfo.data.company.companyName
				) {
					const location = await sosUser.getLocation(
						userState.requestUserInfo.data.locationId,
					)
					setCurrentlySelectedLocation({
						label: location.id || location.name,
						value: location.id,
					})
				}
			}
		}
	})

	useEffect(() => {
		if (addressForm.locationId) {
			setAddressSelectionMode('companyLocation')
			setUserSelectedLocation(true)
		}
	}, [addressForm, onChangeAddress])

	return (
		<div className={classes.newQuoteParcelAddress}>
			<Filters className={classes.filters}>
				<FilterItem
					isSelected={addressSelectionMode === 'companyLocation'}
					onClick={() => {
						if (addressSelectionMode !== 'companyLocation') {
							onChangeAddress(emptyAddressForm)
						}
						setAddressSelectionMode('companyLocation')
					}}
					testId={'filterItem-companyLocation'}
					readOnly={isReadOnly}
				>
					{t('companyLocation', tPrefix)}
				</FilterItem>
				<FilterItem
					isSelected={addressSelectionMode === 'customAddress'}
					onClick={() => {
						if (addressSelectionMode !== 'customAddress') {
							onChangeAddress(emptyAddressForm)
						}
						setAddressSelectionMode('customAddress')
						setUserSelectedLocation(false)
					}}
					testId={'filterItem-customAddress'}
					readOnly={isReadOnly}
				>
					{t('customAddress', tPrefix)}
				</FilterItem>
				<FilterItem
					onClick={() => {
						setUserSelectedLocation(false)
						setIsAddressBookModalOpen(true)
					}}
					testId={'filterItem-addressBook'}
					readOnly={isReadOnly}
				>
					{t('addressBook', tPrefix)}
				</FilterItem>
			</Filters>

			{addressSelectionMode === 'companyLocation' && (
				<>
					<LocationSelector
						onChange={async (selected: TypeaheadOption) => {
							if (selected) {
								setIsFetchingLocationAddress(true)

								const locationData: apiTypes.LocationResponse = await sosUser.getLocation(
									selected.value,
								)
								console.log(selected)

								const locationAddress =
									stopType === 'pickup'
										? locationData.defaults.defaultPickupAddress.address
										: locationData.defaults.defaultDeliveryAddress.address

								onChangeAddress({
									name: locationAddress.name,
									street1: locationAddress.street1,
									street2: locationAddress.street2,
									city: locationAddress.city,
									state: locationAddress.state,
									zip: locationAddress.zip,
									country: locationAddress.country,
									addressType: locationAddress.addressType,
									contactName: locationAddress.name,
									phone: locationAddress.phone,
									email: locationAddress.email,
									locationId: locationData.id,
								})

								setIsFetchingLocationAddress(false)
								setUserSelectedLocation(true)
								setLocationId(selected.value)
							}
						}}
						onlyShowLeafLocations={true}
						isReadOnly={isReadOnly}
						currentlySelectedLocation={currentlySelectedLocation}
					/>
					<Spacer />

					{userSelectedLocation === true && (
						<SimpleAddress address={addressForm} />
					)}

					<Loader isLoading={isFetchingLocationAddress} />
				</>
			)}

			{addressSelectionMode === 'customAddress' && (
				<CustomAddressForm
					testIdPrefix={stopType}
					onUpdate={(address) => onChangeAddress(address)}
					mode={'pickupDelivery'}
					addressForm={addressForm}
					isReadOnly={isReadOnly}
				/>
			)}

			<NewQuoteParcelAddressBookModal
				isAddressBookModalOpen={isAddressBookModalOpen}
				onClose={() => {
					setIsAddressBookModalOpen(false)
				}}
				onClickRow={(address) => {
					setAddressSelectionMode('customAddress')
					onChangeAddress({
						name: address.name,
						street1: address.street1,
						street2: address.street2,
						city: address.city,
						state: address.state,
						zip: address.zip,
						country: address.country,
						addressType: address.addressType,
						contactName: address.name,
						phone: address.phone,
						email: address.email,
					})
					setIsAddressBookModalOpen(false)
				}}
			/>
		</div>
	)
}
