import PubSub from 'pubsub-js'
import { l } from 'ui/lib/lodashImports'
import { log, logError } from 'ui/lib/log/log'
import { apiTypes } from 'ui/api'
import { fireAndForget } from './async'
import { sosUser } from 'ui/state'
import {
	windowExists,
	getWindow,
	getLocationHref,
} from 'ui/components/common/router/windowUtils'
import { isInTMS2 } from 'ui/theme/theme'

const _global: any = global // Typescript node typings require us to re-cast global as any

const verbose = false
const superVerboseIframe = false

let currentToken = 100000
const rpcCallbackList: IRpcCallback[] = []

const _log = (...args): void => {
	if (verbose && windowExists && getWindow().console) {
		log('iFrame>>', 'MSG', ...args)
	}
}
const _logError = (...args): void => {
	if (
		verbose &&
		windowExists &&
		getWindow().console &&
		getWindow().console.error
	) {
		logError('iFrame>>', 'ERROR', ...args)
	}
}

let currentHeight = 0
if (isInTMS2()) {
	setInterval(() => {
		const newHeight = document.getElementById('root').offsetHeight
		if (newHeight !== currentHeight) {
			currentHeight = newHeight
			getWindow().parent.postMessage(
				{ type: 'setIframeHeight', newHeight },
				'*',
			)
		}
	}, 1000 / 30)
}

const callTms2GlobalWithCallback = (
	functionName: string,
	args: any[],
	callback: (err: any, result?: any) => void,
): void => {
	if (!windowExists) {
		return
	}

	if (!getWindow().parent) {
		_log('not in an iframe, RPC failed')
		callback('not in an iframe, RPC failed')
		return
	}

	currentToken += 1

	rpcCallbackList.push({
		started: new Date(),
		token: currentToken,
		callback,
	})
	_log('calling', functionName, currentToken)
	_log({
		type: 'call-global',
		token: currentToken,
		functionName: functionName,
		args: args,
	})
	getWindow().parent.postMessage(
		{
			type: 'call-global',
			token: currentToken,
			functionName: functionName,
			args: args,
		},
		'*', // TODO: fix target origin
	)
}

const onMessage = async (ev): Promise<void> => {
	// Only allow from our parent
	if (!windowExists) {
		return
	}
	if (ev.source !== getWindow().parent) {
		logError('iframe', 'invalid parent window')
		return
	}

	const allowedOrigins = [
		// tms3 front-end deployments
		'https://devapp.swanleap.com',
		'https://rcapp.swanleap.com',
		'https://app.swanleap.com',

		// main tms2 instance aliases
		'https://tms2.clearviewaudit.com',
		'https://lcishipping.com',
		'https://www.lcishipping.com',
		'https://rytecshipping.com',
		'https://www.rytecshipping.com',
		'https://ssftms.com',
		'https://www.ssftms.com',
		'https://clearviewtmsemail.com',
		'https://www.clearviewtmsemail.com',
		'https://weirshipping.com',
		'https://www.weirshipping.com',
		'https://weiroilandgasshipping.com',
		'https://www.weiroilandgasshipping.com',
		'https://shiplink.clearviewaudit.com',
		'https://weirseaboardshipping.com',
		'https://www.weirseaboardshipping.com',
		'https://powerproductsshipping.com',
		'https://www.powerproductsshipping.com',
		'https://durexshipping.com',
		'https://www.durexshipping.com',
		'https://shiplinktest.clearviewaudit.com',
		'https://tms.swanleap.com',
		'https://jbss.swanleap.com',
		'https://tms2.swanleap.com',
		'https://unifirstshipping.com',
		'https://purity.swanleap.com',
		'https://discountrampsshipping.com',
		'https://avery.swanleap.com',
		'https://advcmp.swanleap.com',
		'https://lazarusnaturals.swanleap.com',
		'https://vtishipping.com',
		'https://www.vtishipping.com',
		'https://tigretms.com',
		'https://www.tigretms.com',
		'https://intelligentblendstms.com',
		'https://www.intelligentblendstms.com',
		'https://shipoctopi.com',
		'https://www.shipoctopi.com',
		'https://jaeckleshipping.com',
		'https://www.jaeckleshipping.com',
		'https://shipmerrillmfg.com',
		'https://www.shipmerrillmfg.com',
		'https://encompass.swanleap.com',
		'https://dsgtms.com',
		'https://www.dsgtms.com',

		// tms2 support servers
		'https://tms2worker.clearviewaudit.com',
		'https://api.swanleap.com',
		'https://migrations.clearviewaudit.com',
		'https://sync.swanleap.com',
		'https://dataparse.clearviewaudit.com',
		'https://next.swanleap.com',
		'https://previous.swanleap.com',

		// LFS standalone deployments
		'https://lfs.swanleap.com',
		'https://lfsworker.swanleap.com',
		'https://lfssync.swanleap.com',
		'https://lfssandbox.swanleap.com',
		'https://lfsshipping.com',
		'https://www.lfsshipping.com',
		'https://worker.lfsshipping.com',
		'https://sync.lfsshipping.com',
		'https://sandbox.lfsshipping.com',

		// Nolan and Cunnings standalone deployment
		'https://nolanandcunnings.swanleap.com',
		'https://nolanandcunningsworker.swanleap.com',
		'https://nolanandcunningssync.swanleap.com',
		'https://nandctms.com',
		'https://worker.nandctms.com',
		'https://sync.nandctms.com',

		// Schneider standalone deployments
		'https://schneider.swanleap.com',
		'https://schneiderworker.swanleap.com',
		'https://schneidersync.swanleap.com',
		'https://orangetms.com',
		'https://worker.orangetms.com',
		'https://sync.orangetms.com',
		'https://demo.orangetms.com',

		// TCI standalone deployments
		'https://tci.swanleap.com',
		'https://tciworker.swanleap.com',
		'https://tcisync.swanleap.com',
		'https://tcitms.com',
		'https://worker.tcitms.com',
		'https://sync.tcitms.com',

		// non-production deployments
		'https://dockscheduler.swanleap.com',
		'https://www.dockscheduler.swanleap.com',
		'https://releasecandidate.swanleap.com',
		'https://www.releasecandidate.swanleap.com',
		'https://development.swanleap.com',
		'https://www.development.swanleap.com',
		'https://secure.swanleap.com',
		'https://www.secure.swanleap.com',
		'https://sandbox.ssftms.com',
		'https://brokerdemo.swanleap.com',
		'https://puritysandbox.swanleap.com',
		'https://www.puritysandbox.swanleap.com',
		'https://ingress-nonprod.swanleap.com',
		'https://www.ingress-nonprod.swanleap.com',
		'https://sandbox.swanleap.com',
		'https://www.sandbox.swanleap.com',
		'https://sandbox.lfsshipping.com',
		'https://devencompass.swanleap.com',
	]

	// If our origin is an IP address it probably is coming from jenkins
	const regexEphemeralDeployment = /^http(s)?:\/\/(\d\d?\d?)\.(\d\d?\d?).(\d\d?\d?)\.(\d\d?\d?)/

	// Allow our local dev to accept messages from anywhere
	if (
		getLocationHref().indexOf('http://localhost:4200') !== 0 &&
		getLocationHref().indexOf('http://localhost:4250') !== 0
	) {
		if (
			!l.includes(allowedOrigins, l.toLower(ev.origin)) &&
			!regexEphemeralDeployment.test(ev.origin)
		) {
			logError(
				'iframe',
				'invalid origin',
				`${ev.origin} vs ${allowedOrigins} (${getWindow().location.host})`,
			)
			log('iframe', 'message is', ev)
			return
		}
	}

	const source: any = ev.source

	if (ev.data && ev.data.type === 'call-global-response') {
		const { token, result, err } = ev.data

		const callbackEntry = l.find(rpcCallbackList, (c) => c.token === token)
		if (!callbackEntry) {
			_logError('No callback registered for ' + token)
			return
		}
		l.remove(rpcCallbackList, (c) => c.token === token)
		const timeToExecute = new Date().getTime() - callbackEntry.started.getTime()
		_log(`call executed in ${timeToExecute} ms`)
		if (err) {
			_logError(err)
		}
		callbackEntry.callback(err, result)
	} else if (ev.data && ev.data.type === 'subscription-changed') {
		const { key, changeType, newDoc, oldDoc } = ev.data
		PubSub.publish('update-subscription', { key, changeType, newDoc, oldDoc })
	} else if (ev.data && ev.data.type === 'route-changed') {
		_log('changed page to', ev.data)
		const newPath = '#' + ev.data.pathname // Add hashing
		if (newPath !== getWindow().location.hash) {
			_log('redirect to', newPath)
			getWindow().location.hash = ev.data.pathname
		}
	} else if (ev.data && ev.data.type === 'call-tms3-global') {
		const functionName = ev.data.functionName
		const token = ev.data.token || 0
		const args = ev.data.args || []
		const func = _global[functionName]

		if (superVerboseIframe) {
			log(functionName, token, args)
		}
		log(functionName, token, 'starting')

		let caughtException = null
		let result = null
		if (!func) {
			caughtException = 'No global function named ' + functionName
			_logError(caughtException)
		} else if (!caughtException) {
			try {
				if (superVerboseIframe) {
					log('attempting...', functionName, ...args, func)
				}
				const resultOrPromise = func(...args)
				if (superVerboseIframe) {
					log('initial result...', resultOrPromise, ...args)
				}

				result = await Promise.resolve(resultOrPromise)
				if (superVerboseIframe) {
					log(functionName, token, result)
				}
			} catch (err) {
				caughtException = err
				_logError(caughtException)
			}
		}
		log(functionName, token, 'complete')

		source.postMessage(
			{
				type: 'call-tms3-global-response',
				token: token,
				err: caughtException,
				result: result,
			},
			'*',
		)
	} else if (ev.data && ev.data.type === 'user-changed') {
		_log('changed user', ev.data)
		// Re-sync user with TMS2
		fireAndForget(sosUser.fetchUser, 'fetch-changed-user')
	} else {
		if (ev.data) {
			// Ignore react devtools messages
			if (l.startsWith('' + ev.data.source, 'react-devtools')) {
				return
			}
		}
		_log('ignored iframe message', ev.data)
	}
}

getWindow().addEventListener('message', onMessage, false)

interface IRpcCallback {
	started: Date
	token: number
	callback: (err: any, result: any) => void
}

export function callTms2Global<T>(functionName: string, ...args): Promise<T> {
	_log('call-global', functionName, ...args)
	return new Promise<T>((resolve, reject) => {
		callTms2GlobalWithCallback(functionName, args, (err, result) => {
			if (err) {
				_log('call-global err', functionName)
				reject(err)
			} else {
				_log('call-global OK', functionName)
				resolve(result)
			}
		})
	})
}

export async function navParentTo(
	href: string,
	replace = false,
	newWindow = false,
): Promise<void> {
	await callTms2Global('setUrlFromChildIframe', href, replace, newWindow)
}

export function goToTms2BoL(
	shipment: apiTypes.ShipmentResponse,
	payload: apiTypes.PayloadResponse,
): void {
	fireAndForget(
		async () => await callTms2Global('goToTms2BoL', shipment, payload),
		'calling TMS2 BOL',
	)
}
