import React, { useEffect, useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import * as classes from './SelectCustomersModal.module.scss'
import { clientConfigSearch } from 'ui/components/broker'
import { Button } from 'ui/components/common/button'
import { Card } from 'ui/components/common/card'
import { Row, Col, ListGroup } from 'react-bootstrap'
import { TypeaheadOption } from 'ui/common/components/typeahead'
import { fireAndForget } from 'ui/lib/async'
import { Loader } from 'ui/components/common/loader'

const spacerHeight = '10px'

export const SelectCustomersModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: () => void
	tPrefix: string
	selectedCustomers: string[]
	onFilterApplied: (filters: string[]) => void
}) => {
	const { isModalOpen, onModalClose, tPrefix, selectedCustomers } = props

	const tPrefixModal = tPrefix + '.modalSelectCustomers'
	const testIdPrefix = 'select-customers-modal'

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isFetchingClientConfig, setIsFetchingClientConfig] = useState<boolean>(
		false,
	)
	const [customerOptions, setCustomerOptions] = useState<TypeaheadOption[]>([])
	const [selectedOptions, setSelectedOptions] = useState<TypeaheadOption[]>([])
	const [customerOptions2, setCustomerOptions2] = useState<TypeaheadOption[]>(
		[],
	)

	let searchRef = null

	useEffect(() => {
		if (isModalOpen) {
			fireAndForget(fetchClientConfig, 'Fetching Client Config')
		}
	}, [isModalOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	const fetchClientConfig = async (searchValue?: string): Promise<void> => {
		setIsFetchingClientConfig(true)

		const clientConfigs = await clientConfigSearch(searchValue, false)

		let newSelectedCustomers = selectedCustomers || []

		newSelectedCustomers = newSelectedCustomers.concat(
			selectedOptions
				.filter((option) => !newSelectedCustomers.includes(option.value))
				.map((option) => option.value),
		)

		const configs = clientConfigs.map((config) => ({
			...config,
			selected: newSelectedCustomers?.includes(config.value),
		}))

		const updatedSelectedOptions = selectedOptions.length
			? selectedOptions.filter((option) => option.value !== 'All')
			: configs.filter((option) => newSelectedCustomers.includes(option.value))

		if (
			newSelectedCustomers.some((selected) => selected === 'All') &&
			newSelectedCustomers.length < 2 &&
			searchValue === undefined
		) {
			addAllOptions()
		} else {
			setCustomerOptions(configs)
			setSelectedOptions(updatedSelectedOptions)
		}

		setCustomerOptions2(clientConfigs)
		setIsFetchingClientConfig(false)
	}

	const addAllOptions = (): void => {
		const allOption = [
			{
				label: 'All',
				value: 'All',
				selected: true,
			},
		]

		setCustomerOptions(allOption)
		setSelectedOptions(allOption)
	}

	const onClose = (): void => {
		setCustomerOptions([])
		setSelectedOptions([])
		setCustomerOptions2([])
		onModalClose()
	}

	const onApplyFilter = (): void => {
		setIsLoading(true)

		setTimeout(() => {
			if (props.onFilterApplied) {
				props.onFilterApplied(selectedOptions.map((option) => option.value))
			}
			setIsLoading(false)
			onClose()
		}, 2000)
	}

	const toggleCustomerSelection = (
		option?: TypeaheadOption,
		selected?: boolean,
		addAll?: boolean,
	): void => {
		if (addAll) {
			// ADD ALL
			addAllOptions()
		} else if (addAll === false) {
			// REMOVE ALL
			const updatedCustomerOptions = customerOptions
				.filter((option) => option.value !== 'All')
				.map((selection) => {
					return { ...selection, selected: false }
				})

			if (
				customerOptions.some((selected) => selected.value === 'All') &&
				customerOptions.length < 2 &&
				updatedCustomerOptions.length === 0
			) {
				setCustomerOptions(customerOptions2)
			} else {
				setCustomerOptions(updatedCustomerOptions)
			}

			// setCustomerOptions(updatedCustomerOptions)
			setSelectedOptions([])
		} else {
			// ADD ONE / REMOVE ONE
			const updatedCustomerOptions = customerOptions.map((selection) => {
				return selection.value === option.value
					? { ...selection, selected: selected }
					: selection
			})

			setCustomerOptions(updatedCustomerOptions)

			if (
				selected &&
				!selectedOptions?.some(
					(selectedOption) => selectedOption.value === option.value,
				)
			) {
				setSelectedOptions([option].concat(selectedOptions))
			} else {
				const updatedSelectedOptions = selectedOptions.filter(
					(selectedOption) => selectedOption.value !== option.value,
				)

				setSelectedOptions(updatedSelectedOptions)
			}
		}
	}

	return (
		<Modal
			content={() => (
				<div
					className={`bootstrap-wrapper ${classes.selectCustomersModal}`}
					data-testid={testIdPrefix}
				>
					<Spacer height={spacerHeight} />

					<Row>
						<Col xs={6}>
							<div className={classes.customerFilter}>
								<input
									type='text'
									className={'form-control'}
									onChange={async (event) => {
										const value = event.target.value
										await fetchClientConfig(value)
									}}
									ref={(el) => {
										searchRef = el
									}}
								></input>

								<div
									// className={
									// 	searchRef?.value
									// 		? classes.customerFilterIcon
									// 		: classes.customerFilterIconRemove
									// }
									className={classes.customerFilterIcon}
								>
									<IconButton
										icon={solidIcons.faTimes}
										color={'black'}
										onClick={async () => {
											if (searchRef.value) {
												searchRef.value = ''
												await fetchClientConfig('')
											}
										}}
									></IconButton>
								</div>
							</div>
						</Col>
					</Row>

					<Spacer height={spacerHeight} />

					<Row>
						<Col xs={6}>
							<Card
								title={
									<div className={classes.selectCustomersModalCardTitle}>
										{t('customers', tPrefixModal)}

										<div className={classes.selectCustomersModalCardButtons}>
											<Button
												color={'blue'}
												onClick={() => {
													toggleCustomerSelection(null, false, true)
												}}
												testId={`${testIdPrefix}-customers-card-all-button`}
												isDisabled={false}
											>
												{t('all', tPrefixModal)}
											</Button>

											<Button
												color={'blue'}
												onClick={() => {
													toggleCustomerSelection(null, false, false)
												}}
												testId={`${testIdPrefix}-customers-card-none-button`}
												isDisabled={false}
											>
												{t('none', tPrefixModal)}
											</Button>
										</div>
									</div>
								}
								color={'lightGray'}
								fillHeight={true}
								testId={`${testIdPrefix}-customers-card`}
							>
								{isFetchingClientConfig ? (
									<Loader isLoading={true} />
								) : (
									<div className={classes.selectCustomersModalCardListGroup}>
										<ListGroup>
											{customerOptions.map((selection, idx) => {
												return (
													<ListGroup.Item
														className={
															selection.selected && classes.selectedCustomer
														}
														action
														key={idx}
														onClick={() => {
															const selected = !selection.selected
															toggleCustomerSelection(selection, selected)
														}}
													>
														{selection.label}
													</ListGroup.Item>
												)
											})}
										</ListGroup>
									</div>
								)}
							</Card>
						</Col>

						<Col xs={6}>
							<Card
								title={
									<div className={classes.selectCustomersModalCardTitle}>
										{t('shownCustomers', tPrefixModal)}

										<div className={classes.selectCustomersModalCardButtons}>
											<Button
												color={'blue'}
												onClick={() => {
													toggleCustomerSelection(null, false, false)
												}}
												testId={`${testIdPrefix}-shown-customers-card-clear-button`}
												isDisabled={false}
											>
												{t('clear', tPrefixModal)}
											</Button>
										</div>
									</div>
								}
								color={'lightGray'}
								fillHeight={true}
								testId={`${testIdPrefix}-shown-customers-card`}
							>
								{isFetchingClientConfig ? (
									<Loader isLoading={true} />
								) : (
									<div className={classes.selectCustomersModalCardListGroup}>
										<ListGroup>
											{selectedOptions.map((selection, idx) => {
												return (
													<div key={idx}>
														<ListGroup.Item
															action
															key={idx}
															onClick={() => {
																toggleCustomerSelection(selection, false)
															}}
														>
															{selection.label}
															<div>
																<IconButton
																	key={idx}
																	icon={solidIcons.faTimes}
																	color={'black'}
																></IconButton>
															</div>
														</ListGroup.Item>
													</div>
												)
											})}
										</ListGroup>
									</div>
								)}
							</Card>
						</Col>
					</Row>

					<Spacer height={spacerHeight} />

					<AlignRight>
						<OkCancelButtons
							ok={t('applyFilter', tPrefixModal)}
							onOk={onApplyFilter}
							okColor='green'
							cancel={t('cancel', tPrefixModal)}
							onCancel={onClose}
							isSpinning={isLoading}
							isValid={selectedOptions.length > 0}
							okTestId={`${testIdPrefix}-apply-filter-button`}
							cancelTestId={`${testIdPrefix}-cancel-button`}
						/>
					</AlignRight>
				</div>
			)}
			isOpen={isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('selectCustomersModal', tPrefixModal)}
		/>
	)
}
