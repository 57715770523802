import { apiCommon } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'

// TODO: remove this once the data type is available on the apiTypes.ts
export interface UniqueCodeConfigRequest {
	autoGenerate: boolean
	prefix: string
	suffix: string
	numberOfDigits: number
}

export interface UniqueCodeConfigResponse extends UniqueCodeConfigRequest {
	currentValue: number
}

export interface TmsServiceConfigRequest {
	providerCodeConfig: UniqueCodeConfigRequest
}

export interface TmsServiceConfigResponse {
	providerCodeConfig: UniqueCodeConfigResponse
}

export const createTmsServiceConfig = async (): Promise<
	IRequestState<TmsServiceConfigResponse>
> => {
	const url = `companies/tms-service-config`
	return apiCommon.apiFetch<TmsServiceConfigResponse>(
		() => {},
		{
			method: 'POST',
		},
		url,
	)
}

export const getTmsServiceConfig = async (): Promise<
	IRequestState<TmsServiceConfigResponse>
> => {
	const url = `companies/tms-service-config`
	return apiCommon.apiFetch<TmsServiceConfigResponse>(
		() => {},
		{
			method: 'GET',
		},
		url,
	)
}

export const updateTmsServiceConfig = async (
	data: TmsServiceConfigRequest,
): Promise<IRequestState<TmsServiceConfigResponse>> => {
	const url = `companies/tms-service-config`
	return apiCommon.apiFetch<TmsServiceConfigResponse>(
		() => {},
		{
			method: 'PUT',
			data,
		},
		url,
	)
}

export const deleteTmsServiceConfig = async (): Promise<
	IRequestState<TmsServiceConfigRequest>
> => {
	const url = `companies/tms-service-config`
	return apiCommon.apiFetch<TmsServiceConfigResponse>(
		() => {},
		{
			method: 'DELETE',
		},
		url,
	)
}

export const getAutoGeneratedProviderCode = async (): Promise<
	IRequestState<string>
> => {
	const url = `companies/tms-service-config/autoGenerateProviderCode`
	return apiCommon.apiFetch<string>(
		() => {},
		{
			method: 'GET',
		},
		url,
	)
}
