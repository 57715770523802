import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import { assign } from 'lodash'
import React, { useState } from 'react'
import { apiShipments, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { ndash } from 'ui/components/common'
import { Button } from 'ui/components/common/button'
import { InfiniteLoader } from 'ui/components/common/loader'
import { useOnce } from 'ui/components/hooks'
import {
	tConnectionType,
	tFreightSize,
} from 'ui/components/i18n/commonTranslations'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import * as tableClasses from 'ui/components/rates-table/RatesTable.module.scss'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { booleanObjectFindTrueKey } from 'ui/lib/misc'
import {
	ConnectionForm,
	ConnectionModal,
	defaultConnectionForm,
} from 'ui/pages/shipment-profile/shared/components/connectionModal'
import { ConnectionActiveButton, InviteButton } from './InviteButtons'

export const tPrefix = 'page.shipmentProfile.invites'

interface IConnectionForShipment extends apiTypes.ConnectionResponse {
	invited: boolean
}

export const checkIfConnectionIsInvited = (
	connection: apiTypes.ConnectionResponse,
	rates: apiTypes.RateResponse[],
): boolean => {
	const rateWithConnectionId = l.find(
		rates,
		(rate) => rate.connectionId === connection.id,
	)
	return Boolean(
		rateWithConnectionId && rateWithConnectionId.offerStatus !== 'retracted',
	)
}

const inviteConnections = async (
	connectionIds: string[],
	connections: IConnectionForShipment[],
	connectionIdsBeingInvited: string[],
	setConnectionIdsBeingInvited: (connectionIds: string[]) => void,
	inviteFunction: (
		connectionIds: string[],
	) => Promise<IRequestState<apiTypes.InviteConnectionsResponse>>,
): Promise<void> => {
	if (connectionIds.length) {
		const updatedConnectionsIdsBeingInvited = l
			.cloneDeep(connectionIdsBeingInvited)
			.concat(connectionIds)
		setConnectionIdsBeingInvited(updatedConnectionsIdsBeingInvited)
		const response: IRequestState<apiTypes.InviteConnectionsResponse> = await inviteFunction(
			connectionIds,
		)
		if (response.data) {
			connectionIds.forEach((connectionId) => {
				const connection = l.find(connections, (c) => c.id === connectionId)
				if (connection) {
					connection.invited = true
				}
			})
		} else if (response.error) {
			// TODO print error message? (HEX-1030)
		}
		setConnectionIdsBeingInvited(
			l.without(connectionIdsBeingInvited, ...connectionIds),
		)
	}
}

const toggleConnectionActive = async (
	connectionId: string,
	connections: IConnectionForShipment[],
	connectionIdsBeingActiveToggled: string[],
	setConnectionIdsBeingActiveToggled: (connectionIds: string[]) => void,
): Promise<void> => {
	const connection = l.find(connections, (c) => c.id === connectionId)
	if (connection) {
		const updatedConnectionIdsBeingActiveToggled = l.cloneDeep(
			connectionIdsBeingActiveToggled,
		)
		updatedConnectionIdsBeingActiveToggled.push(connectionId)
		setConnectionIdsBeingActiveToggled(updatedConnectionIdsBeingActiveToggled)
		connection.active = !connection.active
		const response = await apiShipments.updateConnection(
			null,
			connectionId,
			connection,
		)
		if (response.error) {
			connection.active = !connection.active
			// TODO print error message (HEX-1030)
		}
		setConnectionIdsBeingActiveToggled(
			l.without(connectionIdsBeingActiveToggled, connectionId),
		)
	}
}

export const InvitesSection: FC = (props: {
	shipment: apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse
	locationIdForConnections: string
	inviteFunction: (
		connectionIds: string[],
	) => Promise<IRequestState<apiTypes.InviteConnectionsResponse>>
}) => {
	const { shipment } = props
	const [fetchingConnections, setFetchingConnections] = useState(false)
	const [connections, setConnections] = useState<IConnectionForShipment[]>([])
	const [connectionIdsBeingInvited, setConnectionIdsBeingInvited] = useState<
		string[]
	>([])
	const [invitingAllConnections, setInvitingAllConnections] = useState(false)
	const [
		connectionIdsBeingActiveToggled,
		setConnectionIdsBeingActiveToggled,
	] = useState<string[]>([])

	const [connectionModalForm, setConnectionModalForm] = React.useState<
		ConnectionForm
	>(null)

	const fetchInvites = (): void => {
		fireAndForget(async () => {
			setFetchingConnections(true)
			const connectionsResponse = await apiShipments.getConnections(() => {},
			props.locationIdForConnections)
			if (connectionsResponse.data) {
				setConnections(
					connectionsResponse.data.map((connection) =>
						assign(connection, {
							invited: checkIfConnectionIsInvited(connection, shipment.rates),
						}),
					),
				)
			} else if (connectionsResponse.error) {
				sosToast.sendApiErrorResponseToast(
					connectionsResponse,
					tString('connectionFetchError', tPrefix),
				)
			}
			setFetchingConnections(false)
		}, 'initial fetch connections for invite section')
	}

	useOnce(() => {
		fetchInvites()
	})

	return (
		<div>
			<div data-testid={'connectionsTable'}>
				<InfiniteLoader isLoading={fetchingConnections} />
				<AlignRight>
					<Button
						color='blue'
						onClick={async () => {
							setInvitingAllConnections(true)
							const connectionsThatNeedInvites = connections.filter(
								(connection) => connection.active && !connection.invited,
							)
							await inviteConnections(
								connectionsThatNeedInvites.map((c) => c.id),
								connections,
								connectionIdsBeingInvited,
								setConnectionIdsBeingInvited,
								props.inviteFunction,
							)
							setInvitingAllConnections(false)
						}}
						isSpinning={invitingAllConnections}
					>
						{t('inviteAllConnections', tPrefix)}
					</Button>
					<Button
						color='blue'
						onClick={() => {
							setConnectionModalForm(defaultConnectionForm)
						}}
						testId={'add-connection-button'}
					>
						{t('addConnection', tPrefix)}
					</Button>
				</AlignRight>
				<table
					className={tableClasses.tableRates}
					cellPadding={0}
					cellSpacing={0}
				>
					<thead>
						<tr>
							<th className={tableClasses.center}>{t('invited', tPrefix)}</th>
							<th className={tableClasses.center}>{t('active', tPrefix)}</th>
							<th>{t('companyName', tPrefix)}</th>
							<th>{t('freightSize', tPrefix)}</th>
							<th>{t('contactName', tPrefix)}</th>
							<th>{t('email', tPrefix)}</th>
							<th>{t('phone', tPrefix)}</th>
							<th>{t('connectionType', tPrefix)}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{l.map(connections, (connection, cIdx) => {
							const freightSize = booleanObjectFindTrueKey(
								connection.freightSize,
							)
							const connectionType = booleanObjectFindTrueKey(
								connection.connectionType,
							)
							return (
								<tr key={cIdx} data-testid={`connectionsTable-${cIdx}`}>
									<td className={tableClasses.center}>
										<InviteButton
											inviteSent={connection.invited}
											isSpinning={
												connectionIdsBeingInvited.indexOf(connection.id) > -1
											}
											onClick={async () =>
												await inviteConnections(
													[connection.id],
													connections,
													connectionIdsBeingInvited,
													setConnectionIdsBeingInvited,
													props.inviteFunction,
												)
											}
										/>
									</td>
									<td className={tableClasses.center}>
										<ConnectionActiveButton
											isActive={connection.active}
											isSpinning={
												connectionIdsBeingActiveToggled.indexOf(connection.id) >
												-1
											}
											onClick={async () =>
												await toggleConnectionActive(
													connection.id,
													connections,
													connectionIdsBeingActiveToggled,
													setConnectionIdsBeingActiveToggled,
												)
											}
										/>
									</td>
									<td>{connection.companyName || ndash}</td>
									<td>{tFreightSize(freightSize) || ndash}</td>
									<td>{connection.contactName || ndash}</td>
									<td>{connection.email || ndash}</td>
									<td>{connection.phone || ndash}</td>
									<td>{tConnectionType(connectionType) || ndash}</td>
									<td>
										<Button
											color='green'
											onClick={() => {
												setConnectionModalForm({
													id: connection.id,
													companyName: connection.companyName,
													phone: connection.phone,
													email: connection.email,
													contactName: connection.contactName,
													mc: connection.mc,
													usdot: connection.usdot,
													scac: connection.scac,
													isCarrier: connection.connectionType.isCarrier,
													isFreightForwarder:
														connection.connectionType.isFreightForwarder,
													isBroker: connection.connectionType.isBroker,
													truckload: connection.freightSize.truckload,
													volume: connection.freightSize.volume,
													active: connection.active,
													locationId: connection.locationId,
												})
											}}
										>
											{t('editConnection', tPrefix)}
										</Button>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
				{connections.length === 0 && !fetchingConnections && (
					<div className={tableClasses.noRates}>
						{t('noConnections', tPrefix)}
					</div>
				)}
			</div>
			<ConnectionModal
				onHide={(): void => setConnectionModalForm(null)}
				connection={connectionModalForm}
				onSave={() => {
					setConnections([])
					fetchInvites()
					setConnectionModalForm(null)
				}}
			/>
		</div>
	)
}
