// Feature toggles
import { sosToast } from 'common/components/toast'
import { apiCommon, apiLocation, apiTypes, apiUser } from 'ui/api'
import { ClientConfigResponse } from 'ui/api/apiTypes'
import { apiClientConfig } from 'ui/api/broker'
import { IRequestState } from 'ui/api/requestState'
import { TypeaheadOption } from 'ui/common/components/typeahead'
import { filterFormatAndSortConfigsOptions } from 'ui/components/broker'
import {
	getDocument,
	getLocalStorage,
	getLocationHref,
	localStorageExists,
} from 'ui/components/common/router/windowUtils'
import { tString } from 'ui/components/i18n/i18n'
import { idx, tms2_common } from 'ui/lib'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { permissions } from 'ui/lib/permissions'
import { r } from 'ui/lib/ramdaImports'
import { sos2 } from 'ui/lib/state/sos2'
import { StateCustomerManagement } from 'ui/pages/customer-management/sosCustomerManagement'
import { isInDev, isInTMS2 } from 'ui/theme/theme'

const userRefreshTime = 10000

export type PermissionCheckType = 'unknown' | 'allowed' | 'disallowed'
type LoginMode = 'unknown' | 'anonymous' | 'loggedIn'

export interface IDevAuthToken {
	devAuthToken: string
	name: string
}

export interface IStateUser {
	isUserFetched: boolean
	username: string
	locationId: string
	permissions: string[]
	permissionsTree: any
	showPermissions: boolean
	showDevTools: boolean
	devCheckPermissions: boolean
	devShowDebugTags: boolean

	authToken: string
	devAuthToken: string
	devAuthName: string
	devAuthTokens: IDevAuthToken[]
	userIsProvider: PermissionCheckType
	isUserBroker: PermissionCheckType

	requestUpdateBrokerMarkup: IRequestState<any>
	requestUserInfo: IRequestState<apiTypes.UserResponse>
	requestLocationsInfo: IRequestState<apiTypes.LocationResponse[]>
	requestOrganizationRelationship: IRequestState<
		apiTypes.OrganizationRelationshipResponse
	>
	managedLocations: apiTypes.LocationResponse[]
	requestFirstPartyLocations: IRequestState<apiTypes.LocationResponse[]>
	loadedClientConfigs: apiTypes.ClientConfigResponse[]
	loadedLocations: {
		locations: apiTypes.LocationResponse[]
		clientLocations: apiTypes.LocationResponse[]
	}
	selectedClientConfig?: apiTypes.ClientConfigResponse
	pollOnLivePages?: boolean
	sessionPrinterId: string
	sessionScaleId: string
}

const permissionsList = l.uniqBy(
	l.sortBy(
		[
			'Test:*',
			'Test:ComponentExamples:*',
			'Test:ComponentExamples:Show',
			'Test:ComponentExamples:Example1',
			'Test:ComponentExamples:Example2',
		],
		(c) => c,
	),
	(d) => d.toLowerCase(),
)
export { permissionsList }

// Values chosen arbitrarily
const MAX_CLIENT_CONFIGS_IN_MEMORY = 200
const MAX_LOCATIONS_IN_MEMORY = 200

export const getSos = sos2.createLazySos2<IStateUser>('sosUser', 6, () => ({
	isUserFetched: { default: false },
	username: { default: '', localStorage: true },
	locationId: { default: '', localStorage: true },
	permissions: { default: [], localStorage: true },
	permissionsTree: { default: [], localStorage: true },
	showPermissions: { default: true, localStorage: true },
	showDevTools: { default: true, localStorage: true },
	devCheckPermissions: { default: false, localStorage: true },
	devShowDebugTags: { default: false, localStorage: true },
	devAuthName: { default: '', localStorage: true },
	devAuthTokens: { default: [], localStorage: true },
	authToken: { default: '', localStorage: true }, // can be overridden with localStorage['override.user.authToken']
	devAuthToken: { default: '', localStorage: true },
	userIsProvider: { default: 'unknown', localStorage: true },
	isUserBroker: { default: 'disallowed', localStorage: true },
	managedLocations: { default: [], localStorage: true },
	requestFirstPartyLocations: { default: {}, localStorage: true },
	requestUserInfo: { default: {} },
	requestUpdateBrokerMarkup: { default: {} },
	requestLocationsInfo: { default: {} },
	requestOrganizationRelationship: { default: {} },
	loadedClientConfigs: {
		default: [],
		localStorage: true,
	},
	loadedLocations: {
		default: { locations: [], clientLocations: [] },
		localStorage: true,
	},
	selectedClientConfig: {
		default: null,
		localStorage: true,
	},
	pollOnLivePages: { default: true, localStorage: true },
	sessionPrinterId: { default: '', localStorage: true },
	sessionScaleId: { default: '', localStorage: true },
}))

function buildInitialPermissionsTree(): void {
	getSos().change((ds) => {
		ds.permissionsTree = permissions.buildPermissionsTree(ds.permissions)
	})
}
buildInitialPermissionsTree()

export function getLoginMode(): LoginMode {
	let loginMode: LoginMode = 'unknown'
	const stateUser = getSos().getState()

	if (stateUser.isUserFetched) {
		if (stateUser.username && stateUser.username !== 'anonymous') {
			loginMode = 'loggedIn'
		} else {
			loginMode = 'anonymous'
		}
	}

	return loginMode
}

export async function fetchLoggedInUserIsProvider(): Promise<void> {
	const result = await tms2_common.loggedInUserIsProvider()
	getSos().change((ds) => {
		ds.userIsProvider = result ? 'allowed' : 'disallowed'
	})
}

export function getLoggedInUserId(): string {
	return idx(() => getSos().getState().requestUserInfo.data.id)
}

export async function updateSelectableLocations(config): Promise<void> {
	if (config?.id) {
		await apiLocation.fetchLocations(
			(rs) => {
				getSos().change((ds) => {
					ds.requestLocationsInfo = rs
				})
			},
			{
				clientConfigId: config.id,
			},
		)
	} else {
		await apiLocation.fetchLocations((rs) => {
			getSos().change((ds) => {
				ds.requestLocationsInfo = rs
			})
		})
	}
}

export async function fetchUser(): Promise<void> {
	let managedCompanyId: string
	if (isInTMS2()) {
		// Get our current logged in user
		const authInfo = await tms2_common.getAuthInfo()
		getSos().change((ds) => {
			ds.authToken = authInfo.loginToken + ' ' + authInfo.userId
		})
		managedCompanyId = await tms2_common.currently_used_top_level_organization_id()
	}

	let updatedUser = false
	const userPollStartTime = Date.now()
	let requestUserInfo: IRequestState<apiTypes.UserResponse>
	while (!updatedUser && Date.now() < userPollStartTime + userRefreshTime) {
		requestUserInfo = await apiUser.fetchUser(() => {})
		if (
			requestUserInfo.error &&
			!getLocationHref().match(/dev-login|make-offer|provider-portal\/signup/)
		) {
			const attemptedRoute = getLocationHref()
			const redirect = encodeURIComponent(attemptedRoute)
			let url
			if (isInDev()) {
				url = 'http://devapp.swanleap.com/login/index.html?redirect=' + redirect
			} else {
				url = '/login/index.html?redirect=' + redirect
			}
			getDocument().location.href = url
		}
		if (requestUserInfo.data) {
			// must make sure that the user has been DBSynced to update its managed organization
			if (
				!managedCompanyId ||
				requestUserInfo.data.profile.settings.canViewDataForThisCompany ===
					managedCompanyId
			) {
				updatedUser = true
			}
		}
		if (requestUserInfo.error) {
			updatedUser = true
		}
	}
	getSos().change((ds) => {
		ds.requestUserInfo = requestUserInfo
		ds.selectedClientConfig = null
		ds.loadedClientConfigs = []
		if (ds.requestUserInfo.data) {
			ds.locationId = ds.requestUserInfo.data.locationId
			ds.username = ds.requestUserInfo.data.username

			ds.isUserFetched = true
			if (idx(() => ds.requestUserInfo.data.company.services)) {
				ds.isUserBroker = l.find(
					ds.requestUserInfo.data.company.services,
					(c) => c === 'broker',
				)
					? 'allowed'
					: 'disallowed'
			} else {
				ds.isUserBroker = 'disallowed'
			}
		}
		if (ds.requestUserInfo.error) {
			ds.isUserFetched = true
			ds.username = ''
			ds.isUserBroker = 'disallowed'
		}
	})
}

export function updatePermisson(
	permission: string,
	action: 'add' | 'revoke' | 'toggle',
): void {
	getSos().change((ds) => {
		if (action === 'toggle') {
			if (l.find(ds.permissions, (c) => c === permission)) {
				action = 'revoke'
			} else {
				action = 'add'
			}
		}

		if (action === 'revoke') {
			l.remove(ds.permissions, (c) => c === permission)
		} else if (action === 'add') {
			ds.permissions.push(permission)
		}
		// Organize
		ds.permissions = l.sortBy(ds.permissions, (c) => c)
		ds.permissions = l.uniqBy(ds.permissions, (c: string) => c.toLowerCase())
		ds.permissionsTree = permissions.buildPermissionsTree(ds.permissions)
	})
}

export function update(newState: Partial<IStateUser>): void {
	getSos().patchDeep(newState)
}

export function hasPermission(permission: string): boolean {
	const state = getSos().getState()
	return permissions.hasPermission(state.permissionsTree, permission)
}

export function hasExactPermission(permission: string): boolean {
	const state = getSos().getState()
	return permissions.hasExactPermission(state.permissionsTree, permission)
}

export function getAuthToken(): string {
	// Allow overriding this for e2e tests and development

	const state = getSos().getState()

	if (state.devAuthToken) {
		return state.devAuthToken
	}

	if (localStorageExists) {
		const authTokenOverride = getLocalStorage().getItem(
			'override.user.authToken',
		)
		if (authTokenOverride && !isInTMS2()) {
			return authTokenOverride
		}
		const meteorToken = getLocalStorage().getItem('Meteor.loginToken')
		const meteorUser = getLocalStorage().getItem('Meteor.userId')
		if (meteorToken && meteorUser) {
			return `${meteorToken} ${meteorUser}`
		}
	}

	return state.authToken
}

export function toggleShowDevTools(): void {
	getSos().change((ds) => {
		ds.showDevTools = !ds.showDevTools
	})
}

export async function fetchOrganizationRelationship(
	relationshipId: string,
): Promise<IRequestState<apiTypes.OrganizationRelationshipResponse>> {
	return await apiLocation.fetchOrganizationRelationship(
		relationshipId,
		(rs) => {
			getSos().change((ds) => {
				ds.requestOrganizationRelationship = rs
			})
		},
	)
}

export async function fetchCurrentlyManagedLocationInfo(): Promise<void> {
	const locations = await apiLocation.fetchLocations(() => {})
	getSos().change((ds) => {
		ds.managedLocations = locations.data
	})
}

export async function fetchFirstPartyLocationInfo(
	organizationRelationshipId?: string,
): Promise<IRequestState<apiTypes.LocationResponse[]>> {
	if (!organizationRelationshipId) {
		return
	}

	return apiCommon.apiFetchUntilNoMorePagedData(
		(rs) => {
			getSos().change((ds) => {
				ds.requestFirstPartyLocations = rs
			})
		},
		apiLocation.fetchLocations,
		{
			organizationRelationshipId,
		},
	)
}

export async function setDevAuthToken(
	devAuthName: string,
	devAuthToken: string,
): Promise<void> {
	getSos().change((ds) => {
		ds.devAuthName = devAuthName || ds.devAuthName || 'Unnamed'
		ds.devAuthToken = devAuthToken
	})
	await fetchUser()
}

export function setDevAuthName(devAuthName): void {
	getSos().change((ds) => {
		ds.devAuthName = devAuthName
	})
}

export function addDevAuth(): void {
	getSos().change((ds) => {
		ds.devAuthTokens.push({
			devAuthToken: ds.devAuthToken,
			name: ds.devAuthName,
		})
		ds.devAuthTokens = l.uniqBy(
			ds.devAuthTokens,
			(c) => c.devAuthToken + c.name,
		)
	})
}

export function removeDevAuth(): void {
	getSos().change((ds) => {
		ds.devAuthTokens = l.filter(ds.devAuthTokens, (c) =>
			r.not(
				(!c.devAuthToken || c.devAuthToken === ds.devAuthToken) &&
					(!c.name || c.name === ds.devAuthName),
			),
		)
	})
}

export const isUserBroker = (): boolean => {
	const state = getSos().getState()
	return state.isUserBroker === 'allowed'
}

export const getCurrentClientConfig = (): apiTypes.ClientConfigResponse => {
	return getSos().getState().selectedClientConfig
}

export const getLocation = async (
	locationId: string,
	alternateApiKey?: string,
): Promise<apiTypes.LocationResponse> => {
	const state = getSos().getState()
	// check for our location from what's in memory
	let location

	if (alternateApiKey) {
		location = l.find(
			state.loadedLocations.clientLocations,
			(location) => location.id === locationId,
		)
	} else {
		location = l.find(
			state.loadedLocations.locations,
			(location) => location.id === locationId,
		)
	}

	if (!location) {
		// location not in memory, make an API call and save it
		const retrievedLocation = await apiLocation.fetchLocation(
			() => {},
			locationId,
			alternateApiKey,
		)
		if (retrievedLocation.data) {
			addLocationsToMemory([retrievedLocation.data], !l.isNil(alternateApiKey))
			location = retrievedLocation.data
		} else {
			sosToast.sendApiErrorResponseToast(
				retrievedLocation,
				tString('locationFetchError', 'common'),
			)
		}
	}
	return location
}

export const addLocationsToMemory = (
	locations: apiTypes.LocationResponse[],
	clientLocation?: boolean,
): void => {
	const state = getSos().getState()
	const locationsInMemory = clientLocation
		? state.loadedLocations.clientLocations
		: state.loadedLocations.locations

	const locationsNotInMemory = locations.filter(
		(location) =>
			!l.find(
				locationsInMemory,
				(loadedLocation) => loadedLocation.id === location.id,
			),
	)

	const updatedLocationsInMemory = locationsInMemory.concat(
		locationsNotInMemory,
	)
	if (updatedLocationsInMemory.length > MAX_LOCATIONS_IN_MEMORY) {
		updatedLocationsInMemory.splice(
			0,
			updatedLocationsInMemory.length - MAX_LOCATIONS_IN_MEMORY,
		)
	}

	getSos().change((ds) => {
		if (clientLocation) {
			ds.loadedLocations.clientLocations = updatedLocationsInMemory
		} else {
			ds.loadedLocations.locations = updatedLocationsInMemory
		}
	})
}

export const getClientConfigs = async (
	configIds: string[],
): Promise<apiTypes.ClientConfigResponse[]> => {
	const state = getSos().getState()
	// check for our client configs from what's in memory
	const clientConfigsById: {
		[id: string]: apiTypes.ClientConfigResponse
	} = {}

	configIds.forEach((configId) => {
		clientConfigsById[configId] = l.find(
			state.loadedClientConfigs,
			(c) => c.id === configId,
		)
	})
	const unfoundClientConfigIds: string[] = Object.keys(
		clientConfigsById,
	).filter((configId) => !clientConfigsById[configId])
	if (unfoundClientConfigIds.length > 0) {
		// not all client configs in memory, make an API call and save them
		const query = unfoundClientConfigIds.map((id) => `id:${id}`).join(' OR ')
		const retrievedClientConfigs = await apiClientConfig.getClientConfigList(
			() => {},
			query,
			100,
			0,
		)
		if (retrievedClientConfigs.data) {
			addClientConfigsToMemory(retrievedClientConfigs.data.entities)
			retrievedClientConfigs.data.entities.forEach((clientConfig) => {
				clientConfigsById[clientConfig.id] = clientConfig
			})
		} else {
			sosToast.sendApiErrorResponseToast(
				retrievedClientConfigs,
				tString('clientFetchError', 'common'),
			)
		}
	}
	return configIds.map((configId) => clientConfigsById[configId])
}

export const addClientConfigsToMemory = (
	configs: ClientConfigResponse[],
): void => {
	const state = getSos().getState()
	const clientConfigsNotInMemory = configs?.filter(
		(config) => !l.find(state.loadedClientConfigs, (c) => c.id === config.id),
	)
	const updatedClientConfigsInMemory = state.loadedClientConfigs.concat(
		clientConfigsNotInMemory,
	)
	if (updatedClientConfigsInMemory.length > MAX_CLIENT_CONFIGS_IN_MEMORY) {
		updatedClientConfigsInMemory.splice(
			0,
			updatedClientConfigsInMemory.length - MAX_CLIENT_CONFIGS_IN_MEMORY,
		)
	}
	getSos().change(
		(ds) => (ds.loadedClientConfigs = updatedClientConfigsInMemory),
	)
}

export const setSelectedClientConfig = (configId: string): void => {
	const state = getSos().getState()
	let config: apiTypes.ClientConfigResponse
	if (configId && configId !== state?.selectedClientConfig?.id) {
		config = l.find(state.loadedClientConfigs, (c) => c.id === configId)
	} else if (!configId) {
		config = undefined
	} else {
		return
	}
	getSos().change((ds) => (ds.selectedClientConfig = config))
	fireAndForget(
		async () => updateSelectableLocations(config),
		'updating selectable locations',
	)
}

export const updateClientConfig = async (
	changes: Partial<StateCustomerManagement>,
): Promise<IRequestState<apiTypes.ClientConfigResponse>> => {
	let configFromList: apiTypes.ClientConfigResponse = null
	getSos().change((ds) => {
		ds.selectedClientConfig.brokerLocationId = changes.brokerLocationId
		ds.selectedClientConfig.brokerLocationName = changes.brokerLocationName
		ds.selectedClientConfig.defaultMarkupLogic = r.mergeDeepLeft(
			changes.brokerDefaultMarkupForm,
			ds.selectedClientConfig.defaultMarkupLogic || {},
		)
		ds.selectedClientConfig.paymentTerms = r.mergeDeepLeft(
			changes.paymentTermsForm,
			ds.selectedClientConfig.paymentTerms || {},
		)
		if (!l.isNil(changes.brokerNumber)) {
			ds.selectedClientConfig.brokerNumber = changes.brokerNumber
		}
		ds.selectedClientConfig.useClientInfoForBolHeader =
			changes.useCustomerInfoForBOL
		const idx = l.findIndex(
			ds.loadedClientConfigs,
			(c) => c.id === ds.selectedClientConfig.id,
		)
		ds.loadedClientConfigs[idx] = ds.selectedClientConfig
		configFromList = ds.selectedClientConfig
	})

	return await apiClientConfig.updateClientConfig(
		() => {},
		configFromList.id,
		configFromList,
	)
}

export const getLocationFilterForUser = async (
	filterByLocationOverride?: string | null,
): Promise<string[] | null> => {
	if (filterByLocationOverride) {
		return [filterByLocationOverride]
	}

	if (isInTMS2()) {
		return await tms2_common.currently_managed_organization_ids()
	}

	const state = getSos().getState()
	const allAllowed =
		idx(
			() => state.requestUserInfo.data.profile.allowedOrganizations.allAllowed,
		) || false
	if (allAllowed) {
		// empty means don't filter by location at all
		return null
	}

	return idx(() => state.requestUserInfo.data.allowedLocations) || null
}

export const logout = (): Promise<void> => {
	// might need to do selective removal of keys
	return Promise.resolve(getLocalStorage().clear())
}

export const updatePrinterScaleId = (id: string, isPrinter: boolean): void => {
	getSos().change((ds) => {
		if (isPrinter) {
			ds.sessionPrinterId = id
		} else {
			ds.sessionScaleId = id
		}
	})
}

export const loadSavedConfigs = (): TypeaheadOption[] => {
	return filterFormatAndSortConfigsOptions(
		getSos().getState().loadedClientConfigs,
	).map((selection) => ({
		label: selection.label,
		value: selection.value,
		selected: false,
	}))
}
