import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiTypes, apiPrintNode } from 'ui/api'
import { Button } from 'ui/components/common/button'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { Center } from 'ui/components/layout/center'
import { l } from 'ui/lib/lodashImports'
import * as classes from './PrintStationList.module.scss'
import { PrintStationConfigModal } from '../print-station-config-modal'
import { Spacer } from 'ui/components/layout/spacer'
import { PrinterGroupModal } from '../printer-group-modal'

export const tPrefix = 'page.companyManagement.printersAndScales.printStations'

export const PrintStationList: FC = (props: {
	printerList: apiTypes.PrinterResponse[]
	credentialsId: string
}) => {
	const { printerList, credentialsId } = props

	const [printStationList, setPrintStationList] = useState<
		apiTypes.PrinterGroupResponse[]
	>([])
	const [editRowPrintStation, setEditRowPrintStation] = useState<number>(null)
	const [isFetchingPrintStations, setIsFetchingPrintStations] = useState<
		boolean
	>(false)
	const [isDeletingPrinterGroup, setIsDeletingPrinterGroup] = useState<boolean>(
		false,
	)
	const [
		isPrintStationConfigModalOpen,
		setIsPrintStationConfigModalOpen,
	] = useState<boolean>(false)
	const [selectedPrintStationConfig, setSelectedPrintStationConfig] = useState<
		apiTypes.PrinterGroupResponse
	>(null)
	const [isPrinterGroupModalOpen, setIsPrinterGroupModalOpen] = useState<
		boolean
	>(false)

	useOnce(async () => {
		setIsFetchingPrintStations(true)

		const printerGroupResponse = await apiPrintNode.getPrinterGroupList(
			() => {},
			credentialsId,
		)

		if (printerGroupResponse.data) {
			setPrintStationList(printerGroupResponse.data)
		} else if (printerGroupResponse.error) {
			sosToast.sendApiErrorResponseToast(printerGroupResponse)
		}

		setIsFetchingPrintStations(false)
	})

	const assignComputer = async (): Promise<void> => {
		setIsPrinterGroupModalOpen(true)
	}

	return (
		<Center>
			<div className={classes.printStationList}>
				<div className={classes.printStationListTitleButtons}>
					<div className={classes.printStationListTitle}>
						{t('printStationsList', tPrefix)}
					</div>
					<div>
						<Button
							color={'blue'}
							isDisabled={isFetchingPrintStations || isDeletingPrinterGroup}
							testId={'print-station-list-assign-computer'}
							onClick={assignComputer}
						>
							{t('assignComputer', tPrefix)}
						</Button>
						<Button
							color={'green'}
							isDisabled={isFetchingPrintStations || isDeletingPrinterGroup}
							testId={'print-station-list-create-print-station'}
							onClick={() => {
								setSelectedPrintStationConfig(null)
								setIsPrintStationConfigModalOpen(true)
							}}
						>
							{t('createPrintStation', tPrefix)}
						</Button>
					</div>
				</div>
				<table cellPadding={0} cellSpacing={0}>
					<thead>
						<tr>
							<th>{t('printStationsListLabels', tPrefix)}</th>
							<th>{t('config', tPrefix)}</th>
						</tr>
					</thead>
					<tbody>
						{isFetchingPrintStations ? (
							<tr>
								<td colSpan={2}>{t('loadingPrintStation', tPrefix)}</td>
							</tr>
						) : (
							<>
								{printStationList.length > 0 ? (
									<>
										{l.map(printStationList, (station, idx) => {
											return (
												<tr
													key={idx}
													data-testid={`printStationListTable-${idx}`}
												>
													<td>{station.nickname}</td>
													<td>
														<div>
															<IconButton
																icon={
																	idx === editRowPrintStation
																		? solidIcons.faSave
																		: solidIcons.faPencilAlt
																}
																color={'black'}
																onClick={() => {
																	setSelectedPrintStationConfig(station)
																	setIsPrintStationConfigModalOpen(true)
																}}
																testId={'print-station-list-edit-save'}
																disabled={isDeletingPrinterGroup}
															></IconButton>

															<IconButton
																icon={solidIcons.faTrashAlt}
																color={'black'}
																onClick={async () => {
																	if (editRowPrintStation !== null) {
																		setEditRowPrintStation(null)
																	} else {
																		setIsDeletingPrinterGroup(true)

																		const printerGroupResponse = await apiPrintNode.deletePrinterGroup(
																			() => {},
																			credentialsId,
																			station.id,
																		)

																		if (printerGroupResponse.data) {
																			const printerGroupResponse = await apiPrintNode.getPrinterGroupList(
																				() => {},
																				credentialsId,
																			)
																			if (printerGroupResponse.data) {
																				setPrintStationList(
																					printerGroupResponse.data,
																				)
																			} else if (printerGroupResponse.error) {
																				sosToast.sendApiErrorResponseToast(
																					printerGroupResponse,
																				)
																			}
																		} else if (printerGroupResponse.error) {
																			sosToast.sendApiErrorResponseToast(
																				printerGroupResponse,
																			)
																		}

																		setIsDeletingPrinterGroup(false)
																	}
																}}
																testId={'print-station-list-delete'}
																disabled={isDeletingPrinterGroup}
															></IconButton>
														</div>
													</td>
												</tr>
											)
										})}
									</>
								) : (
									<tr>
										<td colSpan={2}>{t('noPrintStationFound', tPrefix)}</td>
									</tr>
								)}
							</>
						)}
					</tbody>
				</table>

				<Spacer height={'30px'} />

				<PrintStationConfigModal
					isModalOpen={isPrintStationConfigModalOpen}
					onModalClose={() => {
						setIsPrintStationConfigModalOpen(false)
					}}
					printerList={printerList}
					credentialsId={credentialsId}
					printStationList={printStationList}
					setPrintStationList={setPrintStationList}
					selectedPrintStationConfig={selectedPrintStationConfig}
				/>

				<PrinterGroupModal
					isModalOpen={isPrinterGroupModalOpen}
					onModalClose={() => {
						setIsPrinterGroupModalOpen(false)
					}}
					printStationList={printStationList}
				/>
			</div>
		</Center>
	)
}
