import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { apiTypes } from 'ui/api'
import { AngryRedBox } from 'ui/components/common/angry-red-box'
import { Button } from 'ui/components/common/button'
import { solidIcons } from 'ui/components/common/icon'
import { IconButton } from 'ui/components/common/icon/Icon'
import { Input } from 'ui/components/common/input'
import { Modal } from 'ui/components/common/modal/Modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { EllipsisPopup } from 'ui/components/common/popup/Popup'
import { sosRouter2 } from 'ui/components/common/router'
import { Select } from 'ui/components/common/select/Select'
import { Separator } from 'ui/components/common/separator'
import { SmallButton } from 'ui/components/common/small-button'
import { CheckboxList } from 'ui/components/form/CheckboxList'
import { FormStackedListSelect } from 'ui/components/form/FormStackedListSelect'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import { FlexRow, FlexVerticalSeparator } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { getTimeSlotsByDate, ITimeSlot } from 'ui/lib/time/timeSlots'
import { DockSchedulerLocationSelector } from 'ui/pages/dock-scheduler/components/DockSchedulerLocationSelector'
import * as classes from 'ui/pages/dock-scheduler/DockScheduler.module.scss'
import { sosDockScheduler } from 'ui/pages/dock-scheduler/state'
import * as sosDockConfigPage from 'ui/pages/dock-scheduler/state/sosDockConfigPage'
import {
	DockModeType,
	FlowType,
} from 'ui/pages/dock-scheduler/state/sosDockConfigPage'
import { Layout } from 'ui/pages/layout/Layout'
import { HeaderText } from 'ui/pages/spot-quote/MakeOfferPage'
import { AllDockDays } from './AllDockDays'
import { dayOptions } from './dayOptions'
import { DockConfigurationDataTable } from './DockConfigurationDataTable'
import { equipmentTypeOptions } from './equipmentTypeOptions'
import { flowOptions } from './flowOptions'
import { modeOptions } from './modeOptions'
import { NewDockModal } from './NewDockModal'
import { NewTimeSlotModal } from './NewTimeSlotModal'

export const tPrefixDockConfig = 'page.dockScheduler.dockConfig'
const tPrefixDockConfigDayOfWeek = 'page.dockScheduler.dockConfig.dayOfWeek'

export const DockConfigurationPage: FC = (props: {}) => {
	useOnce(() => {
		fireAndForget(
			sosDockConfigPage.fetchDocks,
			'Fetching docks for dock config',
		)
	})

	const copyScheduleButtonColor = 'blue'

	const state = sos2.useSubscription(sosDockConfigPage.getSos())
	const dockSchedulerState = sos2.useSubscription(sosDockScheduler.getSos())

	const dockSelectOptions = l.map(
		l.sortBy(dockSchedulerState.docks, (dock) => dock.nickName),
		(dock) => {
			return { label: dock.nickName, value: dock.id }
		},
	)

	useEffect(() => {
		if (state.selectedDockHasUnsavedChanges) {
			sosRouter2.setOnLeave((pageInfo, params): boolean => {
				sosDockConfigPage.updateNavTo({ pageInfo, params })
				sosDockConfigPage.updateUnsavedChangesModalShowing(true)
				return false
			})
		} else {
			sosRouter2.setOnLeave(null)
		}
		return () => sosRouter2.setOnLeave(null)
	}, [state.selectedDockHasUnsavedChanges])

	const dockListLabel = (
		<div className={classes.dockListLabel}>
			<Row>
				<Col className={'text-left'}>{t('docks', tPrefixDockConfig)}</Col>
				<Col>
					<span className={classes.addDockIcon}>
						<IconButton
							icon={solidIcons.faPlus}
							onClick={() => sosDockConfigPage.updateShowNewDockModal(true)}
							testId='addDock'
						/>
					</span>
				</Col>
			</Row>
		</div>
	)

	let timeSlots: ITimeSlot[] = []

	if (state.selectedDock?.schedule?.rules) {
		l.forEach(
			state.selectedDock.schedule.rules,
			(rule) =>
				(timeSlots = timeSlots.concat(
					getTimeSlotsByDate(
						rule,
						DateTime.local()
							.set({ weekday: sosDockConfigPage.luxonDays[state.selectedDay] })
							.toISO(),
					),
				)),
		)
	}

	timeSlots = timeSlots.sort((timeslot1, timeslot2) =>
		timeslot1.startTime > timeslot2.startTime ? 1 : -1,
	)

	const [isUpdatingDock, setIsUpdatingDock] = useState(false)
	const [isDeletingDock, setIsDeletingDock] = useState(false)
	const [showWeeklyDockHours, setShowWeeklyDockHours] = useState(true)
	const [showNewTimeSlotModal, setShowNewTimeSlotModal] = useState(false)

	return (
		<Layout>
			<div className={classes.dockConfigPageWrapper}>
				<DockSchedulerLocationSelector
					onChange={(selected) =>
						fireAndForget(
							sosDockConfigPage.fetchDocks,
							'fetching docks for dock config page',
						)
					}
				/>
				<Spacer />
				{!dockSchedulerState.currentLocation?.id && (
					<div data-testid='pleaseSelectLocation'>
						{t('selectLocationWithDocks', tPrefixDockConfig)}
					</div>
				)}
				{dockSchedulerState.currentLocation?.id && (
					<Row>
						<Col xs={'2'} className={classes.dockConfigDockList}>
							<FormStackedListSelect
								options={dockSelectOptions}
								value={state.selectedDock?.id}
								onChange={sosDockConfigPage.selectDock}
								label={dockListLabel}
								tPrefix={tPrefixDockConfig}
								testId='dockSelector'
								readOnly={isUpdatingDock}
							/>
						</Col>
						<Col>
							{state.selectedDock?.id && (
								<div className={classes.dockConfigBoardWrapper}>
									<AlignRight>
										<div className={classes.verticalCenter}>
											{state.selectedDockHasUnsavedChanges && (
												<AngryRedBox
													label={t('unsavedChanges', tPrefixDockConfig)}
												>
													{t('thisDockHasUnsavedChanges', tPrefixDockConfig)}
												</AngryRedBox>
											)}
											<Button
												color='blue'
												onClick={async () => {
													setIsUpdatingDock(true)
													await sosDockConfigPage.updateSelectedDock()
													setIsUpdatingDock(false)
												}}
												isSpinning={isUpdatingDock}
											>
												{t('saveChangesToThisDock', tPrefixDockConfig)}
											</Button>
											<EllipsisPopup
												position='topRight'
												testId='deleteDockEllipsis'
												isOpen={state.showDeleteOptions}
												onClick={() =>
													sosDockConfigPage.updateShowDeleteOptions(true)
												}
												onClickOutside={() =>
													sosDockConfigPage.updateShowDeleteOptions(false)
												}
											>
												<div>
													<Button
														onClick={async () => {
															setIsDeletingDock(true)
															await sosDockConfigPage.deleteDock()
															setIsDeletingDock(false)
														}}
														testId='deleteDock'
														isSpinning={isDeletingDock}
														color='red'
													>
														{t('deleteDock', tPrefixDockConfig)}
													</Button>
												</div>
											</EllipsisPopup>
										</div>
									</AlignRight>
									{state.errors.length > 0 && (
										<AngryRedBox label={t('errors', tPrefixDockConfig)}>
											{l.map(state.errors, (error, errorIdx) => (
												<div key={errorIdx}>- {error}</div>
											))}
										</AngryRedBox>
									)}
									<Spacer />
									<div>
										<label className={classes.dockConfigLabel}>
											{t('name', tPrefixDockConfig)}
										</label>
										<Input
											testId={'dockName'}
											value={state.selectedDock?.name}
											onChange={(newVal: string) =>
												sosDockConfigPage.updateDockName(newVal)
											}
											width='150px'
										/>
									</div>

									<Spacer height='15px' />
									<div>
										<FlexRow>
											<IconButton
												icon={
													showWeeklyDockHours
														? solidIcons.faAngleDown
														: solidIcons.faAngleUp
												}
												onClick={() =>
													setShowWeeklyDockHours(!showWeeklyDockHours)
												}
											/>
											<div>{t('weeklyDockHours', tPrefixDockConfig)}</div>
										</FlexRow>
									</div>
									{showWeeklyDockHours && (
										<AllDockDays rules={state.selectedDock?.schedule?.rules} />
									)}
									<Spacer height='20px' />
									<FlexRow>
										<div>
											<div>
												<label className={classes.dockConfigLabel}>
													{t('dockDay', tPrefixDockConfig + '.dayOfWeek')}
												</label>
											</div>
											<Select
												onChange={(day) => sosDockConfigPage.updateDockDay(day)}
												value={state.selectedDay}
												name={'dockDay'}
												options={dayOptions}
												tPrefix={tPrefixDockConfig + '.dayOfWeek'}
												testId='dockDay'
											/>
											<DockConfigurationDataTable
												timeSlots={timeSlots}
												scheduleId={state.selectedDock?.scheduleId}
												isLoading={state.isFetchingSchedule}
											/>
											<AlignRight>
												<Button
													color='blue'
													onClick={() => setShowNewTimeSlotModal(true)}
													className={classes.dockConfigAddTimeSlotButton}
												>
													{t('addTimeSlot', tPrefixDockConfig)}
												</Button>
											</AlignRight>
										</div>

										<div>
											<HeaderText fontSize='fontLarge'>
												{t('copyScheduleTo', tPrefixDockConfig)}:
											</HeaderText>
											<Separator />
											<div className={classes.copyScheduleDayList}>
												<SmallButton
													color={copyScheduleButtonColor}
													onClick={() =>
														sosDockConfigPage.copyScheduleTo(timeSlots, [
															'monday',
															'tuesday',
															'wednesday',
															'thursday',
															'friday',
															'saturday',
															'sunday',
														])
													}
													testId='copyToAll'
												>
													{t('allDays', tPrefixDockConfigDayOfWeek)}
												</SmallButton>
												<SmallButton
													color={copyScheduleButtonColor}
													onClick={() =>
														sosDockConfigPage.copyScheduleTo(timeSlots, [
															'monday',
															'tuesday',
															'wednesday',
															'thursday',
															'friday',
														])
													}
													testId='copyToWeekdays'
												>
													{t('weekdays', tPrefixDockConfigDayOfWeek)}
												</SmallButton>
												<SmallButton
													color={copyScheduleButtonColor}
													onClick={() =>
														sosDockConfigPage.copyScheduleTo(timeSlots, [
															'saturday',
															'sunday',
														])
													}
													testId='copyToWeekendDays'
												>
													{t('weekendDays', tPrefixDockConfigDayOfWeek)}
												</SmallButton>
												<Spacer />
												<SmallButton
													color={copyScheduleButtonColor}
													onClick={() =>
														sosDockConfigPage.copyScheduleTo(timeSlots, [
															'sunday',
														])
													}
													testId='copyToSunday'
												>
													{t('sunday', tPrefixDockConfigDayOfWeek)}
												</SmallButton>
												<SmallButton
													color={copyScheduleButtonColor}
													onClick={() =>
														sosDockConfigPage.copyScheduleTo(timeSlots, [
															'monday',
														])
													}
													testId='copyToMonday'
												>
													{t('monday', tPrefixDockConfigDayOfWeek)}
												</SmallButton>
												<SmallButton
													color={copyScheduleButtonColor}
													onClick={() =>
														sosDockConfigPage.copyScheduleTo(timeSlots, [
															'tuesday',
														])
													}
													testId='copyToTuesday'
												>
													{t('tuesday', tPrefixDockConfigDayOfWeek)}
												</SmallButton>
												<SmallButton
													color={copyScheduleButtonColor}
													onClick={() =>
														sosDockConfigPage.copyScheduleTo(timeSlots, [
															'wednesday',
														])
													}
													testId='copyToWednesday'
												>
													{t('wednesday', tPrefixDockConfigDayOfWeek)}
												</SmallButton>
												<SmallButton
													color={copyScheduleButtonColor}
													onClick={() =>
														sosDockConfigPage.copyScheduleTo(timeSlots, [
															'thursday',
														])
													}
													testId='copyToThursday'
												>
													{t('thursday', tPrefixDockConfigDayOfWeek)}
												</SmallButton>
												<SmallButton
													color={copyScheduleButtonColor}
													onClick={() =>
														sosDockConfigPage.copyScheduleTo(timeSlots, [
															'friday',
														])
													}
													testId='copyToFriday'
												>
													{t('friday', tPrefixDockConfigDayOfWeek)}
												</SmallButton>
												<SmallButton
													color={copyScheduleButtonColor}
													onClick={() =>
														sosDockConfigPage.copyScheduleTo(timeSlots, [
															'saturday',
														])
													}
													testId='copyToSaturday'
												>
													{t('saturday', tPrefixDockConfigDayOfWeek)}
												</SmallButton>
											</div>
										</div>

										<FlexVerticalSeparator />
										<CheckboxList
											options={flowOptions}
											checkedValues={state.selectedDock?.allowedFlows}
											label='allowedFlows'
											tPrefix={tPrefixDockConfig}
											onChange={(value: FlowType, checked: boolean) =>
												sosDockConfigPage.updateAllowedFlow(value, checked)
											}
											headerFontSize='fontLarge'
											checkboxLabelFontSize='fontMedium'
										/>
										<FlexVerticalSeparator />
										<CheckboxList
											options={modeOptions}
											checkedValues={state.selectedDock?.allowedModes}
											label='allowedModes'
											tPrefix={tPrefixDockConfig}
											onChange={(value: DockModeType, checked: boolean) =>
												sosDockConfigPage.updateAllowedModes(value, checked)
											}
											headerFontSize='fontLarge'
											checkboxLabelFontSize='fontMedium'
										/>
										<FlexVerticalSeparator />
										<div>
											<CheckboxList
												options={equipmentTypeOptions}
												checkedValues={state.selectedDock?.equipmentTypes}
												label='allowedEquipment'
												tPrefix={tPrefixDockConfig + '.equipmentType'}
												onChange={(
													value: apiTypes.ShipmentResponse['equipmentType'],
													checked: boolean,
												) =>
													sosDockConfigPage.updateDockEquipmentTypes(
														value,
														checked,
													)
												}
												headerFontSize='fontLarge'
												checkboxLabelFontSize='fontMedium'
											/>
											<FlexVerticalSeparator />
										</div>
									</FlexRow>
								</div>
							)}
						</Col>
					</Row>
				)}

				<NewDockModal isOpen={state.showNewDockModal} newDock={state.newDock} />
				<NewTimeSlotModal
					isOpen={showNewTimeSlotModal}
					newTimeSlot={state.newTimeSlot}
					existingTimeSlots={timeSlots}
					close={() => setShowNewTimeSlotModal(false)}
				/>
				<Modal
					isOpen={state.unsavedChangesModalShowing}
					content={() => (
						<div>
							{t('youHaveUnsavedChanges', tPrefixDockConfig)}
							<AlignRight>
								<OkCancelButtons
									ok={t('save', tPrefixDockConfig)}
									okColor='green'
									cancel={t('discardChanges', tPrefixDockConfig)}
									isValid={true}
									onOk={async () => {
										setIsUpdatingDock(true)
										await sosDockConfigPage.updateSelectedDock()
										sosDockConfigPage.updateUnsavedChangesModalShowing(false)
										setIsUpdatingDock(false)
										sosRouter2.navigateTo(
											state.navTo.pageInfo,
											state.navTo.params,
										)
									}}
									isSpinning={isUpdatingDock}
									onCancel={() => {
										sosRouter2.setOnLeave(null)
										sosRouter2.navigateTo(
											state.navTo.pageInfo,
											state.navTo.params,
										)
									}}
								/>
							</AlignRight>
						</div>
					)}
					onModalClose={() =>
						sosDockConfigPage.updateUnsavedChangesModalShowing(false)
					}
					title={t('unsavedChanges', tPrefixDockConfig)}
				/>
			</div>
		</Layout>
	)
}
