import { apiTypes } from 'ui/api/'
import { idx } from 'ui/lib'
import { prettyLocation } from 'ui/lib/locations/prettyLocations'
import { l } from 'ui/lib/lodashImports'
import { uniqueTruthyMap } from 'ui/lib/misc'
import {
	getBolNumbers,
	getPickTicketNumbers,
} from 'ui/lib/shipments/shipmentUtils'
import { IShipmentListMappedData, sosUser } from 'ui/state'
import { calculateBrokerPrices } from 'ui/pages/shipment-profile/broker/functions/calculateBrokerPrices'
import { extractContainersFromShipment } from 'ui/lib/shipments'
import { toCountryCode } from 'ui/data/countryAndRegionUtils'

export function processShipments(
	data: apiTypes.ShipmentListResponse | apiTypes.BrokerShipmentListResponse,
	clientConfigs: apiTypes.ClientConfigResponse[],
): IShipmentListMappedData[] {
	const isUserBroker = sosUser.isUserBroker()

	const locations: apiTypes.LocationResponse[] = sosUser.getSos().getState()
		.requestLocationsInfo.data

	return l.map(
		data.entities,
		(c: apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse) => {
			const salesOrders = l.flatMap(
				uniqueTruthyMap(c.payloads, (payload) => payload.salesOrders || []),
			)
			const purchaseOrders = l.flatMap(
				uniqueTruthyMap(c.payloads, (payload) => payload.purchaseOrders || []),
			)

			const bolIdentifiers = getBolNumbers(c)
			const pickTicketNumbers = getPickTicketNumbers(c)

			const providerIdentifiers = uniqueTruthyMap(
				c.payloads,
				(payload) => payload.providerIdentifier,
			)

			const loadNumbers = uniqueTruthyMap(
				c.payloads,
				(payload) => payload.loadNumber,
			)
			const vendorIdentifiers = uniqueTruthyMap(c.payloads, (payload) => {
				return l.join(
					l.compact([payload.vendorIdentifier, payload.vendorName]),
					'\n',
				)
			})

			let flow = 'unknown'

			if (c.payloads.length === 0) {
				flow = 'none'
			} else {
				let hasPickupLocation = false
				let hasDeliveryLocation = false
				l.forEach(c.payloads, (d) => {
					if (idx(() => d.originStop.metaData.locationId) != null) {
						hasPickupLocation = true
					}
					if (idx(() => d.destinationStop.metaData.locationId) != null) {
						hasDeliveryLocation = true
					}
				})

				if (hasPickupLocation && hasDeliveryLocation) {
					flow = 'transfer'
				} else if (hasPickupLocation) {
					flow = 'outbound'
				} else if (hasDeliveryLocation) {
					flow = 'inbound'
				} else {
					flow = 'drop'
				}
			}

			const expenseToLocation: apiTypes.LocationResponse =
				c.expenseTo && locations.find((loc) => loc.id === c.expenseTo)

			const containers: apiTypes.Container[] = extractContainersFromShipment(
				c,
				true,
			)

			const classes: string[] = condenseClasses(containers)

			const shipment: IShipmentListMappedData = {
				id: c.id,
				identifier: c.identifier,
				chargeTo: idx(() => expenseToLocation.name),
				accessorials: c.accessorials,
				billToRole: c.billToRole,
				classes,
				pickupNumber: c.pickupNumber,
				sealNumber: c.sealNumber,
				proNumber: c.proNumber,
				mode: c.bookedRate?.method,
				flow: flow,
				originStreet1: c.origin.street1,
				originStreet2: c.origin.street2,
				originZip: c.origin.zip,
				originCity: c.origin.city,
				originState: c.origin.state,
				originCompany: c.origin.company,
				originCityState: prettyLocation(
					c.origin.city,
					c.origin.state,
					c.origin.country,
				),
				originCountryCode: toCountryCode(c.origin.country),
				destinationStreet1: c.destination.street1,
				destinationStreet2: c.destination.street2,
				destinationZip: c.destination.zip,
				destinationCity: c.destination.city,
				destinationState: c.destination.state,
				destinationCompany: c.destination.company,
				destinationCityState: prettyLocation(
					c.destination.city,
					c.destination.state,
					c.destination.country,
				),
				destinationCountryCode: toCountryCode(c.destination.country),
				totalWeight: c.totalWeight,
				estimatedDistance: c.estimatedDistance,
				equipmentType: c.equipmentType,
				shipmentStatus:
					sosUser.isUserBroker() && 'brokerShipmentStatus' in c
						? c.brokerShipmentStatus
						: c.shipmentStatus,
				quotedCost: idx(() => c.bookedRate.costTotal),
				providerName: idx(() => c.bookedRate.carrier),
				salesOrders,
				purchaseOrders,
				bolIdentifiers,
				trackingNumbers: uniqueTruthyMap(
					c.payloads,
					(payload) => payload.trackingNumber,
				),
				trackingStatuses: uniqueTruthyMap(
					c.payloads,
					(payload) => payload.trackingStatus,
				),
				providerIdentifiers,
				pickTicketNumbers,
				loadNumbers,
				vendorIdentifiers,
				scac: idx(() => c.bookedRate.scac),
				handlingUnits: c.ltlCount ? c.ltlCount : c.parcelCount,
				specialInstructions: c.specialInstructions,
				bookedDate: c.bookedDate,
				quotedDate: c.quotedDate,
				voidDate: c.voidDate,
				createdDate: c.createdDate,
				updatedDate: c.updatedDate,
				pickupDate: idx(() => c.pickupTimeRange.initialDate), //Might want to implement date ranges for pickup date range support
				deliveryDate: idx(() => c.deliveryTimeRange.initialDate), //Might want to implement date ranges for pickup date range support
				estimatedDeliveryDate: idx(() => c.bookedRate.deliveryDate),
				shippedBy: c.shippedBy,
				bookedBy: c.bookedBy,
				locationId: c.locationId,
				currency: idx(() => c.bookedRate.currencyCode),
				parcelReferenceOne: uniqueTruthyMap(
					containers,
					(container) => container.labelReferenceValue1,
				),
				parcelReferenceTwo: uniqueTruthyMap(
					containers,
					(container) => container.labelReferenceValue2,
				),
				pickupTime: c.pickupTimeRange,
				deliveryTime: c.deliveryTimeRange
			}
			if (isUserBroker && 'contractId' in c) {
				const clientConfig = l.find(
					clientConfigs,
					(clientConfig) => clientConfig.id === c.contractId,
				)
				shipment.clientName = clientConfig?.tmsCompanyName || ''
				const sellPrice = idx(() => c.offers[0].flatOffer.grossRate)
				const buyPrice = idx(() => c.bookedRate.costTotal)
				const brokerPrices = calculateBrokerPrices(
					{ buyPrice, sellPrice },
					clientConfig?.defaultMarkupLogic,
				)
				shipment.brokerBuyPrice = brokerPrices.buyPrice
				shipment.brokerSellPrice = brokerPrices.sellPrice
				shipment.brokerMarkupPercent = brokerPrices.markupPercent
				shipment.brokerProfit = brokerPrices.profit
				shipment.customerInvoicingStatus = c.customerInvoicingStatus
				shipment.brokerReferenceNumber = c.brokerReferenceNumber
			}

			return shipment
		},
	)
}

function condenseClasses(containers): string[] {
	const allClasses = []
	containers.forEach((container) => {
		allClasses.push(container.class)
	})
	return allClasses
}
