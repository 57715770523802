import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { theme } from 'ui/theme'
import { sosToast } from 'common/components/toast'
import { apiTypes, apiPrintNode } from 'ui/api'
import { useOnce } from 'ui/components/hooks'
import { sosUser } from 'ui/state'
import { PrinterGroupModal } from '../company-management/printers-and-scales/printer-group-modal'
import { Layout } from '../layout'
import { tString } from 'ui/components/i18n/i18n'

export const WelcomePage: FC = (props: {}) => {
	const currentUserLocationId = sosUser.getSos().getState().locationId
	const selectedPrintStationCookie = document.cookie.substr(23)
	const [printStationList, setPrintStationList] = useState<
		apiTypes.PrinterGroupResponse[]
	>([])
	const [isPrinterGroupModalOpen, setIsPrinterGroupModalOpen] = useState<
		boolean
	>(false)

	useOnce(async () => {
		const userCredentialsResponse = await apiPrintNode.getCredentialsList(
			() => {},
			currentUserLocationId,
		)

		if (userCredentialsResponse.data.length) {
			if (userCredentialsResponse.data[0]) {
				const credential = userCredentialsResponse.data[0]

				const printerGroupResponse = await apiPrintNode.getPrinterGroupList(
					() => {},
					credential.id,
				)

				if (printerGroupResponse.data) {
					setPrintStationList(printerGroupResponse.data)
					setIsPrinterGroupModalOpen(
						(selectedPrintStationCookie === '' ||
							selectedPrintStationCookie === 'null') &&
							printerGroupResponse.data.length > 0,
					)
				} else if (printerGroupResponse.error) {
					sosToast.sendApiErrorResponseToast(printerGroupResponse)
				}
			}
		} else if (userCredentialsResponse.error) {
			sosToast.sendApiErrorResponseToast(userCredentialsResponse)
		} else {
			sosToast.sendToast({
				header: tString(
					'noCredentialsFoundForSelectedLocation',
					'page.companyManagement.printersAndScales',
				),
				type: 'warning',
			})
		}
	})

	if (theme.isInTMS2()) {
		return null // Display nothing in TMS2
	}

	return (
		<Layout>
			<iframe
				title='Welcome Page'
				src='https://www.swanleap.com/welcome_landing/welcome.html'
				width='100%'
				height='830px;'
				frameBorder='0'
			/>

			<PrinterGroupModal
				isModalOpen={isPrinterGroupModalOpen}
				onModalClose={() => {
					setIsPrinterGroupModalOpen(false)
				}}
				printStationList={printStationList}
			/>
		</Layout>
	)
}
