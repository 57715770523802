import React, { useState } from 'react'
import { apiTypes, apiPrintNode } from 'ui/api'
import * as classes from 'ui/pages/company-management/printers-and-scales/PrinterScalesListTable.module.scss'
import { Button } from 'ui/components/common/button'
import { t } from 'ui/components/i18n/i18n'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { useOnce } from 'ui/components/hooks'
import { FC } from 'app/FunctionalComponent'
import { Spacer } from 'ui/components/layout/spacer'

const tPrefix = 'page.companyManagement.printersAndScales.tabs.scales'

export const ScalesTableContent: FC = (props: {
	userCredentials: apiTypes.PrintNodeCredentialsResponse
}) => {
	const { userCredentials } = props

	const [isScanningScales, setIsScanning] = useState<boolean>(false)
	const [scalesList, setScalesList] = useState<
		apiPrintNode.PrintNodeScalesResponse[]
	>([])

	useOnce(() => {
		fireAndForget(scanScalesList, 'Fetching Scales List')
	})

	const scanScalesList = async (): Promise<void> => {
		setIsScanning(true)

		const scalesListResponse = await apiPrintNode.getScaleList(userCredentials)

		if (scalesListResponse) {
			const scalesLists = l.flatMap(
				scalesListResponse,
				(scalesList) => scalesList.data,
			)

			const filteredScalesList = scalesLists.filter(
				(scale) => scale !== undefined,
			)
			setScalesList(filteredScalesList)
		}

		setIsScanning(false)
	}

	return (
		<div
			className={classes.printersListScalesTable}
			data-testid={'scales-list-container'}
		>
			<div className={classes.printerScaleListButtonContainer}>
				<Button
					color={'green'}
					isSpinning={isScanningScales}
					testId={'printer-list-scan-for-printer-changes'}
					onClick={scanScalesList}
				>
					{t('scanScaleChanges', tPrefix)}
				</Button>
			</div>
			<table
				className={classes.printerScalesTable}
				cellPadding={0}
				cellSpacing={0}
			>
				<thead>
					<tr>
						<th>{t('deviceName', tPrefix)}</th>
						<th>{t('computerId', tPrefix)}</th>
						<th>{t('currentWeight', tPrefix)}</th>
					</tr>
				</thead>
				<tbody>
					{l.map(scalesList, (scale, idx) => {
						return (
							<tr key={idx} data-testid={`scaleListTable-${idx}`}>
								<td>{scale.deviceName}</td>
								<td>{scale.computerId}</td>
								<td>{scale.measurement.lb}</td>
							</tr>
						)
					})}
				</tbody>
			</table>

			<Spacer height={'30px'} />
		</div>
	)
}
