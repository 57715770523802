import { apiTypes } from 'ui/api'
import { tString } from 'ui/components/i18n/i18n'
import { DateTime } from 'luxon'

// every string in the returned array is a line of text
export const formatCardDateTime = (
	dateTimeInfo: apiTypes.DateTimeInfo,
): string[] => {
	if (!dateTimeInfo?.timeType) {
		return null
	}
	if (dateTimeInfo.timeType === 'between') {
		const initialDateTime = DateTime.fromFormat(
			`${dateTimeInfo.initialDate}-${dateTimeInfo.initialTime}`,
			'yyyy-MM-dd-hh:mm:ss',
		)
		const latestDateTime = DateTime.fromFormat(
			`${dateTimeInfo.latestDate || dateTimeInfo.initialDate}-${
				dateTimeInfo.latestTime
			}`,
			'yyyy-MM-dd-hh:mm:ss',
		)
		if (initialDateTime.get('ordinal') === latestDateTime.get('ordinal')) {
			// date range is on same day
			return [
				toDateString(initialDateTime),
				`${toTimeString(initialDateTime)}-${toTimeString(latestDateTime)}`,
			]
		} else {
			// date range is on different day
			return [
				`${toDateString(initialDateTime)} ${toTimeString(initialDateTime)}-`,
				`${toDateString(latestDateTime)} ${toTimeString(latestDateTime)}`,
			]
		}
	} else {
		const singleDateTime = getSingleDateTimeFromDateTimeInfo(dateTimeInfo)
		let firstLine = ''
		if (dateTimeInfo.timeType === 'before') {
			firstLine = tString('before', 'common') + ' '
		} else if (dateTimeInfo.timeType === 'after') {
			firstLine = tString('after', 'common') + ' '
		}
		firstLine += toDateString(singleDateTime)
		return [firstLine, toTimeString(singleDateTime)]
	}
}

const toDateString = (dateTime: DateTime): string => dateTime.toFormat('DDD')

const toTimeString = (dateTime: DateTime): string => dateTime.toFormat('t')

const getSingleDateTimeFromDateTimeInfo = (
	dateTimeInfo: apiTypes.DateTimeInfo,
): DateTime => {
	const date = dateTimeInfo.initialDate || dateTimeInfo.latestDate
	const time = dateTimeInfo.initialTime || dateTimeInfo.latestTime
	return DateTime.fromFormat(`${date}-${time}`, 'yyyy-MM-dd-hh:mm:ss')
}
