import { sosToast } from 'common/components/toast'
import { apiShipments } from 'ui/api'

export const getDocumentImage = async (
	shippingDocumentId: string,
): Promise<string> => {
	const imageResponse = await apiShipments.getShippingDocument(() => {},
	shippingDocumentId)
	if (imageResponse.data) {
		return imageResponse.data.image
	} else if (imageResponse.error) {
		sosToast.sendApiErrorResponseToast(imageResponse)
	}
}
