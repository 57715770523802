import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { containerTypes } from './containerTypes'
import { freightClasses } from './freightClasses'
import { apiTypes } from 'ui/api'

export interface INewQuoteCommoditiesForm {
	id: string
	count: string
	class: apiTypes.Container['class']
	length: string
	width: string
	height: string
	packedWeight: string
	description: string
	type: string
	piecesCount: string
	// piecesType: string
	piecesType: apiTypes.containerType
	piecesLength: string
	piecesWidth: string
	piecesHeight: string
	hazmat: string
	// stackable?: string // Not implemented in API (DP-477)
	nmfc: string
	nmfcSub: string
	hazmatClass: string
	unNumber: string
	packagingGroup: apiTypes.Good['hazmatPackingGroup']
	bol?: string
	so: string
	po: string
	notes: string
	pickupStopNumber?: number // used on readOnly multistop commodities table
	deliveryStopNumber?: number // used on readOnly multistop commodities table
	reference1?: string
	reference2?: string
	declaredValue?: string
}

export const newQuoteCommoditiesFormMetadata: IFormMetadataCollection<INewQuoteCommoditiesForm> = {
	id: {},
	count: { mustBeANumber: true, required: true },
	class: { options: freightClasses },
	length: { mustBeANumber: true },
	width: { mustBeANumber: true },
	height: { mustBeANumber: true },
	packedWeight: { mustBeANumber: true },
	description: {},
	type: { options: containerTypes },
	piecesCount: { mustBeANumber: true, required: true },
	piecesType: { options: containerTypes },
	piecesLength: { mustBeANumber: true },
	piecesWidth: { mustBeANumber: true },
	piecesHeight: { mustBeANumber: true },
	hazmat: { mustBeABoolean: true },
	// stackable: { mustBeABoolean: true }, // (DP-477)
	nmfc: {},
	nmfcSub: {},
	hazmatClass: {},
	unNumber: {},
	packagingGroup: {},
	bol: {},
	so: {},
	po: {},
	notes: {},
	reference1: {},
	reference2: {},
}
