import { NotFound } from 'app/AppRouter'
import { sosToast } from 'common/components/toast'
import { TypeaheadOption } from 'common/components/typeahead'
import React, { ReactElement, useState } from 'react'
import { Card } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ClientSelector } from 'ui/components/broker'
import { PictureUpload } from 'ui/components/common/images/PictureUpload'
import { Loader } from 'ui/components/common/loader'
import { Debug } from 'ui/components/dev'
import { StackedItem } from 'ui/components/form'
import { watch } from 'ui/components/hooks'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { FileDropZoneData } from 'ui/components/shared/csvValidator/file-drop-zone'
import { LocationSelector } from 'ui/components/shared/location-selector'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { BrokerDefaultMarkup } from 'ui/pages/customer-management/broker/default-markup/BrokerDefaultMarkup'
import { CompanyInformation } from 'ui/pages/customer-management/broker/default-markup/CompanyInformation'
import { PaymentTerms } from 'ui/pages/customer-management/broker/default-markup/PaymentTerms'
import { UpsertCompanyModal } from 'ui/pages/customer-management/broker/default-markup/UpsertCompanyModal'
import { Layout } from 'ui/pages/layout'
import { sosUser } from 'ui/state'
import { addClass } from 'ui/theme/theme'
import { sosCustomerManagement } from '../..'
import classes from './ClientManagement.module.scss'
import { CreditLimit } from './credit-limit/CreditLimit'

export const tPrefixClientManagement = 'page.customerManagement'

export const ClientManagementPage = (): ReactElement => {
	const stateUser = sos2.useSubscription(sosUser.getSos())
	const state = sos2.useSubscription(sosCustomerManagement.sos)

	const [isUpsertCompanyModalOpen, setIsUpsertCompanyModalOpen] = useState<
		boolean
	>(false)

	watch(() => {
		fireAndForget(async () => {
			await sosCustomerManagement.updateWithSelectedConfig(
				stateUser.selectedClientConfig,
			)
		}, 'Updating Customer management data when client config changes')
	}, [stateUser.selectedClientConfig?.id])

	if (!sosUser.isUserBroker()) {
		return (
			<Layout>
				<NotFound />
			</Layout>
		)
	}
	return (
		<Layout>
			<div className='bootstrap-wrapper'>
				<div className={classes.container}>
					<Row className={'align-items-center'}>
						<Col className={'mb-3'}>
							<StackedItem
								label={t('clientSelector', tPrefixClientManagement)}
								hideOptional={true}
							>
								<ClientSelector isClearable={false} />
							</StackedItem>
						</Col>
						<Col>
							<div className={addClass('float-right', classes.alignOnRight)}>
								<Button
									variant='primary'
									data-testid='add-company'
									onClick={() => {
										setIsUpsertCompanyModalOpen(true)
									}}
								>
									{t('addCompany', tPrefixClientManagement)}
								</Button>
							</div>
						</Col>
					</Row>
					<Loader
						isLoading={
							!state.clientAddress && !l.isNil(stateUser.selectedClientConfig)
						}
					/>
					{state.brokerLocationId && (
						<React.Fragment>
							<Card>
								<Card.Header>
									{t('assignedBrokerLocation', tPrefixClientManagement)}
								</Card.Header>
								<Card.Body>
									<LocationSelector
										onChange={async (selected: TypeaheadOption) => {
											if (selected) {
												const result = await sosCustomerManagement.setBrokerLocationId(
													selected.value,
												)
												if (result.error) {
													sosToast.sendApiErrorResponseToast(
														result,
														tString(
															'errorUpdatingAssignedBrokerLocation',
															tPrefixClientManagement,
														),
													)
												} else {
													sosToast.sendToast({
														type: 'success',
														header: tString(
															'assignedBrokerLocationUpdated',
															tPrefixClientManagement,
														),
													})
												}
											}
										}}
										currentlySelectedLocation={{
											value: state.brokerLocationId,
											label: state.brokerLocationName,
										}}
										onlyShowLeafLocations={false}
										getBrokerLocations={true}
									/>
								</Card.Body>
							</Card>
							<Spacer />
						</React.Fragment>
					)}
					{state.clientAddress &&
						state.paymentTermsForm &&
						state.brokerDefaultMarkupForm && (
							<>
								<CompanyInformation
									companyAddress={state.clientAddress}
									brokerNumber={state.brokerNumber}
									useCustomerInfoForBOLHeader={state.useCustomerInfoForBOL}
								/>
								<Spacer />
								<PictureUpload
									onPictureUpload={async (fileData: FileDropZoneData) => {
										await sosCustomerManagement.saveClientLogo(fileData)
									}}
									existingImageUrl={state.brandingImageUrl}
									headerText={t('clientLogo', tPrefixClientManagement)}
								/>
								<Spacer />
								<CreditLimit />
								<Spacer />
								<PaymentTerms paymentTerms={state.paymentTermsForm} />
								<Spacer />
								<BrokerDefaultMarkup
									brokerDefaultMarkup={state.brokerDefaultMarkupForm}
								/>
							</>
						)}

					{isUpsertCompanyModalOpen && (
						<UpsertCompanyModal
							show={isUpsertCompanyModalOpen}
							onHide={(): void => {
								setIsUpsertCompanyModalOpen(false)
							}}
						/>
					)}

					<Debug
						data={state.brokerDefaultMarkupForm}
						label='Broker Default Markup'
					/>
				</div>
			</div>
			<Debug label={'stateUser'} data={stateUser} />
			<Debug label={'state'} data={state} />
		</Layout>
	)
}
