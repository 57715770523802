import { apiTypes } from 'ui/api/'
import { l } from 'ui/lib/lodashImports'
import { extractGoodsFromShipment } from 'ui/lib/shipments'
import { sosUser } from 'ui/state'

type PaymentTypes = '3rdParty' | 'Prepaid' | 'Collect'

export const formatAddr = (address: apiTypes.Address): string => {
	if (!address) {
		return ''
	}
	let addressString = address.street1
	if (address.street2) {
		addressString += ' ' + address.street2
	}
	if (address.street3) {
		addressString += ' ' + address.street3
	}
	return addressString
}

export const formatCityStateZip = (address: apiTypes.Address): string => {
	if (!address) {
		return ''
	}
	let addressString = `${address.city}, ${address.state} ${address.zip}`
	if (address.country) {
		addressString += ', ' + address.country
	}
	return addressString
}

export const getBillInstructions = (
	shipment: apiTypes.ShipmentResponse,
	payload: apiTypes.PayloadResponse,
): string => {
	let instructions = ''

	if (shipment?.bookedRate?.quoteNumber) {
		instructions += `-- Quote Number: ${shipment.bookedRate.quoteNumber} --`
	}

	if (shipment?.specialInstructions) {
		instructions += `-- ${shipment.specialInstructions} --`
	}

	if (payload?.originStop?.metaData?.specialInstructions) {
		instructions += `-- Origin ->${payload.originStop.metaData.specialInstructions} --`
	}

	if (payload?.destinationStop?.metaData?.specialInstructions) {
		instructions += `-- Destination ->${payload.destinationStop.metaData.specialInstructions} --`
	}

	if (payload?.destinationStop?.address?.addressType) {
		if (payload.destinationStop.address.addressType !== 'commercial') {
			let addressType = l.startCase(payload.destinationStop.address.addressType)
			if (addressType === 'Other') {
				addressType = 'Non-Standard'
			}
			instructions += `-- Delivering To A ${addressType} Location --`
		}
	}

	if (payload?.destinationStop?.metaData?.datetimeDesired) {
		instructions +=
			'-- Destination ->' +
			payload.destinationStop.metaData.datetimeDesired +
			' --'
	}

	if (shipment?.bookedRate?.guaranteed) {
		// if(shipment.bookedRate.service is "ABF_ltl_guaranteed"){
		// 	instructions += `-- Timekeeper Guarantee Delivery By ${ formatDate(shipment.bookedRate.guaranteed, "human_date_time")} --`
		// } else if(shipment.bookedRate.serviceLevel === "saia_ltl_guaranteed") {
		// 	instructions += `-- ${shipment.bookedRate.service_human} Guarantee Delivery By ${ formatDate(shipment.bookedRate.guaranteed, "human_date_time")} --`
		instructions += `-- Guarantee Delivery By ${shipment.bookedRate.guaranteed} --`
	}

	if (
		payload?.destinationStop?.metaData?.accessorials?.notifyConsignee &&
		payload?.destinationStop?.metaData?.accessorialsInfo?.notifyConsigneeName
	) {
		instructions += `-- Notify Consignee ${payload.destinationStop.metaData.accessorialsInfo.notifyConsigneeName} At ${payload.destinationStop.metaData.accessorialsInfo.notifyConsigneePhone} --`
	}

	if (payload?.destinationStop?.metaData?.accessorials?.appointment) {
		instructions += `-- Scheduled Appointment: call ${payload.destinationStop.metaData.accessorialsInfo.notifyConsigneeName} at ${payload.destinationStop.metaData.accessorialsInfo.notifyConsigneePhone} --`
	}

	if (payload?.destinationStop?.metaData?.accessorials?.liftgateAtDelivery) {
		instructions += '-- Liftgate Required At Delivery --'
	}

	if (payload?.originStop?.metaData?.accessorials?.liftgateAtPickup) {
		instructions += '-- Liftgate Required At Pickup --'
	}

	if (payload?.destinationStop?.metaData?.accessorials?.saturdayDelivery) {
		instructions += '-- Saturday Delivery --'
	}

	if (
		payload?.destinationStop?.metaData?.accessorials?.sortAndSegregateAtDelivery
	) {
		instructions += '-- Sort and Segregate at Delivery --'
	}

	if (payload?.originStop?.metaData?.accessorials?.sortAndSegregateAtPickup) {
		instructions += '-- Sort and Segregate at Pickup --'
	}

	return instructions
}

export const getPaymentType = (
	shipment: apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse,
	payload: apiTypes.PayloadResponse,
): PaymentTypes => {
	if (shipment.bookedRate.quoteType === 'vendorQuote') {
		if (l.isEqual(payload.originStop.address, shipment.billTo.address)) {
			return 'Prepaid'
		} else {
			return '3rdParty'
		}
	}

	if (
		shipment.bookedRate.quoteType === 'brokerOffer' ||
		'brokerShipmentStatus' in shipment
	) {
		return '3rdParty'
	}

	if (l.isEqual(payload.originStop.address, shipment.billTo.address)) {
		return 'Prepaid'
	} else if (
		l.isEqual(payload.destinationStop.address, shipment.billTo.address)
	) {
		return 'Collect'
	} else {
		return '3rdParty'
	}
}

export const needsHazmat = (shipment: apiTypes.ShipmentResponse): boolean => {
	return l.some(extractGoodsFromShipment(shipment), (c) => c.hazmat)
}

export const getBillTo = async (
	shipment: apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse,
): Promise<apiTypes.BillTo> => {
	if (shipment.bookedRate?.billTo) {
		return { address: shipment.bookedRate.billTo }
	} else if (shipment['brokerShipmentStatus']) {
		const locationResponse: apiTypes.LocationResponse = await sosUser.getLocation(
			shipment.locationId,
		)
		return {
			address: locationResponse?.defaults.defaultBillingAddress.address,
		}
	} else {
		return shipment.billTo
	}
}

export const formatBolData = async (
	shipment: apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse,
	payload: apiTypes.PayloadResponse,
): Promise<{
	[k: string]: string
}> => {
	// const useGoodsInformation: boolean = false //has_permission('must_use_goods_on_bol', 'user', Meteor.user())

	const shipmentIsHazmat = needsHazmat(shipment)

	const bookedRateBolInfo: apiTypes.RateResponse['bolInfo'] =
		shipment.bookedRate.bolInfo

	let referenceNumber: apiTypes.BrokerShipmentResponse['brokerReferenceNumber']

	if ('brokerReferenceNumber' in shipment) {
		referenceNumber = shipment.brokerReferenceNumber
	}

	const bol: { [k: string]: string } = {
		ShipmentReferenceNumber: referenceNumber || '',
		FromName: payload.originStop?.address?.company,
		ContactName1: payload.originStop?.address?.name || '',
		Phone1: payload.originStop?.address?.phone,
		FromAddr: formatAddr(payload.originStop?.address),
		FromCityStateZip: formatCityStateZip(payload.originStop?.address),
		ToName: payload.destinationStop?.address?.company,
		ContactName2: payload.destinationStop?.address?.name || '',
		Phone2: payload.destinationStop?.address?.phone,
		ToAddress: formatAddr(payload.destinationStop?.address),
		ToCityStateZip: formatCityStateZip(payload.destinationStop?.address),
		BillName: '',
		BillAddress: '',
		BillCityStateZip: '',
		ShipperSignature: '',
		ShipperSignatureDate: '',
		BillInstructions: getBillInstructions(shipment, payload),
		BillInstructions2: '',
		Date: payload.originStop?.metaData?.desiredDateTimeInfo?.initialDate,
		PrePaid: getPaymentType(shipment, payload) === 'Prepaid' ? 'X' : '',
		Collect: getPaymentType(shipment, payload) === 'Collect' ? 'X' : '',
		'3rdParty': getPaymentType(shipment, payload) === '3rdParty' ? 'X' : '',
		CarrierName:
			l.capitalize(shipment.bookedRate?.carrier) ||
			shipment.billTo?.carrierName ||
			'', //TODO: figure out how to get ACCT number
		Page_ttl: '1',
		SealNum: shipment.sealNumber || '',
		TrailerNum: shipment.trailerNumber || '',
		SCAC: shipment.bookedRate?.scac || shipment.billTo?.carrierScac,
		PRO: shipment.proNumber || '',
		ProBarcode: shipment.proNumber ? '*' + shipment.proNumber + '*' : '',
		BOLnum: payload.bolIdentifier,
		BOLBarcode: payload.bolIdentifier ? '*' + payload.bolIdentifier + '*' : '',
		CarrierPhone: '',
		OrderNum1: payload.providerOrderNumber,
		PONumHeader: 'Purchase Order #',
		SONumHeader: 'Sales Order #',
		HMEmergencyPhone: '',
		TotalPkgs: '',
		Total_Weight: '',
		WT_ttl: '',
		HU_QTY_ttl: '',
		Pkg_QTY_ttl: '',
		PageNumber1: '1',
		PageNumber2: '1',
		// Amt: payload.liability_amount,
		// Per: payload.liability_unit,
		bol_company: '',
		ContactCompanyName: '',
		ContactCompanyPhone: '',
		use_hazmat_bol: shipmentIsHazmat ? 'yes' : 'no',
		use_spanish_bol: '',
		logo: bookedRateBolInfo?.logoUrl,
		brandingName: bookedRateBolInfo?.brandingAddress?.company,
		brandingStreet1: bookedRateBolInfo?.brandingAddress?.street1,
		brandingStreet2: bookedRateBolInfo?.brandingAddress?.street2,
		brandingCityStateZip: `${bookedRateBolInfo?.brandingAddress?.city || ''}, ${
			bookedRateBolInfo?.brandingAddress?.state || ''
		} ${bookedRateBolInfo?.brandingAddress?.zip || ''}`,
	}

	const bolHelperPromises: Promise<any>[] = []

	if (shipmentIsHazmat) {
		//TODO handle instances where addressToUse is not own
		bolHelperPromises.push(
			sosUser.getLocation(shipment.locationId).then((locationResponse) => {
				if (locationResponse.defaults.defaultPickupAddress.address) {
					bol.HMEmergencyPhone =
						locationResponse.defaults.defaultHazmatContact.address.phone
					bol.ContactCompanyName =
						locationResponse.defaults.defaultPickupAddress.address.company
					bol.ContactCompanyPhone =
						locationResponse.defaults.defaultPickupAddress.address.phone
				}
			}),
		)
	}

	bolHelperPromises.push(
		getBillTo(shipment).then((billTo: apiTypes.BillTo) => {
			let billName = billTo?.address?.company
			if (billTo?.carrierAccountNumber) {
				billName += ' Acct: ' + billTo.carrierAccountNumber
			}
			bol.BillName = billName

			bol.BillAddress = formatAddr(billTo?.address)
			bol.BillCityStateZip = formatCityStateZip(billTo?.address)
		}),
	)

	// if (useGoodsInformation) {
	// 	const goodsTotals = {
	// 		shippedQuantity: 0,
	// 		weight: 0,
	// 		handlingUnits: 0,
	// 	}

	let totalWeight = 0,
		totalPackages = 0

	l.forEach(payload.containers, (c, cIdx) => {
		cIdx++
		bol['Weight' + cIdx] = '' + c.packedWeight
		bol['WT_' + cIdx] = '' + c.packedWeight
		bol['Dimensions' + cIdx] = `${c.length} X ${c.width} X ${c.height}`
		bol['HU_QTY_' + cIdx] = '' + c.count
		bol['HU_Type_' + cIdx] = l.capitalize(c.type)
		bol['Desc_' + cIdx] = c.description
		bol['Class' + cIdx] = c.class

		totalWeight += c.packedWeight

		if (c.containers[0]) {
			const innerContainer = c.containers[0]
			bol['NumPkgs' + cIdx] = '' + innerContainer.count
			bol['Pkg_QTY_' + cIdx] = '' + innerContainer.count
			bol['Pkg_Type_' + cIdx] = l.capitalize(innerContainer.type)
			totalPackages += innerContainer.count

			if (innerContainer.goods[0]) {
				const good = innerContainer.goods[0]
				bol['AddInfo' + cIdx] = good.description || ''
				bol['PONum' + cIdx] = good.purchaseOrder
				bol['SONum' + cIdx] = good.salesOrder
				bol['NMFC' + cIdx] = !l.isNil(good.nmfcSubCode)
					? good.nmfcCode + '--' + good.nmfcSubCode
					: good.nmfcCode
			}
		}
	})

	bol['Total_Weight'] = '' + totalWeight
	bol['TotalPkgs'] = '' + totalPackages

	await Promise.all(bolHelperPromises)
	l.forEach(bol, (c, cKey) => {
		if (c === undefined || c === null) {
			bol[cKey] = ''
		}
	})

	return bol
}
