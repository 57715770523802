import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card'
import { Icon, regularIcons } from 'ui/components/common/icon'
import { Input } from 'ui/components/common/input'
import { Select } from 'ui/components/common/select'
import { Separator } from 'ui/components/common/separator'
import { SmallButton } from 'ui/components/common/small-button'
import { StackedItem, StringCheckbox } from 'ui/components/form'
import { tHandlingUnitType } from 'ui/components/i18n/commonTranslations'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { hazmatPackingGroupOptions } from 'ui/data/hazmatPackingGroupOptions'
import { l } from 'ui/lib/lodashImports'
import { sosProductCatalogModal } from 'ui/modals'
import { INewQuoteCommoditiesForm } from 'ui/pages/new-quote/INewQuoteCommoditiesForm'
import { addClass } from 'ui/theme/theme'
import classes from './Commodities.module.scss'
import { containerTypes } from './containerTypes'
import { freightClasses } from './freightClasses'
import { sosNewQuote } from './state'

export const LabelText: FC = (props: {
	children: React.ReactNode
	smaller?: boolean
}) => {
	let className = classes.labelText
	if (props.smaller) {
		className = addClass(className, classes.smaller)
	}

	return <div className={className}>{props.children}</div>
}

const tPrefix = 'page.newQuote.commodities'
const tPrefixUnitType = tPrefix + '.unitType'

export const Dimensions: FC = (props: {
	label: React.ReactNode
	length: string
	width: string
	height: string
	readOnly?: boolean
	onChange: (key, newVal) => void
	index?: number
}) => {
	if (props.readOnly && !props.length && !props.height && !props.width) {
		return null
	}
	return (
		<div>
			<StackedItem
				label={
					<span>
						{props.label} {t('dimensions', tPrefix)}
					</span>
				}
				showRequired={true}
			>
				<FlexRow verticalAlign='center'>
					<FlexItem>
						<Input
							readOnly={props.readOnly}
							value={props.length || ''}
							width='30px'
							onChange={(newVal) => {
								props.onChange('length', newVal)
							}}
							testId={'length-' + props.index}
						/>
					</FlexItem>
					<FlexItem> x </FlexItem>
					<FlexItem>
						<Input
							readOnly={props.readOnly}
							value={props.width || ''}
							width='30px'
							onChange={(newVal) => {
								props.onChange('width', newVal)
							}}
							testId={'width-' + props.index}
						/>
					</FlexItem>
					<FlexItem> x </FlexItem>
					<FlexItem>
						<Input
							readOnly={props.readOnly}
							value={props.height || ''}
							width='30px'
							onChange={(newVal) => {
								props.onChange('height', newVal)
							}}
							testId={'height-' + props.index}
						/>
					</FlexItem>
				</FlexRow>
			</StackedItem>
		</div>
	)
}

export const calculateCommodityTotals = (
	commodities: INewQuoteCommoditiesForm[],
): {
	totalHandlingUnits: number
	totalPieces: number
	totalWeight: number
} => {
	let totalHandlingUnits = 0
	let totalPieces = 0
	let totalWeight = 0

	l.forEach(commodities, (c) => {
		let i = 0
		i = parseInt(c.count, 10)
		if (!l.isNaN(i)) {
			totalHandlingUnits += i
		}
		i = parseInt(c.piecesCount, 10)
		if (!l.isNaN(i)) {
			totalPieces += i
		}
		i = parseInt(c.packedWeight, 10)
		if (!l.isNaN(i)) {
			totalWeight += i
		}
	})

	return {
		totalHandlingUnits,
		totalPieces,
		totalWeight,
	}
}

export const CommoditiesForm: FC = (props: {
	commodities: INewQuoteCommoditiesForm[]
	fullWidth?: boolean
	linearFeet?: number
}) => {
	const { commodities, fullWidth } = props

	const standardWidth = '145px'

	const totals = calculateCommodityTotals(props.commodities)

	return (
		<Card
			title={t('commodities', tPrefix)}
			color='darkBlue'
			fullWidth={fullWidth}
			testId='commodities-card'
		>
			{l.map(commodities, (c, cIdx) => (
				<div key={cIdx} className={classes.editCommoditiesContainer}>
					<FlexRow>
						<FlexItem>
							<StackedItem label={t('unitType', tPrefix)} showRequired={true}>
								<Select
									width={standardWidth}
									value={c.type}
									isClearable={false}
									options={containerTypes}
									tPrefix={tPrefixUnitType}
									onChange={(newVal) =>
										sosNewQuote.updateCommodity(c.id, { type: newVal })
									}
								/>
							</StackedItem>
							<StackedItem
								label={
									<>
										{t(c.type, tPrefixUnitType)}&nbsp;
										{t('quantity', tPrefix)}
									</>
								}
								showRequired={true}
							>
								<Input
									width='50px'
									value={c.count || ''}
									onChange={(newVal) =>
										sosNewQuote.updateCommodity(c.id, { count: newVal })
									}
								/>
							</StackedItem>
							<Dimensions
								label={<span>{t(c.type || 'unit', tPrefixUnitType)}</span>}
								length={c.length}
								width={c.width}
								height={c.height}
								onChange={(key, newVal) => {
									sosNewQuote.updateCommodity(c.id, { [key]: newVal })
								}}
								index={cIdx}
							/>
							<StackedItem
								label={t('totalWeight', tPrefix)}
								showRequired={true}
							>
								<Input
									width={'80px'}
									value={c.packedWeight || ''}
									onChange={(newVal) =>
										sosNewQuote.updateCommodity(c.id, {
											packedWeight: newVal,
										})
									}
									testId={'total-weight-' + cIdx}
								/>
							</StackedItem>
						</FlexItem>
						<FlexItem>
							<StackedItem
								label={t('description', tPrefix)}
								showRequired={true}
							>
								<Input
									width='200px'
									value={c.description || ''}
									onChange={(newVal) =>
										sosNewQuote.updateCommodity(c.id, { description: newVal })
									}
									testId={'description-' + cIdx}
								/>
							</StackedItem>
							<StackedItem label='' showRequired={true}>
								<SmallButton
									color='gray'
									onClick={() => {
										// sosProductCatalog.updateState({ updateCommodityId: c.id })
										sosProductCatalogModal.showModal(true, c.id)
									}}
								>
									{t('productCatalog', 'page.newQuote.commodities')}
								</SmallButton>
							</StackedItem>
						</FlexItem>

						<FlexItem>
							<StackedItem label={t('piecesType', tPrefix)} showRequired={true}>
								<Select
									// width={'110px'}
									value={c.piecesType}
									isClearable={false}
									options={containerTypes}
									tPrefix={tPrefixUnitType}
									onChange={(newVal) =>
										sosNewQuote.updateCommodity(c.id, { piecesType: newVal })
									}
								/>
							</StackedItem>
							<StackedItem
								label={
									<span>
										{tHandlingUnitType(c.piecesType)}
										{t('quantity', tPrefix)}
									</span>
								}
								showRequired={true}
							>
								<Input
									width='50px'
									value={c.piecesCount || ''}
									onChange={(newVal) =>
										sosNewQuote.updateCommodity(c.id, { piecesCount: newVal })
									}
								/>
							</StackedItem>
							<Dimensions
								label={
									<span>{t(c.piecesType || 'piece', tPrefixUnitType)}</span>
								}
								length={c.piecesLength}
								width={c.piecesWidth}
								height={c.piecesHeight}
								onChange={(key, newVal) => {
									const keyMap = {
										length: 'piecesLength',
										height: 'piecesHeight',
										width: 'piecesWidth',
									}
									sosNewQuote.updateCommodity(c.id, { [keyMap[key]]: newVal })
								}}
							/>
						</FlexItem>

						<FlexItem>
							<StackedItem label={t('class', tPrefix)} showRequired={true}>
								<FlexRow>
									<Select
										value={c.class}
										isClearable={false}
										options={freightClasses}
										onChange={(newVal) =>
											sosNewQuote.updateCommodity(c.id, { class: newVal })
										}
										testId={'commodityClass-' + cIdx}
									/>
								</FlexRow>
							</StackedItem>
							<StackedItem label='' showRequired={true}>
								<SmallButton
									color='gray'
									onClick={() => sosNewQuote.calculateClassNewQuote(c.id)}
								>
									{t('calculateClass', tPrefix)}
								</SmallButton>
							</StackedItem>
						</FlexItem>

						<FlexItem>
							<StackedItem label={t('NMFC', tPrefix)}>
								<Input
									width={standardWidth}
									value={c.nmfc || ''}
									onChange={(newVal) =>
										sosNewQuote.updateCommodity(c.id, { nmfc: newVal })
									}
								/>
							</StackedItem>

							<StackedItem label={t('NMFC_sub', tPrefix)}>
								<Input
									width={standardWidth}
									value={c.nmfcSub || ''}
									onChange={(newVal) =>
										sosNewQuote.updateCommodity(c.id, { nmfcSub: newVal })
									}
								/>
							</StackedItem>
						</FlexItem>
						<FlexItem minWidth={'210px'}>
							<div>
								<StringCheckbox
									value={c.hazmat}
									onChange={(newVal) =>
										sosNewQuote.updateCommodity(c.id, { hazmat: newVal })
									}
								>
									{t('hazmat', tPrefix)}
								</StringCheckbox>
							</div>
							{c.hazmat === 'true' && (
								<React.Fragment>
									<StackedItem
										label={t('hazmatClass', tPrefix)}
										hideOptional={true}
									>
										<Input
											width={standardWidth}
											value={c.hazmatClass || ''}
											onChange={(newVal) =>
												sosNewQuote.updateCommodity(c.id, {
													hazmatClass: newVal,
												})
											}
										/>
									</StackedItem>
									<StackedItem
										label={t('unNumber', tPrefix)}
										hideOptional={true}
									>
										<Input
											width={standardWidth}
											value={c.unNumber || ''}
											onChange={(newVal) =>
												sosNewQuote.updateCommodity(c.id, {
													unNumber: newVal,
												})
											}
										/>
									</StackedItem>
									<StackedItem
										label={t('packagingGroup', tPrefix)}
										hideOptional={true}
									>
										<Select
											width={standardWidth}
											value={c.packagingGroup || ''}
											isClearable={false}
											options={hazmatPackingGroupOptions}
											onChange={(newVal) =>
												sosNewQuote.updateCommodity(c.id, {
													packagingGroup: newVal,
												})
											}
										/>
									</StackedItem>
								</React.Fragment>
							)}
							{/*<div> (DP-477)*/}
							{/*<StringCheckbox*/}
							{/*value={c.stackable}*/}
							{/*onChange={(newVal) =>*/}
							{/*sosNewQuote.updateCommodity(c.id, { stackable: newVal })*/}
							{/*}*/}
							{/*>*/}
							{/*{t('stackable', tPrefix)}*/}
							{/*</StringCheckbox>*/}
							{/*</div>*/}
						</FlexItem>
						<FlexItem>
							<FlexRow>
								<FlexItem>
									<StackedItem label={t('billOfLading', tPrefix)}>
										<Input
											width={standardWidth}
											value={c.bol || ''}
											onChange={(newVal) =>
												sosNewQuote.updateCommodity(c.id, { bol: newVal })
											}
											testId={'bol-' + cIdx}
										/>
									</StackedItem>
								</FlexItem>
								<FlexItem>
									<StackedItem label={t('shippingOrder', tPrefix)}>
										<Input
											width={standardWidth}
											value={c.so || ''}
											onChange={(newVal) =>
												sosNewQuote.updateCommodity(c.id, { so: newVal })
											}
										/>
									</StackedItem>
								</FlexItem>
								<FlexItem>
									<StackedItem label={t('purchaseOrder', tPrefix)}>
										<Input
											width={standardWidth}
											value={c.po || ''}
											onChange={(newVal) =>
												sosNewQuote.updateCommodity(c.id, { po: newVal })
											}
										/>
									</StackedItem>
								</FlexItem>
								<FlexItem>
									<StackedItem label={t('notes', tPrefix)}>
										<Input
											width={standardWidth}
											value={c.notes || ''}
											onChange={(newVal) =>
												sosNewQuote.updateCommodity(c.id, { notes: newVal })
											}
										/>
									</StackedItem>
								</FlexItem>
							</FlexRow>
						</FlexItem>
						{commodities.length > 1 && (
							<FlexItem>
								<SmallButton
									onClick={() => {
										sosNewQuote.deleteCommodity(c.id)
									}}
									color='gray'
								>
									<Icon icon={regularIcons.faTrashAlt} /> Delete
								</SmallButton>
							</FlexItem>
						)}
					</FlexRow>
					<Separator />
				</div>
			))}
			<div className={classes.commoditiesTotals}>
				<div>
					<SmallButton
						onClick={sosNewQuote.addAdditionalCommodityLine}
						color='blue'
					>
						{t('addAdditionalCommodityLine', 'page.newQuote.commodities')}
					</SmallButton>
				</div>
				<div>
					<LabelText>
						{t('totalHandlingUnits', 'page.newQuote.commodities')}
					</LabelText>
					{totals.totalHandlingUnits}
				</div>
				<div>
					<LabelText>{t('totalPieces', 'page.newQuote.commodities')}</LabelText>
					{totals.totalPieces}
				</div>
				<div>
					<LabelText>{t('totalWeight', 'page.newQuote.commodities')}</LabelText>
					{totals.totalWeight} lbs.
				</div>
				<div className={classes.commoditiesTotalLinearFeet}>
					<LabelText>
						{t('totalLinearFeet', 'page.newQuote.commodities')}
					</LabelText>
					{
						<Input
							value={`${props.linearFeet || 0}`}
							width='30px'
							onChange={(newVal) => {
								sosNewQuote.updateTotalLinearFeet(newVal)
							}}
							testId={'linear-total-fee'}
						/>
					}
				</div>
			</div>
		</Card>
	)
}
