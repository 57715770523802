import { FC } from 'app/FunctionalComponent'
import { TypeaheadOption } from 'common/components/typeahead/TypeaheadOption'
import React from 'react'
import { apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { FilterItem, Filters } from 'ui/components/common/filters'
import { Loader } from 'ui/components/common/loader'
import { ISelectOptions } from 'ui/components/common/select'
import { StackedItem } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { EditAddressFormStacked, IAddress } from 'ui/components/shared/address'
import { IOnSelectLocation } from 'ui/components/shared/address/IOnSelectLocation'
import { SimpleAddress } from 'ui/components/shared/address/SimpleAddress'
import { LocationSelector } from 'ui/components/shared/location-selector/LocationSelector'
import { idx } from 'ui/lib'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosUser } from 'ui/state'
import { IStateUser } from 'ui/state/sosUser'
import { BillToThirdParty } from '.'
import * as classes from './BillTo.module.scss'
import { sosNewQuote } from './state'
import { IStateNewQuote } from './state/sosNewQuote'

export type BillToSelectorType =
	| 'companyLocation'
	| 'pickup'
	| 'delivery'
	| 'customAddress'
	| 'addressBook'

const allFilters: BillToSelectorType[] = [
	'pickup',
	'delivery',
	'companyLocation',
	'customAddress',
	'addressBook',
]
const tPrefix = 'page.newQuote.billTo'

export const AddressWrapper: FC = (props: {
	children: React.ReactNode
	label: React.ReactNode
}) => {
	return (
		<div>
			<div className={classes.addressLabel}>{props.label}</div>
			<div className={classes.address}>{props.children}</div>
		</div>
	)
}

function getBillingAddress(
	state: IStateNewQuote,
	stateUser: IStateUser,
): { billToAddress: IAddress; billToShipToAddress: IAddress } {
	const { selectedBillToFilter } = state

	if (
		selectedBillToFilter === 'customAddress' ||
		selectedBillToFilter === 'addressBook'
	) {
		return {
			billToAddress: idx(() => state.billTo.address),
			billToShipToAddress: null,
		}
	}

	if (
		selectedBillToFilter === 'pickup' ||
		selectedBillToFilter === 'delivery'
	) {
		let billToLocationId = ''
		let shipTo: IAddress = null
		if (state.selectedBillToFilter === 'pickup') {
			billToLocationId = state.stops[0].addressState.locationId
			shipTo = state.stops[0].addressState.address
		} else if (state.selectedBillToFilter === 'delivery') {
			billToLocationId = state.stops[1].addressState.locationId
			shipTo = state.stops[1].addressState.address
		}

		const billToLocation: apiTypes.LocationResponse = l.find(
			stateUser.requestLocationsInfo.data,
			(location) => billToLocationId === location.id,
		)
		const billingAddress: IAddress = idx(
			() => billToLocation.defaults.defaultBillingAddress.address,
		)

		if (billingAddress) {
			return { billToAddress: billingAddress, billToShipToAddress: shipTo }
		} else {
			return { billToAddress: shipTo, billToShipToAddress: null }
		}
	}

	return { billToAddress: null, billToShipToAddress: null }
}

export const BillTo: FC = (props: {
	state: IStateNewQuote
	filters?: BillToSelectorType[]
	billToLocations?: IRequestState<ISelectOptions[]>
	onSelectLocation?: IOnSelectLocation
	organizationRelationshipId?: string
}) => {
	const {
		state,
		// billToLocations,
		// onSelectLocation,
		// organizationRelationshipId,
	} = props
	let filters = props.filters
	if (!filters) {
		filters = allFilters
	}
	const stateUser = sos2.useSubscription(sosUser.getSos())

	const { billToAddress } = getBillingAddress(state, stateUser)

	return (
		<div className={classes.container} data-testid='bill-to'>
			<Filters>
				{l.map(filters, (c) => (
					<FilterItem
						key={c}
						isSelected={state.selectedBillToFilter === c}
						onClick={() => {
							// TODO: clear out address?
							sosNewQuote.selectBillToFilter(c)
						}}
					>
						{/* {c.name === 'addressBook' && (
							<Icon
								className={classes.addressBookIcon}
								icon={solidIcons.faAddressBook}
							/>
						)}{' '} */}
						{/*{t(c, 'page.newQuote.billTo')}*/}
						{t(
							sosUser.isUserBroker() && c === 'companyLocation'
								? 'customerLocation'
								: c,
							'page.newQuote.billTo',
						)}
					</FilterItem>
				))}
			</Filters>
			{state.selectedBillToFilter === 'companyLocation' && (
				<div>
					<StackedItem
						label={t('companyLocationShort', 'shared.address')}
						showRequired={true}
					>
						<LocationSelector
							onChange={(selected: TypeaheadOption) => {
								props.onSelectLocation(
									selected.value,
									(changes) =>
										sosNewQuote.updateBillTo({
											billTo: changes,
											billToStopId: '',
										}),
									props.organizationRelationshipId,
									state.billTo.id,
								)
							}}
							includeAll={false}
							onlyShowLeafLocations={false}
						/>
					</StackedItem>
					<Loader isLoading={state.billTo.isLocationLoading} />
					{r.not(state.billTo.isLocationLoading) && state.billTo.locationId && (
						<AddressWrapper label={t('billingAddress', tPrefix)}>
							<SimpleAddress address={state.billTo.address} />
						</AddressWrapper>
					)}
				</div>
			)}
			{(state.selectedBillToFilter === 'pickup' ||
				state.selectedBillToFilter === 'delivery') && (
				<div>
					{billToAddress && (
						<AddressWrapper label={t('billingAddress', tPrefix)}>
							<SimpleAddress address={billToAddress} />
						</AddressWrapper>
					)}
					<Spacer />
					{/*{billToShipToAddress && (*/}
					{/*<AddressWrapper*/}
					{/*label={t(state.selectedBillToFilter + 'ShippingAddress', tPrefix)}*/}
					{/*>*/}
					{/*<SimpleAddress address={billToShipToAddress} />*/}
					{/*</AddressWrapper>*/}
					{/*)}*/}
				</div>
			)}
			{/* {state.selectedBillToFilter === 'delivery' && (
				<div>
					<AddressWrapper label={t('billingAddress', tPrefix)}>
						<SimpleAddress address={billingAddress} />
					</AddressWrapper>
					<Spacer />
					<AddressWrapper label={t('shippingAddress', tPrefix)}>
						<SimpleAddress address={state.stops[1].addressState.address} />
					</AddressWrapper>
				</div>
			)} */}
			{r.includes(state.selectedBillToFilter, [
				'customAddress',
				'addressBook',
			]) && (
				<div>
					<EditAddressFormStacked
						addressState={state.billTo}
						showSave={true}
						updateAddressState={(changes) => {
							changes.locationId = ''
							sosNewQuote.updateBillTo({
								billTo: changes,
								billToStopId: '',
							})
						}}
					/>
				</div>
			)}
			{paidByThirdParty(state) && (
				<div>
					<BillToThirdParty state={state} />
				</div>
			)}
		</div>
	)
}

function paidByThirdParty(state: IStateNewQuote): boolean {
	//TODO: if selectedBillToFilter is addressBook and the address selected is a client location, then return false
	return !(
		state.selectedBillToFilter === 'companyLocation' ||
		(state.selectedBillToFilter === 'pickup' &&
			state.stops[0].addressState.locationId) ||
		(state.selectedBillToFilter === 'delivery' &&
			state.stops[1].addressState.locationId)
	)
}

//
/* {_.map(state.stops, (c) => {
						let billToLocation: apiTypes.LocationResponse = _.find(
							stateUser.requestLocationsInfo.data,
							(location) => c.addressState.locationId === location.id,
						)
						let billingAddress: IAddress = idx(
							() => billToLocation.defaults.defaultBillingAddress.address,
						)
						return (
							<FlexRow>
								<FlexItem>
									<PaperCard
										key={c.stopId}
										onClick={
											c.stopId === state.billToStopId
												? null
												: () => {
														sosNewQuote.updateBillTo({
															billToStopId: c.stopId,
															billTo: {
																locationId: '',
																address:
																	billingAddress || c.addressState.address,
															},
														})
													}
										}
										selected={c.stopId === state.billToStopId}
										shadow={true}
									>
										<strong className={classes.addresslabel}>
											{t('shippingAddress', tPrefix)}
										</strong>
										<SimpleAddress address={c.addressState.address} />
										{c.addressState.locationId && billingAddress && (
											<div>
												<Separator />
												<strong className={classes.addresslabel}>
													{t('billingAddress', tPrefix)}
												</strong>
												<SimpleAddress
													inset={true}
													showAddressType={false}
													address={billingAddress}
												/>
											</div>
										)}
									</PaperCard>
								</FlexItem>
							</FlexRow>
						)
					})} */
