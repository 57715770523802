import { LocationResponse } from 'ui/api/apiTypes'
import { toCountryName } from 'ui/data/countryAndRegionUtils'
import { l } from 'ui/lib/lodashImports'
import { sosUser } from 'ui/state'
import { sosTopLevelLocationConfiguration } from '.'

export async function fetchTopLevelLocation(): Promise<void> {
	const locationId: string = sosUser.getSos().getState().requestUserInfo.data
		.locationId
	const topLevelLocation: LocationResponse = await sosUser.getLocation(
		locationId,
	)
	if (!topLevelLocation) {
		sosTopLevelLocationConfiguration.getSos().change((ds) => {
			ds.apiError =
				'Unable to fetch location data. Please refresh and try again.'
		})
	}
	const stateLocation = l.cloneDeep(topLevelLocation)
	stateLocation.defaults.defaultBillingAddress.address.country = toCountryName(
		topLevelLocation.defaults.defaultBillingAddress.address.country,
	)
	sosTopLevelLocationConfiguration.getSos().change((ds) => {
		ds.location = stateLocation
	})
}
