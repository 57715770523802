import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card'
import { Separator } from 'ui/components/common/separator'
import { StackedItem } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import {
	FlexHorizontalSeparator,
	FlexVerticalSeparator,
} from 'ui/components/layout/flexRow'
import { IAccessorialState } from 'ui/components/shared/accessorials/IAccessorialState'
import { Address, AddressSelectorType } from 'ui/components/shared/address'
import {
	IAddressState,
	LocationDropdownSourceType,
} from 'ui/components/shared/address/IAddressState'
import { IOnSelectLocation } from 'ui/components/shared/address/IOnSelectLocation'
import { UpdateAddressStateType } from 'ui/components/shared/address/UpdateAddressStateType'
import { IDateTimeDesiredState } from 'ui/components/shared/ShipmentStopCard/DateTimeDesired'
import { cardConfigs } from 'ui/components/shared/ShipmentStopCard/ShipmentStopCardConfiguration'
import { StopType } from 'ui/pages/new-quote/state/sosNewQuote'
import { PickupAndDeliveryTime, SpecialInstructions } from '.'
import * as classes from './ShipmentStopCard.module.scss'
// import { TypeaheadOption } from 'common/components/typeahead'

export interface IShipmentStopCardState {
	addressState: IAddressState
	updateAddress?: UpdateAddressStateType
	accessorialState?: IAccessorialState
	updateAccessorials?: (accessorialChanges: Partial<IAccessorialState>) => void
	specialInstructions?: string
	updateSpecialInstructions?: (instructions: string, stopId?: string) => void
	filters?: AddressSelectorType[]
	updateAddressFilter?: (filter: AddressSelectorType, addressId: string) => void
	showMap?: boolean
	onSelectLocation?: IOnSelectLocation
	organizationRelationshipId?: string
	dateTimeDesiredState?: IDateTimeDesiredState
	updateDateTimeDesired?: (
		dateTimeChanges: Partial<IDateTimeDesiredState>,
		stopId?: string,
	) => void
	saveAddress?: (addressState: IAddressState) => Promise<boolean>
	showSaveAddress?: boolean
	addressSaving?: boolean
}

export const ShipmentStopCard: FC = (props: {
	stopId?: string
	mode: StopType
	stopCardState: IShipmentStopCardState
	stopNumber?: number
	accessorials?: React.ReactNode
	locationAddressType?: StopType
	shipment?: any
	locationsSource?: LocationDropdownSourceType
	// defaultLocation?: TypeaheadOption
}) => {
	const {
		mode,
		stopCardState,
		stopNumber,
		locationsSource,
		// defaultLocation,
	} = props
	const config = cardConfigs[mode]

	return (
		<div
			data-testid={
				'shipment-stop-card-' + mode + (stopNumber ? '-' + stopNumber : '')
			}
		>
			<Card title={t(config.tTitle)} color={config.headerColor}>
				<div className={classes.shipmentStopCard}>
					<div className={classes.addressContainer}>
						<Address
							addressState={stopCardState.addressState}
							updateAddressState={stopCardState.updateAddress}
							filters={stopCardState.filters}
							updateAddressFilter={stopCardState.updateAddressFilter}
							showMap={stopCardState.showMap}
							onSelectLocation={stopCardState.onSelectLocation}
							organizationRelationshipId={
								stopCardState.organizationRelationshipId
							}
							onSaveAddress={stopCardState.saveAddress}
							stopType={mode}
							showSaveAddress={stopCardState.showSaveAddress}
							addressSaving={stopCardState.addressSaving}
							locationsSource={locationsSource}
						/>
					</div>
					{stopNumber && (
						<div>
							<strong>{t('shared.shipmentStopCard.stopNumber')}</strong>
							{`: ${stopNumber}`}
							<Separator />
						</div>
					)}

					<FlexHorizontalSeparator className={classes.horizontalSeparator} />
					<FlexVerticalSeparator className={classes.verticalSeparator} />

					<div>
						<StackedItem
							label={t('accessorials', 'page.newQuote')}
							showRequired={true}
						>
							{props.accessorials}
						</StackedItem>
					</div>

					<FlexHorizontalSeparator className={classes.horizontalSeparator} />
					<FlexVerticalSeparator className={classes.verticalSeparator} />

					<div className={classes.timeContainer}>
						<div className={classes.labelPickupAndDeliveryTime}>
							<PickupAndDeliveryTime
								stopId={props.stopId}
								mode={props.mode}
								state={props.stopCardState.dateTimeDesiredState}
								stopNumber={props.stopNumber}
								updateState={props.stopCardState.updateDateTimeDesired}
							/>
							<SpecialInstructions
								stopId={props.stopId}
								stopType={mode}
								value={stopCardState.specialInstructions}
								onChange={stopCardState.updateSpecialInstructions}
							/>
						</div>
					</div>
				</div>
			</Card>
		</div>
	)
}
