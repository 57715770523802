import { FC } from 'app/FunctionalComponent'
import { TypeaheadOption } from 'common/components/typeahead'
import { AsyncTypeahead } from 'common/components/typeahead/AsyncTypeahead'
import { DateTime } from 'luxon'
import React from 'react'
import { apiTypes } from 'ui/api'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { useOnce } from 'ui/components/hooks'
import { tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { CalendarInput } from 'ui/components/shared/ShipmentStopCard'
import { idx } from 'ui/lib'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { DockSchedulerLocationSelector } from 'ui/pages/dock-scheduler/components/DockSchedulerLocationSelector'
import { DockSchedulerAppointmentFilters } from '../components/DockSchedulerAppointmentFilters'
import {
	minutesPerColumn,
	TimeSlotHeaders,
	VoidTimeSlot,
} from '../components/DockSchedulerTimeSlots'
import * as classes from '../DockScheduler.module.scss'
import { sosDockScheduler, sosDockSchedulerCalendarTab } from '../state'
import { IStateDockSchedulerCalendarTab } from '../state/sosDockSchedulerCalendarTab'
import { DockSchedulerCalendarCard } from './DockSchedulerCalendarCard'
import { DockSchedulerCalendarDayScroller } from './DockSchedulerCalendarDayScroller'
import { getCalendarCards } from './dockSchedulerCalendarUtils'

const tPrefix = 'page.dockScheduler.calendar'

export interface ICalendarCard {
	appointmentId: string
	identifier: string
	appointmentStatus: apiTypes.AppointmentResponse['status']
	carrier: string
	mode: apiTypes.AppointmentResponse['shipmentInfo']['mode']
	flow: apiTypes.AppointmentResponse['stopType']
	startHours: number // can be a decimal, for example 3.5 would correspond to 3:30
	durationMinutes: number
	equipmentType: apiTypes.AppointmentResponse['shipmentInfo']['equipmentType']
	dockName: string
	otherCompaniesOnAppointment: string[]
}

function convertAppointmentToTypeahaeadLabel(
	appointment: apiTypes.AppointmentResponse,
): string {
	const labelPieces: string[] = []
	if (appointment.shipmentInfo?.proNumber) {
		labelPieces.push(appointment.shipmentInfo.proNumber)
	}
	if (appointment.trailerInfo?.carrier) {
		labelPieces.push(appointment.trailerInfo.carrier)
	}
	if (appointment.shipmentInfo?.otherCompanyNamesOnShipment?.length > 0) {
		labelPieces.push(
			appointment.shipmentInfo.otherCompanyNamesOnShipment.join(', '),
		)
	}
	if (appointment.startTime) {
		labelPieces.push(DateTime.fromISO(appointment.startTime).toISODate())
	}
	return labelPieces.join(', ')
}

export const DockSchedulerCalendar: FC = (props: {
	docks: apiTypes.DockResponse[]
}) => {
	const state: IStateDockSchedulerCalendarTab = sos2.useSubscription(
		sosDockSchedulerCalendarTab.getSos(),
	)
	useOnce(() =>
		fireAndForget(
			sosDockSchedulerCalendarTab._fetchCalendarAppointments,
			'fetching initial appointments for dock scheduler calendar',
		),
	)

	const { hourStarts, cardRows } = getCalendarCards(
		state.appointments,
		props.docks,
		state.selectedDate,
		idx(
			() =>
				sosDockScheduler.getSos().getState().currentLocation.defaults
					.defaultDeliveryAddress.address.zip,
		),
	)
	return (
		<div>
			<DockSchedulerLocationSelector />
			<Spacer />
			<FlexRow
				wrap={false}
				fullWidth={true}
				mode={'center'}
				verticalAlign={'bottom'}
			>
				<FlexItem align={'center'} fitContent={true}>
					<DockSchedulerCalendarDayScroller
						selectedDate={state.selectedDate}
						onUpdateSelectedDate={(date) =>
							sosDockSchedulerCalendarTab.setCalendarDate(date, false)
						}
						startDate={state.dayScrollerStartDate}
						onUpdateStartDate={
							sosDockSchedulerCalendarTab.updateDayScrollerStartDate
						}
						readOnly={state.fetchingAppointments}
					/>
				</FlexItem>
			</FlexRow>

			<Spacer width={'20px'} />

			<FlexRow wrap={false} fullWidth={true} verticalAlign={'center'}>
				<FlexItem fitContent={true}>
					<div className={classes.calendarSearchBar}>
						<AsyncTypeahead
							testId={'dockSchedulerAppointmentSearch'}
							size='small'
							options={[]}
							onSearch={async (searchTerm: string) => {
								const typeaheadAppointments = await sosDockSchedulerCalendarTab.getAppointmentsFromTypeaheadSearchTerm(
									searchTerm,
								)
								return typeaheadAppointments.map(
									(appointment: apiTypes.AppointmentResponse) => {
										return {
											value: appointment.id,
											label: convertAppointmentToTypeahaeadLabel(appointment),
										}
									},
								)
							}}
							onChange={(selected: TypeaheadOption) => {
								sosDockSchedulerCalendarTab.selectTypeaheadOption(
									selected ? selected.value : null,
								)
							}}
							placeholder={tString('search', tPrefix)}
						/>
					</div>
				</FlexItem>
				<FlexItem fitContent={true}>
					<CalendarInput
						isOpen={state.calendarOpen}
						dateString={state.selectedDate}
						onChangeOpen={sosDockSchedulerCalendarTab.toggleCalendarShowing}
						onChangeText={(date) =>
							sosDockSchedulerCalendarTab.setCalendarDate(date, true)
						}
						readOnly={state.fetchingAppointments}
						className={classes.calendarDateInput}
					/>
				</FlexItem>
				<FlexItem fitContent={true}>
					<DockSchedulerAppointmentFilters
						selectedFlow={state.flowFilter}
						selectedMode={state.modeFilter}
						onUpdateFlow={
							sosDockSchedulerCalendarTab.updateCalendarTabFlowFilter
						}
						onUpdateMode={
							sosDockSchedulerCalendarTab.updateCalendarTabModeFilter
						}
						readOnly={state.fetchingAppointments}
					/>
				</FlexItem>
				<FlexItem>
					<AlignRight>
						<IconButton
							onClick={sosDockSchedulerCalendarTab._fetchCalendarAppointments}
							icon={solidIcons.faSyncAlt}
							spin={state.fetchingAppointments}
							buttonClassName={classes.calendarRefreshButton}
						/>
					</AlignRight>
				</FlexItem>
			</FlexRow>

			<Spacer />

			{hourStarts.length && (
				<div>
					<TimeSlotHeaders hourStarts={hourStarts} />
					<div data-testid='dock-scheduler-calendar-table-body'>
						{l.map(cardRows, (cardRow, rowIdx) => {
							return (
								<div key={rowIdx} className={classes.calendarCardRow}>
									{l.map(cardRow, (card, cardIdx) => {
										if (card.appointmentStatus) {
											return (
												<DockSchedulerCalendarCard
													key={cardIdx}
													cardData={card}
													highlighted={
														card.appointmentId ===
														state.highlightedAppointmentId
													}
												/>
											)
										} else {
											return (
												<VoidTimeSlot
													key={cardIdx}
													relativeWidth={
														card.durationMinutes / minutesPerColumn
													}
												/>
											)
										}
									})}
								</div>
							)
						})}
					</div>
				</div>
			)}
		</div>
	)
}
