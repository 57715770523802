import { sosToast } from 'common/components/toast'
import { DateTime } from 'luxon'
import * as r from 'ramda'
import * as apiLocation from 'ui/api/apiLocation'
import * as apiTypes from 'ui/api/apiTypes'
import { tString } from 'ui/components/i18n/i18n'
import { IAddressState } from 'ui/components/shared/address'
import { createTime } from 'ui/components/shared/ShipmentStopCard/DateTimeDesired'
import { idx } from 'ui/lib'
import { l } from 'ui/lib/lodashImports'
import { IStateStop, StopType } from 'ui/pages/new-quote/state/sosNewQuote'
import { sosUser } from 'ui/state'

export function setDesiredDateTimeChanges(
	requestLocation: apiTypes.LocationResponse,
): Partial<IStateStop> {
	const changes: Partial<IStateStop> = {}
	const dayOfTheWeek = DateTime.local()
		.setLocale('en-US')
		.toLocaleString({ weekday: 'long' })
		.toLowerCase()
	if (idx(() => requestLocation.dockHours[dayOfTheWeek])) {
		const { openingTime, closingTime } = requestLocation.dockHours[dayOfTheWeek]
		if (openingTime && closingTime) {
			changes.dateTimeDesiredState = {
				timeType: 'between',
				date1: DateTime.local().toISODate(),
				time1: createTime(openingTime),
				date2: DateTime.local().toISODate(),
				time2: createTime(closingTime),
			}
		}
	}
	return changes
}

export function setAddressChangesByStopType(
	addressLocationStates: apiTypes.LocationResponse,
	stopType: StopType,
): Partial<IAddressState> {
	let changes: Partial<IAddressState>
	if (stopType === 'delivery') {
		changes = {
			address: l.cloneDeep(
				addressLocationStates?.defaults?.defaultDeliveryAddress?.address,
			),
		}
	} else if (stopType === 'pickup') {
		changes = {
			address: l.cloneDeep(
				addressLocationStates?.defaults?.defaultPickupAddress?.address,
			),
		}
	} else {
		changes = {
			address: l.cloneDeep(
				addressLocationStates?.defaults?.defaultBillingAddress?.address,
			),
		}
	}

	if (!changes.address.company) {
		changes = {
			address: Object.assign({}, changes.address, {
				company: addressLocationStates.name,
			}),
		}
	}

	return changes
}

export function getStopTypeByAddressId(
	stops: IStateStop[],
	addressId: string,
): StopType {
	const stop = r.find((c) => c.addressState.id === addressId, stops)
	return idx(() => stop.stopType)
}

export async function getLocation(
	locationId: string,
	relationshipId?: string,
	alternateApiKey?: string,
): Promise<apiTypes.LocationResponse> {
	if (relationshipId) {
		const locationResponse = await apiLocation.fetchLinkedLocation(
			(rs) => {},
			relationshipId,
			locationId,
			alternateApiKey,
		)

		if (locationResponse.error) {
			sosToast.sendApiErrorResponseToast(
				locationResponse,
				tString('locationFetchError', 'common'),
			)
		} else {
			return locationResponse.data
		}
	} else {
		return sosUser.getLocation(locationId, alternateApiKey)
	}
}

export type DefaultAddressType =
	| 'defaultPickupAddress'
	| 'defaultDeliveryAddress'
	| 'defaultBillingAddress'
