import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'
import classes from './Filters.module.scss'

export const FilterItem: FC = (props: {
	isSelected?: boolean
	children: React.ReactNode
	onClick?: () => void
	testId?: string
	readOnly?: boolean
}) => {
	let className = classes.filterItem
	className = theme.addClassIf(props.isSelected, classes.selected, className)
	className = theme.addClassIf(props.readOnly, classes.disabled, className)

	return (
		<div
			className={className}
			onClick={props.onClick}
			data-testid={props.testId}
		>
			{props.children}
		</div>
	)
}
